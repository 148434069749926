import React from 'react';
import { useTranslation } from 'react-i18next';

const DrivesListHeaderComponent = () => {
    const { t: translate } = useTranslation();

    return (
        <div className='drives-list__header'>
            <div className='drives-list__cell drives-list__cell--sticky'>{translate('ABB.Powertrain.Frontend.drivesDataAssetName')}</div>
            <div className='drives-list__cell'>
                {translate('ABB.Powertrain.Frontend.drivesDataConnectionStatus')}
            </div>
            <div className='drives-list__cell'>
                {translate('ABB.Powertrain.Frontend.drivesDataSignalreceived')}
            </div>
            <div className='drives-list__cell'>
                {translate('ABB.Powertrain.Frontend.drivesDataNodeID')}
            </div>
            <div className='drives-list__cell drives-list__cell--sticky-right'>

            </div>
        </div>
    );
};

export default DrivesListHeaderComponent;
