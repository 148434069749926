
import React from 'react';
import PropTypes from 'prop-types';

import colors from 'theme/_colors.scss';

const IconMemoryFull = ({ width = 16, height = 16, viewBox = '0 0 16 16', color = colors.pomegranate }) => {
    return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fillRule='evenodd' clipRule='evenodd' d='M4.6 2.33333V6.19533C4.59991 6.63651 4.42927 7.05965 4.1255 7.372L3.3 8.222V13H5.55515C5.71997 13.4783 5.94745 13.9262 6.22748 14.3333H2.65C2.47761 14.3333 2.31228 14.2631 2.19038 14.1381C2.06848 14.013 2 13.8435 2 13.6667V7.94667C1.99987 7.76997 2.06813 7.60044 2.1898 7.47533L3.2051 6.43067C3.26585 6.3682 3.29998 6.28357 3.3 6.19533V1.66667C3.3 1.48986 3.36848 1.32029 3.49038 1.19526C3.61228 1.07024 3.77761 1 3.95 1H11.75C11.9224 1 12.0877 1.07024 12.2096 1.19526C12.3315 1.32029 12.4 1.48986 12.4 1.66667V5.97961C11.9875 5.83008 11.5518 5.73084 11.1 5.68908V2.33333H4.6ZM9.14999 3H10.45V5.66667H9.14999V3ZM7.2 3H8.5V5.66667H7.2V3ZM5.25 3H6.55V5.66667H5.25V3ZM7.51007 7.98468C8.33289 7.14076 9.44887 6.66666 10.6125 6.66666C11.7761 6.66666 12.8921 7.14076 13.7149 7.98468C14.5377 8.82859 15 9.97318 15 11.1667C15 12.3601 14.5377 13.5047 13.7149 14.3486C12.8921 15.1926 11.7761 15.6667 10.6125 15.6667C9.44887 15.6667 8.33289 15.1926 7.51007 14.3486C6.68726 13.5047 6.22501 12.3601 6.22501 11.1667C6.22501 9.97318 6.68726 8.82859 7.51007 7.98468ZM9.98572 12.4524V13.7381H11.2393V12.4524H9.98572ZM9.98572 7.95237V11.8095H11.2393V7.95237H9.98572Z' fill={color} />
    </svg>
        ;
};

IconMemoryFull.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
export default IconMemoryFull;
