import React from 'react';
import './style.scss';
import ConnectedAssetsHeader from './ConnectedAssetsHeaderComponent';
import { LoadingIndicator } from 'sharedComponents';
import { apiCallStatus } from 'helpers/constants';
import { translate } from 'helpers/translateHelper';

const ConnectedAssetsComponent = ({ gatewayConnectedAssetsData }) => {
    const { loadingStatus } = gatewayConnectedAssetsData || {};

    if (loadingStatus === apiCallStatus.LOADING) {
        return <LoadingIndicator />;
    }

    if (loadingStatus === apiCallStatus.ERROR) {
        return <div>{translate('ABB.Powertrain.Frontend.errorGatewayConnectedAsset')}</div>;
    }

    return (
        <div className='connected-assets'>
            {loadingStatus === apiCallStatus.SUCCESS &&
                <ConnectedAssetsHeader gatewayConnectedAssetsData={gatewayConnectedAssetsData} />
            }

        </div>
    );
};

export default ConnectedAssetsComponent;
