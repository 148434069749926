import React from 'react';
import PropTypes from 'prop-types';

import colors from 'theme/_colors.scss';

const IconOrganization = ({ width = '12', height = '12', viewBox = '0 0 16 16', color = colors.black }) => {
    return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M15 4V0H11V2H5V0H1V7H5V3H7V15H8H11V16H15V12H11V14H8V9H11V10H15V6H11V8H8V3H11V4H15Z' fill={color} />
    </svg>;
};

IconOrganization.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default IconOrganization;
