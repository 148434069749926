import { CHART_PLUGINS } from 'sharedComponents/Chart/constants';

export const chartPlugins = [CHART_PLUGINS.NO_DATA_TO_DISPLAY, CHART_PLUGINS.HIGHCHARTS_MORE, CHART_PLUGINS.BOOST];

export const LIMIT_LINE_MARKERS = {
    UpperAlarm: 'triangle',
    UpperAlert: 'triangle',
    LowerAlarm: 'triangle-down',
    LowerAlert: 'triangle-down',
};

export const LIMIT_LINES_ORDER = ['Upper alarm', 'Upper alert', 'Lower alert', 'Lower alarm'];
