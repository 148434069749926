import React, { useEffect, useState, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { GeneralRouterTabs, LoadingIndicator } from 'sharedComponents';
import { getTabOptions } from './helpers';
import { PowertrainConfiguration } from './PowertrainConfiguration';
import { SubscriptionStatus } from './SubscriptionStatus';
import { SensorFirmwareManagement } from './SensorFirmwareManagement';
import { UserManagement } from './UserManagement';
import { getModel } from './selectors';
import * as actions from './SubscriptionStatus/actions';
import { AssetManagement } from './AssetManagement';
import UserCapabilityHelper from '../../helpers/userCapabilityHelper';

const GatewayManagement = React.lazy(() => import('./GatewayManagement'));

const PowertrainToolsComponent = (props) => {
    const { t: translate } = useTranslation();
    const location = useLocation();
    const generalFilterAssetIds = props?.model?.generalFilter;
    const capabilities = useSelector(state => state?.overview?.userCapabilities);

    const [tabOptions, setTabOptions] = useState({});
    const [defaultRoute, setDefaultRoute] = useState(null);

    const routeClassName = location.pathname
        .replace(/^\//, '')
        .replace(/\//g, '-')
        || '';

    useEffect(() => {
        const userCapabilities = new UserCapabilityHelper(capabilities);
        const options = getTabOptions(translate);

        if (!userCapabilities?.hasGatewayAdminViewCapability()) {
            delete options.gatewayManagement;
        }
        if (!userCapabilities?.hasCiamViewCapability()) {
            delete options.userManagement;
            delete options.assetManagemnt;
        }
        if (!userCapabilities?.hasGatewayMaintenanceModeCapability()) {
            delete options.sensorFirmwareManagement;
        }

        const firstTab = Object.values(options).find(tab => tab?.route);
        setDefaultRoute(firstTab?.route || tabOptions.powertrainConfiguration.route);

        setTabOptions(options);
    }, [capabilities, translate]);

    useEffect(() => {
        if (generalFilterAssetIds?.length > 0) {
            const assetIds = generalFilterAssetIds.map((item) => item?.id);
            const assetIdParams = { assetIds, type: null, statusCodes: [], maxEndDate: null };
            props.actions.getSubscriptionInfo(assetIdParams);
        }
    }, [generalFilterAssetIds, props.actions]);

    if (!defaultRoute) {
        return <LoadingIndicator />;
    }

    return (
        <div className={`general-page-content ${routeClassName}`}>
            <GeneralRouterTabs
                options={tabOptions}
                variant='primary'
            >
                <Suspense fallback={<LoadingIndicator />}>
                    <Switch>
                        {tabOptions?.powertrainConfiguration?.route &&
                            <Route path={tabOptions.powertrainConfiguration.route}>
                                <PowertrainConfiguration />
                            </Route>
                        }
                        {tabOptions?.subscriptionStatus?.route &&
                            <Route path={tabOptions.subscriptionStatus.route}>
                                <SubscriptionStatus />
                            </Route>
                        }
                        {tabOptions?.userManagement?.route &&
                            <Route path={tabOptions.userManagement.route}>
                                <UserManagement />
                            </Route>
                        }
                        {tabOptions?.assetManagemnt?.route &&
                            <Route path={tabOptions.assetManagemnt.route}>
                                <AssetManagement />
                            </Route>
                        }
                        {tabOptions?.gatewayManagement?.route &&
                            <Route path={tabOptions.gatewayManagement.route}>
                                <GatewayManagement />
                            </Route>
                        }
                        {tabOptions?.sensorFirmwareManagement?.route &&
                            <Route path={tabOptions.sensorFirmwareManagement.route}>
                                <SensorFirmwareManagement />
                            </Route>
                        }
                        <Redirect to={defaultRoute} />
                    </Switch>
                </Suspense>
            </GeneralRouterTabs>
        </div>
    );
};

const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
};

const PowertrainToolsConn = connect(
    mapStateToProps,
    mapDispatchToProps
)(PowertrainToolsComponent);

PowertrainToolsComponent.propTypes = {};

export default PowertrainToolsConn;
