import { CHART_PLUGINS } from 'sharedComponents/Chart/constants';

export const actionTypes = {
    GET_FFT_V2: 'operationalParameters_v2/GET_FFT_V2',
};

export const chartModules = [CHART_PLUGINS.NO_DATA_TO_DISPLAY, CHART_PLUGINS.HIGHCHARTS_MORE, CHART_PLUGINS.BOOST];

export const frequencyMax = {
    'MSR': 12800,
    'CoMo': 820,
    'Gen1': 125,
    'Edwin': 1665
};

export const sensorTypesID = {
    'Motor': 1,
    'Pump': 2,
    'Bearing': 3,
    'CoMo': 5,
    'CoMoLight': 9,
    'CoMoOG': 10,
    'MSR': 6
};

export const PiezoSensor = {
    axisName: 'PiezoFFTJsonData',
    id: 'Piezo'
};

export const sensorTypes = {
    ACC: 'acc',
    PIEZZO: 'piezzo'
};
