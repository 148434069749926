import React from 'react';
import { Container, Row } from 'react-bootstrap';
import map from 'lodash/map';
import { useTranslation } from 'react-i18next';

import { LoadingStatusWrapper } from 'sharedComponents/WithLoadingStatus';

import { Header } from './Header';
import AssetDetailsList from './AssetDetailsList';

import './style.scss';

const AssetDetailsComponent = ({
    asset,
    model: {
        assetDetails: {
            details,
            sections,
            loadingStatus
        },
    }

}) => {
    const { t: translate } = useTranslation();
    return (
        <Container fluid id='asset-details-container'>
            <Row><Header asset={asset} /></Row>

            <div className='asset-details-component'>
                <LoadingStatusWrapper loadingStatus={loadingStatus} >
                    <div className='asset-detail-lists-container'>
                        {details?.length ? <AssetDetailsList title={translate('ABB.Powertrain.Frontend.assetDetailsAssetDataTitle')} data={details} /> : null}
                    </div>
                    <div className='asset-detail-lists-container'>
                        {
                            map(sections, (section) => {
                                return section.data.length ? <AssetDetailsList key={section.sectionName} title={translate(section.label)} data={section.data} /> : null;
                            })
                        }
                    </div>
                </LoadingStatusWrapper>
            </div>
        </Container>
    );
};

export default AssetDetailsComponent;
