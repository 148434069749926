import { getApiActionCreator } from 'helpers/actionHelper';
import { actionTypes } from './constants';

export const triggerDataLoggerCommandsAction = getApiActionCreator(actionTypes.GET_DATA_LOGGER_TRIGGER);
export const subscribeUnsubscribeDataLoggerCommandsAction = getApiActionCreator(actionTypes.GET_DATA_LOGGER_SUBSCRIBE_UNSUBSCRIBE);
export const getDataLoggerCommandsAction = getApiActionCreator(actionTypes.GET_DATA_LOGGER_COMMANDS);

export const triggerDataLoggerCommands = ({ assetId, loggerType, commandId, commandDisplayName }) => {
    return {
        type: actionTypes.GET_DATA_LOGGER_TRIGGER_REQUEST,
        assetId,
        loggerType,
        commandId,
        commandDisplayName
    };
};


export const getDataLoggerCommands = (assetId) => {
    return {
        type: actionTypes.GET_DATA_LOGGER_COMMANDS_REQUEST,
        assetId
    };
};

export const subscribeUnsubscribeDataLoggerCommands = ({ assetId, loggerType, commandId, commandDisplayName }) => {
    return {
        type: actionTypes.GET_DATA_LOGGER_SUBSCRIBE_UNSUBSCRIBE_REQUEST,
        assetId,
        loggerType,
        commandId,
        commandDisplayName
    };
};

export const clearStore = () => {
    return (dispatch) => {
        dispatch({ type: actionTypes.CLEAR_STORE });
    };
};
