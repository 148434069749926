import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    IconAssetOffline,
    IconAssetOnline,
    IconInfo,
    IconPTAsset
} from 'svgIcons/MotionPortalIcons';
import { GeneralDialog } from 'sharedComponents/GeneralDialog';
import { popupTypes } from 'helpers/constants';
import { getTimeSinceDateString } from 'helpers/dateHelper';
import { assetOnlineStatus } from '../../../constants';

const DrivesListRowComponent = ({ gatewayConnectedDriveData }) => {
    const { t: translate } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState(null);

    const handleDiscardAndContinue = () => {
        setShowModal(false);
    };

    const handleInfoClick = (connectedAssetItem) => {
        setSelectedAsset(connectedAssetItem);
        setShowModal(true);
    };

    const renderInfoPopup = (connectedAssetItem) => {
        const assetPropertyItems = connectedAssetItem?.assetProperties || [];
        const modifiedGatewayInfo = [
            {
                propertyKey: translate('ABB.Powertrain.Frontend.assetNameLabel'),
                propertyName: translate('ABB.Powertrain.Frontend.assetNameLabel'),
                propertyValue: connectedAssetItem?.name || '-'
            },
            {
                propertyKey: translate('ABB.Powertrain.Frontend.serialNumberLabel'),
                propertyName: translate('ABB.Powertrain.Frontend.serialNumberLabel'),
                propertyValue: connectedAssetItem?.serialNumber || '-'
            },
            ...assetPropertyItems
        ];

        const pairedData = [];
        for (let i = 0; i < modifiedGatewayInfo?.length; i += 2) {
            pairedData.push(modifiedGatewayInfo?.slice(i, i + 2));
        }

        const renderRow = (pair, idx) =>
            <div className='asset-info__row' key={idx}>
                {pair.map((item, index) =>
                    <div className='asset-info__col' key={index}>
                        <div className='asset-info__label'>
                            {item?.propertyName || item?.propertyKey || ' '}
                        </div>
                        <div className='asset-info__value' title={item?.propertyValue}>
                            {item?.propertyValue || '-'}
                        </div>
                    </div>
                )}
            </div>
            ;

        return <div className='asset-info'>
            <div className='asset-info__primary'>
                {pairedData.map((pair, index) => renderRow(pair, index))}
            </div>
            <div className='asset-info__secondary'>
                <div className='asset-info__row'>
                    <div className='asset-info__col'>
                        <div className='asset-info__label'>{translate('ABB.Powertrain.Frontend.assetInfoOperator')}</div>
                        <div className='asset-info__value' title={connectedAssetItem?.organization?.name}>{connectedAssetItem?.organization?.name || '-'}</div>
                    </div>
                    <div className='asset-info__col'>
                        <div className='asset-info__label'>{translate('ABB.Powertrain.Frontend.countryNameLabel')}</div>
                        <div className='asset-info__value' title={connectedAssetItem?.site?.country}>{connectedAssetItem?.site?.country || '-'}</div>
                    </div>
                </div>
                <div className='asset-info__row'>
                    <div className='asset-info__col'>
                        <div className='asset-info__label'>{translate('ABB.Powertrain.Frontend.assetInfoCustomer')}</div>
                        <div className='asset-info__value' title={connectedAssetItem?.site?.siteName}>{connectedAssetItem?.site?.siteName || '-'}</div>
                    </div>
                    <div className='asset-info__col'>
                        <div className='asset-info__label'>{translate('ABB.Powertrain.Frontend.assetInfoSiteID')}</div>
                        <div className='asset-info__value' title={connectedAssetItem?.site?.siteId}>{connectedAssetItem?.site?.siteId || '-'}</div>
                    </div>
                </div>
            </div>
        </div>;
    };

    if (gatewayConnectedDriveData?.length === 0) {
        return <div className='empty-drive-list'>{translate('ABB.Powertrain.Frontend.emptyDriveAssets')}</div>;
    }

    return (
        <>{gatewayConnectedDriveData?.length > 0 &&
            gatewayConnectedDriveData.map(connectedAssetItem =>
                <div className='drives-list__row' key={connectedAssetItem?.assetId}>
                    <div className='drives-list__cell drives-list__cell--sticky'>
                        <div className='drive-name'>
                            <div className='drive-name__icon'>
                                <IconPTAsset />
                            </div>
                            <div className='drive-name__wrap'>
                                <span className='drive-name__text' title={connectedAssetItem?.name}>{connectedAssetItem?.name || ''}</span>
                                <span className='drive-name__slno' title={connectedAssetItem?.serialNumber}>{connectedAssetItem?.serialNumber || '-'}</span>
                            </div>
                        </div>
                    </div>
                    <div className='drives-list__cell'>
                        <div className='cell-wrap'>
                            {connectedAssetItem?.connectionStatus === assetOnlineStatus.ONLINE ?
                                <IconAssetOnline />
                                :
                                <IconAssetOffline />
                            }
                            <span className='cell-wrap__label'>
                                {connectedAssetItem?.connectionStatus ===
                                    assetOnlineStatus.ONLINE
                                    ? translate(
                                        'ABB.Powertrain.Frontend.gatewayStatusOnlineLabel'
                                    )
                                    : translate(
                                        'ABB.Powertrain.Frontend.gatewayStatusOfflinLabel'
                                    )}
                            </span>
                        </div>
                    </div>
                    <div className='drives-list__cell'>
                        {getTimeSinceDateString(connectedAssetItem?.connectionStatusChangeTimestamp)}
                    </div>
                    <div className='drives-list__cell'>
                        {connectedAssetItem?.nodeId || '-'}
                    </div>
                    <div className='drives-list__cell drives-list__cell--sticky-right'>
                        <button onClick={() => handleInfoClick(connectedAssetItem)}>
                            <IconInfo width={'16'} height={'16'} color={'black'} />
                        </button>
                    </div>
                </div>
            )}
            {selectedAsset && <GeneralDialog
                show={showModal}
                close={() => setShowModal(false)}
                title={translate('ABB.Powertrain.Frontend.modalHeaderAssetInfo')}
                closeButton={true}
                notificationType={popupTypes.ASSET}
                modalClassName={'asset-info__modal'}
                cancelButtonProps={{
                    text: translate('ABB.Powertrain.Frontend.closeBtnLbl'),
                    onClick: handleDiscardAndContinue
                }}
            >
                {renderInfoPopup(selectedAsset)}
            </GeneralDialog>
            }
        </>
    );
};

export default DrivesListRowComponent;
