import React, { useEffect, useState } from 'react';

import colors from 'theme/_colors.scss';
import { CHART_PLUGINS } from 'sharedComponents/Chart/constants';
import { translate } from 'helpers/translateHelper';
import { convertScatterPlotsData } from './helpers';
import { afterChartReDraw, chartLegendItemClick } from '../../../../helpers';
import { renderToStaticMarkup } from 'react-dom/server';
import ScatterTooltip from './ScatterTooltip';
import { RemovableLegend } from 'sharedComponents/ChartComponents/CommonLegend';
import { ChartNoDataText } from 'sharedComponents/ChartComponents';
import { defaultBoostThreshold, ASSET_TYPES_MS } from 'helpers/constants';

export const chartPlugins = [CHART_PLUGINS.HIGHCHARTS_MORE, CHART_PLUGINS.NO_DATA_TO_DISPLAY, CHART_PLUGINS.BOOST];

const useChartConfig = (chartData, removeFn, asset) => {

    const [options, setOptions] = useState({});

    useEffect(() => {
        const scatterData = convertScatterPlotsData(chartData);
        const { xUnit, yUnit, components, allSeriesLength } = scatterData;
        const scatterNames = scatterData ? scatterData?.data?.split(' vs ') : [];
        const xName = scatterNames.length > 1 && asset?.type === ASSET_TYPES_MS.DRIVE ? scatterNames[1] : scatterNames[0];
        const yName = scatterNames.length > 1 && asset?.type === ASSET_TYPES_MS.DRIVE ? scatterNames[0] : scatterNames[1];

        setOptions({
            chart: {
                type: 'scatter',
                className: 'chartstyle',
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                zoomType: 'xy',
                height: '600',
                events: {
                    displayError: function (event) {
                        // https://github.com/highcharts/highcharts/issues/14006
                        if (event.code === 12) {
                            event.preventDefault();
                        }
                    },
                    redraw: function (chartInstance) {
                        afterChartReDraw(chartInstance.target.container.id, removeFn);
                    }
                },
                marginBottom: 350,
                spacingTop: 20,
                marginRight: 20
            },
            boost: {
                useGPUTranslations: false,
                usePreAllocated: true
            },
            title: {
                text: ''
            },
            xAxis: {
                startOnTick: true,
                endOnTick: true,
                title: {
                    enabled: true,
                    text: `${xName} (${xUnit})`,
                },
                showLastLabel: true,
                id: 'xAxis'
            },
            yAxis: {
                title: {
                    enabled: true,
                    text: `${yName} (${yUnit})`,
                },
                id: 'yAxis'
            },
            plotOptions: {
                scatter: {
                    marker: {
                        radius: 3,
                        states: {
                            hover: {
                                enabled: true,
                                lineColor: colors.doveGray
                            }
                        }
                    },
                    states: {
                        hover: {
                            marker: {
                                enabled: false
                            }
                        }
                    },
                    boostThreshold: allSeriesLength > defaultBoostThreshold ? 1 : 0
                },
                series: {
                    events: {
                        legendItemClick: chartLegendItemClick
                    }
                }
            },
            lang: {
                noData: renderToStaticMarkup(<ChartNoDataText
                    title={translate('ABB.Powertrain.Frontend.chartNoMeasurementData')}
                    text={translate('ABB.Powertrain.Frontend.chartNoMeasurementDataUserGuide')} />)
            },
            noData: {
                useHTML: true
            },
            series: components,
            tooltip: {
                useHTML: true,
                outside: true,
                backgroundColor: 'rgba(255, 255, 255, 0.6)',
                shadow: false,
                formatter: function () {
                    return renderToStaticMarkup(
                        <ScatterTooltip
                            component={this.series.userOptions.component}
                            xUnit={xUnit}
                            yUnit={yUnit}
                            xName={xName}
                            yName={yName}
                            x={this.x}
                            y={this.y}
                            date={this.point.date}
                        />);
                }
            },
            legend: {
                useHTML: true,
                layout: 'vertical',
                align: 'left',
                enabled: true,
                floating: true,
                reversed: true,
                verticalAlign: 'top',
                itemDistance: 16,
                itemMarginTop: 10,
                itemStyle: {
                    fontSize: '14px',
                    fontFamily: 'ABBvoice-Regular',
                    letterSpacing: '0',
                    lineHeight: '17px',
                },
                symbolRadius: 0,
                squareSymbol: false,
                symbolHeight: 8,
                symbolWidth: 8,
                y: 300,
                labelFormatter: function () {
                    return renderToStaticMarkup(<RemovableLegend
                        signalSourceName={this.userOptions.component}
                        legend={this}
                        className='cross-asset__legend'
                    />);
                }
            },
            credits: {
                enabled: false
            },
            navigation: {
                buttonOptions: {
                    theme: {
                        states: {
                            hover: {
                                fill: colors.white,
                                stroke: 'none'
                            },
                            select: {
                                fill: colors.white,
                                select: 'none'
                            }
                        }
                    }
                }
            },
            exporting: {
                enabled: false,
            }
        });
    }, [chartData]);

    return options;
};

export default useChartConfig;
