import { actionTypes } from './constants';

const initialState = {
    loggerCommandsList: {
        data: [],
        loadingStatus: null,
        error: null
    },
    triggerLoggerCommands: {
        data: {},
        loadingStatus: null,
        error: null
    },
    subscribeLoggerCommands: {
        data: {},
        loadingStatus: null,
        error: null
    }
};

export default function(state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_DATA_LOGGER_COMMANDS: {
            return {
                ...state,
                loggerCommandsList: {
                    ...state?.loggerCommandsList,
                    data: action?.data,
                    loadingStatus: action?.loadingStatus
                }
            };
        }
        case actionTypes.GET_DATA_LOGGER_TRIGGER: {
            return {
                ...state,
                triggerLoggerCommands: {
                    ...state?.triggerLoggerCommands,
                    data: action?.data,
                    loadingStatus: action?.loadingStatus
                }
            };
        }
        case actionTypes.GET_DATA_LOGGER_SUBSCRIBE_UNSUBSCRIBE: {
            return {
                ...state,
                subscribeLoggerCommands: {
                    ...state?.subscribeLoggerCommands,
                    data: action?.data,
                    loadingStatus: action?.loadingStatus
                }
            };
        }
        case actionTypes.CLEAR_STORE: {
            return {
                ...state,
                loggerCommandsList: {
                    ...state?.loggerCommandsList
                },
                triggerLoggerCommands: {
                    ...initialState.triggerLoggerCommands
                },
                subscribeLoggerCommands: {
                    ...initialState.subscribeLoggerCommands
                }
            };
        }
        default:
            return state;
    }
}
