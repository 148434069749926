import React from 'react';
import PropTypes from 'prop-types';
const IconPTAsset = ({ color = '#1F1F1F' }) => {
    return <svg width='11' height='16' viewBox='0 0 11 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M10.85 8.85L8.5 11.21L6.5 9.21L4.85 10.85L4.15 10.15L6.5 7.79L8.5 9.79L10.15 8.15L10.85 8.85ZM7 14H1V1H7V6L8 7V0H0V14C0 14.2652 0.105357 14.5196 0.292893 14.7071C0.48043 14.8946 0.734784 15 1 15V16H7V15C7.26522 15 7.51957 14.8946 7.70711 14.7071C7.89464 14.5196 8 14.2652 8 14V13L7 12V14ZM4 6.5L5.46 5.16V2.5H2.46V5.16L4 6.5Z' fill={color} />
    </svg>
        ;
};
IconPTAsset.propTypes = {
    color: PropTypes.string
};
export default IconPTAsset;
