import { takeLatest, call, put } from 'redux-saga/effects';
import * as actionTypes from './constants';
import { getChangedParameterListV2Action } from './actions';
import { sortBy } from 'lodash';
import moment from 'moment';
import { apiCallStatus } from 'helpers/constants';
import logger from 'helpers/logger';
import { ParameterFilesApi } from 'api';

function* getChangedParameterListV2Saga({ asset, from, to }) {
    try {
        if (!asset || !from || !to) {
            yield put(getChangedParameterListV2Action(apiCallStatus.SUCCESS, []));
        } else {
            // Let the UI know that data fetching is ongoing
            yield put(getChangedParameterListV2Action(apiCallStatus.LOADING));
            const response = yield call(() => ParameterFilesApi.getChangedParameterFileListV2(asset.id, from, to));
            logger.debug('Changed parameter file list 2.0 loaded successfully with following params:', asset);
            let data = response ? response : [];
            data = sortBy(data, parameter => new moment(parameter.time)).reverse();
            yield put(getChangedParameterListV2Action(apiCallStatus.SUCCESS, data));
        }
    } catch (err) {
        yield put(getChangedParameterListV2Action(apiCallStatus.ERROR, null, err));
    }
}

export function* changedParameterListV2Watchers() {
    yield takeLatest(actionTypes.GET_CHANGED_PARAMETER_LIST_V2_REQUEST, getChangedParameterListV2Saga);
}
