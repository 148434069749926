import PropTypes from 'prop-types';
import React from 'react';

import { LoadingStatusWrapper } from 'sharedComponents/WithLoadingStatus';
import AssetList from './AssetList';

import './style.scss';


const AssetListComponent = ({
    model: {
        assets,
        selectedAsset,
        selectedAssetCapability,
        generalFilterLoadingStatus,
        assetsLoadingStatus,
        //powertrainsLoadingStatus,
        selectedKPIType,
        filters,
        crossAssetAnalysisActiveTab,
        chartData,
        subscription,
        capabilityLoadingStatus
    },
    actions,
    onAssetClick
}) => {
    return <div className='asset-list-container'>
        <LoadingStatusWrapper loadingStatus={[...generalFilterLoadingStatus, assetsLoadingStatus]}>
            <AssetList
                assets={assets}
                selectedAsset={selectedAsset}
                selectedAssetCapability={selectedAssetCapability}
                selectedKPIType={selectedKPIType}
                selectedPowertrain={filters.selectedPowertrain}
                onAssetClick={onAssetClick}
                getKPIsForKPIType={actions.getKPIsForKPIType}
                getTrendKPIsForAssetV2={actions.getTrendKPIsForAssetV2}
                setKpi={actions.setKpi}
                chartData={chartData}
                crossAssetAnalysisActiveTab={crossAssetAnalysisActiveTab}
                subscription={subscription}
                getSubscriptionInfo={actions.getSubscriptionInfo}
                capabilityLoadingStatus={capabilityLoadingStatus}
            />
        </LoadingStatusWrapper>
    </div>;
};

AssetListComponent.propTypes = {
    actions: PropTypes.shape({
        setActiveTabAction: PropTypes.func,
        setSearchboxFilterAction: PropTypes.func,
        setGatewayTypeFilterAction: PropTypes.func,
        setSelectedPowertrainID: PropTypes.func,
        setSelectedAsset: PropTypes.func,
        getKPIsForKPIType: PropTypes.func,
        getSubscriptionInfo: PropTypes.func,
        getTrendKPIsForAssetV2: PropTypes.func,
        setKpi: PropTypes.func,
    }),
    model: PropTypes.shape({
        powertrains: PropTypes.any,
        assets: PropTypes.any,
        selectedAsset: PropTypes.any,
        selectedAssetCapability: PropTypes.any,
        selectedKPIType: PropTypes.any,
        generalFilterLoadingStatus: PropTypes.array,
        assetsLoadingStatus: PropTypes.any,
        powertrainsLoadingStatus: PropTypes.any,
        filters: PropTypes.any,
        crossAssetAnalysisActiveTab: PropTypes.any,
        chartData: PropTypes.any,
        subscription: PropTypes.any,
        capabilityLoadingStatus: PropTypes.any,
    }),
    onAssetClick: PropTypes.func
};

export default AssetListComponent;
