export const actionTypes = {
    GET_GATEWAY_LIST_SETTINGS: 'powertrainTools/gatewayManagement/GET_GATEWAY_LIST_SETTINGS',
    GET_GATEWAY_LIST: 'powertrainTools/gatewayManagement/GET_GATEWAY_LIST',
    GET_GATEWAY_TYPE_SETTINGS: 'powertrainTools/gatewayManagement/GET_GATEWAY_TYPE_SETTINGS',
    GET_GATEWAY_TYPE: 'powertrainTools/gatewayManagement/GET_GATEWAY_TYPE',
    CLEAR_STORE: 'powertrainTools/gatewayManagement/CLEAR_STORE',
};

export const assetOnlineStatus = {
    ONLINE: 'Online',
    OFFLINE: 'Offline'
};
