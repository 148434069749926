import { createSelector } from 'reselect';

const getParameterFileList = ({ parameterBackupV2 }) => {
    return parameterBackupV2 && parameterBackupV2.parameterFileList;
};

const getParameterFileListLoadingStatus = ({ parameterBackupV2 }) => {
    return parameterBackupV2 && parameterBackupV2.parameterFileList && parameterBackupV2.parameterFileList.loadingStatus;
};

const getParameterFileLoadingStatus = ({ parameterBackupV2 }) => {
    return parameterBackupV2 && parameterBackupV2.parameterFile && parameterBackupV2.parameterFile.loadingStatus;
};

const getParameterFile = ({ parameterBackupV2 }) => {
    return parameterBackupV2 && parameterBackupV2.parameterFile;
};

const getSelectedAsset = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails.selectedAsset;
};

export const getModel = createSelector([
    getParameterFileList,
    getParameterFile,
    getSelectedAsset,
    getParameterFileListLoadingStatus,
    getParameterFileLoadingStatus
],
    (
        parameterFileList,
        parameterFile,
        selectedAsset,
        parameterFileListLoadingStatus,
        parameterFileLoadingStatus
    ) => {
        return {
            parameterFileList,
            parameterFile,
            selectedAsset,
            parameterFileListLoadingStatus,
            parameterFileLoadingStatus
        };
    });
