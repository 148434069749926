import {
    getLoadingStatuses,
    getAssetsLoadingStatus,
    getGeneralFilteredPowertrains,
    getMonitoredGeneralFilteredAssets,
    getPowertrainLoadingStatusv2
} from 'helpers/selectors';
import { createSelector } from 'reselect';

const getActiveTab = ({ powertrainAssetOverview }) => {
    return powertrainAssetOverview.activeTab;
};

const getAssetTypeFilter = ({ powertrainAssetOverview }) => {
    return powertrainAssetOverview.assetTypeFilter;
};

const getConditionStatusFilter = ({ powertrainAssetOverview }) => {
    return powertrainAssetOverview.conditionStatusFilter;
};

const getSearchBoxFilter = ({ powertrainAssetOverview }) => {
    return powertrainAssetOverview.searchboxFilter;
};


export const getModel = createSelector(
    [getLoadingStatuses,
        getActiveTab,
        getAssetTypeFilter,
        getConditionStatusFilter,
        getSearchBoxFilter,
        getMonitoredGeneralFilteredAssets,
        getGeneralFilteredPowertrains,
        getAssetsLoadingStatus,
        getPowertrainLoadingStatusv2
    ],
    (
        generalFilterLoadingStatuses,
        activeTab,
        assetTypeFilter,
        conditionStatusFilter,
        searchBoxFilter,
        assets,
        powertrains,
        assetsLoadingStatus,
        powertrainsLoadingStatus
    ) => {
        return {
            generalFilterLoadingStatuses,
            activeTab,
            assetTypeFilter,
            conditionStatusFilter,
            searchBoxFilter,
            assets,
            powertrains,
            assetsLoadingStatus,
            powertrainsLoadingStatus
        };
    }
);
