import endpoints, { formatEndpoint } from '../endpoints';
import { RestManagerWithoutBaseUrl } from '../RestManager';


class Capability {
    static getCapabilities(organizationId, assetIds, capabilityCodes) {
        const endpoint = formatEndpoint(endpoints.capability.getCapabilities);
        return RestManagerWithoutBaseUrl.post(endpoint, { organizationId, assetIds, capabilityCodes });
    }

    static checkCapabilities(capabilityCodes, organizationId) {
        const endpoint = formatEndpoint(endpoints.capability.checkCapabilities);
        return RestManagerWithoutBaseUrl.post(endpoint, { capabilityCodes, organizationId });
    }
}

export default Capability;
