import { routes } from 'routes';

export const getTabOptions = (translate) => {
    const tabsOptions = [
        {
            title: translate('ABB.Powertrain.Frontend.gatewayManagementConnectedAssets'),
            route: routes.GatewayManagementConnectedAssets,
        },
        {
            title: translate('ABB.Powertrain.Frontend.gatewayManagementGatewayInfo'),
            route: routes.GatewayManagementInfo
        }
    ];

    return tabsOptions;
};
