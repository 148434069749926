import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getTimeSinceDateString } from 'helpers/dateHelper';
import { LoadingStatusWrapper, PopOutChart, InfoMessage, GeneralSelect } from 'sharedComponents';
import { useTranslation } from 'react-i18next';
import useChartConfig from './useChartConfig';
import { chartModules } from './constants';
import './style.scss';
import { getSpectrumRangeOptions } from './spectrumRangeOptions';
import {
    LastSyncDate,
    PopOutButton,
    ResetZoomButton,
    withLeftButtonGroup,
    withPopOutRow,
    withRightButtonGroup
} from 'sharedComponents/Chart/headers';


const VibrationFFTComponent = (props) => {

    const { t: translate } = useTranslation();
    const { model } = props;
    const [isZoomed, setIsZoomed] = useState(false);
    const [spectrumRangeSelection, setSpectrumRangeSelection] = useState({});
    const chartConfig = useChartConfig(model.fftData, spectrumRangeSelection);
    const spectrumRangeOptions = getSpectrumRangeOptions(translate, model.speed, model.sensorTypeID, model.fftData);
    const fftData = false;
    const getValue = () => {
        return spectrumRangeOptions.find(option => option.id === spectrumRangeSelection);
    };
    const setZoomBySpectrumRange = (mainChartInst) => (xAxisExtreme) => {
        mainChartInst?.xAxis[0]?.setExtremes(0, xAxisExtreme);
        const xAxisZeroExtremes = mainChartInst?.xAxis[0].getExtremes();
        mainChartInst?.xAxis[1]?.setExtremes(0, xAxisZeroExtremes.max * 60);
        mainChartInst?.yAxis[0]?.setExtremes();
    };
    const zoomOut = (mainChartInst) => {
        mainChartInst.zoomOut();
        const selectionValue = getValue();
        setZoomBySpectrumRange(mainChartInst)(selectionValue ? selectionValue.xAxisZoomValue : spectrumRangeOptions[1].xAxisZoomValue);
    };

    const SpectrumRangeSelector = (props) => {
        const { mainChartInst, setIsZoomed } = props;
        return mainChartInst ? <GeneralSelect
            className='condition-index-filter'
            options={spectrumRangeOptions}
            getOptionLabel={option => option.title}
            getOptionValue={option => option.id}
            value={getValue()}
            defaultValue={spectrumRangeOptions && spectrumRangeOptions.length && spectrumRangeOptions[1]}
            menuShouldScrollIntoView={false}
            onChange={(value) => {
                setZoomBySpectrumRange(mainChartInst)(value.xAxisZoomValue);
                setSpectrumRangeSelection(value.id);
                setIsZoomed(false);
            }}
            isSearchable={false}
        /> : null;
    };

    const MainChartHeader = withPopOutRow([
        withLeftButtonGroup([SpectrumRangeSelector, LastSyncDate]),
        withRightButtonGroup([ResetZoomButton, PopOutButton])
    ]);

    const PopOutChartHeader = withPopOutRow([
        withLeftButtonGroup([LastSyncDate]),
        withRightButtonGroup([ResetZoomButton, PopOutButton])
    ]);

    return <>
        <LoadingStatusWrapper
            useCustomAnchor
            transparent
            indicatorConfig={{ placement: 'sticky' }}
            loadingStatus={[model?.fftDataLoadingStatus]} >
            <PopOutChart
                hasKpi={model?.hasRawData}
                data={fftData}
                renderNoKpi={() => <InfoMessage
                    title='ABB.Powertrain.Frontend.opFFTVibrationNoRawDataTitle'
                    text='ABB.Powertrain.Frontend.opFFTVibrationNoRawDataContent' />}
                lastMeasurementLabel={getTimeSinceDateString(model?.fftData?.timestamp)}
                isZoomed={isZoomed}
                setIsZoomed={setIsZoomed}
                loadingStatus={model?.fftDataLoadingStatus}
                options={chartConfig}
                modules={chartModules}
                container='op-fft-chart'
                labelLastSync={translate('ABB.Powertrain.Frontend.labelLastRawDataSync')}
                zoomOut={zoomOut}
                mainChartHeader={MainChartHeader}
                popOutChartHeader={PopOutChartHeader}
            />
        </LoadingStatusWrapper>
    </>;
};

VibrationFFTComponent.propTypes = {
    actions: PropTypes.shape({
        getDownloadRawData: PropTypes.func
    }),
    handleTabSelection: PropTypes.func,
    model: PropTypes.shape({
        fftData: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array
        ]),
        getFFTDataLoadingStatus: PropTypes.string,
        measurementExportLoadingStatus: PropTypes.string,
        trendDataLoadingStatus: PropTypes.string,
    }),
};

export default VibrationFFTComponent;
