import { reducer as PowertrainConfigurationReducer } from './PowertrainConfiguration/PowertrainConfigurationReducer';
import { reducer as SubscriptionStatusReducer } from './SubscriptionStatus/SubscriptionStatusReducer';
import { reducer as PowertrainWizardReducer } from './PowertrainConfiguration/PowertrainWizard/PowertrainWizardReducer';
import { reducer as GatewayListReducer, gatewayTypeReducer as GatewayTypeReducer } from './GatewayManagement/GatewayManagementReducer';
import { reducer as GatewayInfoReducer, connectedAssetReducer as GatewayConnectedAssetsReducer } from './GatewayManagement/GatewayDetails/GatewayDetailsReducer';


import { combineReducers } from 'redux';
import { reducer as SensorFirmwareManagementReducer } from './SensorFirmwareManagement/SensorFirmwareManagementReducer';

export const reducer = combineReducers({
    configuration: PowertrainConfigurationReducer,
    subscriptionStatus: SubscriptionStatusReducer,
    wizard: PowertrainWizardReducer,
    gateway: GatewayListReducer,
    sensorFirmwareManagement: SensorFirmwareManagementReducer,
    gatewayInformation: GatewayInfoReducer,
    gatewayConnectedAssets: GatewayConnectedAssetsReducer,
    gatewayType: GatewayTypeReducer
});
