import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { filter, map } from 'lodash';
import { Stack } from 'react-bootstrap';

import { GeneralButton } from 'sharedComponents/GeneralButton';
import { apiCallStatus } from 'helpers/constants';
import { loggerCommands } from '../constants';

const TriggerDataLoggerComponent = ({ model, actions, asset }) => {
    const { t: translate } = useTranslation();
    const { specialLoggerCommandsLoadingStatus, triggerLoggerCommands, triggerDataLoggerCommandsLoadingStatus } = model;
    const filteredDataLoggerCommands = filter(triggerLoggerCommands, triggerLoggerCommand => triggerLoggerCommand?.commandType === loggerCommands.TRIGGER);

    const triggerDataLogger = (loggerCommand) => {
        actions?.triggerDataLoggerCommands({
            assetId: asset?.id,
            loggerType: loggerCommand?.loggerType,
            commandId: loggerCommand?.commandId,
            commandDisplayName: loggerCommand?.commandDisplayName
        });
    };

    return (
            map(filteredDataLoggerCommands, (loggerCommand, i) => {
            return (
                <Stack direction='horizontal' key={i}>
                    <div className='data-logger__type py-2'>{loggerCommand?.commandDisplayName}</div>
                    <div className='py-1 d-flex ms-auto'>
                        {
                            loggerCommand?.commandType === loggerCommands.TRIGGER &&
                            <GeneralButton
                                type='normal'
                                text={translate('ABB.Powertrain.Frontend.dataLoggerTriggerLabel')}
                                onClick={() => triggerDataLogger(loggerCommand)}
                                disabled={triggerDataLoggerCommandsLoadingStatus === apiCallStatus.LOADING ||
                                    specialLoggerCommandsLoadingStatus === apiCallStatus.LOADING
                                }
                            />
                        }
                    </div>
                </Stack>
            );
        })
    );
};

TriggerDataLoggerComponent.propTypes = {
    model: PropTypes.object,
    actions: PropTypes.object,
    asset: PropTypes.object
};

export default TriggerDataLoggerComponent;
