import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from './actions';
import NotificationDropdownComponent from './NotificationDropdownComponent';
import { getModel } from './selectors';
import { setShowSwitchOrgModal } from '../../../SwitchOrganizationModal/actions';
import { NOTIFICATION_INTERVAL } from './constants';


const NotificationDropdownContainer = (props) => {
    const dispatch = useDispatch();

    const getNotificationList = () => {
        dispatch(actions.getNotificationListAction());
    };

    useEffect(() => {
        getNotificationList();
        const notificationInterval = setInterval(() => {
            getNotificationList();
        }, NOTIFICATION_INTERVAL);

        return () => {
            clearInterval(notificationInterval);
        };
    }, []);
    return <NotificationDropdownComponent {...props} />;
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, setShowSwitchOrgModal }, dispatch)
    };
};

const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model,
        state
    };
};

const NotificationDropdownContainerConn = connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationDropdownContainer);

export default NotificationDropdownContainerConn;
