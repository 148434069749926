import React from 'react';
import PropTypes from 'prop-types';

const IconSuggestion = ({ width = '14', height = '14', viewBox = '0 0 14 14', color = '#1F1F1F' }) =>
    <svg width={width} height={height} viewBox={viewBox} fill={color} xmlns='http://www.w3.org/2000/svg'>
        <path d='M7.61 12.035V13.018C7.60882 13.1481 7.58202 13.2768 7.53113 13.3966C7.48024 13.5163 7.40625 13.6249 7.31339 13.7161C7.22053 13.8073 7.11062 13.8793 6.98993 13.928C6.86924 13.9767 6.74014 14.0012 6.61 14H3.61C3.058 14 2.61 13.56 2.61 13.018V12.035H7.61ZM7 6.14V7.123H3V6.14H7ZM5 0C2.27 0 0.044 2.151 0 4.834C0.0964491 5.99436 0.466566 7.11533 1.08 8.105L1.2 8.302C2.29 10.07 2.47 10.492 2.49 10.512L2.55 11.052H7.45L7.5 10.611C7.58 10.375 8.33 9.117 8.62 8.646C8.78 8.361 8.91 8.154 8.94 8.086C9.54542 7.09008 9.9085 5.9659 10 4.804C9.94 2.134 7.719 0 5 0ZM8.05 7.624L7.76 8.115C7.13 9.156 6.76 9.716 6.64 10.08H6V8.105H4V10.07H3.37C3.22 9.775 2.89 9.166 2.05 7.79L2 7.645C1.44871 6.80081 1.10596 5.83767 1 4.835C1.049 2.699 2.826 0.993 5 0.993C6.04159 0.975811 7.04837 1.36789 7.804 2.085C8.17397 2.43605 8.47028 2.8573 8.67563 3.32414C8.88098 3.79098 8.99125 4.29406 9 4.804C8.90619 5.80493 8.58097 6.77034 8.05 7.624Z' fill={color} />
    </svg>;

IconSuggestion.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string
};

export default IconSuggestion;

