export const actionTypes = {
    GET_DATA_LOGGER_COMMANDS: 'dataLoggerCommand/GET_DATA_LOGGER_COMMANDS',
    GET_DATA_LOGGER_COMMANDS_REQUEST: 'dataLoggerCommand/GET_DATA_LOGGER_COMMANDS_REQUEST',
    GET_DATA_LOGGER_TRIGGER: 'dataLoggerCommand/GET_DATA_LOGGER_TRIGGER',
    GET_DATA_LOGGER_TRIGGER_REQUEST: 'dataLoggerCommand/GET_DATA_LOGGER_TRIGGER_REQUEST',
    GET_DATA_LOGGER_SUBSCRIBE_UNSUBSCRIBE: 'dataLoggerCommand/GET_DATA_LOGGER_SUBSCRIBE_UNSUBSCRIBE',
    GET_DATA_LOGGER_SUBSCRIBE_UNSUBSCRIBE_REQUEST: 'dataLoggerCommand/GET_DATA_LOGGER_SUBSCRIBE_UNSUBSCRIBE_REQUEST',
    CLEAR_STORE: 'dataLoggerCommand/CLEAR_STORE'
};

export const toggleOptions = {
    SUBSCRIBE: 'subscribe',
    UNSUBSCRIBE: 'unsubscribe'
};

export const loggerCommands = {
    TRIGGER: 'Trigger',
    STARTSTOP: 'StartStop',
    SUBSCRIBEUNSUBSCRIBE: 'SubscribeUnsubscribe'
};

export const toggleTriggerOptions = {
    TRIGGER: 'Trigger',
    STOP: 'Stop',
};

export const commandIdOptions = {
    SUBSCRIBE: 'Subscribe',
    UNSUBSCRIBE: 'Unsubscribe',
    ENABLE: 'Enable',
    DISABLE: 'Disable',
    TRIGGER: 'Trigger'
};

export const unsubscribeDisableName = {
    UNSUBSCRIBE: 'UNSUBSCRIBE',
    DISABLE: 'DISABLE',
};
