import React from 'react';
import colors from 'theme/_colors.scss';


export const StateID = {
    POOR: 3,
    TOLERABLE: 2,
    GOOD: 1,
    UNKNOWN: 0,
    NOT_APPLICABLE: -1
};

export const apiCallStatus = {
    LOADING: 'loading',
    SUCCESS: 'success',
    ERROR: 'error'
};

export const popupTypes = {
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',
    ASSET: 'asset'
};

export const propertyType = {
    Decimal: 'Decimal',
    DateTime: 'DateTime',
    Epochtime: 'Epochtime',
    Boolean: 'Boolean',
    Identifier: 'Identifier',
    String: 'String',
    Double: 'Double'
};

export const kpiTypes = {
    TREND: 1,
    SCATTER: 2,
    HISTOGRAM: 3
};

export const dateSelectorType = {
    CUSTOM: 'custom',
    PREDEFINED: 'predefined'
};

export const measurementUnitType = {
    BASE: 1
};

export const MEASUREMENT_UNIT = {
    METRIC: 1,
    IMPERIAL: 2
};

export const DAY_IN_MS = 1000 * 60 * 60 * 24;

export const dataSources = {
    SMARTSENSOR: 1,
    CMD4: 3,
};


export const ASSET_TYPES = {
    MOTOR: 1,
    PUMP: 2,
    BEARING: 3,
    DRIVE: 4,
    TRANSFORMER: 5,
    GENERATOR: 6,
    AMBIENT: 7,
    ECMDRIVE: 8,
    GENERICMACHINE: 9,
    GEARING: 10,
    WIMON: 11,
    AUTOMATICLUBRICATOR: 12,
    SMARTHMIDEVICE: 13,
    MOVINGPART: 14,
    FAN: 15,
    GEARBOX: 16,
};

export const ASSET_TYPES_MS = {
    MOTOR: 'Motor',
    PUMP: 'Pump',
    BEARING: 'Bearing',
    DRIVE: 'Drive',
    AMBIENT: 'Ambient',
    ECMDRIVE: 'ECMDrive',
    GENERICMACHINE: 'GenericMachine',
    GEARING: 'Gearing',
    WIMON: 'WiMon',
    SMARTHMIDEVICE: 'SmartHMIDevice',
    MOVINGPART: 'MovingPart',
    FAN: 'Fan',
    GEARBOX: 'Gearbox',
};

export const ASSET_TYPES_MS_ID = {
    Motor: 1,
    Pump: 2,
    Bearing: 3,
    Drive: 4,
    Transformer: 5,
    Generator: 6,
    Ambient: 7,
    ECMDrive: 8,
    GenericMachine: 9,
    Gearing: 10,
    WiMon: 11,
    AutomaticClubricator: 12,
    SmartHMIDevice: 13,
    MovingPart: 14,
    Fan: 15,
    Gearbox: 16,
};

export const firstDataColor = colors.jacarta;

export const userRoles = {
    SUPERVIEWER: 1,
    SUPERADMIN: 2,
    NORMAL: 3
};

export const NOTIFICATION_TYPES = {
    ERROR: 'error',
    WARN: 'warning',
    INFO: 'info',
    SUCCESS: 'success'
};

export const NOTIFICATION_BAR = {
    FREE_FOR_RENEWAL: 'Free for renewal',
    FREE: 'Free',
    CONVERTURL: 'https://new.abb.com/contact-centers',
};

export const environments = {
    SMART_SENSOR_DEV_LOCAL: 'Development',
    POWERTRAIN_DEV_LOCAL: 'Powertrain',
    SMART_SENSOR_DEV: 'SsDev',
    POWERTRAIN_DEV: 'PtDev',
    SMART_SENSOR_ALPHA: 'SsTest',
    POWERTRAIN_ALPHA: 'PtTest',
    SMART_SENSOR_BETA: 'SsBeta',
    SMART_SENSOR_BETA_CN: 'SsBetaCN',
    POWERTRAIN_BETA: 'PtBeta',
    POWERTRAIN_BETA_CN: 'PtBetaCN',
    SMART_SENSOR_PROD: 'SsProd',
    SMART_SENSOR_PROD_CN: 'SsProdCN',
    POWERTRAIN_PROD: 'PtProd',
    POWERTRAIN_PROD_CN: 'PtProdCN'
};

export const environmentStages = {
    DEV: 'Development',
    ALPHA: 'Alpha',
    BETA: 'Beta',
    PROD: 'Production'
};


export const unauthenticatedRouteNames = [
    '/',
    '/Login',
    '/signin-oidc',
    '/signout-callback-oidc',
    '/eCOS',
    '/sandbox'
];

export const routesWithInfinitSession = [
    '/symptom-monitoring',
    { ...unauthenticatedRouteNames }
];

export const qualityFlags = {
    OutOfRange: 1,
    OutOfBoundary: 2,
    UnableToCalculate: 3,
    Success: 9
};

export const qualityFlagsTimeSeries = {
    Success: 'Good'
};

export const assetTypes = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    NORMAL: 'normal'
};

export const chartColors = [colors.jacarta, colors.citron, colors.redBerry, colors.mariner, colors.lochinvar, colors.frenchRose];

export const MEASUREMENT_HEALTH_STATUSES = {
    Critical: 'Critical',
    Weak: 'Weak',
    Healthy: 'Healthy',
    Undefined: 'Undefined'
};

export const onlineHelpLinks = {
    [environmentStages.BETA]: 'https://beta.powertrain.abb.com/powertrainonlinehelp-v20/',
    [environmentStages.ALPHA]: 'https://test.powertrain.abb.com/powertrainonlinehelp-v20/',
    [environmentStages.DEV]: 'https://dev.powertrain.abb.com/powertrainonlinehelp-v20/',
    [environmentStages.PROD]: 'https://powertrain.abb.com/powertrainonlinehelp-v20/'
};

export const healthStatuses = {
    HEALTHY: 1,
    WEAK: 2,
    CRITICAL: 3
};

export const filterTabs = {
    POWERTRAINS: 'Powertrains',
    ASSETS: 'Assets'
};

export const contractInfoMessages = {
    activeSubscription: 1,
    noContract: 'ABB.Powertrain.Frontend.NoContractForDrive',
    noSubscription: 'ABB.Powertrain.Frontend.NoSubscription',
    trialPeriodExpired: 'ABB.Powertrain.Frontend.DriveTialPeriodExpired',
    contractExpired: 'ABB.Powertrain.Frontend.DriveContractExpired',
    unMonitored: 'ABB.Powertrain.Frontend.AssetIsUnmonitored',
};

export const CAPABILITIES = {
    level: 'level',
    parameterFileLimit: 'parameterFileLimit',
    stressIndex: 'stressIndex',
    trend: 'trend',
    histogram: 'histogram',
    scatter: 'scatter',
    userLimits: 'userLimits',
    dataHistory: 'dataHistory',
    eventHistory: 'eventHistory',
    remoteAssistance: 'remoteAssistance',
    parameterBackup: 'parameterBackup',
    emailAlertParameterChanged: 'emailAlertParameterChanged',
    comparison: 'assetComparison',
    emailAlertFaultsWarnings: 'emailAlertFaultsWarnings',
    emailAlertDisconnectedDrive: 'emailAlertDisconnectedDrive',
    emailAlertUserLimits: 'emailAlertUserLimits',
    bulkUpload: 'bulkUpload',
    selfGeneratedReport: 'selfGeneratedReport',
    expertReport: 'expertReport',
    selectReportTemplate: 'selectReportTemplate',
    conditionBasedMaintenance: 'conditionBasedMaintenance',
    eventSelfFaultEvaluation: 'eventSelfFaultEvaluation',
    eventTrendSignal: 'eventTrendSignal',
    eventLoggerVisualization: 'eventLoggerVisualization',
    eventLoggerDownload: 'eventLoggerDownload',
    kpiVisibility: 'kpiVisibility',
    eventList: 'eventList',
    changedParameterList: 'changedParameterList',
    specialLogger: 'specialLogger',
    driveState: 'driveState',
    triggerDatalogger1: 'triggerDatalogger1',
    triggerDatalogger2: 'triggerDatalogger2',
    triggerDatalogger3: 'triggerDatalogger3',
    triggerAPBUlogger: 'triggerAPBUlogger',
    triggerParameterBackup: 'triggerParameterBackup',
    conditionIndex: 'conditionIndex',
    editComponent: 'editComponent',
    expertReportUpload: 'expertReportUpload',
    eventListMultipleAssets: 'eventListMultipleAssets',
    assetFavorite: 'WEB_AssetFavorite', //capability not in use
    assetLabel: 'WEB_AssetLabel',
    assetPicture: 'WEB_AssetPicture',
    configureAssetHealth: 'WEB_ConfigureAssetHealth',
    assetMeasurementExport: 'assetMeasurementExport',
    assetGroupMeasurementExport: 'assetGroupMeasurementExport',
    vibrationFFT: 'WEB_TimeBasedAnalytics',
    assetInstantReport: 'assetInstantReport',
    loadMeasurementOnDemand: 'WEB_LoadMeasurementOnDemand',
    collectRawData: 'WEB_LoadRawData',
    deleteReport: 'deleteReport',
    fleetOverviewReportDelete: 'fleetOverviewReportDelete'
};

export const NEWCAPABILITIES = {
    assetLabel: 'assetLabel',
    assetPicture: 'assetPicture',
    editComponent: 'editComponent',
    changedParameterList: 'changedParameterList',
    // the value updated based on the backend team discussion refer to the ticket # 382818
    userLimits: 'assetLimitSettings',
    emailAlertFaultsWarnings: 'notifications',
    emailAlertDisconnectedDrive: 'notifications',
    emailAlertUserLimits: 'notifications',
    emailAlertParameterChanged: 'notifications',
    notificationSetup: 'notificationSetup',
    assetOverviewReport: 'assetOverviewReport',
    assetInstantReport: 'assetInstantReport',
    expertReport: 'expertReport',
    energyReport: 'energyReport',
    expertReportUpload: 'expertReportUpload',
    downloadPdfReport: 'downloadPdfReport',
    deleteReport: 'deleteReport',
    publishReport: 'publishReport',
    seeUnpublishedReports: 'seeUnpublishedReports',
    rejectReport: 'rejectReport',
    conditionOverview: 'conditionOverview',
    operationalParameters: 'operationalParameters',
    eventList: 'eventList',
    eventListMultipleAssets: 'comparison',
    eventSelfFaultEvaluation: 'eventSelfFaultEvaluation',
    histogram: 'histogram',
    trend: 'trend',
    scatter: 'scatter',
    bulkUpload: 'bulkUpload',
    parameterBackup: 'parameterBackup',
    assetMeasurementExport: 'assetMeasurementExport',
    loadMeasurementOnDemand: 'loadMeasurementOnDemand',
    conditionIndex: 'conditionIndex',
    kpiVisibility: 'kpiVisibility',
    comparison: 'comparison',
    collectRawData: 'collectRawData',
    eventLoggerVisualization: 'eventLoggerVisualization',
    eventTrendSignal: 'eventTrendSignal',
    eventLoggerDownload: 'eventLoggerDownload',
    conditionBasedMaintenance: 'conditionBasedMaintenance',
    conditionDetails: 'conditionDetails',
    viewHiddenParameters: 'viewHiddenParameters',
    assetLimitSettings: 'assetLimitSettings',
    notificationSettings: 'notificationSettings',
    trendHistory365days: 'trendHistory365days',
    vibrationFFT: 'vibrationFFT',
    fleetOverviewReportDelete: 'fleetOverviewReportDelete',
    operationalParametersSelectLimit: 'operationalParametersSelectLimit'
};

export const FEATURES = {
    isCBMEnabled: 'isCBMEnabled',
    isReportsEnabled: 'isReportsEnabled',
    isExpertReportUploadEnabled: 'isExpertReportUploadEnabled',
};

export const conditionStatusKeys = {
    GOOD: 'GOOD',
    TOLERABLE: 'TOLERABLE',
    POOR: 'POOR',
    UNKNOWN: 'UNKNOWN'
};

export const statuses = {
    ALL: 'All',
    AVAILABILITY: 'Availability',
    ENVIRONMENT: 'Environment',
    RELIABILITY: 'Reliability',
    STRESS: 'Stress'
};

export const gatewayTypes = {
    ALL: 'All',
    NETA21: 'NETA21',
    DCP: 'DCP',
    EGW02: 'EGW02'
};

// export const gatewayTypeTitles = {
//     ['NETA21']: 'NETA-21',
//     ['DCP']: 'DCP',
//     ['EGW02']: 'EGW-02'
// };

export const allAssetTypes = 'AllAssetTypes';


export const unitSymbols = {
    fahrenheit: '°F'
};

export const defaultLanguage = 'en';

export const supportedLanguageCodes = ['de', 'en', 'es', 'fr', 'it', 'nl', 'pl', 'pt', 'ru', 'ro', 'sv', 'vi', 'zh', 'kor', 'ja', 'zh-hant'];

export const languageLabels = {
    'de': 'German (Deutsch)',
    'en': 'English',
    'es': 'Spanish (Español)',
    'fr': 'French (Français)',
    'it': 'Italian (Italiano)',
    'nl': 'Dutch (Netherlands)',
    'pl': 'Polish (Polski)',
    'pt': 'Portuguese (Português)',
    'ro': 'Romanian (Română)',
    'ru': 'Russian (Pусский)',
    'sv': 'Swedish (Svenska)',
    'zh': 'Chinese (中文)',
    'kor': 'Korean (한국어)',
    'ja': 'Japanese (日本語)',
    'zh-hant': 'Chinese Traditional (繁體中文)',
    'vi': 'Vietnamese (Tiếng Việt)',
};

export const defaultBoostThreshold = 3000;

//The word Maintenance is intentionally mispelled in the keys and values to make it compatible with the mispelling in the backend enum
//The backend has been requested to fix the spelling, when they do this needs to be changed
export const maintenancePriorityIDs = {
    MaintenanceOverdue: 3,
    PlanMaintenance: 2,
    Over1yToMaintenance: 1,
    Unknown: 0,
    Unsupported: -1
};

export const maintenancePriorities = {
    MaintenanceOverdue: 'MaintenanceOverdue',
    PlanMaintenance: 'PlanMaintenance',
    Over1yToMaintenance: 'Over1yToMaintenance',
    Unknown: 'Unknown',
    NotSupported: 'NotSupported'
};

export const maintenanceRecommendation = {
    Bad: {
        maintenanceID: 4,
        iconID: 3,
        translationKey: maintenancePriorities.MaintenanceOverdue
    },
    Tolerable: {
        maintenanceID: 3,
        iconID: 2,
        translationKey: maintenancePriorities.PlanMaintenance
    },
    Good: {
        maintenanceID: 1,
        iconID: 1,
        translationKey: maintenancePriorities.Over1yToMaintenance
    },
    Unknown: {
        maintenanceID: 2,
        iconID: 0,
        translationKey: maintenancePriorities.Unknown
    },
    Unsupported: {
        maintenanceID: 0,
        iconID: -1,
        translationKey: maintenancePriorities.NotSupported
    }
};

export const newConditionIndices = {
    Poor: {
        conditionIndexID: 'Poor',
        iconID: 3,
        translationKey: 'poorConditionIndex'
    },
    Tolerable: {
        conditionIndexID: 'Tolerable',
        iconID: 2,
        translationKey: 'tolerableConditionIndex'
    },
    Good: {
        conditionIndexID: 'Good',
        iconID: 1,
        translationKey: 'goodConditionIndex'
    },
    Unknown: {
        conditionIndexID: 'Unknown',
        iconID: 0,
        translationKey: 'unknownConditionIndex'
    },
    Unsupported: {
        conditionIndexID: 'Unsupported',
        iconID: -1,
        translationKey: 'unsupportedConditionIndex'
    }
};

export const LimitConditions = {
    Alert: {
        conditionIndexID: 'Alert',
        iconID: 2,
        translationKey: 'alertLimit'
    },
    Alarm: {
        conditionIndexID: 'Alarm',
        iconID: 3,
        translationKey: 'alarmLimit'
    },
    Good: {
        conditionIndexID: 'Good',
        iconID: 1,
        translationKey: 'goodLimit'
    },
    Unknown: {
        conditionIndexID: 'Unknown',
        iconID: 0,
        translationKey: 'unknownConditionIndex'
    },
    Unsupported: {
        conditionIndexID: 'Unsupported',
        iconID: -1,
        translationKey: 'unsupportedConditionIndex'
    }
};

export const limitIndications = {
    Alarm: {
        conditionIndexID: 'Alarm',
        iconID: 3
    },
    Alert: {
        conditionIndexID: 'Alert',
        iconID: 2
    },
    Normal: {
        conditionIndexID: 'Normal',
        iconID: 1
    },
    Unknown: {
        conditionIndexID: 'Unknown',
        iconID: 0
    }
};

export const conditionUnknownReason = {
    NotSupported: 'NotSupported',
    NoSubscription: 'NoSubscription',
    NoData: 'NoData'
};

export const customerTypes = {
    Internal: 'Internal',
    External: 'External',
    Unknown: 'Unknown'
};

export const internalDomains = ['abb.com', 'accenture.com', 'capgemini.com'];

export const dataSourceTypes = {
    'SmartSensors': 1,
    'Drives': 3,
    'Merged': 4,
    'CIAM': 4
};

export const commonRequestParams = {
    refreshData: true,
    pageNumber: 1,
    pageSize: 20000
};

const CBMDisclaimerLink = 'https://urldefense.com/v3/__https:/businessapps.abb.com/sites/DrivesIB4/_layouts/15/FIABB.Drives.IB4.SharePoint/UserRequestForm.aspx__;!!OrxsNty6D4my!-HFDooFYyEe7VKWbsaS7ZWj51QhvDXFCy1KwdbU1AdEazKYqCjAFpLBNCweIZJdVE_TfguJATEVPS6Ed3RZr4WN1IwDjXTQ_$';
export const CBMDisclaimerText = <p>Please note that this service is based upon customer content collected by the device and provided to ABB alongside any historical asset maintenance records stored in ABB systems. The Quality of the Customer Content is subject to measurement tolerance and statistical lifetime model calculations and may not address all possible drive failure modes, therefore the service may not fully accurately reflect the actual status of the machine from which the Device collects such Customer Content. The service is to be used as a means of guidance to support and help the user to plan maintenance activities, however any decision taken by Customer based on the information displayed in the Service, is taken at customer’s sole risk. If the service does not display meaningful maintenance information, it is recommended as a first action to check maintenance records stored in ABB systems. Please contact your local ABB or register for ABB drives Installed base management tool user access <a rel='noreferrer' target='_blank' href={CBMDisclaimerLink} >here</a>.</p>;

export const oneYearInDays = 365;

export const comparisonTypes = {
    greaterThan: { label: 'greaterThan', value: 1 },
    lessThan: { label: 'lessThan', value: 2 }
};

export const userCapabilities = {
    conditionOverview: 'conditionOverview',
    publishReport: 'publishReport',
    seeUnpublishedReports: 'seeUnpublishedReports',
    rejectReport: 'rejectReport',
    operationalParameters: 'operationalParameters',
    notificationSettings: 'notificationSettings',
    gatewayAdminView: 'gatewayAdminView',
    fleetOverviewReportDelete: 'fleetOverviewReportDelete',
    operationalParametersLimitStatus: 'operationalParametersLimitStatus',
    conditionOverviewSubscriptionFilter: 'conditionOverviewSubscriptionFilter',
    ciamView: 'ciamView',
    operationalParametersMinMax: 'operationalParametersMinMax',
    gatewayMaintenanceMode: 'gatewayMaintenanceMode',
    copyOrganizationNotificationSettings: 'copyOrganizationNotificationSettings',
    multiChartView: 'multiChartView',
    fftChart: 'fftChart',
    fftChartLegacy: 'fftChartLegacy',
    parameterBackupView: 'parameterBackupView',
    parameterBackupLegacyView: 'parameterBackupLegacyView',
    changedParameterListView: 'changedParameterListView',
    changedParameterListLegacyView: 'changedParameterListLegacyView'
};

// NOTIFICATION TYPE CODES COPIED FROM WIKI PAGE
// https://dev.azure.com/BUDrivesOrg/ABB_MOSE_SmartSolutions/_wiki/wikis/Powertrain%20Micro%20Service%20Architecture/5321/Notification?anchor=notifications-in-detail
// NEED TO CHECK WITH RESPONSE AND CHANGE ACCORDINGLY
export const NotificationTypeCode = {
    //Group: Event
    Fault: 'Fault',
    Warning: 'Warning',
    //Group: Condition
    // AssetConditionChangedAlarmTrend: 'AssetConditionChangedAlarmTrend',
    // AssetConditionChangedAlertTrend: 'AssetConditionChangedAlertTrend',
    // AssetConditionChangedAlertThreshold: 'AssetConditionChangedAlertThreshold',
    // AssetConditionChangedAlarmThreshold: 'AssetConditionChangedAlarmThreshold',
    AssetConditionChangedAlarmShortTermTrend: 'AssetConditionChangedAlarmShortTermTrend',
    AssetConditionChangedAlarmLongTermTrend: 'AssetConditionChangedAlarmLongTermTrend',
    AssetConditionChangedAlertShortTermTrend: 'AssetConditionChangedAlertShortTermTrend',
    AssetConditionChangedAlertLongTermTrend: 'AssetConditionChangedAlertLongTermTrend',
    AssetConditionChangedAlertThresholdCrossing: 'AssetConditionChangedAlertThresholdCrossing',
    AssetConditionChangedAlarmThresholdCrossing: 'AssetConditionChangedAlarmThresholdCrossing',
    AssetConditionChangedAlert: 'AssetConditionChangedAlert',
    AssetConditionChangedAlarm: 'AssetConditionChangedAlarm',
    //Group: Limits
    AssetLimitReachedAlarm: 'AssetLimitReachedAlarm',
    AssetLimitReachedAlert: 'AssetLimitReachedAlert',
    AssetLimitAlarmReturnToNormalOperation: 'AssetLimitAlarmReturnToNormalOperation',
    AssetLimitAlertReturnToNormalOperation: 'AssetLimitAlertReturnToNormalOperation',
    //Group: Connectivity
    AssetDisconnected: 'AssetDisconnected',
    GatewayDisconnected: 'GatewayDisconnected',
    //Group: SensorVitality
    SensorSignalBad: 'SensorSignalBad',
    SensorBatteryLow: 'SensorBatteryLow',
    SensorMemoryFull: 'SensorMemoryFull',
    SensorNewFirmwareAvailable: 'SensorNewFirmwareAvailable',
    //Group: Maintenance
    ConditionBasedMaintenanceScheduleDue: 'ConditionBasedMaintenanceScheduleDue',
    PreventiveMaintenanceKitScheduleDue: 'PreventiveMaintenanceKitScheduleDue',
    ManualMaintenanceScheduleDue: 'ManualMaintenanceScheduleDue',
    //Group: Configuration
    ParameterChanged: 'ParameterChanged',
    AssetNameplatePropertyChanged: 'AssetNameplatePropertyChanged',
    LoadMeasurementProcessed: 'LoadMeasurementProcessed',
    //Group: UserAction
    TimeseriesDownloadCompleted: 'TimeseriesDownloadCompleted',
    TimeseriesDownloadFailed: 'TimeseriesDownloadFailed',
    TimeseriesDownloadNodata: 'TimeseriesDownloadNodata',
    ReportGenerationCompleted: 'ReportGenerationCompleted',
    LoadMeasurementRequestCompleted: 'LoadMeasurementRequestCompleted',
    ParameterBackupRequestCompleted: 'ParameterBackupRequestCompleted',
    DataloggerRequestCompleted: 'DataloggerRequestCompleted',
    BlackboxLoggerRequestCompleted: 'BlackboxLoggerRequestCompleted',
    InstallationReportRequestCompleted: 'InstallationReportRequestCompleted',
    //Group: Commissioning
    AssetCommissioned: 'AssetCommissioned',
    AssetDecommissionedL: 'AssetDecommissioned',
    SensorReplaced: 'SensorReplaced',
    //Group: Subscription
    AssetSubscriptionExpired: 'AssetSubscriptionExpired'
};
