import React from 'react';
import PropTypes from 'prop-types';
import ConditionMonitoringAssetListItem from './ConditionMonitoringAssetListItem';
import { ConditionIconsRow } from '../ConditionIconsRow';
import { getAssetIcon } from 'helpers/icons';
import map from 'lodash/map';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { mapAssetStatuses } from 'helpers/assetStatusHelper';

const ConditionMonitoringAssetList = ({
    assets,
    powertrainId,
    onAssetSelection }) => {
    const { t: translate } = useTranslation();
    return <ul className='condition-monitoring-asset-list'>
        {assets?.length ? map(assets, (asset, assetKey) =>
            asset &&
            < ConditionMonitoringAssetListItem
                key={assetKey}
                assetName={asset?.componentName}
                assetIcon={getAssetIcon(asset?.componentType?.typeName, { width: 20, height: 20 })}
                conditionIconsRow={ConditionIconsRow(mapAssetStatuses(asset.statuses))}
                onAssetSelection={() => onAssetSelection(asset?.componentID, powertrainId)}
            />
        ) : <span className='no-information'>{translate('ABB.Powertrain.Frontend.condMonitoringOverviewNoInformation')}</span>
        }
    </ul >;
};

ConditionMonitoringAssetList.propTypes = {
    assets: PropTypes.array,
    onAssetSelection: PropTypes.func
};

export default ConditionMonitoringAssetList;
