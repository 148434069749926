import { basePathWithoutSlash } from 'helpers/formatters';
import { WebStorageStateStore } from 'oidc-client-ts';

/**
 * Contains app global configuration
 */
const config = {
    apiUrl: `${process.env['APIUrl']}/pt-api`,
    apiUrlWithoutBase: `${process.env['APIUrl']}`,
    apiUrlWithSmartSensor: `${process.env['APIUrl']}/smartsensor`,
    apiUrlWithCMD: `${process.env['APIUrl']}/cmd`,
    tokenExpirationLimit: 1000 * 60 * 5,
    tokenRefreshPeriodInSeconds: 1000 * 6,
    dataSourcesType: {
        CommonSensor: 1,
        RCM4: 3
    },
    userSessionLength: 1000 * 60 * 60 * 24
};

export const oidcConfiguration = {
    'redirect_uri': `${window.location.origin}${process.env.SPABasePath}/signin-oidc`,
    'post_logout_redirect_uri': `${window.location.origin}${process.env.SPABasePath}/signout-callback-oidc`,
    'response_type': 'code',
    'scope': 'profile myabb openid',
    'userStore': new WebStorageStateStore({ prefix: `${basePathWithoutSlash()}/authenticated_`, store: window.localStorage }), //TODO for localstorageHelper
    'max_age': 60 * 60 * 24,
};

export default config;
