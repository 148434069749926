import React, { useEffect } from 'react';
import SubscriptionStatusComponent from './SubscriptionStatusComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { LoadingStatusWrapper } from 'sharedComponents/WithLoadingStatus';
import { getModel } from './selectors';
import * as actions from './actions';

import './style.scss';
import { setSelectedNotification } from '../../PowertrainOverview/Header/NotificationDropdown/actions';

const SubscriptionStatusContainer = (props) => {

	useEffect(() => {
		return () => {
			props.actions.clearStore();
		};
	}, []);

	return <LoadingStatusWrapper
		loadingStatus={
			props.model.generalFilterLoadingStatuses
		}>
		<SubscriptionStatusComponent {...props} />
	</LoadingStatusWrapper>;
};


const mapStateToProps = (state) => {
	const model = getModel(state);
	return {
		model
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators({ ...actions, setSelectedNotification }, dispatch)
	};
};

const SubscriptionStatusContainerConn = connect(
	mapStateToProps,
	mapDispatchToProps
)(SubscriptionStatusContainer);

export default SubscriptionStatusContainerConn;
