import { actionTypes } from './constants';
import { AssetDetailsApi } from 'api';
import { apiCallStatus } from 'helpers/constants';
import { getApiActionCreator } from 'helpers/actionHelper';
import { getSelectedAsset } from '../selectors';

const getFFTAction = getApiActionCreator(actionTypes.GET_FFT_V2);

export const getFFTV2Data = () => {
    return (dispatch, getState) => {
        const state = getState();
        const selectedAsset = getSelectedAsset(state);

        dispatch(getFFTAction(apiCallStatus.LOADING));

        AssetDetailsApi.getFFTV2Data(selectedAsset.id)
            .then((data) => {
                dispatch(getFFTAction(apiCallStatus.SUCCESS, data));
            })
            .catch((err) => {
                dispatch(getFFTAction(apiCallStatus.ERROR, null, err));
            });
    };
};

