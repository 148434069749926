import { baseToCustomUOM, getMeasurementUnits, getMeasurementUnitSymbol } from 'helpers/unitHelper';

export const handleUnitConversion = (response) => {
    const userMeasurementUnitGroups = getMeasurementUnits();
    response?.timeseries?.map((ts) => {
        const timeseriesUnitGroupID = ts?.timeseriesUnitGroupID;
        const unit = getMeasurementUnitSymbol(timeseriesUnitGroupID);
        const minValueObj = baseToCustomUOM?.convertAndFormatWithGroups(timeseriesUnitGroupID, ts?.minValue, userMeasurementUnitGroups);
        const maxValueObj = baseToCustomUOM?.convertAndFormatWithGroups(timeseriesUnitGroupID, ts?.maxValue, userMeasurementUnitGroups);
        ts.limits?.map((lmt) => {
            const valueObj = baseToCustomUOM?.convertAndFormatWithGroups(
                timeseriesUnitGroupID,
                lmt?.limitValue,
                userMeasurementUnitGroups);
            lmt.limitValue = valueObj?.measurementValue % 1 === 0 ? valueObj?.measurementValue : valueObj?.measurementValue?.toFixed(2);
        });
        ts.unit = unit;
        ts.minValue = minValueObj?.measurementValue % 1 === 0 ? minValueObj?.measurementValue : minValueObj?.measurementValue?.toFixed(2);
        ts.maxValue = maxValueObj?.measurementValue % 1 === 0 ? maxValueObj?.measurementValue : maxValueObj?.measurementValue?.toFixed(2);
    });

    return response;
};
