import React from 'react';
import AssetManagementComponent from './AssetManagementComponent';
import { connect } from 'react-redux';
import { LoadingStatusWrapper } from 'sharedComponents/WithLoadingStatus';
import { getModel } from './selectors';

import './style.scss';

const AssetManagementContainer = (props) => {

    return <LoadingStatusWrapper
        loadingStatus={
            props.model.generalFilterLoadingStatuses
        }>
        <AssetManagementComponent {...props} />
    </LoadingStatusWrapper>;
};


const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};


const AssetManagementContainerConn = connect(
    mapStateToProps,
)(AssetManagementContainer);

export default AssetManagementContainerConn;
