import React from 'react';
import { map, round } from 'lodash';
import Tippy from '@tippy.js/react';
import { IconInfo } from 'svgIcons/MotionPortalIcons';
import { useTranslation } from 'react-i18next';
import { sensorFeatureKeys } from '../../constants';
import {
    formatSensorFeatureValue,
    getOperatingModeTypeLabel,
    getRequestStatusText,
    getResponseStatusText,
    getColumns,
} from '../helpers';
import { ITEMS_PER_PAGE } from '../constants';
import { Battery, DegradedModeBattery, GeneralDialog, GeneralTable } from 'sharedComponents';
import { sortingDirections } from 'sharedComponents/GeneralTable';
import colors from 'theme/_colors.scss';


const SensorFeaturesList = ({ visibleFeatures, show, setshow, isFirmwareUpdateEnforced }) => {
    const { t: translate } = useTranslation();
    const toggleShow = () => {
        setshow(false);
    };
    const getValueForFeatureKey = (feature) => {
        switch (feature.featureKeyName) {
            case sensorFeatureKeys.BatteryPercentage:
                return feature.featureValue ? <Battery value={feature.featureValue} /> : '-';
            case sensorFeatureKeys.PowerSavingMode:
                return feature.featureValue ?
                    translate('ABB.Powertrain.Frontend.powerSavingModeEnabled') :
                    translate('ABB.Powertrain.Frontend.powerSavingModeDisabled');
            case sensorFeatureKeys.OperatingMode:
                return translate(getOperatingModeTypeLabel(feature.featureValue));
            case sensorFeatureKeys.RawDataCollectionRequest:
            case sensorFeatureKeys.OnDemandDataCollectionRequest:
                return getRequestStatusText(feature.featureValue, translate);
            case sensorFeatureKeys.SensorRSSIValue:
                return feature.featureValue && feature.featureValue.rssiValue ?
                    <div className='notification-type-RSSI'>
                        <Tippy
                            content={translate('ABB.Powertrain.Frontend.sensorFeatureRSSIValueDescription')}
                            interactive={true}
                            interactiveBorder={10}
                            animation='scale'
                            theme='light-border'
                            trigger='click'
                            placement='left'>
                            <div className='info-icon'>
                                <IconInfo width={'14px'} height={'14px'} color={'black'} />
                                {feature.featureValue.rssiValue + ' dBm'}
                            </div>
                        </Tippy>
                    </div> : '-';
            case sensorFeatureKeys.BatteryMode:
                return <div className='notification-type-degraded-mode'>
                    <Tippy
                        content={<>
                            <b>{translate('ABB.Powertrain.Frontend.sensorFeaturesdegradedModeDescriptionTitle' + feature.featureValue)}</b>
                            <div>{translate('ABB.Powertrain.Frontend.sensorFeaturesdegradedModeDescriptionContent' + feature.featureValue)}</div>
                        </>}
                        interactive={true}
                        interactiveBorder={10}
                        animation='scale'
                        theme='light-border'
                        trigger='click'
                        placement='left'>
                        <div className='info-icon'>
                            <IconInfo width={'14px'} height={'14px'} />
                            {feature?.featureValue && <DegradedModeBattery value={feature.featureValue} />}
                        </div>
                    </Tippy>
                </div>;
            case sensorFeatureKeys.RawDataCollectionResponseMessage:
            case sensorFeatureKeys.OnDemandDataCollectionResponseMessage:
                return getResponseStatusText(feature.featureValue, translate);

            case sensorFeatureKeys.BatteryLevel: {
                const roundedBatteryLevel = round(feature.featureValue);
                return roundedBatteryLevel || roundedBatteryLevel === 0 ? `${roundedBatteryLevel} %` : '-';
            }

            case sensorFeatureKeys.MsrDetails:
                return <div>
                    <button className='view-details' onClick={() => setshow(true)}>{translate('ABB.Powertrain.Frontend.MsrViewDetails')}</button>
                    {show && <GeneralDialog
                        show={show}
                        close={toggleShow}
                        title={translate('ABB.Powertrain.Frontend.MsrCommissioningDetails')}
                        closeButton={true}
                    >
                        <GeneralTable
                            className='msr-details-table'
                            columns={getColumns(translate)}
                            data={feature?.featureValueObject?.ChannelMappings}
                            paginationProps={{ pageSize: ITEMS_PER_PAGE, hideOnSinglePage: true }}
                            initialSortingProps={{
                                direction: sortingDirections.DESC
                            }} />
                    </GeneralDialog>}
                </div>;
            case sensorFeatureKeys.FirmwareVersion:
                return <div>
                    {feature.featureValue}
                    {isFirmwareUpdateEnforced ?
                        <div>
                            <div className='error-message-firmware-update'>
                                <Tippy
                                    content={translate('ABB.Powertrain.Frontend.sensorDetailsFirmwareUpdateDescriptionText')}
                                    interactive={true}
                                    interactiveBorder={10}
                                    animation='scale'
                                    theme='light-border'
                                    trigger='click'
                                    placement='bottom'
                                >
                                    <div className='info-icon'>
                                        <IconInfo width={'14px'} height={'14px'} color={colors.torchRed} />
                                    </div>
                                </Tippy>
                                <div className='error-message-text'>{translate('ABB.Powertrain.Frontend.sensorDetailsFirmwareUpdateText')}</div>
                            </div>
                        </div>
                        : null}
                </div>;
            default:
                return formatSensorFeatureValue(feature, translate);
        }
    };

    return map(visibleFeatures, (el, index) => {
        return (
            <div key={index} className='sensor-properties-list-item'>
                <div className='data-label'>{translate(`ABB.Powertrain.Frontend.sensorFeature_${el.featureKeyName}`) || el.featureDisplayName}</div>
                <div className='data-value'>{getValueForFeatureKey(el)}</div>
            </div>
        );
    });
};

export default SensorFeaturesList;
