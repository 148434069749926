import { createSelector } from 'reselect';

const getAssetOrganizationID = ({ generalFilter }) => {
	return generalFilter?.selectedFilters?.organizations[0]?.organizationID;
};

const getSuggestedLabels = ({ assetDetailsInfoHeader }) => {
	return assetDetailsInfoHeader.suggestedLabels.data;
};

const getSuggestedLoadingStatus = ({ assetDetailsInfoHeader }) => {
	return assetDetailsInfoHeader.suggestedLabels.loadingStatus;
};

const getAssetLabels = ({ assetDetailsInfoHeader }) => {
	return assetDetailsInfoHeader.labels.data;
};

const getAssetLabelsLoadingStatus = ({ assetDetailsInfoHeader }) => {
	return assetDetailsInfoHeader.labels.loadingStatus;
};

const getAddLabelsLoadingStatus = ({ assetDetailsInfoHeader }) => {
	return assetDetailsInfoHeader.addLabels.loadingStatus;
};

const getRemoveLabelsLoadingStatus = ({ assetDetailsInfoHeader }) => {
	return assetDetailsInfoHeader.removeLabels.loadingStatus;
};

export const getModel = createSelector([
	getAssetOrganizationID,
	getSuggestedLabels,
	getSuggestedLoadingStatus,
	getAssetLabels,
	getAssetLabelsLoadingStatus,
	getAddLabelsLoadingStatus,
	getRemoveLabelsLoadingStatus
], (
	organizationID,
	suggestedLabels,
	suggestedLoadingStatus,
	assetLabels,
	assetLabelsLoadingStatus,
	addLabelsLoadingStatus,
	removeLabelsLoadingStatus
) => {
	return {
		organizationID,
		suggestedLabels,
		suggestedLoadingStatus,
		assetLabels,
		assetLabelsLoadingStatus,
		addLabelsLoadingStatus,
		removeLabelsLoadingStatus
	};
});
