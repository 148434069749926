export const actionTypes = {
    SET_ACTIVE_TAB: 'operationalParametersV2/SET_ACTIVE_TAB',
    SET_DATE_SELECTION: 'overview_v2/SET_DATE_SELECTION',
    SET_SELECTED_ASSET: 'powertrainAssetDetails/SET_SELECTED_ASSET',
    LOAD_MEASUREMENT: 'operationalParameters_v2/LOAD_MEASUREMENT',
    COLLECT_RAW_DATA: 'operationalParameters_v2/COLLECT_RAW_DATA',
    SET_ACTIVE_TREND_TAB: 'operationalParameters/SET_ACTIVE_TREND_TAB',
    SET_MULTICHART_CONFIG: 'operationalParameters_v2/SET_MULTICHART_CONFIG',
    GET_MULTI_TREND_DATA: 'operationalParameters_v2/GET_MULTI_TREND_DATA',
    CLEAR_MULTICHART_DATA: 'operationalParameters_v2/CLEAR_MULTICHART_DATA',
    CLEAR_UNMONITORED_CHART_DATA: 'operationalParameters_v2/CLEAR_UNMONITORED_CHART_DATA',
};

export const opButtons = {
    trends: 'Trends',
    scatterPlot: 'ScatterPlot',
    histogram: 'Histogram',
    vibrationFFT: 'VibrationFFT',
    vibrationFFTV2: 'VibrationFFT V2'
};

export const multiChartOptions = {
    SINGLE: 'single',
    MULTI: 'multi'
};

export const multiChartToggleOptions = [{
    label: 'ABB.Powertrain.Frontend.operationalParametersSingleGraph',
    value: multiChartOptions.SINGLE
},
{
    label: 'ABB.Powertrain.Frontend.operationalParametersMultiGraph',
    value: multiChartOptions.MULTI
}];

export const tabContainerIds = {
    [opButtons.trends]: 'trend-container',
    [opButtons.scatterPlot]: 'scatter-container',
    [opButtons.histogram]: 'histogram-container',
    [opButtons.vibrationFFT]: 'vibration-fft-container',
    [opButtons.vibrationFFTV2]: 'vibration-fft-container'
};
