import { cloneDeep } from 'lodash';
import { opLimitsConstants } from './constants';

export const checkMaxLimitSelected = (options, selectedLimits) => {
    return options.map((gl) => {
        const optionSelected = selectedLimits?.find((sl) => sl?.id === gl?.id);
        if (!optionSelected && selectedLimits?.length === opLimitsConstants.MAX_SELECTED_LIMITS) {
            return { ...gl, isDisabled: true };
        } else {
            return { ...gl, isDisabled: false };
        }
    });
};

// Maintain limits betweeen signals, assets and organizations.
export const setMaintainedLimitsData = (state, { data, asset, selectedSignal, selectedOrganization }) => {
    const getMaintainSelectedLimits = cloneDeep(state.maintainSelectedLimits);
    const assetId = asset?.id, organizationID = selectedOrganization?.organizationID, timeseriesKey = selectedSignal?.timeseriesKey;
    // Verify all ID's are available
    if (getMaintainSelectedLimits && assetId && organizationID && timeseriesKey) {
        if (getMaintainSelectedLimits[organizationID]) {
            if (getMaintainSelectedLimits[organizationID][assetId]) {
                getMaintainSelectedLimits[organizationID][assetId][timeseriesKey] = data;
            } else {
                getMaintainSelectedLimits[organizationID][assetId] = {
                    [timeseriesKey]: data
                };
            }
        } else {
            getMaintainSelectedLimits[organizationID] = {
                [assetId]: {
                    [timeseriesKey]: data
                }
            };
        }
    }
    return getMaintainSelectedLimits;
};

// Get Maintained limits betweeen signals, assets and organizations.
const getMaintainedLimitsData = (maintainSelectedLimits, asset, selectedSignal, selectedOrganization) => {
    const assetId = asset?.id, organizationID = selectedOrganization?.organizationID, timeseriesKey = selectedSignal?.timeseriesKey;
    if (maintainSelectedLimits && assetId && organizationID && timeseriesKey) {
        if (maintainSelectedLimits[organizationID] && maintainSelectedLimits[organizationID][assetId] && maintainSelectedLimits[organizationID][assetId][timeseriesKey]) {
            return maintainSelectedLimits[organizationID][assetId][timeseriesKey];
        }
    }
    return null;
};

// Choosing maintained limits or default selected limits
export const getSelectedLimitValues = (maintainSelectedLimits, selectedAsset, selectedSignal, selectedOrganization, selectedLimits) => {
    const maintainedlimit = getMaintainedLimitsData(maintainSelectedLimits, selectedAsset, selectedSignal, selectedOrganization);
    return maintainedlimit ? maintainedlimit : selectedLimits || [];
};
