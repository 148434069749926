import { createSelector } from 'reselect';


const getAssetGroupOverviewList = ({ conditionIndices }) => {
    return conditionIndices?.assetGroups?.data;
};

const getAssetGroupOverviewLoadingStatus = ({ conditionIndices }) => {
    return conditionIndices?.assetGroups?.loadingStatus;
};

const getPageNumber = ({ conditionIndices }) => {
    return conditionIndices?.assetGroups?.pageNumber;
};

const getHasMore = ({ conditionIndices }) => {
    return conditionIndices?.assetGroups?.hasMore;
};
const getShowThreshold = ({ conditionIndices }) => {
    return conditionIndices?.assetGroups?.showThreshold;
};
const getShowTrend = ({ conditionIndices }) => {
    return conditionIndices?.assetGroups?.showTrend;
};

export const getModel = createSelector(
    [
        getAssetGroupOverviewList,
        getAssetGroupOverviewLoadingStatus,
        getPageNumber,
        getShowThreshold,
        getShowTrend,
        getHasMore
    ],
    (
        assetGroupOverviewList,
        assetGroupOverviewLoadingStatus,
        pageNumber,
        showThreshold,
        showTrend,
        hasMore
    ) => {
        return {
            assetGroupOverviewList,
            assetGroupOverviewLoadingStatus,
            pageNumber,
            showThreshold,
            showTrend,
            hasMore
        };
    }
);
