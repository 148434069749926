import React, { useState, Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { SearchBox, LoadingIndicator, RouterTabs } from 'sharedComponents';
import ConnectedAssetsDrives from './ConnectedAssetsDrives/ConnectedAssetsDrivesComponent';
import ConnectedAssetsEnvironmentSensors from './ConnectedAssetsEnvironmentSensors/ConnectedAssetsEnvironmentSensorsComponent';
import { getTabOptions, MAX_SEARCH_LENGTH } from './helpers';
import { assetTypes, gatewayRouteURL } from './constants';

const history = createBrowserHistory();

const ConnectedAssetsHeaderComponent = ({ gatewayConnectedAssetsData }) => {
    const { t: translate } = useTranslation();
    const [searchValue, setSearchValue] = useState('');
    const [tabOptions, setTabOptions] = useState(getTabOptions(translate));
    const [driveAssets, setDriveAssets] = useState([]);
    const [otherAssets, setOtherAssets] = useState([]);
    const [isSearchBoxAvailable, setIsSearchBoxAvailable] = useState(true);

    useEffect(() => {
        if (gatewayConnectedAssetsData?.gatewayConnectedAssets?.gatewayAssets &&
            gatewayConnectedAssetsData?.gatewayConnectedAssets?.gatewayAssets.length > 0) {
            const gatewayConnectedAssetsCounts = gatewayConnectedAssetsData?.gatewayConnectedAssets?.gatewayAssets?.reduce(
                (acc, asset) => {
                    if (asset.assetType === assetTypes.DRIVE) {
                        acc.drives += 1;
                    } else {
                        acc.environmentSensors += 1;
                    }
                    return acc;
                },
                { drives: 0, environmentSensors: 0 }
            );
            const getModifiedTabOptions = getTabOptions(translate);
            getModifiedTabOptions[0].title = `${getModifiedTabOptions[0].title} (${gatewayConnectedAssetsCounts.drives})`;
            getModifiedTabOptions[1].title = `${getModifiedTabOptions[1].title} (${gatewayConnectedAssetsCounts.environmentSensors})`;
            setTabOptions(getModifiedTabOptions);
            gatewayConnectedAssetsCounts?.drives === 0 ? setIsSearchBoxAvailable(false) : setIsSearchBoxAvailable(true);

        }
    }, [translate, gatewayConnectedAssetsData?.gatewayConnectedAssets?.gatewayAssets]);

    useEffect(() => {
        if (gatewayConnectedAssetsData?.gatewayConnectedAssets?.gatewayAssets &&
            gatewayConnectedAssetsData?.gatewayConnectedAssets?.gatewayAssets?.length > 0) {
            const gatewayAssetsData = gatewayConnectedAssetsData?.gatewayConnectedAssets?.gatewayAssets;
            const drives = gatewayAssetsData.filter(asset => asset.assetType === assetTypes.DRIVE);
            const others = gatewayAssetsData.filter(asset => asset.assetType !== assetTypes.DRIVE);
            setDriveAssets(drives);
            setOtherAssets(others);
        }
    }, [gatewayConnectedAssetsData?.gatewayConnectedAssets?.gatewayAssets]);

    const getBasePath = () => {
        const match = location?.pathname?.match(new RegExp(`^(\\/[^/]+)?\\/${gatewayRouteURL}\\/`));
        return match?.[1] || '';
    };

    const basePath = getBasePath();

    const handleTabChange = (route) => {
        if (tabOptions[0].route === route) {
            driveAssets.length === 0 ? setIsSearchBoxAvailable(false) : setIsSearchBoxAvailable(true);
        } else {
            otherAssets.length === 0 ? setIsSearchBoxAvailable(false) : setIsSearchBoxAvailable(true);
        }
    };

    const handleChangeSearch = (value) => {
        if (value.length <= MAX_SEARCH_LENGTH) {
            setSearchValue(value);
        }
    };

    return (
        <div className='connected-assets__header'>
            <div className='header__search-wrapper'>
                {isSearchBoxAvailable &&
                    <SearchBox
                        className='search-box'
                        placeholder={translate('ABB.Powertrain.Frontend.gatewayManagementSearch')}
                        onChange={handleChangeSearch}
                        value={searchValue}
                    />
                }
            </div>
            <div className='header__tab-wrapper'>
                <Router history={history}>
                    <RouterTabs
                        tabs={tabOptions}
                        variant='toggle'
                        defaultTab={tabOptions[0].route}
                        onChange={handleTabChange}
                        getBasePath={basePath}
                    >
                        <Suspense fallback={<LoadingIndicator />}>
                            <Switch>
                                <Route path={`${basePath}${tabOptions[0].route}`}>
                                    <ConnectedAssetsDrives gatewayConnectedDriveData={driveAssets} searchQuery={searchValue} />
                                </Route>
                                <Route path={`${basePath}${tabOptions[1].route}`}>
                                    <ConnectedAssetsEnvironmentSensors gatewayConnectedEnvironmentSensorData={otherAssets} searchQuery={searchValue} />
                                </Route>
                                <Redirect from='*' to={`${basePath}${tabOptions[0].route}`} />
                            </Switch>
                        </Suspense>
                    </RouterTabs>
                </Router>
            </div>
        </div>
    );
};

export default ConnectedAssetsHeaderComponent;
