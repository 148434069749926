import { createSelector } from 'reselect';

const getLimitKPI = ({ limitConfig }) => {
    return limitConfig.limitKPI || [];
};

const getTimeSeriesList = ({ limitConfig }) => {
    return limitConfig.timeSeries || [];
};

const getCurrentTimeseries = ({ limitConfig }) => {
    return limitConfig.currentTimeSeries || [];
};

const getShowSuggestLimitModal = ({ limitConfig }) => {
    return limitConfig.showSuggestLimitModal;
};

const getSelectedTimeSeriesForSuggestion = ({ limitConfig }) => {
    return limitConfig.selectedTimeSeriesForSuggestion;
};

const getSelectedTimeSeriesSuggestionData = ({ limitConfig }) => {
    return limitConfig.suggestedLimit;
};

const getSelectedTrendKPIs = ({ operationalParametersV2 }) => {
    return operationalParametersV2?.preselectedKPIs?.single?.trend;
};

const getClickedKpiConfig = ({ operationalParametersV2 }) => {
    return operationalParametersV2?.clickedKpiConfig;
};

const getExpandedSignals = ({ limitConfig }) => {
    return limitConfig.expandedSignals;
};

const getDefaultLimts = ({ limitConfig }) => {
    return limitConfig?.defaultLimit?.data;
};

const getDefaultLimtsLoading = ({ limitConfig }) => {
    return limitConfig?.defaultLimit?.isLoading;
};

export const getModel = createSelector([
    getLimitKPI,
    getCurrentTimeseries,
    getTimeSeriesList,
    getShowSuggestLimitModal,
    getSelectedTimeSeriesForSuggestion,
    getSelectedTimeSeriesSuggestionData,
    getSelectedTrendKPIs,
    getClickedKpiConfig,
    getExpandedSignals,
    getDefaultLimts,
    getDefaultLimtsLoading
], (
    limitKPI,
    currentTimeSeries,
    timeSeries,
    showSuggestLimitModal,
    selectedTimeSeriesForSuggestion,
    suggestedLimit,
    selectedTrendKPIs,
    clickedKpiConfig,
    expandedSignals,
    defaultLimits,
    defaultLimtsLoading
) => {
    return {
        limitKPI,
        currentTimeSeries,
        timeSeries,
        showSuggestLimitModal,
        selectedTimeSeriesForSuggestion,
        suggestedLimit,
        selectedTrendKPIs,
        clickedKpiConfig,
        expandedSignals,
        defaultLimits,
        defaultLimtsLoading
    };
});
