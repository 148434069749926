import { actionTypes } from './constants';
import { getApiActionCreator } from 'helpers/actionHelper';

export const getGatewayListAction = getApiActionCreator(actionTypes.GET_GATEWAY_LIST_SETTINGS);

export const getGatewayList = (organizationID, siteId = [], filter = null) => {
    const payload = {
        organizationID,
        siteId
    };
    if (filter?.keyword) {
        payload.keyword = filter.keyword;
    }

    if (filter?.gatewayType) {
        payload.gatewayType = filter.gatewayType;
    }

    return {
        type: actionTypes.GET_GATEWAY_LIST,
        payload
    };
};

export const getGatewayTypeAction = getApiActionCreator(actionTypes.GET_GATEWAY_TYPE_SETTINGS);

export const getGatewayType = () => {
    return {
        type: actionTypes.GET_GATEWAY_TYPE
    };
};

export const clearStore = () => {
    return (dispatch) => {
        dispatch({ type: actionTypes.CLEAR_STORE });
    };
};
