import { EventLogsApi } from 'api';
import { apiCallStatus } from 'helpers/constants';
import logger from 'helpers/logger';
import * as actionTypes from '../constants';
import { getDateInterval, formatEventListRequest } from './helpers';
import { NOTIFICATION_TYPES } from 'sharedComponents/GeneralNotification/constants';
import { notify } from 'sharedComponents/GeneralNotification';
import { getApiActionCreator } from 'helpers/actionHelper';


const getEventLogsAction = getApiActionCreator(actionTypes.GET_EVENT_LOGS);
const closeEventLogAction = getApiActionCreator(actionTypes.CLOSE_EVENT_LOG);

export const getEventLogs = (assets, filters, truncatedText) => {
    return (dispatch) => {
        dispatch(getEventLogsAction(apiCallStatus.LOADING));
        const dateSelection = getDateInterval(filters.dateSelection, filters.dateKind);
        EventLogsApi.getEventList(formatEventListRequest(assets, dateSelection, filters)).then((eventLogs) => {
            dispatch(getEventLogsAction(apiCallStatus.SUCCESS, eventLogs));
            if (eventLogs?.nextCursor) {
                notify(truncatedText, NOTIFICATION_TYPES.WARN, true);
            }
            logger.debug('Event logs successfully loaded');
        }, (err) => {
            dispatch(getEventLogsAction(apiCallStatus.ERROR, null, err));
        });
    };
};

export const closeEventLog = (event, closingReasonCode, comment) => {
    return (dispatch) => {
        dispatch(closeEventLogAction(apiCallStatus.LOADING));
        EventLogsApi.postEventComment({
            eventIds: [event.eventId],
            closingReasonCode: closingReasonCode?.id,
            comment
        })
            .then(() => {
                dispatch(closeEventLogAction(apiCallStatus.SUCCESS));
            }).catch((err) => {
                dispatch(closeEventLogAction(apiCallStatus.ERROR, null, err));
            });
    };
};
