import { actionTypes } from './constants';
import { AssetDetailsApi } from 'api';
import { apiCallStatus } from 'helpers/constants';
import { getApiActionCreator } from 'helpers/actionHelper';
import { getSelectedAsset } from '../selectors';
import { getLegacyIdFromAsset, getAssetDataSourceType } from 'helpers/assetAndPowertrainFilterHelper';

const getFFTAction = getApiActionCreator(actionTypes.GET_FFT);

export const getFFTData = () => {
    return (dispatch, getState) => {
        const state = getState();
        const selectedAsset = getSelectedAsset(state);
        const assetID = getLegacyIdFromAsset(selectedAsset);
        const dataSource = getAssetDataSourceType(selectedAsset);

        dispatch(getFFTAction(apiCallStatus.LOADING));

        AssetDetailsApi.getFFTData(assetID, dataSource)
            .then((data) => {
                dispatch(getFFTAction(apiCallStatus.SUCCESS, data));
            })
            .catch((err) => {
                dispatch(getFFTAction(apiCallStatus.ERROR, null, err));
            });
    };
};

