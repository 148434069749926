import { PlotApi, TimeSeriesApi } from 'api/';
import { getApiActionCreator } from 'helpers/actionHelper';
import { apiCallStatus, kpiTypes } from 'helpers/constants';
import { commonActionTypes } from './constants';
import { actionTypes } from './OperationalParametersV2/SingleChartView/Trend/constants';

const trendKpiAction = getApiActionCreator(commonActionTypes.GET_ASSET_TREND_KPIS);
const trendKpiActionV2 = getApiActionCreator(commonActionTypes.GET_ASSET_TREND_KPIS_V2);
const trendKpiActionSelecton = getApiActionCreator(actionTypes.GET_TREND_KPI_LIST_SELECTION);
export const getTrendKPIsForAsset = (asset) => {
    return (dispatch) => {
        dispatch(trendKpiAction(apiCallStatus.LOADING));
        return PlotApi.getMeasurementTypes(asset, kpiTypes.TREND)
            .then((data) => {
                dispatch(trendKpiAction(apiCallStatus.SUCCESS, data));
                return data;
            })
            .catch((err) => {
                dispatch(trendKpiAction(apiCallStatus.ERROR, null, err));
            });
    };
};

export const getTrendKPIsForAssetV2 = (asset) => {
    return (dispatch, getState) => {
        const trendCheck = getState().operationalParametersV2?.charts?.single?.trend;
        if (trendCheck?.length && trendCheck[0]?.kpiList?.length) {
            dispatch(trendKpiActionSelecton(apiCallStatus.SUCCESS, []));
        }
        dispatch(trendKpiActionV2(apiCallStatus.LOADING));
        return TimeSeriesApi.getMeasurementTypes(asset?.id)
            .then((data) => {
                dispatch(trendKpiActionV2(apiCallStatus.SUCCESS, data));
                return data;
            })
            .catch((err) => {
                dispatch(trendKpiActionV2(apiCallStatus.ERROR, null, err));
            });
    };
};
