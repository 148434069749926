import PropTypes from 'prop-types';
import React, { useEffect, useRef, useCallback } from 'react';
import { filter } from 'lodash';
import { useLocation } from 'react-router-dom';
import { TrendChart } from '../../../CommonCharts/Trend';
import {
    EnableUniqueScaling,
    LastSyncDate,
    PopOutButton,
    ResetZoomButton,
    withLeftButtonGroup,
    withPopOutRow,
    withRightButtonGroup,
    DownloadAll
} from 'sharedComponents/Chart/headers';
import { getFlatAvailableKPIList } from '../../../../commonTrendHelpers';
import './style.scss';
import { LimitSelector } from '../LimitSelector';
import { getSelectedLimitValues } from '../LimitSelector/helper';

const TrendChartComponent = ({
    asset: { isMonitored },
    model: {
        selectedTrendKPIs,
        userLimits,
        enableUniqueScaling,
        lastMeasurementTimeStampLabel,
        availableTrendKPIs,
        selectedTrendsData: {
            data,
            loadingStatus,
            zoom: { isZoomed },
            chartID
        },
        dateSelection,
        selectedAsset,
        selectedLimits,
        maintainSelectedLimits,
        trendKPIsList,
        selectedOrganization
    },
    actions: {
        reloadAllSelectedTrendKpiData,
        setIsZoomed,
        zoomInToSelectedTrendKpiData,
        removeSelectedKpiLegend,
        clearSelectedKpi,
        resetSelectedKpi
    } }) => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const assetId = searchParams.get('assetId');

    const prevAssetIdRef = useRef(selectedAsset);
    const isInitialRender = useRef(true);

    const selectedSignal = trendKPIsList?.length && trendKPIsList?.find((lmt) => lmt?.timeseriesKey === selectedTrendKPIs[0]);
    const selectedLimitValues = getSelectedLimitValues(maintainSelectedLimits, selectedAsset, selectedSignal, selectedOrganization, selectedLimits);

    const optimizedResetSelectedKpi = useCallback(() => {
        resetSelectedKpi();
    }, [resetSelectedKpi]);

    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
            prevAssetIdRef.current = selectedAsset;
            return;
        }

        if (prevAssetIdRef.current?.componentType?.typeName !== selectedAsset?.componentType?.typeName) {
            optimizedResetSelectedKpi();
            prevAssetIdRef.current = selectedAsset;
        }
    }, [assetId, optimizedResetSelectedKpi, selectedAsset]);

    const filteredSelectedTrendsData = filter(data, (item) =>
        selectedTrendKPIs?.includes(item?.kpiIdentifier)
    );

    const zoomInServer = (availableTrendKPIs, selectedTrendKPIs, from, to) => {
        const trendKPIList = getFlatAvailableKPIList(availableTrendKPIs);
        const filteredArray = selectedTrendKPIs?.map((kpi) => {
            return trendKPIList[kpi];
        });
        zoomInToSelectedTrendKpiData(filteredArray, from, to);
    };

    const resetServerZoom = () => {
        reloadAllSelectedTrendKpiData(selectedTrendKPIs);
    };

    useEffect(() => {
        if (!isMonitored) {
            clearSelectedKpi();
        }
    }, [isMonitored]);

    const CustomHeader = withPopOutRow([
        withLeftButtonGroup([LastSyncDate, EnableUniqueScaling]),
        withRightButtonGroup([LimitSelector, ResetZoomButton, DownloadAll, PopOutButton])]);

    return (
        <TrendChart
            selectedTrendKPIs={selectedTrendKPIs}
            selectedTrendsData={filteredSelectedTrendsData}
            removeSelectedKpiLegend={removeSelectedKpiLegend}
            trendDataLoadingStatus={loadingStatus}
            userLimits={userLimits}
            enableUniqueScaling={enableUniqueScaling}
            lastMeasurementTimeStampLabel={lastMeasurementTimeStampLabel}
            isZoomed={isZoomed}
            availableTrendKPIs={availableTrendKPIs}
            chartKey={chartID}
            reloadAllSelectedTrendKpiData={resetServerZoom}
            zoomInToSelectedTrendKpiData={zoomInServer}
            setIsZoomed={setIsZoomed}
            mainChartHeader={CustomHeader}
            dateSelection={dateSelection}
            selectedLimits={selectedLimitValues}
        />
    );
};

TrendChartComponent.propTypes = {
    actions: PropTypes.shape({
        reloadAllSelectedTrendKpiData: PropTypes.func,
        setIsZoomed: PropTypes.func,
        zoomInToSelectedTrendKpiData: PropTypes.func,
        resetSelectedKpi: PropTypes.func
    }),
    model: PropTypes.shape({
        selectedTrendKPIs: PropTypes.array,
        enableUniqueScaling: PropTypes.bool,
        lastMeasurementTimeStampLabel: PropTypes.string,
        selectedTrendsData: PropTypes.shape({
            loadingStatus: PropTypes.string,
            data: PropTypes.array,
            zoom: PropTypes.shape({
                isZoomed: PropTypes.bool,
            }),
            chartID: PropTypes.string
        }),
        userLimits: PropTypes.array,
    })
};

export default TrendChartComponent;
