import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import { DisclaimerBanner, GeneralDialog, LoadingIndicator, LoadingStatusWrapper } from 'sharedComponents';

import { apiCallStatus } from 'helpers/constants';
import { loggerCommands } from './constants';
import { TriggerDataLogger } from './TriggerDataLogger';
import { BlackboxLogger } from './BlackboxLogger';
import { SpecialLogger } from './SpecialLogger';
import './style.scss';


const DataLoggerComponent = ({ model, asset, show, close }) => {
    const { t: translate } = useTranslation();
    const { triggerLoggerCommands,
            specialLoggerCommands,
            dataLoggerCommandsLodingStatus,
            subscribeWarningTextKey,
            amcosWarningTextKey,
            triggerDataLoggerCommandsLoadingStatus,
            specialLoggerCommandsLoadingStatus
        } = model;

    const listofDataLoggerCommands = [...triggerLoggerCommands || [], ...specialLoggerCommands || []];

    const renderDataLoggerCommands = () => {
        if (dataLoggerCommandsLodingStatus === apiCallStatus.LOADING) {
            return <LoadingIndicator />;
        }

        if (dataLoggerCommandsLodingStatus === apiCallStatus.SUCCESS) {
            const hasSubscribeUnsubscribeCommands = listofDataLoggerCommands.some(cmd =>
                cmd.commandType === loggerCommands.SUBSCRIBEUNSUBSCRIBE
            );
            const hasTriggerCommands = listofDataLoggerCommands.some(cmd =>
                cmd.commandType === loggerCommands.TRIGGER ||
                cmd.commandType === loggerCommands.STARTSTOP
            );
            return (
                <Container className='data-logger-modal-container g-0'>
                    {
                        hasSubscribeUnsubscribeCommands && <>
                            <div className='data-logger__sub-heading mb-2'>{`${translate('ABB.Powertrain.Frontend.dataLoggerSubscribeTitle')}`}</div>
                            { subscribeWarningTextKey && <DisclaimerBanner className='data-logger__disclaimer_text' text={translate(`ABB.Powertrain.Frontend.${subscribeWarningTextKey}`)}/> }
                            <div className='data-logger__list-container'>
                                <LoadingStatusWrapper
                                    indicatorConfig={{ size: 'small', style: { 'padding': '8px 0px' } }}
                                    transparent
                                    loadingStatus={[triggerDataLoggerCommandsLoadingStatus, specialLoggerCommandsLoadingStatus]}
                                >
                                    <SpecialLogger asset={asset} />
                                </LoadingStatusWrapper>
                            </div>
                        </>
                    }
                    { hasSubscribeUnsubscribeCommands && hasTriggerCommands && <hr className='data-logger__type-separator' /> }
                    {
                        hasTriggerCommands && <>
                            <div className='data-logger__sub-heading'>{`${translate('ABB.Powertrain.Frontend.dataLoggerTriggerTitle')}`}</div>
                            { amcosWarningTextKey && <DisclaimerBanner className='data-logger__disclaimer_text' text={translate(`ABB.Powertrain.Frontend.${amcosWarningTextKey}`)}/> }
                            <div className='data-logger__list-container'>
                                <LoadingStatusWrapper
                                    indicatorConfig={{ size: 'small', style: { 'padding': '8px 0px' } }}
                                    transparent
                                    loadingStatus={[triggerDataLoggerCommandsLoadingStatus, specialLoggerCommandsLoadingStatus]}
                                >
                                    <TriggerDataLogger asset={asset} />
                                    <BlackboxLogger asset={asset} />
                                </LoadingStatusWrapper>
                            </div>
                        </>
                    }
                </Container>
            );
        }
    };

    return <GeneralDialog
        modalClassName='data-logger-modal'
        show={show}
        close={close}
        title={`${translate('ABB.Powertrain.Frontend.dataLoggerTitle')}`}
        closeButton={true}
        acceptButtonProps={{
            text: translate('ABB.Powertrain.Frontend.closeBtnLbl'),
            onClick: () => {close();}
        }}
    >
        {renderDataLoggerCommands()}
    </GeneralDialog>;
};

DataLoggerComponent.propTypes = {
    model: PropTypes.object,
    actions: PropTypes.object,
    asset: PropTypes.object,
    show: PropTypes.bool,
    close: PropTypes.func
};

export default DataLoggerComponent;
