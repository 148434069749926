import endpoints, { formatEndpoint } from '../endpoints';
import { RestManagerWithoutBaseUrl } from '../RestManager';
class GatewayManagementApi {
    static getGatewayList(organizationID) {
        const endpoint = formatEndpoint(endpoints.gatewayManagement.getGatewayList);
        return RestManagerWithoutBaseUrl.post(endpoint, organizationID);
    }

    static getGatewayType() {
        const endpoint = formatEndpoint(endpoints.gatewayManagement.getGatewayType);
        return RestManagerWithoutBaseUrl.get(endpoint);
    }

    static getGatewayDetails(gatewayID) {
        const endpoint = formatEndpoint(endpoints.gatewayManagement.getGatewayDetails, { gatewayID });
        return RestManagerWithoutBaseUrl.get(endpoint);
    }

    static getGatewayConnectAssets(gatewayID) {
        const endpoint = formatEndpoint(endpoints.gatewayManagement.getGatewayConnectedAssets, { gatewayID });
        return RestManagerWithoutBaseUrl.get(endpoint);
    }
}
export default GatewayManagementApi;
