import { matchPath } from 'react-router';
import { createSelector } from 'reselect';
import { generalFilterIsVisibleOn } from './constants';

const getErrorType = ({ overview }) => {
    return overview && overview.errorType ? overview.errorType : null;
};

const getUserManager = ({ portal }) => {
    return portal.userManager;
};

const getCurrentPath = ({ router }) => {
    return router?.location?.pathname;
};

const getIsVisibleOnRoute = createSelector([getCurrentPath], (currentPath) =>
    !!matchPath(currentPath, { path: generalFilterIsVisibleOn })
);

const getSelectedFilters = ({ generalFilter }) => {
    return generalFilter.selectedFilters;
};

export const getModel = createSelector([
    getErrorType,
    getUserManager,
    getIsVisibleOnRoute,
    getSelectedFilters
], (
    errorType,
    userManager,
    isGeneralFilterVisibleOnRoute,
    selectedFilters
) => {
    return {
        errorType,
        userManager,
        isGeneralFilterVisibleOnRoute,
        selectedFilters
    };
});
