import React from 'react';
import PropTypes from 'prop-types';

const IconDisconnected = ({ width = '16px', height = '16px', viewBox = '0 0 16 16', color = 'none' }) =>
    <svg width={width} height={height} viewBox={viewBox} fill={color} xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_38650_45366)'>
            <path d='M4.66604 6.66653L9.33271 11.3332L6.93271 13.7332C6.39472 14.2584 5.69293 14.5833 4.94439 14.6536C4.19585 14.724 3.44581 14.5356 2.81938 14.1199L1.80604 15.1399L0.859375 14.1932L1.87937 13.1799C1.46397 12.5517 1.27643 11.8001 1.348 11.0504C1.41958 10.3006 1.74595 9.59811 2.27271 9.05986L4.66604 6.66653ZM14.1927 0.859863L15.1394 1.80653L14.126 2.8132C14.5384 3.44048 14.7235 4.19 14.6508 4.93712C14.578 5.68424 14.2517 6.38394 13.726 6.91986L11.9994 8.66653L11.3327 7.99986L9.99938 9.3332L9.05938 8.3932L10.3927 7.05986L8.93937 5.60653L7.60604 6.93986L6.66604 5.99986L7.99938 4.66653L7.33271 3.99986L9.06604 2.24653C9.60636 1.72018 10.311 1.39526 11.0621 1.32609C11.8132 1.25692 12.5654 1.4477 13.1927 1.86653L14.1927 0.859863Z' fill='black' fillOpacity='0.4' />
        </g>
        <defs>
            <clipPath id='clip0_38650_45366'>
                <rect width='16' height='16' fill='white' />
            </clipPath>
        </defs>
    </svg>;

IconDisconnected.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fill: PropTypes.string,
    viewBox: PropTypes.string,
};

export default IconDisconnected;
