import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getModel } from '../selectors';
import * as actions from '../actions';
import BlackboxLoggerComponent from './BlackboxLoggerComponent';

const BlackboxLoggerContainer = (props) => {
	return <BlackboxLoggerComponent {...props}/>;
};

const mapStateToProps = (state) => {
	const model = getModel(state);
	return { model };
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
};

const BlackboxLoggerContainerConn = connect(
	mapStateToProps,
	mapDispatchToProps
)(BlackboxLoggerContainer);

export default BlackboxLoggerContainerConn;
