import { routes } from 'routes';
import { ENVIRONMENTS } from './constants';

export const getTabOptions = (translate) => {
    const tabsOptions = {
        powertrainConfiguration: {
            title: translate('ABB.Powertrain.Frontend.ptToolsPowertrainConfigurationTab'),
            route: routes.ToolsPowertrainConfiguration
        },
        subscriptionStatus: {
            title: translate('ABB.Powertrain.Frontend.ptToolsSubscriptionStatusTab'),
            route: routes.ToolsSubscriptionStatus,
        },
        gatewayManagement: {
            title: translate('ABB.Powertrain.Frontend.ptToolsGatewayManagementTab'),
            route: routes.ToolsGatewayManagement,
        },
        userManagement: {
            title: translate('ABB.Powertrain.Frontend.ptToolsUserManagementTab'),
            route: routes.ToolsUserManagement,
        },
        assetManagemnt: {
            title: translate('ABB.Powertrain.Frontend.ptToolsAssetManagementTab'),
            route: routes.ToolsAssetManagement,
        },
        sensorFirmwareManagement: {
            title: translate('ABB.Powertrain.Frontend.ptToolsSensorFirmwareManagementTab'),
            route: routes.ToolsSensorFirmwareManagement,
        }
    };
    return tabsOptions;
};

export const getCIAMEnviroment = (environmentStageSelector) => {
    if (!environmentStageSelector || typeof environmentStageSelector !== 'string') {
        return ENVIRONMENTS.PROD;
    }

    let hostname = '';

    try {
        hostname = new URL(environmentStageSelector).hostname || '';
    } catch {
        return ENVIRONMENTS.PROD;
    }

    hostname = hostname?.toLowerCase();

    if (hostname?.includes(ENVIRONMENTS.STAGE)) {
        return ENVIRONMENTS.STAGE;
    } else if (hostname?.includes(ENVIRONMENTS.TEST)) {
        return ENVIRONMENTS.TEST;
    } else if (hostname?.includes(ENVIRONMENTS.TRIAL)) {
        return ENVIRONMENTS.TRIAL;
    } else if (hostname?.includes(ENVIRONMENTS.DEV)) {
        return ENVIRONMENTS.DEV;
    } else {
        return ENVIRONMENTS.PROD;
    }
};
