import map from 'lodash/map';
import forEach from 'lodash/forEach';

import { EventLogsApi } from 'api';
import { apiCallStatus } from 'helpers/constants';
import * as constants from './constants';
import logger from 'helpers/logger';
import { getApiActionCreator } from 'helpers/actionHelper';


const addSelectedLoggerSignalsAction = getApiActionCreator(constants.ADD_SELECTED_LOGGER_SIGNAL);

const removeSelectedSignalAction = (signalID) => {
	return {
		type: constants.REMOVE_SELECTED_LOGGER_SIGNAL,
		signalID
	};
};

export const removeSelectedSignal = (signalID) => {
	return (dispatch) => {
		dispatch(removeSelectedSignalAction(signalID));
	};
};

export const getEventLoggerSignals = (loggerId, signals) => {
	const signalNames = signals.map((signal) => signal?.label);
	return dispatch => {
		dispatch(addSelectedLoggerSignalsAction(apiCallStatus.LOADING));
		EventLogsApi.getEventLogLoggerSignals(loggerId, signalNames)
			.then((data) => {
				logger.debug('Event logger signal(s) loaded successfully');
				const selectedSignals = map(data.signals, (signal, i) => {
					return {
						loggerID: loggerId,
						loggerTimestamp: data.loggerTimestamp,
						triggerPoint: data.triggerPoint,
						timeStepMS: data.timeStepMS,
						signal,
						signalID: signals[i].id,
						signalData: [],
						type: data.type,
						filename: data.filename,
						fileFullName: data.fileFullName
					};
				});
				forEach(data.signalData, (sig) => {
					forEach(sig.v, (v, index) => {
						selectedSignals[index].signalData.push([new Date(sig.t).getTime(), v]);
					});
				});
				dispatch(addSelectedLoggerSignalsAction(apiCallStatus.SUCCESS, selectedSignals));
			})
			.catch((err) => {
				dispatch(addSelectedLoggerSignalsAction(apiCallStatus.ERROR, null, err));
			});
	};
};
