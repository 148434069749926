import find from 'lodash/find';
import {
    apiCallStatus,
    ASSET_TYPES_MS,
    contractInfoMessages,
    NEWCAPABILITIES,
    CAPABILITIES,
    kpiTypes,
    dataSources,
} from 'helpers/constants';

export default class newCapabilityHelper {

    constructor(capability, asset) {
        if (capability && asset) {
            const capabilityData = capability?.data;
            const capabilities = capabilityData?.assetCapabilitiesInfos?.length && capabilityData?.assetCapabilitiesInfos[0]?.capabilityInfos;
            if (capabilities && asset.id === capabilityData.assetCapabilitiesInfos[0].assetId) {
                this.componentAllCapabilities = capabilities;
                this.asset = asset;
            }
            this.isCapabilityLoading = capability.loadingStatus && capability.loadingStatus === apiCallStatus.LOADING;
            this.contractInfoMessage = capabilityData?.contractInfo?.contractInfo?.message;
        }

    }

    hasCapability(capabilityToCheck) {
        let hasCapability = false;
        if (this.componentAllCapabilities?.length) {
            const foundCapability = find(this.componentAllCapabilities, (capability) => capability.capabilityCode === capabilityToCheck);
            if (foundCapability) {
                hasCapability = foundCapability.isAvailable === true;
            }
        }
        return hasCapability;
    }

    hasEmptyAvailable() {
        return false;
    }

    hasCapabilityAvailable(capabilityToCheck) {
        let foundCap = false;
        if (this.componentAllCapabilities) {
            const foundCapability = find(this.componentAllCapabilities, (capability) => capability.capabilityCode === capabilityToCheck);
            if (foundCapability) {
                foundCap = true;
            }
        }
        return foundCap;
    }

    isLoading() {
        return this.isCapabilityLoading;
    }

    isSet() {
        return !!(this.componentAllCapabilities && this.asset);
    }

    hasNoVisibility() {
        return this.hasEmptyAvailable();
    }

    hasBulkUploadCapability() {
        return this.hasCapability(CAPABILITIES.bulkUpload);
    }

    hasBulkUploadCapabilityAvailable() {
        return this.hasCapabilityAvailable(CAPABILITIES.bulkUpload);
    }

    hasEditComponentCapability() {
        return this.hasCapability(CAPABILITIES.editComponent);
    }

    hasEditChkCapability() {
        return this.hasCapabilityAvailable(CAPABILITIES.editComponent);
    }

    hasParameterBackupCapability() {
        return this.hasCapability(NEWCAPABILITIES.parameterBackup);
    }

    hasParameterBackupCapabilityAvailable() {
        return this.hasCapabilityAvailable(NEWCAPABILITIES.parameterBackup);
    }

    hasChangedParameterListCapability() {
        return this.hasCapability(NEWCAPABILITIES.changedParameterList);
    }

    hasChangedParameterListCapabilityAvailable() {
        return this.hasCapabilityAvailable(NEWCAPABILITIES.changedParameterList);
    }

    hasUserLimitsCapability() {
        return this.hasCapability(CAPABILITIES.userLimits);
    }

    hasUserLimitsCapabilityAvailable() {
        return this.hasCapabilityAvailable(CAPABILITIES.userLimits);
    }

    hasConditionIndexCapability() {
        return this.hasCapability(NEWCAPABILITIES.conditionIndex);
    }

    hasTrendCapability() {
        return this.hasCapability(NEWCAPABILITIES.trend);
    }

    hasOpCapability() {
        return this.hasCapability(NEWCAPABILITIES.operationalParameters);
    }

    hasTrendHistory365DaysAvailable() {
        return this.hasCapability(NEWCAPABILITIES.trendHistory365days);
    }

    hasTrendCapabilityAvailable() {
        return this.hasCapabilityAvailable(NEWCAPABILITIES.trend);
    }

    hasHistogramCapability() {
        return this.hasCapability(NEWCAPABILITIES.histogram);
    }

    hasHistogramCapabilityAvailable() {
        return this.hasCapabilityAvailable(NEWCAPABILITIES.histogram);
    }

    hasVibrationFFTCapability() {
        return this.hasCapability(NEWCAPABILITIES.vibrationFFT);
    }

    hasScatterCapability() {
        return this.hasCapability(NEWCAPABILITIES.scatter);
    }

    hasScatterCapabilityAvailable() {
        return this.hasCapabilityAvailable(NEWCAPABILITIES.scatter);
    }

    hasEventSelfFaultEvaluationCapability() {
        return this.hasCapability(CAPABILITIES.eventSelfFaultEvaluation);
    }

    hasEventLoggerVisualizationCapability() {
        return this.hasCapability(CAPABILITIES.eventLoggerVisualization);
    }

    hasEventTrendSignalCapability() {
        return this.hasCapability(CAPABILITIES.eventTrendSignal);
    }

    hasEventLoggerDownloadCapability() {
        return this.hasCapability(CAPABILITIES.eventLoggerDownload);
    }

    hasEventListCapability() {
        return this.hasCapability(NEWCAPABILITIES.eventList);
    }

    hasEventListCapabilityAvailable() {
        return this.hasCapabilityAvailable(NEWCAPABILITIES.eventList);
    }

    hasAssistancePageCapability() {
        const hasCapability = this.hasEventSelfFaultEvaluationCapability() ||
            this.hasEventLoggerVisualizationCapability() ||
            this.hasEventTrendSignalCapability() ||
            this.hasEventLoggerDownloadCapability();
        return hasCapability;

    }

    hasEmailAlertCapability() {
        return this.hasCapability(CAPABILITIES.emailAlertDisconnectedDrive)
            || this.hasCapability(CAPABILITIES.emailAlertFaultsWarnings)
            || this.hasCapability(CAPABILITIES.emailAlertParameterChanged)
            || this.hasCapability(CAPABILITIES.emailAlertUserLimits);
    }

    hasEmailAlertCapabilityAvailable() {
        return this.hasCapabilityAvailable(CAPABILITIES.emailAlertDisconnectedDrive)
            || this.hasCapabilityAvailable(CAPABILITIES.emailAlertFaultsWarnings)
            || this.hasCapabilityAvailable(CAPABILITIES.emailAlertParameterChanged)
            || this.hasCapabilityAvailable(CAPABILITIES.emailAlertUserLimits);
    }

    hasLabelingCapability() {
        return this.hasCapability(NEWCAPABILITIES.assetLabel);
    }

    hasNameplateGalleryCapability() {
        return this.hasCapability(CAPABILITIES.assetPicture);
    }

    isSmartSensor() {
        const dataSourceType = this.asset?.assetType === ASSET_TYPES_MS?.DRIVE ? dataSources?.CMD4 : dataSources?.SMARTSENSOR;
        return dataSourceType === dataSources.SMARTSENSOR;
        //return this.asset?.componentType && this.asset.componentType.dataSourceType === dataSources.SMARTSENSOR;
    }

    hasAlertAlarmOffsetConfigCapability() {
        return this.hasCapability(CAPABILITIES.configureAssetHealth);
    }

    hasAlertAlarmCapabilityAvailable() {
        return this.hasCapabilityAvailable(CAPABILITIES.configureAssetHealth);
    }

    hasMeasurementExportCapability() {
        return this.hasCapability(CAPABILITIES.assetMeasurementExport);
    }

    hasMeasurementExportCapabilityAvailable() {
        return this.hasCapabilityAvailable(CAPABILITIES.assetMeasurementExport);
    }

    hasMeasurementGroupExportCapability() {
        return this.hasCapability(CAPABILITIES.assetGroupMeasurementExport);
    }

    hasCrossAssetAnalysisCapability() {
        return this.hasCapability(NEWCAPABILITIES.comparison);
    }

    hasCBMCapability() {
        return this.hasCapability(CAPABILITIES.conditionBasedMaintenance);
    }

    hasPlotTypeCapability(plotType) {
        switch (plotType) {
            case kpiTypes.TREND: return this.hasCapability(NEWCAPABILITIES.trend);
            case kpiTypes.SCATTER: return this.hasCapability(NEWCAPABILITIES.scatter);
            case kpiTypes.HISTOGRAM: return this.hasCapability(NEWCAPABILITIES.histogram);
            default: return false;
        }
    }

    hasLoadMeasurementOnDemandCapability() {
        return this.hasCapability(NEWCAPABILITIES.loadMeasurementOnDemand);
    }

    hasCollectRawDataCapability() {
        return this.hasCapability(NEWCAPABILITIES.collectRawData);
    }

    hasInstantReportCapability() {
        return this.hasCapability(CAPABILITIES.assetInstantReport);
    }

    hasInstantReportCapabilityAvailable() {
        return this.hasCapabilityAvailable(CAPABILITIES.assetInstantReport);
    }

    hasAssetPictureCapability() {
        return this.hasCapability(NEWCAPABILITIES.assetPicture);
    }

    hasLimitConfigCapability() {
        return this.hasCapability(NEWCAPABILITIES.assetLimitSettings);
    }

    checkEventListMultipleAssetsCapability() {
        return this.hasCapability(NEWCAPABILITIES.eventListMultipleAssets);
    }

    hasConditionDetailsNewCapability() {
        return this.hasCapability(NEWCAPABILITIES.conditionDetails);
    }

    hasOperationalParametersSelectLimitCapability() {
        return this.hasCapability(NEWCAPABILITIES.operationalParametersSelectLimit);
    }

    subscriptionStatus() {
        switch (this.contractInfoMessage) {
            case contractInfoMessages.unMonitored:
                return this.contractInfoMessage;
            case contractInfoMessages.noContract:
                return this.contractInfoMessage;
            case contractInfoMessages.noSubscription:
                return this.contractInfoMessage;
            case contractInfoMessages.trialPeriodExpired:
                return this.contractInfoMessage;
            case contractInfoMessages.contractExpired:
                return this.contractInfoMessage;
            default:
                return contractInfoMessages.activeSubscription;
        }
    }
}
