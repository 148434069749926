import React from 'react';
import PropTypes from 'prop-types';

const IconHistory = ({ width = 16, height = 16, viewBox = '0 0 16 16' }) => {
	return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7C14 8.85652 13.2625 10.637 11.9497 11.9497C10.637 13.2625 8.85652 14 7 14V12.5C8.0878 12.5 9.15117 12.1774 10.0556 11.5731C10.9601 10.9687 11.6651 10.1098 12.0813 9.10476C12.4976 8.09977 12.6065 6.9939 12.3943 5.927C12.1821 4.86011 11.6583 3.8801 10.8891 3.11091C10.1199 2.34172 9.13989 1.8179 8.073 1.60568C7.0061 1.39346 5.90023 1.50238 4.89524 1.91866C3.89025 2.33494 3.03126 3.03989 2.42692 3.94436C1.82257 4.84883 1.5 5.9122 1.5 7C1.49769 8.39963 2.03492 9.7463 3 10.76V9H4V13H0V12H2.14C1.14908 11.0291 0.468978 9.78575 0.186024 8.42766C-0.0969295 7.06956 0.0300276 5.65802 0.550779 4.37222C1.07153 3.08641 1.96259 1.98433 3.11085 1.20588C4.2591 0.427422 5.61277 0.0077031 7 0ZM4.55 3.47L7.5 6.42L9.47 4.45L10.53 5.51L7.49 8.55L3.49 4.53L4.55 3.47Z' fill='#1F1F1F'/>
    </svg>;
};

IconHistory.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string
};

export default IconHistory;
