import { takeLatest, call, put } from 'redux-saga/effects';
import { GatewayManagementApi } from 'api';
import { apiCallStatus } from 'helpers/constants';
import logger from 'helpers/logger';
import { actionTypes } from './constants';
import {
    getGatewayListAction,
    getGatewayTypeAction
} from './actions';

function* getGatewayList(action) {
    const siteIdentifiers = action.payload;
    yield put(getGatewayListAction(apiCallStatus.LOADING));
    try {
        const responseList = yield call(GatewayManagementApi.getGatewayList, siteIdentifiers);
        yield put(getGatewayListAction(apiCallStatus.SUCCESS, responseList));
        logger.debug('Gateway List successfully loaded for page ');
    } catch (err) {
        yield put(getGatewayListAction(apiCallStatus.ERROR, [], err));
    }
}

function* getGatewayType() {
    yield put(getGatewayTypeAction(apiCallStatus.LOADING));
    try {
        const responseList = yield call(GatewayManagementApi.getGatewayType);
        yield put(getGatewayTypeAction(apiCallStatus.SUCCESS, responseList));
        logger.debug('Gateway Type successfully loaded for page ');
    } catch (err) {
        yield put(getGatewayTypeAction(apiCallStatus.ERROR, [], err));
    }
}

export function* GatewayManagementWatchers() {
    yield takeLatest(actionTypes.GET_GATEWAY_LIST, getGatewayList);
}

export function* GatewayTypeWatchers() {
    yield takeLatest(actionTypes.GET_GATEWAY_TYPE, getGatewayType);
}
