import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './style.scss';
import { getModel } from './selectors';
import * as actions from './actions';
import LimitSelectorComponent from './LimitSelectorComponent';


const LimitSelectorContainer = (props) => {
    return <LimitSelectorComponent {...props} />;
};

const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    };
};

const LimitSelectorContainerConn = connect(
    mapStateToProps,
    mapDispatchToProps
)(LimitSelectorContainer);


export default LimitSelectorContainerConn;
