/* eslint-disable import/no-unused-modules */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { IconKebab } from 'svgIcons/MotionPortalIcons';
import './style.scss';
import listenForOutsideClicks from 'sharedComponents/ContextMenu/helper';

const ContextMenuComponent = ({ isOpen, setContextMenuId, id, ...props }) => {
	const menuRef = useRef(null);
	const [listening, setListening] = useState(false);
	useEffect(listenForOutsideClicks(listening, setListening, menuRef, setContextMenuId));
	const toggle = () => {
		if (isOpen) {
			setContextMenuId(null);
		} else {
			setContextMenuId(id);
		}
	};
	const handleSelection = (option) => {
		toggle();
		const callbackFunction = option.onOptionSelect || props.onSelect;

		if (typeof callbackFunction === 'function') {
			callbackFunction(option.id);
		}
	};

	const buttonClass = isOpen ? 'context-menu-button-active' : 'context-menu-button';
	return (
		<div className='context-menu' ref={menuRef} role='context-menu'>
			<div
				id={id}
				className={buttonClass}
				onClick={() => { toggle(); }}
			>
				<IconKebab active={isOpen} />
			</div>
			{isOpen &&
				<div className='context-menu-list'>
					{props.options.map(option => {
						const cssClass = option.disabled === true ? 'context-menu-list-item-disabled' : 'context-menu-list-item';
						return (
							<div
								id={`contextMenuItem_${option.id}`}
								key={`contextMenuItem_${option.id}`}
								className={cssClass}
								role='presentation'
								onClick={() => {
									if (!(option.disabled === true)) {
										handleSelection(option);
									}
								}}
							>
								{option.title}
							</div>
						);
					}
					)}
				</div>
			}
		</div>
	);
};

ContextMenuComponent.defaultProps = {
	id: '',
	options: [],
	onSelect: undefined,
};

ContextMenuComponent.propTypes = {
	id: PropTypes.string,
	options: PropTypes.array,
	onSelect: PropTypes.func,
};

export default ContextMenuComponent;
