import { actionTypes } from '../constants';

export const setSelectedLimits = (data) => ({
    type: actionTypes.SET_SELECTED_LIMITS,
    data
});

export const setSelectedLimitsAction = (data) => ({
    type: actionTypes.SET_SELECTED_LIMITS_ACTION,
    data
});

export const setIsDefaultLimitsSelected = (data) => ({
    type: actionTypes.SET_IS_DEFAULT_SELECTED_LIMITS,
    data
});

export const maintainLimitsAction = (data, asset, selectedSignal, selectedOrganization) => ({
    type: actionTypes.MAINTAIN_LIMITS_BETWEEN_SIGNALS_AND_ASSETS,
    data,
    asset,
    selectedSignal,
    selectedOrganization
});
