import { createSelector } from 'reselect';
import {
    getLoadingStatuses,
    getAllAssets
} from 'helpers/selectors';
import { filteredAssetData } from './helpers';


const getAssetNumber = ({ conditionIndices, overview }) => {
    // TODO: The following logic needs to be updated with `conditionIndices?.assets?.count` when List API replace with micro-service.
    let filteredData = [];
    if (conditionIndices?.assets?.data?.length > 0 && overview.assets.data?.length > 0) {
        filteredData = filteredAssetData(overview.assets.data, conditionIndices.assets.data);
        return filteredData.length;
    }
};

const getAssetGroupNumber = ({ conditionIndices }) => {
    return conditionIndices?.assetGroups?.count;
};

const getAssetGroupAssetNumber = ({ conditionIndices }) => {
    return conditionIndices?.assetGroups?.assetCount;
};

const getPowertrainNumber = ({ conditionIndices }) => {
    return conditionIndices?.powertrains?.count;
};

const getPowertrainAssetNumber = ({ conditionIndices }) => {
    return conditionIndices?.powertrains?.assetCount;
};

const getAssetOverviewLoadingStatus = ({ conditionIndices }) => {
    return conditionIndices?.assets?.loadingStatus;
};

const getAssetGroupOverviewLoadingStatus = ({ conditionIndices }) => {
    return conditionIndices?.assetGroups?.loadingStatus;
};

const getPowertrainOverviewLoadingStatus = ({ conditionIndices }) => {
    return conditionIndices?.powertrains?.loadingStatus;
};

const getConditionFilter = ({ conditionIndices }) => {
    return conditionIndices?.filter?.conditionFilter;
};

const getAssetTypeFilter = ({ conditionIndices }) => {
    return conditionIndices?.filter?.assetTypeFilter;
};

const getSubscriptionTypeFilter = ({ conditionIndices }) => {
    return conditionIndices?.filter?.subscriptionTypeFilter;
};

const getSubscriptiontypes = ({ conditionIndices }) => {
    return conditionIndices?.subscriptionTypes?.data;
};

const getActiveTab = ({ conditionIndices }) => {
    return conditionIndices?.selectedTab;
};

const getSearchBoxFilter = ({ conditionIndices }) => {
    return conditionIndices?.filter?.searchBox;
};

const getSelectedFilters = ({ generalFilter }) => {
    return generalFilter?.selectedFilters;
};

const getSelectedOrganization = ({ generalFilter }) => {
    return generalFilter?.selectedFilters?.organizations[0];
};

export const getModel = createSelector(
    [
        getLoadingStatuses,
        getAssetNumber,
        getAssetGroupNumber,
        getAssetGroupAssetNumber,
        getPowertrainNumber,
        getPowertrainAssetNumber,
        getAssetOverviewLoadingStatus,
        getAssetGroupOverviewLoadingStatus,
        getPowertrainOverviewLoadingStatus,
        getConditionFilter,
        getAssetTypeFilter,
        getSubscriptionTypeFilter,
        getSubscriptiontypes,
        getActiveTab,
        getSearchBoxFilter,
        getSelectedFilters,
        getAllAssets,
        getSelectedOrganization
    ],
    (
        generalFilterLoadingStatuses,
        assetNumber,
        assetGroupNumber,
        assetGroupAssetNumber,
        powertrainNumber,
        powertrainAssetNumber,
        assetOverviewLoadingStatus,
        assetGroupOverviewLoadingStatus,
        powertrainOverviewLoadingStatus,
        conditionFilter,
        assetTypeFilter,
        subscriptionTypeFilter,
        subscriptionTypes,
        activeTab,
        searchBoxFilter,
        selectedFilters,
        allAssets,
        selectedOrganization
    ) => {
        return {
            generalFilterLoadingStatuses,
            assetNumber,
            assetGroupNumber,
            assetGroupAssetNumber,
            powertrainNumber,
            powertrainAssetNumber,
            assetOverviewLoadingStatus,
            assetGroupOverviewLoadingStatus,
            powertrainOverviewLoadingStatus,
            conditionFilter,
            assetTypeFilter,
            subscriptionTypeFilter,
            subscriptionTypes,
            activeTab,
            searchBoxFilter,
            selectedFilters,
            allAssets,
            selectedOrganization
        };
    }
);
