import { actionTypes } from './constants';
import { apiCallStatus } from 'helpers/constants';

const initialState = {
    gatewayInfo: {},
    error: null,
    loadingStatus: null
};

const initialStateConnectedAssets = {
    gatewayConnectedAssets: {},
    error: null,
    loadingStatus: null
};

// eslint-disable-next-line import/no-unused-modules
export function reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.CLEAR_STORE: {
            return initialState;
        }
        case actionTypes.GET_GATEWAY_INFO_SETTINGS:
            if (action.loadingStatus === apiCallStatus.LOADING) {
                return {
                    ...state,
                    loadingStatus: apiCallStatus.LOADING,
                    error: null
                };
            } else if (action.loadingStatus === apiCallStatus.SUCCESS) {
                return {
                    ...state,
                    loadingStatus: apiCallStatus.SUCCESS,
                    gatewayInfo: action.data,
                    error: null
                };
            } else if (action.loadingStatus === apiCallStatus.ERROR) {
                return {
                    ...state,
                    loadingStatus: apiCallStatus.ERROR,
                    gatewayInfo: {},
                    error: action.error
                };
            }
            break;
        default:
            return state;
    }
}

// eslint-disable-next-line import/no-unused-modules
export function connectedAssetReducer(state = initialStateConnectedAssets, action) {
    switch (action.type) {
        case actionTypes.CLEAR_STORE: {
            return initialStateConnectedAssets;
        }
        case actionTypes.GET_GATEWAY_CONNECTED_ASSETS_SETTINGS:
            if (action.loadingStatus === apiCallStatus.LOADING) {
                return {
                    ...state,
                    loadingStatus: apiCallStatus.LOADING,
                    error: null
                };
            } else if (action.loadingStatus === apiCallStatus.SUCCESS) {
                return {
                    ...state,
                    loadingStatus: apiCallStatus.SUCCESS,
                    gatewayConnectedAssets: action.data,
                    error: null
                };
            } else if (action.loadingStatus === apiCallStatus.ERROR) {
                return {
                    ...state,
                    loadingStatus: apiCallStatus.ERROR,
                    gatewayConnectedAssets: {},
                    error: action.error
                };
            }
            break;
        default:
            return state;
    }
}
