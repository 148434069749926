import { slice } from 'lodash';
import { routes } from 'routes';
import { NotificationTypeCode } from 'helpers/constants';

export const handleIconType = (notificationTypeCode, conditionType) => {
    if (conditionType && conditionType !== '') {
        const notificationType = `${notificationTypeCode}${conditionType[0].toUpperCase() + conditionType.slice(1)}`;
        return notificationType.replaceAll(' ', '');
    }
    return notificationTypeCode;
};

// NOTIFICATION GROUP CODES COPIED FROM WIKI PAGE
// https://dev.azure.com/BUDrivesOrg/ABB_MOSE_SmartSolutions/_wiki/wikis/Powertrain%20Micro%20Service%20Architecture/5321/Notification?anchor=notifications-in-detail
export const notificationGroupCodes = {
    Condition: 'Condition',
    Maintenance: 'Maintenance',
    Event: 'Event',
    Limits: 'Limits',
    Connection: 'Connection',
    Connectivity: 'Connectivity',
    SensorVitality: 'SensorVitality',
    Configuration: 'Configuration',
    UserAction: 'UserAction',
    Subscription: 'Subscription',
    Commissioning: 'Commissioning'
};

export const getDataChunkForPage = (data, currentPage, pageSize) => {
    return slice(data, (currentPage - 1) * pageSize, currentPage * pageSize);
};

const navigateToRoute = (history, pathname, searchParams = {}, state = {}) => {
    history.push({
        pathname,
        search: new URLSearchParams(searchParams).toString()
    }, state);
};

export const handleNotificationNavigation = (item, history) => {
    if (!item || !history) {
        return;
    }

    const { notificationGroupCode, notificationTypeCode, assetId } = item;

    switch (notificationGroupCode) {
        case notificationGroupCodes.Event:
            navigateToRoute(history, routes.EventLog, { assetId }, { notificationData: item });
            break;
        case notificationGroupCodes.Condition:
        case notificationGroupCodes.Configuration:
            switch (notificationTypeCode) {
                case NotificationTypeCode.AssetNameplatePropertyChanged:
                case NotificationTypeCode.ParameterChanged:
                    navigateToRoute(history, routes.AssetDetails, { assetId }, { notificationData: item });
                    break;
                default:
                    navigateToRoute(history, routes.OperationalParametersV2, { assetId }, { notificationData: item });
                    break;
            }
            break;
        case notificationGroupCodes.Limits:
            navigateToRoute(history, routes.OperationalParametersV2, { assetId }, { notificationData: item });
            break;
        case notificationGroupCodes.Maintenance:
            navigateToRoute(history, routes.MaintenanceOverview);
            break;
        case notificationGroupCodes.SensorVitality:
        case notificationGroupCodes.Commissioning:
            navigateToRoute(history, routes.SensorProperties, { assetId }, { notificationData: item });
            break;
        case notificationGroupCodes.Subscription:
            navigateToRoute(history, routes.ToolsSubscriptionStatus, {}, { notificationData: item });
            break;
        case notificationGroupCodes.UserAction:
            navigateToRoute(history, routes.OperationalParametersV2, { assetId }, { notificationData: item });
            break;
        default:
            if (assetId) {
                navigateToRoute(history, routes.AssetDetails, { assetId });
            } else {
                navigateToRoute(history, routes.ConditionOverview);
            }
            break;
    }
};
