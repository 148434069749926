import React, { useEffect, useState, useCallback } from 'react';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { IconNameplate } from 'svgIcons/MotionPortalIcons';
import { getAssetIcon } from 'helpers/icons';
import { apiCallStatus } from 'helpers/constants';
import { PICTURE_TYPES } from './constants';
import ImageViewerModal from './ImageViewerModal';
import { getThumbnailForPictureType, formatAndSortImages } from './helpers';
import ImageHolder from './ImageHolder';
import './style.scss';

const AssetNameplateImageHolderComponent = (props) => {

	const {
		asset, actions, model
	} = props;
	const { t: translate } = useTranslation();
	const [mainAssetImage, setMainAssetImage] = useState(null);
	const [mainNameplateImage, setMainNameplateImage] = useState(null);
	const [images, setImages] = useState(null);
	const [modalVisible, setModalVisible] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState(null);

	const placeholderProps = { width: 45, height: 45 };

	const setThumbnails = (pictures) => {
		setMainAssetImage(getThumbnailForPictureType(pictures, PICTURE_TYPES.AssetImage));
		setMainNameplateImage(getThumbnailForPictureType(pictures, PICTURE_TYPES.NameplateImage));
	};

	useEffect(() => {
		asset && actions.getAssetPictures(asset?.id);
	}, []);

	useEffect(() => {
		if (model?.pictures?.data) {
			setThumbnails(model?.pictures?.data);
			setImages(model?.pictures?.data);
			const isAssetImage = model?.pictures?.data?.length && model?.pictures?.data[model.pictures?.data?.length - 1]?.pictureType;
			if (isAssetImage === PICTURE_TYPES.AssetImage) {
				setSelectedIndex(0);
			} else if (isAssetImage === PICTURE_TYPES.NameplateImage) {
				setSelectedIndex(model?.pictures?.data?.length - 1);
			} else if (isAssetImage === PICTURE_TYPES.Attachment) {
				setSelectedIndex(1);
			}
		}

	}, [model?.pictures?.data]);

	useEffect(() => {
	}, [model?.assetNameplateImage]);
	const selindex = images?.length ? images?.length - 1 : null;


	const onImageHolderClick = useCallback((selectedIndex) => {
		setModalVisible(true);
		setSelectedIndex(selectedIndex || 0);
	}, []);

	const onAssetPictureUpload = (file, assetPictureType) => {
		asset && actions.uploadAssetPictureRequest(asset?.id, file?.name, 0, assetPictureType, file);
	};
	const getLoadingStatus = () => {
		if (
			model?.assetNameplateImage?.loadingStatus === apiCallStatus.LOADING
		) {
			return model?.assetNameplateImage?.loadingStatus;
		}
		return model.pictures.loadingStatus;
	};

	const deleteAssetImage = async (img) => {
		if (asset && img) {
			const imgIndex = formatAndSortImages(images, translate).findIndex((i) => i.pictureId === img?.pictureId);
			await actions.deleteAssetImage(asset.id, img?.pictureId);
			setSelectedIndex(images?.length === imgIndex + 1 ? imgIndex - 1 : imgIndex);
		}
	};

	return (
		<Col md='auto' className='asset-nameplate-image-container'>
			<ImageHolder imageToRender={mainAssetImage} placeholder={getAssetIcon(asset?.assetType, placeholderProps)} onImageHolderClick={onImageHolderClick} />
			<ImageHolder imageToRender={mainNameplateImage} placeholder={<IconNameplate {...placeholderProps} />} selectedIndex={selindex} onImageHolderClick={onImageHolderClick} />
			<ImageViewerModal
				images={images}
				loadingStatus={getLoadingStatus()}
				show={modalVisible}
				selectedIndex={selectedIndex}
				closeFunction={() => { setModalVisible(false); setSelectedIndex(null); }}
				placeholder={getAssetIcon(asset?.assetType, { width: 100, height: 100 })}
				uploadImageHandler={onAssetPictureUpload}
				deleteAssetImage={deleteAssetImage}
				showDeleteConfirm={model.showDeleteConfirm}
				setShowDeleteConfirm={actions.setShowDeleteConfirm}
			/>
		</Col>
	);
};

export default AssetNameplateImageHolderComponent;
