import React, { useCallback, useEffect, useState } from 'react';
import { IconInfoType } from 'svgIcons/MotionPortalIcons';
import { translate } from 'helpers/translateHelper';
import { updateSignal } from '../actions';
import { GeneralDialog } from 'sharedComponents/GeneralDialog';
import { useDispatch, useSelector } from 'react-redux';
import { getModel } from '../selectors';
import { LIMIT_VALUE_REGEX } from './constants';

const OffsetModal = ({ showOffset, setShowOffset, item }) => {
    const dispatch = useDispatch();
    const { timeSeries } = useSelector(getModel);
    const title = `${translate('ABB.Powertrain.Frontend.editOffset')} | ${item.displayName}`;

    const [localOffsetValue, setLocalOffsetValue] = useState(item.offset || '');
    const [isDisable, setIsDisable] = useState(true);
    const [focusedInput, setFocusedInput] = useState(false);

    const filteredTimeSeries = timeSeries.timeSeriesList.filter(timeSeriesItem => timeSeriesItem.uniqId === item.uniqId);

    useEffect(() => {
        if (filteredTimeSeries.length > 0) {
            const signal = filteredTimeSeries[0];
            const isOffsetChanged = localOffsetValue !== '' && (localOffsetValue || '') !== (signal.offset || '');
            setIsDisable(!isOffsetChanged);
        }
    }, [localOffsetValue]);

    const handleInputChange = useCallback((e) => {
        const value = e.target.value;
        // CHECKING OFFSET VALUE WITH MIN AND MAX VALUE FROM SIGNAL
        if (item?.maxValue && item?.minValue && item?.maxValue >= value && item?.minValue <= value && LIMIT_VALUE_REGEX.test(value)) {
            setLocalOffsetValue(value);
        }
    }, []);

    const handelSubmitOffset = useCallback(() => {
        dispatch(updateSignal(item.uniqId, 'offset', localOffsetValue));
        dispatch(updateSignal(item.uniqId, 'isModified', true));
        setShowOffset(false);
    }, [dispatch, item.uniqId, localOffsetValue, setShowOffset]);

    const handleCancel = useCallback(() => {
        const previousOffset = filteredTimeSeries[0]?.offset ?? '';
        dispatch(updateSignal(item.uniqId, 'offset', previousOffset));
        dispatch(updateSignal(item.uniqId, 'isModified', false));
        setShowOffset(false);
    }, [filteredTimeSeries, dispatch, item.uniqId, setShowOffset]);

    return (
        <GeneralDialog
            show={showOffset}
            close={() => setShowOffset(false)}
            title={title}
            cancelButtonProps={{
                text: translate('ABB.Powertrain.Frontend.cancelLabel'),
                onClick: handleCancel
            }}
            acceptButtonProps={{
                text: translate('ABB.Powertrain.Frontend.applyBtnLbl'),
                onClick: handelSubmitOffset,
                disabled: isDisable
            }}
            closeButton={true}
            persistent={false}
            id={'limit_Offset'}
        >
            <div className='offset-info'>
                <IconInfoType color='#0051FF' />
                <p>{translate('ABB.Powertrain.Frontend.offsetModalDescription')}</p>
            </div>
            <div className='offset-input'>
                <div className='offset-input-title'>{translate('ABB.Powertrain.Frontend.calibrationValue')}</div>
                <div className={`limit-value-uom ${focusedInput ? 'highlight' : ''}`}>
                    <input
                        type='number'
                        placeholder={translate('ABB.Powertrain.Frontend.offsetValue')}
                        value={localOffsetValue}
                        onChange={handleInputChange}
                        name='limitOffset'
                        onFocus={() => { setFocusedInput(true); }}
                        onBlur={() => { setFocusedInput(false); }}
                    />
                    <div className='uom-text'>{item.unit}</div>
                </div>
            </div>
        </GeneralDialog>
    );
};

export default OffsetModal;
