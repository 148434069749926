import React from 'react';

import colors from 'theme/_colors.scss';
import { IconBell, IconInfoFilled } from 'svgIcons/MotionPortalIcons';
import { LEVEL, LIMIT_MENU_OPTIONS, LIMIT_SYMBOL } from './constants';

export const getContextMenuOptions = (signal, isOffsetEnabled, options) => {
    const hasAnyBasedOnDefaultLimit = signal?.item?.limits?.filter((item) => item?.isBasedOnDefault);
    const hasAnyDefaultLimit = signal?.item?.limits?.find((item) => item?.isDefault);
    let menuOptions = options;
    if (signal?.checkIsSmseAsset) { menuOptions = options.filter((item) => item.id !== LIMIT_MENU_OPTIONS.RESET_DEFAULT_LIMITS); }

    return menuOptions.map(item => {
        if (item.id === LIMIT_MENU_OPTIONS.REMOVE_SIGNAL) {
            return { ...item, disabled: hasAnyDefaultLimit || hasAnyBasedOnDefaultLimit?.length ? true : false };
        }
        if (item.id === LIMIT_MENU_OPTIONS.EDIT_OFFSET) {
            return isOffsetEnabled ? { ...item, disabled: false } : { ...item, disabled: true };
        }
        if (item.id === LIMIT_MENU_OPTIONS.RESET_DEFAULT_LIMITS) {
            return hasAnyBasedOnDefaultLimit?.length ? { ...item, disabled: false } : { ...item, disabled: true };
        }
        return item;
    });
};


export const getSliderValues = (limits) => {
    return limits.map(limit => ({
        type: limit.limitLevel,
        value: limit.limitValue,
        symbol: limit.limitDirection === LEVEL.LowerLimit ? LIMIT_SYMBOL.Lower : LIMIT_SYMBOL.Greater
    }));
};

// export const getUnitsGroupID = (timeseriesNameTextKey, kpiList) => {
//     const timeSeriesList = kpiList.reduce((result, group) => {
//         return result.concat(group.timeseries);
//     }, []);

//     const foundTimeseries = timeSeriesList.find(item => item.timeseriesNameTextKey === timeseriesNameTextKey);

//     return foundTimeseries ? foundTimeseries.timeseriesUnitGroupID : null;
// };

export const getSliderValue = {
    oneDirectionAlarmOrAlert: [
        { type: 'Alarm', value: 75, symbol: '>' },
    ],
    oneDirectionAlarmAndAlert: [
        { type: 'Alert', value: 35, symbol: '>' },
        { type: 'Alarm', value: 75, symbol: '>' },
    ],
    inRangeAlarmOrAlert: [
        { type: 'Alarm', value: 35, symbol: '>' },
        { type: 'Alarm', value: 75, symbol: '<' },
    ],
    outOfRangeAlarmOrAlert: [
        { type: 'Alarm', value: 35, symbol: '<' },
        { type: 'Alarm', value: 75, symbol: '>' },
    ],
    inRangeAlarmAndAlert: [
        { type: 'Alert', value: 35, symbol: '>' },
        { type: 'Alarm', value: 75, symbol: '>' },
        { type: 'Alert', value: 100, symbol: '>' },
        { type: 'Alarm', value: 130, symbol: '<' },
    ],
    outOfRangeAlarmAndAlert: [
        { type: 'Alert', value: 80, symbol: '>' },
        { type: 'Alarm', value: 30, symbol: '<' },
        { type: 'Alarm', value: 100, symbol: '>' },
        { type: 'Alert', value: 50, symbol: '<' },
    ],
};

export const getColumnConfigSuggestedLimits = (translate, timeSeries) => {
    return [
        {
            title: translate('ABB.Powertrain.Frontend.limitType'),
            selectable: false,
            key: 'limitType',
            visible: true
        },
        {
            title: translate('ABB.Powertrain.Frontend.currentLimit'),
            selectable: true,
            key: 'currentLimit',
            visible: timeSeries.limits?.length > 0
        },
        {
            title: translate('ABB.Powertrain.Frontend.suggestedLimit'),
            selectable: true,
            key: 'suggestedLimit',
            visible: true
        }
    ];
};

export const limitTypes = ['currentLimit', 'suggestedLimit'];
const NOTIFICATION_TYPES = {
    INFO: 'info',
    NOTIFICATION: 'notification'
};

export const getNotificationIcons = (type) => {
    switch (type) {
        case NOTIFICATION_TYPES.INFO:
            return (
                <IconInfoFilled width={16} height={16} color={colors.nero} />
            );
        case NOTIFICATION_TYPES.NOTIFICATION:
            return (
                <IconBell width={16} height={16} />
            );
        default: null;
    }
};
