import React, { useState, useEffect } from 'react';
import map from 'lodash/map';
import { useTranslation } from 'react-i18next';
import { sensorPropertyKeys } from '../../constants';
import {
    formatSensorDetailsData,
    // formatGatewayInfo
} from './helpers';
import { getVisibleFeatures } from '../helpers';

const SensorDetailsList = ({ sensorProperties, /*gatewayInfo, sensorType,*/
    isFirmwareUpdateEnforced }) => {

    const { t: translate } = useTranslation();
    const [sensorDetails, setSensorDetails] = useState([]);
    const visibleFeatures = getVisibleFeatures(sensorProperties?.sensorProperties);

    const getValueForDeatilsKey = (feature) => {
        switch (feature.sensorPropertyKey) {
            case sensorPropertyKeys.Mac:
                return feature?.values[0]?.value || '-';
            case sensorPropertyKeys.AuthenticationID:
                return feature?.values[0]?.value || '-';
            case sensorPropertyKeys.CPUTemperatureCalibrationOffset:
                return feature?.values[0]?.value || '-';
            case sensorPropertyKeys.BatteryVoltageCalibrationConstant:
                return feature?.values[0]?.value || '-';
            case sensorPropertyKeys.MEMSAccelerometerCalibrationConstant:
                return feature?.values[0]?.value || '-';
            case sensorPropertyKeys.PiezoAccelerometerCalibrationConstant:
                return feature?.values[0]?.value || '-';
            case sensorPropertyKeys.PulseSupportType:
                return feature?.values[0]?.value || '-';
            case sensorPropertyKeys.TotalBatteryCapacity:
                return feature?.values[0]?.value || '-';
            default:
                return feature?.values[0]?.value || '-';
        }
    };

    const formatSensorPropertiesData = (sensorProperties) => {
        const sensorPropertiesDataArr = [];
        sensorProperties.map(item => {
            const sensorPropertiesDataObj = {};
            sensorPropertiesDataObj.label = translate(`ABB.Powertrain.Frontend.sensorFeature_${item.sensorPropertyKey}`) || item.sensorPropertyDisplayName;
            sensorPropertiesDataObj.value = getValueForDeatilsKey(item);
            sensorPropertiesDataArr.push(sensorPropertiesDataObj);
        });
        return sensorPropertiesDataArr;
    };

    useEffect(() => {
        setSensorDetails(formatSensorDetailsData(sensorProperties,
            isFirmwareUpdateEnforced, translate));
        const sensorDetailsData = formatSensorPropertiesData(visibleFeatures);
        setSensorDetails(prevState => [...prevState, ...sensorDetailsData]);
    }, [sensorProperties]);

    return map(sensorDetails, (el, index) => {
        return el ?
            <div key={index} className='sensor-properties-list-item'>
                <div className='data-label'>{el.label ? el.label : translate(el.sensorPropertyTextKey) || el.sensorPropertyDisplayName}</div>
                <div className='data-value'>{el.value}
                </div>
            </div> : null;
    });
};

export default SensorDetailsList;
