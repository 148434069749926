import { createSelector } from 'reselect';
import {
    getAssetsLoadingStatus, getPowertrainLoadingStatusv2
} from 'helpers/selectors';


const getSelectedAsset = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails.selectedAsset;
};

const getSelectedAssetV2 = ({ conditionIndices }) => {
    return conditionIndices?.assets;
};

const getAssetCapabilityLoadingStatus = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails.selectedAssetCapability?.loadingStatus;
};

const getNewCapabilityLoadingStatus = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails?.newAssetCapability?.loadingStatus;
};

const getGeneralFilterLoadingStatuses = ({ generalFilter }) => {
    return {
        organizationsLoadingStatus: generalFilter.organizations.loadingStatus,
        sitesLoadingStatus: generalFilter.sites.loadingStatus,
        assetGroupsLoadingStatus: generalFilter.assetGroups.loadingStatus,
        contextLoadingStatus: generalFilter.contextLoadingStatus
    };
};

const getSitesCheckedFilters = ({ generalFilter }) => {
    return generalFilter.selectedFilters.sitesChecked;
};
const getConditionInsightsStatus = ({ detailedInformation }) => {
    return detailedInformation?.conditionInsightAssetStatus;
};

export const getModel = createSelector([
    getSelectedAsset,
    getSelectedAssetV2,
    getAssetCapabilityLoadingStatus,
    getNewCapabilityLoadingStatus,
    getGeneralFilterLoadingStatuses,
    getSitesCheckedFilters,
    getAssetsLoadingStatus,
    getPowertrainLoadingStatusv2,
    getConditionInsightsStatus
],
    (
        asset,
        assetV2,
        capabilityLoadingStatus,
        newCapabilityLoadingStatus,
        generalFilterLoadingStatuses,
        sitesChecked,
        assetsLoadingStatus,
        powertrainsLoadingStatus
    ) => {
        return {
            asset,
            assetV2,
            capabilityLoadingStatus,
            newCapabilityLoadingStatus,
            generalFilterLoadingStatuses,
            sitesChecked,
            assetsLoadingStatus,
            powertrainsLoadingStatus
        };
    });
