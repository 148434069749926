import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconCheckMark } from 'svgIcons/MotionPortalIcons';
import colors from 'theme/_colors.scss';

const EmptyNotificationComponent = () => {
    const { t: translate } = useTranslation();
    return (
        <div className='d-flex align-items-center justify-content-center flex-column my-3'>
            <IconCheckMark width='32px' height='32px' color={colors.doveGray} />
            <div className='empty-notification-text mt-2'>
                {translate('ABB.Powertrain.Frontend.emptyNotificationMsg')}
            </div>
        </div>
    );
};

export default EmptyNotificationComponent;
