import { createSelector } from 'reselect';
import { getAllAssets } from 'helpers/selectors';

const getSubscriptionStatus = ({ powertrainTools }) => {
    return powertrainTools?.subscriptionStatus?.subscriptionArr?.data?.assetSubscriptionsInfos;
};

const getSubscriptionLoadingStatus = ({ powertrainTools }) => {
    return powertrainTools?.subscriptionStatus?.subscriptionArr?.loadingStatus;
};

const getNewCapability = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails?.newAssetCapability;
};

const getProductTypeCode = ({ assetOverview }) => {
    return assetOverview?.assetOverview;
};

const getAssetOnline = ({ assetOverview }) => {
    return assetOverview?.assetOverview?.dataConnectionStatus;
};

const getLastStatusChangeRecievedTimestamp = ({ assetOverview }) => {
    return assetOverview?.assetOverview?.lastStatusChangeRecievedTimestamp;
};

const getConnectivityType = ({ assetOverview }) => {
    return assetOverview?.assetOverview?.connectivityType;
};

const getAssetLabels = ({ assetDetailsInfoHeader }) => {
    return assetDetailsInfoHeader.labels.data;
};

const getAssetDetailsLabelsLoadingStatus = ({  assetDetailsInfoHeader }) => {
    return assetDetailsInfoHeader?.labels?.loadingStatus;
};

export const getModel = createSelector([
    getSubscriptionStatus,
    getSubscriptionLoadingStatus,
    getAllAssets,
    getNewCapability,
    getProductTypeCode,
    getAssetOnline,
    getLastStatusChangeRecievedTimestamp,
    getConnectivityType,
    getAssetLabels,
    getAssetDetailsLabelsLoadingStatus
], (
    subscriptionStatus,
    subscriptionLoadingStatus,
    allAssets,
    newSelectedAssetCapability,
    productTypeCode,
    assetOnline,
    lastStatusChangeRecievedTimestamp,
    connectivityType,
    assetLabels,
    assetDetailsLabelsLoadingStatus
) => {
    return {
        subscriptionStatus,
        subscriptionLoadingStatus,
        allAssets,
        newSelectedAssetCapability,
        productTypeCode,
        assetOnline,
        lastStatusChangeRecievedTimestamp,
        connectivityType,
        assetLabels,
        assetDetailsLabelsLoadingStatus
    };
});
