import React, { useState, useEffect } from 'react';
import map from 'lodash/map';
import { useTranslation } from 'react-i18next';
import {
    formatSensorGatewayInfo,
} from './helpers';


const SensorGatewayInfoDeatils = ({ sensorGatewayInfo }) => {

    const { t: translate } = useTranslation();
    const [sensorGatewayDetails, setSensorGatewayDetails] = useState([]);


    useEffect(() => {
        setSensorGatewayDetails(formatSensorGatewayInfo(sensorGatewayInfo, translate));
    }, [sensorGatewayInfo]);

    return map(sensorGatewayDetails, (el, index) => {
        return el ?
            <div key={index} className='sensor-properties-list-item'>
                <div className='data-label'>{el?.label}</div>
                <div className='data-value'>{el?.value}
                </div>
            </div> : null;
    });
};

export default SensorGatewayInfoDeatils;
