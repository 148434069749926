import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { map } from 'lodash';
import { getToggleOptions } from './helpers';
import { tabContainerIds } from './constants';
import { OperationalParametersHeader } from './OperationalParametersHeader';
import { DownloadMeasurements } from '../Header/DownloadMeasurements';

import './style.scss';

const OperationalParametersComponent = ({
    model: {
        activeTab,
        multiChart,
        measurementDownloadDialogParams
    },
    asset,
    actions
}) => {
    // if we need newcapability service then change to useNewCapability shared hook
    const toggleOptions = getToggleOptions(multiChart);

    const closeDownloadMeasurement = () => {
        actions.clearDownloadMeasurementStore();
    };

    return toggleOptions.length ?
        <Container fluid id='operational-parameters-container'>
            <OperationalParametersHeader toggleOptions={toggleOptions} />
            <DownloadMeasurements
                show={measurementDownloadDialogParams?.measurementTypeIds?.length > 0}
                close={() => closeDownloadMeasurement()} />
            <Row className='operational-parameter-tab-chart-row'>
                <Col md={12} className='operational-parameter-tab-container'>
                    {
                        map(toggleOptions, (opt) => {
                            return <div key={opt.value} style={{ display: opt.value === activeTab ? null : 'none' }} id={tabContainerIds[opt.value]}>
                                <opt.component activeTab={activeTab} asset={asset} multiChart={multiChart} />
                            </div>;
                        })
                    }
                </Col>
            </Row>
        </Container> : null;
};

OperationalParametersComponent.propTypes = {
    model: PropTypes.shape({
        activeTab: PropTypes.string,
    }),
    asset: PropTypes.object,
};

export default OperationalParametersComponent;
