import { createSelector } from 'reselect';


const getSelectedAsset = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails.selectedAsset;
};

const getAssetMuteSettings = ({ muteAsset }) => {
    return muteAsset.assetMuteSettings?.data;
};

const getAssetMuteSettingsLoadingStatus = ({ muteAsset }) => {
    return muteAsset.assetMuteSettings?.loadingStatus;
};

const getUpdateMuteSettingsLoadingStatus = ({ muteAsset }) => {
    return muteAsset.updateAssetMuteSettingsLoadingStatus;
};

const getSelectedOrganization = ({ generalFilter }) => {
    return generalFilter?.selectedFilters?.organizations[0];
};

export const getModel = createSelector([
    getSelectedAsset,
    getAssetMuteSettings,
    getAssetMuteSettingsLoadingStatus,
    getUpdateMuteSettingsLoadingStatus,
    getSelectedOrganization
],
    (
        selectedAsset,
        assetMuteSettings,
        assetMuteSettingsLoadingStatus,
        updateMuteSettingsLoadingStatus,
        selectedOrganization
    ) => {
        return {
            selectedAsset,
            assetMuteSettings,
            assetMuteSettingsLoadingStatus,
            updateMuteSettingsLoadingStatus,
            selectedOrganization
        };
    });
