import React from 'react';
import { useTranslation } from 'react-i18next';

const EnvironmentSensorsHeaderComponent = () => {
    const { t: translate } = useTranslation();
    return (
        <div className='environment-sensor-list__header'>
            <div className='environment-sensor-list__cell environment-sensor-list__cell--sticky'>{translate('ABB.Powertrain.Frontend.drivesDataAssetName')}</div>
            <div className='environment-sensor-list__cell'>
                {translate('ABB.Powertrain.Frontend.environmentSensorsLastSignalReceived')}
            </div>
        </div>
    );
};

export default EnvironmentSensorsHeaderComponent;
