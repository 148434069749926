import React from 'react';
import PropTypes from 'prop-types';
const IconGatewayFilter = ({ width = 16, height = 16, viewBox = '0 0 16 16', color = '#1F1F1F' }) => {
    return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M10 15H8.7L6 12V7L1 2V1H15V2L10 7V15Z' fill={color} />
    </svg>;
};
IconGatewayFilter.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    viewBox: PropTypes.string,
    color: PropTypes.string
};
export default IconGatewayFilter;
