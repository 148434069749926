import { filter, map, reduce, find } from 'lodash';
import { allAssetTypes, gatewayTypes, statuses, dataSources, ASSET_TYPES_MS, ASSET_TYPES_MS_ID } from './constants';


export const isSubstringOf = (haystack, needle) => {
    return haystack && needle && haystack?.toString()?.toLowerCase()?.includes(needle?.toLowerCase());
};

const getSelectedConditionIndexStatus = (itemWithStatuses, conditionIndexFilter) => {
    return conditionIndexFilter === statuses?.ALL
        ? itemWithStatuses?.overallStatus
        : itemWithStatuses?.statuses[conditionIndexFilter]?.status;
};

export const passesFilter = (filterAvailable, filterCondition) => {
    return filterAvailable
        ? filterCondition
        : true;
};

export const matchesWithTheSearchFilter = (asset, searchTerm) => {

    const { serialNumber, componentID, id, legacyAssetId, assetId, powertrainId, componentName, name, systemName } = asset;
    const propertiesToFilter = [serialNumber, componentID, id, legacyAssetId, assetId, powertrainId, componentName, name, systemName];

    return systemName
        ? isSubstringOf(systemName, searchTerm)
        : reduce(
            propertiesToFilter,
            (acc, nextProperty) => acc || isSubstringOf(nextProperty, searchTerm),
            false);
};

const matchesWithThePowertrainSearchFilter = (powertrain, searchTerm) => {
    if (!powertrain || !searchTerm) {
        return false;
    }

    return isSubstringOf(powertrain?.powertrainId, searchTerm) ||
        isSubstringOf(powertrain?.name, searchTerm) ||
        isSubstringOf(powertrain?.id, searchTerm);
};

export const filterAssets = (assets, { conditionStatusFilter, searchboxFilter, conditionIndexFilter, gatewayTypeFilter, assetTypeFilter }) => {
    const isConditionStatusFilterSet = Object.values(conditionStatusFilter).reduce((acc, next) => acc || next);
    return filter(assets, (asset) => {
        return passesFilter(isConditionStatusFilterSet, conditionStatusFilter[getSelectedConditionIndexStatus(asset, conditionIndexFilter || statuses.ALL)])
            && passesFilter(searchboxFilter && searchboxFilter.length, matchesWithTheSearchFilter(asset, searchboxFilter?.toLowerCase()))
            && passesFilter(gatewayTypeFilter, gatewayTypeFilter === gatewayTypes.ALL || gatewayTypeFilter === asset?.gatewayType)
            && passesFilter(assetTypeFilter, assetTypeFilter === allAssetTypes || assetTypeFilter === asset?.componentType?.typeName);
    });
};

export const filterPowertrains = (powertrains, filters) => {
    if (!powertrains) {
        return [];
    }
    const { searchboxFilter } = filters;

    const filteredPowertrains = map(powertrains, powertrain => {
        const powertrainMatches = !searchboxFilter ||
            matchesWithThePowertrainSearchFilter(powertrain, searchboxFilter);

        if (powertrainMatches) {
            return {
                ...powertrain,
                assets: powertrain?.assets || []
            };
        }

        const filteredAssets = powertrain?.assets?.filter(asset =>
            !searchboxFilter ||
            isSubstringOf(asset?.id, searchboxFilter) ||
            isSubstringOf(asset?.serialNumber, searchboxFilter) ||
            isSubstringOf(asset?.name, searchboxFilter) ||
            isSubstringOf(asset?.smSeId, searchboxFilter)
        );

        return filteredAssets && filteredAssets?.length > 0 ? { ...powertrain, assets: filteredAssets } : null;
    });

    return filteredPowertrains?.filter(powertrain => powertrain !== null);
};

const assetTypeIncluded = (powertrain, assetTypeFilter) => {
    const assetTypeMatching = (asset) => asset?.componentType?.typeName === assetTypeFilter;
    return powertrain?.components?.some(assetTypeMatching);
};

export const filterPowertrainsOverview = (powertrains, { conditionStatusFilter, searchboxFilter, assetTypeFilter }) => {
    const isConditionStatusFilterSet = Object.values(conditionStatusFilter).reduce((acc, next) => acc || next);
    const isAssetFilterSet = assetTypeFilter !== allAssetTypes;


    return filter(powertrains, powertrain => {
        return (
            passesFilter(
                isConditionStatusFilterSet,
                conditionStatusFilter[powertrain?.overallStatus]
            ) &&
            passesFilter(
                searchboxFilter && searchboxFilter?.length,
                matchesWithTheSearchFilter(powertrain, searchboxFilter?.toLowerCase())
            ) &&
            passesFilter(
                isAssetFilterSet,
                assetTypeIncluded(powertrain, assetTypeFilter)
            )
        );
    });
};

export const isAssetInCurrentContext = (filterValue, assets) => {
    return !!find(assets, (asset) => {
        return asset.componentID === filterValue ||
            asset.serialNumber && asset.serialNumber?.toLowerCase() === filterValue?.toLowerCase();
    });
};


// eslint-disable-next-line import/no-unused-modules
export const getAssetByAnyId = (assets, filterValue) => {
    return find(assets, (asset) => {
        return asset?.id?.toString() === filterValue ||
            asset?.serialNumber && asset.serialNumber?.toLowerCase() === filterValue?.toLowerCase()
            || asset?.assetId?.toString() === filterValue;
    });
};


// eslint-disable-next-line import/no-unused-modules
export const getAssetDataSourceType = (asset) => {
    return asset?.assetType === ASSET_TYPES_MS?.DRIVE ? dataSources?.CMD4 : dataSources?.SMARTSENSOR;
};
// eslint-disable-next-line import/no-unused-modules
export const getAssetTypeId = (typeValue) => {
    return ASSET_TYPES_MS_ID[typeValue];

};

export const getLegacyIdFromAsset = (asset) => {
    return asset?.assetType === ASSET_TYPES_MS?.DRIVE ? asset?.serialNumber : asset?.smSeId;
};
