import { createSelector } from 'reselect';
import { getContractInfo } from '../../helpers';

const getNotifications = ({ notificationStatusBar }) => {
	return notificationStatusBar.data;
};

const selectContractInfo = ({ powertrainTools }) => {
	return powertrainTools?.subscriptionStatus?.subscriptionArr?.data?.assetSubscriptionsInfos[0]?.subscriptionInfos;
};

const capabilityLoadingStatus = ({ powertrainTools }) => {
	return powertrainTools?.subscriptionStatus?.subscriptionArr?.loadingStatus;
};

const getAssetDetails = ({ detailedInformation }) => {
	return detailedInformation.assetDetails?.data;
};

const selectedAssetSubscriptionInfo = ({ powertrainTools }) => {
	return powertrainTools?.subscriptionStatus?.subscriptionArr?.data?.assetSubscriptionsInfos[0]?.subscriptionInfos[0]?.type;
};

const getsubscription = createSelector([getAssetDetails, selectContractInfo, capabilityLoadingStatus],
	(assetDetails, contractInfo, loadingStatus) => {
		return {
			contractInfo: getContractInfo(assetDetails, contractInfo, loadingStatus)
		};
	}
);

export const getModel = createSelector([
	getNotifications,
	selectContractInfo,
	selectedAssetSubscriptionInfo,
	getsubscription
], (
	notification,
	contractInfo,
	assetSubscriptionInfo,
	subscription
) => {
	return {
		notification,
		contractInfo,
		assetSubscriptionInfo,
		subscription
	};
});
