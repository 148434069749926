import { createSelector } from 'reselect';
import { formatAssetInfoData, formatSections } from './helpers';
import { getOrganizations } from 'helpers/selectors';


const getSelectedAsset = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails.selectedAsset;
};

const getAssetDetails = ({ detailedInformation }) => {
    return detailedInformation.assetDetails;
};

const getSubscriptionStatus = ({ powertrainTools }) => {
    return powertrainTools?.subscriptionStatus?.subscriptionArr?.data?.assetSubscriptionsInfos;
};
const selectContractInfo = ({ powertrainAssetDetails }) => {
    if (powertrainAssetDetails?.selectedAssetCapability?.data?.componentID === powertrainAssetDetails?.selectedAsset?.componentID) {
        return powertrainAssetDetails.selectedAssetCapability?.data?.contractInfo?.message ?
            powertrainAssetDetails.selectedAssetCapability?.data?.contractInfo : null;
    }
    else {
        return null;
    }
};

const getAssetDetailsSelector = createSelector([getSelectedAsset, getAssetDetails, getOrganizations, getSubscriptionStatus, selectContractInfo],
    (selectedAsset, assetDetails, organizations, subscriptionStatus) => {
        return {
            details: formatAssetInfoData({
                ...assetDetails.data?.details,
                organizationName: selectedAsset?.organization?.name,
                siteName: selectedAsset?.site?.name,
                countryName: selectedAsset?.site?.country,
                subscriptionCode: subscriptionStatus?.length > 0 && subscriptionStatus[0]?.subscriptionInfos && subscriptionStatus[0]?.subscriptionInfos.length > 0 ? subscriptionStatus[0]?.subscriptionInfos[0]?.type ? subscriptionStatus[0]?.subscriptionInfos[0]?.type : '-' : '-',
                subscriptionEndDate: subscriptionStatus?.length > 0 && subscriptionStatus[0]?.subscriptionInfos && subscriptionStatus[0]?.subscriptionInfos.length > 0 ? subscriptionStatus[0]?.subscriptionInfos[0]?.endDate ? subscriptionStatus[0]?.subscriptionInfos[0]?.endDate : '-' : '-'
            }),
            sections: formatSections(assetDetails.data?.sections),
            loadingStatus: assetDetails?.loadingStatus
        };
    });


export const getModel = createSelector([
    getAssetDetailsSelector
], (
    assetDetails
) => {
    return {
        assetDetails
    };
});
