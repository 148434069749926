import { getApiActionCreator } from 'helpers/actionHelper';
import { actionTypes } from './constants';
import { loadPowertrainsV2 } from '../../GeneralFilter/GeneralFilterModal/actions';


export const getPowertrainsAction = getApiActionCreator(actionTypes.GET_POWERTRAINS);
export const deletePowertrainAction = getApiActionCreator(actionTypes.DELETE_POWERTRAIN);

//TO DO: edit when common powertrain list is used for configuration and overview
export const getOverviewPowertrainsAction = getApiActionCreator(actionTypes.GET_OVERVIEW_POWERTRAINS);

export const setSelectedPowertrain = (powertrain) => {
    return {
        type: actionTypes.SET_SELECTED_POWERTRAIN,
        powertrain
    };
};

export const clearStore = () => {
    return (dispatch) => {
        dispatch({ type: actionTypes.CLEAR_STORE });
    };
};

export const setSearchboxFilter = (searchboxFilter) => {
    return {
        type: actionTypes.SET_SEARCHBOX_FILTER,
        searchboxFilter
    };
};

export const setPowertrainForEditing = (powertrain) => {
    return {
        type: actionTypes.SET_POWERTRAIN_FOR_EDITING,
        powertrain
    };
};

export const getPowertrainsRequest = (siteIds, organizationIds, nameFilter, pageNumber) => ({
    type: actionTypes.LOAD_POWERTRAINS_REQUEST,
    siteIds,
    organizationIds,
    nameFilter,
    pageNumber
});

export const deletePowertrainRequest = (powertrainId, organizationIds, siteIds, nameFilter) => ({
    type: actionTypes.DELETE_POWERTRAIN_REQUEST,
    powertrainId,
    organizationIds,
    siteIds,
    nameFilter
});

//TO DO: edit when common powertrain list is used for configuration and overview
export const reloadOverviewPowertrainsRequest = () => {
    return (dispatch, getState) => {
        const sites = getState().generalFilter.sites.data;
        const selectedFilters = getState().generalFilterEdit.selectedFilters;
        loadPowertrainsV2(sites, selectedFilters?.organizations, true, dispatch);
    };
};
