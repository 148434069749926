import { actionTypes } from './constants';

const initialState = {
    showSwitchOrgModal: false
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SHOW_SWITCH_ORG_MODAL: {
            return {
                ...state,
                showSwitchOrgModal: action.show
            };
        }
        default:
            return state;
    }
}
