import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';

const IconCopy = ({ width = '14px', height = '15px', color = colors.codGray }) => {
    return <svg width={width} height={height} viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M8 9H9V6H12V5H9V2H8V5H5V6H8V9ZM13 0H4C3.73478 0 3.48043 0.105357 3.29289 0.292893C3.10536 0.48043 3 0.734784 3 1V4H0V14H10V11H13C13.2652 11 13.5196 10.8946 13.7071 10.7071C13.8946 10.5196 14 10.2652 14 10V1C14 0.734784 13.8946 0.48043 13.7071 0.292893C13.5196 0.105357 13.2652 0 13 0ZM13 10H4V1H13V10Z' fill={color}/>
    </svg>;
};

IconCopy.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
};

export default IconCopy;
