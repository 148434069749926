import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    ScrollableList
} from 'sharedComponents';
import {
    IconDataLogger,
    IconSortDecending,
    IconWarningType
} from 'svgIcons/MotionPortalIcons';
import { InfoButtonWithOnlineHelp } from 'sharedComponents/InfoButtonWithOnlineHelp';
import { AssetOverviewDetails } from '../../AssetOverviewDeatils';
import { apiCallStatus } from 'helpers/constants';
import colors from 'theme/_colors.scss';
import './style.scss';


const ScrollableListComponent = ({
    noDataLabel,
    className,
    loadingStatus,
    hasMore,
    showThreshold,
    showTrend,
    pageSize,
    loadMore,
    renderList,
    selectedAsset,
    hasData,
    selectedPowertrain,
    selectedAssetGroup,
    asset,
    onReset,
    showAsset,
    onHorizontalScroll
}) => {
    const { t: translate } = useTranslation();
    const [leftWidth, setLeftWidth] = useState(100);
    const [isDragging, setIsDragging] = useState(false);
    const [showRightContainer, setShowRightContainer] = useState(false);
    const [horizontalScrollActive, setHorizontalScrollActive] = useState(false);
    const horizontalScrollRef = useRef(null);
    const selectedTab = useSelector(state => state?.conditionIndices?.selectedTab);

    const resetState = useCallback(() => {
        setLeftWidth(100);
        setShowRightContainer(false);
        if (onReset) {
            onReset();
        }
    }, [onReset]);

    useEffect(() => {
        if (!showAsset || loadingStatus === apiCallStatus.LOADING || !hasData?.length) {
            resetState();
            setLeftWidth(100);
        } else if (selectedAsset?.assetId && loadingStatus !== apiCallStatus.LOADING) {
            if (leftWidth === 100) {
                setLeftWidth(70);
            }
            setShowRightContainer(true);
        } else {
            resetState();
        }
    }, [loadingStatus, selectedAsset, hasData, showAsset]);

    const handleMouseDown = useCallback(() => {
        setIsDragging(true);
    }, []);

    const handleMouseUp = useCallback(() => {
        setIsDragging(false);
    }, []);

    const handleMouseMove = useCallback(
        (e) => {
            if (isDragging) {
                const containerRect = e.currentTarget.getBoundingClientRect();
                const newLeftWidth = (e.clientX - containerRect.left) / containerRect.width * 100;

                const constrainedLeftWidth = Math.min(Math.max(newLeftWidth, 50), 70);
                setLeftWidth(constrainedLeftWidth);
            }
        },
        [isDragging]
    );

    const handleMouseTouchMove = useCallback(
        (e) => {
            if (isDragging) {
                const containerRect = e.currentTarget.getBoundingClientRect();
                const clientX = e.touches[0]?.clientX;
                const newLeftWidth = (clientX - containerRect.left) / containerRect.width * 100;

                const constrainedLeftWidth = Math.min(Math.max(newLeftWidth, 50), 70);
                setLeftWidth(constrainedLeftWidth);
            }
        },
        [isDragging]
    );

    const onhorizontalScroll = () => {
        const {
            scrollLeft,
            scrollTop,
            scrollHeight,
            clientHeight
        } = horizontalScrollRef.current;
        if (horizontalScrollRef?.current) {
            if (scrollLeft > 0) {
                setHorizontalScrollActive(true);
            } else {
                setHorizontalScrollActive(false);
            }
            onHorizontalScroll(scrollLeft > 0);
        }
        if (hasMore && loadMore && typeof loadMore === 'function' &&
            loadingStatus !== apiCallStatus.LOADING &&
            horizontalScrollRef.current) {
            if (Math.round(scrollTop) + clientHeight === scrollHeight) {
                loadMore();
            }
        }
    };

    const renderInfoTooltip = (description) => {
        return <InfoButtonWithOnlineHelp infoText={description} />;
    };

    const renderHeader = () => {
        return <div className='asset-list-header'>
            <div
                className={`
                header-item header-item--cell-fixed
                ${horizontalScrollActive ? 'horizontal-scroll-active' : ''}
                ${className} ${loadingStatus === apiCallStatus.LOADING ? 'loading' : ''}`}
            >
                {translate('ABB.Powertrain.Frontend.conditionIndicesOverviewNameColumnTitle')}
            </div>
            <div className='header-item header-item--first-cell'>
                {translate('ABB.Powertrain.Frontend.conditionIndicesOverviewConditionIndices')}
                <div className='column-icon-right'>
                    <IconSortDecending color={colors.black} />
                </div>
            </div>
            <div className='header-item'>
                {translate('ABB.Powertrain.Frontend.limitsTitle')}
            </div>
            {showThreshold &&
                <div className='header-item'>
                    <div className='column-icon'>
                        <IconWarningType color={colors.black} />
                    </div>
                    {translate('ABB.Powertrain.Frontend.conditionIndicesOverviewThresholdColumnTitle')}
                    {renderInfoTooltip(translate('ABB.Powertrain.Frontend.thresholdColumnInfoText'))}
                </div>}
            {showTrend &&
                <div className='header-item'>
                    <div className='column-icon'>
                        <IconDataLogger heigth='16px' width='16px' />
                    </div>
                    {translate('ABB.Powertrain.Frontend.conditionIndicesOverviewTrendColumnTitle')}
                    {renderInfoTooltip(translate('ABB.Powertrain.Frontend.trendColumnInfoText'))}
                </div>
            }
        </div>;
    };

    return (
        <div className='overview-list resizable-layout'
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}>
            <div className='left-container' style={{ width: `${leftWidth}%` }}>
                <div
                    role='scrollable-list'
                    className={`overview-scrollable-list ${className} ${loadingStatus === apiCallStatus.LOADING ? 'overview-scrollable-list__render' : ''}`}
                    onScroll={onhorizontalScroll}
                    ref={horizontalScrollRef}
                >
                    <div className={`list-item ${selectedTab?.toLowerCase()}`}>
                        {renderHeader()}
                        <ScrollableList
                            emptyLabel={noDataLabel}
                            withLoading
                            loadingStatus={loadingStatus}
                            hasMore={hasMore}
                            pageSize={pageSize}
                            loadMore={() => loadMore()}
                            noWrapper={true}
                        >
                            {renderList()}
                        </ScrollableList>

                    </div>
                </div>
            </div>
            {
                showAsset && showRightContainer &&
                <>
                    <div className='resize-handle' onTouchStartCapture={handleMouseDown} onMouseDown={handleMouseDown}>
                        <button className='resize-handle__button' onTouchStartCapture={handleMouseDown} onTouchEndCapture={handleMouseUp} onTouchMove={handleMouseTouchMove} onMouseDown={handleMouseDown}>
                            <IconAdjust />
                        </button>
                    </div>
                    <div className='right-container' style={{ width: `${100 - leftWidth}%` }}>
                        <div className={`asset-overview-details ${className}`}>
                            <AssetOverviewDetails
                                selectedAsset={selectedAsset}
                                data={hasData}
                                selectedPowertrain={selectedPowertrain}
                                selectedAssetGroup={selectedAssetGroup}
                                asset={asset}
                            />
                        </div>
                    </div>
                </>
            }
        </div>
    );
};

const IconAdjust = () => {
    return (
        <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='10' cy='3.5' r='1' transform='rotate(90 10 3.5)' fill='#696969' />
            <circle cx='6' cy='3.5' r='1' transform='rotate(90 6 3.5)' fill='#696969' />
            <circle cx='10' cy='8.5' r='1' transform='rotate(90 10 8.5)' fill='#696969' />
            <circle cx='6' cy='8.5' r='1' transform='rotate(90 6 8.5)' fill='#696969' />
            <circle cx='10' cy='13.5' r='1' transform='rotate(90 10 13.5)' fill='#696969' />
            <circle cx='6' cy='13.5' r='1' transform='rotate(90 6 13.5)' fill='#696969' />
        </svg>
    );
};

ScrollableListComponent.propTypes = {
    noDataLabel: PropTypes.string,
    className: PropTypes.string,
    loadingStatus: PropTypes.string,
    hasMore: PropTypes.bool,
    pageSize: PropTypes.number,
    loadMore: PropTypes.func,
    renderList: PropTypes.func,
    selectedAsset: PropTypes.object,
    showThreshold: PropTypes.bool,
    showTrend: PropTypes.bool
};

export default ScrollableListComponent;
