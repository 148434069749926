import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getModel } from './selectors';
import GeneralFilterComponent from './GeneralFilterComponent';
import * as actions from './actions';
import { apiCallStatus } from 'helpers/constants';
import { clearStore } from '../../PowertrainAssetDetails/CrossAssetAnalysis/ChartView/actions';
import { useQueryParams } from 'sharedHooks/useQuery';

const GeneralFilterContainer = (props) => {
    const {
        selectedFilters: { organizations },
        loadingData: {
            organizationsLoadingStatus,
            sitesLoadingStatus,
            assetGroupsLoadingStatus
        },
        rawSites,
        isAdmin,
        isCIAMSyncFlag,
        syncSmartSensorBEFlag
    } = props.model;
    const queryParams = useQueryParams();

    useEffect(() => {
        if (organizationsLoadingStatus === apiCallStatus.SUCCESS
            && sitesLoadingStatus === apiCallStatus.SUCCESS
            && assetGroupsLoadingStatus === apiCallStatus.SUCCESS
            && organizations.length
            && Array.isArray(rawSites)) {
            let refreshFlag = true;
            if (isAdmin) {
                if (isCIAMSyncFlag) { refreshFlag = true; }
                else { refreshFlag = false; }
            }
            props?.actions?.launchCIAMSyncForAdmin(organizations, refreshFlag, queryParams?.orgId, syncSmartSensorBEFlag);
            props?.actions?.setCIAMSyncFlag(false);
        }
    }, [sitesLoadingStatus, organizationsLoadingStatus, assetGroupsLoadingStatus, organizations]);


    return <GeneralFilterComponent {...props} />;
};

const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, clearStore }, dispatch)
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GeneralFilterContainer);
