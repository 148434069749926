import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import { InfoMessage, LoadingStatusWrapper, PopOutChart } from 'sharedComponents';
import useChartConfig, { chartPlugins } from './useChartConfig';
import { scatterContainerId } from './constants';

import '../style.scss';

const ScatterPlotComponent = ({
    asset,
    chartData,
    removeKPIData,
    chartLoadingStatus,
    isZoomed,
    setIsZoomed
}) => {
    const chartOptions = useChartConfig(chartData, removeKPIData, asset);

    return (
        <Container fluid className='cross-asset-chart-container'>
            <LoadingStatusWrapper
                useCustomAnchor
                transparent
                loadingStatus={chartLoadingStatus}>
                <PopOutChart
                    hasKpi={!!chartData?.length}
                    hasData={!!chartData?.length}
                    renderNoKpi={() => <InfoMessage
                        title='ABB.Powertrain.Frontend.chartNoKPIsSelected'
                        text='ABB.Powertrain.Frontend.chartNoKPIsSelectedUserGuide' />}
                    renderNoData={() => <InfoMessage
                        title='ABB.Powertrain.Frontend.chartNoMeasurementData'
                        text='ABB.Powertrain.Frontend.chartNoMeasurementDataUserGuide' />}
                    loadingStatus={chartLoadingStatus}
                    options={chartOptions}
                    modules={chartPlugins}
                    container={scatterContainerId}
                    isZoomed={isZoomed}
                    setIsZoomed={setIsZoomed}
                    hideDownloadButton={true}
                />
            </LoadingStatusWrapper>
        </Container>
    );
};

ScatterPlotComponent.propTypes = {
    actions: PropTypes.shape({
        setSelectedDates: PropTypes.func,
        getChartData: PropTypes.func,
        getChartDataForNewDate: PropTypes.func,
        removeKPIData: PropTypes.func
    }),
    activeTab: PropTypes.string,
    toggleOptions: PropTypes.array,
    handleTabSelection: PropTypes.func,
    model: PropTypes.shape({
        dateSelection: PropTypes.shape({
            from: PropTypes.any,
            to: PropTypes.any
        }),
        selectedKpi: PropTypes.object,
        chartData: PropTypes.array,
        asset: PropTypes.object,
        chartLoadingStatus: PropTypes.string,
        selectedKPIType: PropTypes.number
    })
};

export default ScatterPlotComponent;


