export const filterTabs = {
    POWERTRAINS: 'Powertrains',
    ASSETS: 'Assets'
};

export const actionTypes = {
    SET_ACTIVE_TAB: 'powertrainAssetDetails/SET_ACTIVE_TAB',
    SET_SELECTED_ASSET: 'powertrainAssetDetails/SET_SELECTED_ASSET',
    SET_SELECTED_POWERTRAIN: 'powertrainAssetDetails/SET_SELECTED_POWERTRAIN',
    GET_ASSET_CAPABILITY: 'powertrainAssetDetails/GET_ASSET_CAPABILITY',
    GET_ASSET_NEWCAPABILITIES: 'powertrainAssetDetails/GET_ASSET_NEWCAPABILITY',
    UPDATE_ASSET_SUCCESS: 'overview/UPDATE_ASSET_SUCCESS',
    SET_SELECTED_FILTERS: 'generalFilter/SET_SELECTED_FILTERS',
    LOAD_ASSET_CONDITION_INDICES_REQUEST: 'powertrainAssetsOverview/LOAD_ASSET_CONDITION_INDICES_REQUEST',
    CLEAR_STORE: 'powertrainAssetDetails/CLEAR_STORE'
};

export const scrollToViewDelayInAssetDetails = 300;

export const pagesWithTrendChart = {
    operationalParameters: 'operationalParameters',
    operationalParametersV2: 'operationalParametersV2',
    crossAssetVisualization: 'crossAssetVisualization'
};

export const pageSize = 500;
