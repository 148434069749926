import { createSelector } from 'reselect';
import { getActiveTab } from '../selectors';

const getFFTData = ({ operationalParametersV2 }) => {
    return operationalParametersV2?.FFTV2?.data;
};

const hasRawData = ({ operationalParametersV2 }) => {
    return operationalParametersV2?.FFTV2?.data && Object.hasOwn(operationalParametersV2.FFTV2.data, 'dataValues')
        && operationalParametersV2.FFTV2.data.dataValues.length !== 0;
};

const getFFTDataLoadingStatus = ({ operationalParametersV2 }) => {
    return operationalParametersV2?.FFTV2?.loadingStatus;
};

const getSensortypeID = ({ detailedInformation }) => {
    return detailedInformation?.assetDetails?.data?.details?.sensorTypeID;
};

const getSpeed = ({ detailedInformation }) => {
    const nameplateData = detailedInformation.assetDetails?.data?.sections.find(
        (section) => section.sectionKey === 'NameplateData')?.data;
    const nameplateSpeed = nameplateData?.find(
        (entry) => entry.propertyKey === 'Nameplate_Speed'
    )?.propertyValue;
    const nameplateFrequency = nameplateData?.find(
        (entry) => entry.propertyKey === 'Nameplate_LineFrequency'
    )?.propertyValue;
    return nameplateFrequency && nameplateSpeed && nameplateSpeed / nameplateFrequency;
};

export const getModel = createSelector([
    getActiveTab,
    getFFTData,
    getFFTDataLoadingStatus,
    hasRawData,
    getSpeed,
    getSensortypeID
], (
    activeTab,
    fftData,
    fftDataLoadingStatus,
    hasRawData,
    speed,
    sensorTypeID
) => {
    return {
        activeTab,
        fftData,
        fftDataLoadingStatus,
        hasRawData,
        speed,
        sensorTypeID
    };
});
