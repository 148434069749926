import map from 'lodash/map';
import { ASSET_TYPES_MS, ASSET_TYPES_MS_ID, dataSources } from 'helpers/constants';
export const assetObjectToComponentRequest = (asset) => {
	//recent change related Component and Legacy replacemnt.
	const componentID = asset?.assetType === ASSET_TYPES_MS?.DRIVE ? asset?.serialNumber : asset?.smSeId;
	const componentTypeID = ASSET_TYPES_MS_ID[asset?.assetType];
	const dataSourceType = asset?.assetType === ASSET_TYPES_MS?.DRIVE ? dataSources?.CMD4 : dataSources?.SMARTSENSOR;
	return {
		ComponentID: componentID,
		TypeID: componentTypeID,
		DataSourceType: dataSourceType,
	};
};

export const assetObjectToComponentRequest2 = (asset) => {
	const componentID = asset?.assetType === ASSET_TYPES_MS?.DRIVE ? asset?.serialNumber : asset?.smSeId;
	const componentTypeID = ASSET_TYPES_MS_ID[asset?.assetType];
	const dataSourceType = asset?.assetType === ASSET_TYPES_MS?.DRIVE ? dataSources?.CMD4 : dataSources?.SMARTSENSOR;
	return {
		componentID: componentID,
		componentTypeID: componentTypeID,
		dataSourceType: dataSourceType
	};
};

export const mapToOrganizationRequestBody = (selectedOrgFilters) => {
	return map(selectedOrgFilters, (o) => { return { organizationID: o?.organizationID, dataSourceType: o?.dataSourceType }; });
};

//export const
