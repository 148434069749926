import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from './actions';
import DataLoggerComponent from './DataLoggerComponent';
import { getModel } from './selectors';

const DataLoggerContainer = (props) => {

    useEffect(() => {
        return () => {
            props.actions.clearStore();
        };
    }, []);

	return <DataLoggerComponent {...props} />;
};

const mapStateToProps = (state) => {
    const model = getModel(state);
    return { model };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
};

const DataLoggerContainerConn = connect(
    mapStateToProps,
    mapDispatchToProps
)(DataLoggerContainer);

export default DataLoggerContainerConn;
