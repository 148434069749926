import { createSelector } from 'reselect';
import { getSelectedOrganization, getLoadingStatuses } from 'helpers/selectors';

const getMaintananceSchedule = ({ powertrainTools }) => {
    return powertrainTools.sensorFirmwareManagement.maintenanceSchedule ?? {};
};

const getExternalID = ({ powertrainTools }) => {
    return powertrainTools.sensorFirmwareManagement.externalID ?? null;
};

export const getModel = createSelector([
    getExternalID,
    getMaintananceSchedule,
    getSelectedOrganization,
    getLoadingStatuses
],
    (
        externalId,
        maintenanceSchedule,
        organizationID,
        generalFilterLoadingStatuses
    ) => {
        return {
            externalId,
            maintenanceSchedule,
            organizationID,
            generalFilterLoadingStatuses
        };
    });
