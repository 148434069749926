import PropTypes from 'prop-types';
import React from 'react';
import { getAssetIcon } from 'helpers/icons';
import { Col, Row } from 'react-bootstrap';
import IconCirclePlus from 'svgIcons/MotionPortalIcons/IconCirclePlus';
import IconCircleMinus from 'svgIcons/MotionPortalIcons/IconCircleMinus';
import { operations } from '../../constants';


const AssetItem = ({
    asset,
    actionType,
    action,
}) => {
    return <Row className='asset-item'>
        <Col className='asset-item-name'>
            {getAssetIcon(asset.assetType, { width: 22, height: 22 })}
            <span className='asset-item-name-label'>
                {asset?.name}
            </span>
        </Col>
        {action && <Col className='action'>
            <span onClick={() => { action(asset); }}>
                {actionType === operations.ADD ? <IconCirclePlus /> : < IconCircleMinus />}
            </span>
        </Col>}
    </Row>;
};

AssetItem.propTypes = {
    asset: PropTypes.any,
    actionType: PropTypes.string,
    action: PropTypes.func,
};

export default AssetItem;
