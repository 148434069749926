import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { routes } from 'routes';
import PropTypes from 'prop-types';
import { Col, Container, Row, Stack } from 'react-bootstrap';

import { DetailedInformation } from './DetailedInformation';
import { CrossAssetAnalysis } from './CrossAssetAnalysis';
import { GeneralRouterTabs } from 'sharedComponents';
import { getRoutes, getTabOptions } from './helpers';
import { AssetPowertrainSelector } from './AssetPowertrainSelector';
import { Breadcrumbs } from 'sharedComponents/Breadcrumbs';
import { GeneralButton } from 'sharedComponents/GeneralButton';
import { getBreadcrumbStorage, setBreadcrumbStorage } from 'helpers/breadcrumbHelper';
import './style.scss';
import { useQueryParams } from 'sharedHooks/useQuery';

const PowertrainAssetDetailsComponent = ({ model, location }) => {
    const history = useHistory();
    const routeLocation = useLocation();
    const { t: translate } = useTranslation();
    const { assetId: assetIdFromQuery } = useQueryParams();
    const selectedAssetCrossAssetAnalysisName = model?.selectedAssetCrossAssetAnalysis;
    const [tabOptions, setTabOptions] = useState(getTabOptions(translate));
    const [breadcrumbRoute, setBreadcrumbRoute] = useState([]);
    const [isCrossKPITab, setIsCrossKPITab] = useState(true);
    const [isNavigatingToCrossKPI, setIsNavigatingToCrossKPI] = useState(false);
    const [data, setData] = useState(() => {
        const storedData = getBreadcrumbStorage();
        return storedData ? storedData : location.state;
    });
    const [selectedAssetName, setSelectedAssetName] = useState(model.selectedAsset?.componentName);

    const routeData = Array.isArray(data?.routeData) ? data?.routeData[0] : data?.routeData;
    const defaultSelectedAssetId = routeData?.selectedAsset?.id || routeData?.selectedAsset?.assetId;
    const defaultSelectedAssetName = routeData?.selectedAsset?.name || routeData?.selectedAsset?.assetName;
    const assetId = model?.selectedAsset?.id ? model?.selectedAsset?.id : assetIdFromQuery ? assetIdFromQuery : defaultSelectedAssetId;
    const name = model?.selectedAsset?.name || selectedAssetCrossAssetAnalysisName || defaultSelectedAssetName;
    const powertrainName = routeData?.powertrainName || '';
    const assetGroupName = routeData?.assetGroupName || '';

    const updateBreadcrumbRoute = () => {
        const routeDetails = getBreadcrumbStorage();
        let updatedRoute;
        const routePathNames = getRoutes(routeDetails?.routeName, translate);
        const routeData = Array.isArray(routeDetails?.routeData) ? routeDetails?.routeData.length && routeDetails?.routeData[0] : routeDetails?.routeData;
        const crossKPIPath = { path: '', name: translate('ABB.Powertrain.Frontend.ptAssetDetailsCrossKPITab') };
        const conditionOverviewPath = { path: routePathNames.routePath, name: routePathNames.routeName };
        const assetDetailsPath = { path: { pathname: routes.DetailedInformation, search: new URLSearchParams({ assetId }).toString() }, name };

        if ((isNavigatingToCrossKPI || selectedAssetCrossAssetAnalysisName) && !isCrossKPITab) {
            updatedRoute = [
                { ...conditionOverviewPath },
                { ...assetDetailsPath },
                { ...crossKPIPath },
            ];
        } else if (isCrossKPITab) {
            const secondRouteName = powertrainName || assetGroupName || routeData?.assetName || routeData?.length && routeData[0]?.assetName;
            updatedRoute = [
                { ...conditionOverviewPath },
                { path: routes.Overview, name: secondRouteName }
            ];
        } else if (!model?.selectedAsset && routeLocation?.pathname?.includes('cross-asset-analysis')) { // ADDED THIS CONDITION FOR THE CASE WHEN NO ASSET IS SELECTED IN CROSS ASSET ANALYSIS
            updatedRoute = [
                { ...conditionOverviewPath },
                { ...crossKPIPath },
            ];
        } else if (model?.selectedAsset && routeLocation?.pathname?.includes('cross-asset-analysis')) { // ADDED THIS CONDITION FOR THE CASE WHEN ASSET IS SELECTED IN CROSS ASSET ANALYSIS
            updatedRoute = [
                { ...conditionOverviewPath },
                { ...assetDetailsPath },
                { ...crossKPIPath },
            ];
        } else {
            updatedRoute = [
                { ...conditionOverviewPath },
                { ...assetDetailsPath }
            ];
        }
        setBreadcrumbRoute(updatedRoute);
    };

    const navigateToDetailedInformation = (clickedSearch) => {
        setIsCrossKPITab(true);
        updateBreadcrumbRoute(false);
        const assetId = clickedSearch.get('assetId');
        if (assetId) {
            const selectedAsset = routeData?.assets ? routeData?.assets?.find(asset => asset?.id?.toString() === assetId || asset?.assetId?.toString() === assetId || asset?.serialNumber?.toString() === assetId) : routeData[0]?.assets?.find(asset => asset?.id?.toString() === assetId || asset?.assetId?.toString() === assetId || asset?.serialNumber?.toString() === assetId);
            if (selectedAsset) {
                setSelectedAssetName(selectedAsset?.assetName);
            }
        }
        history.push({
            pathname: routes.DetailedInformation,
            search: clickedSearch.toString(),
            state: location.state
        });
    };

    const updateRouteData = () => {
        const routeDetails = getBreadcrumbStorage();
        if (!routeDetails) {
            return;
        }

        const routeData = Array.isArray(routeDetails?.routeData) ? routeDetails?.routeData.length && routeDetails?.routeData[0] : routeDetails?.routeData;

        if (!routeData || !routeData.assets || routeData.assets.length === 0) {
            return;
        }
        const selectedAsset = routeData?.assets?.find(asset => asset?.assetName === selectedAssetName);
        if (selectedAsset) {
            routeData.selectedAsset = selectedAsset;
            const updatedData = {
                routeData,
                routeName: location.state?.routeName || routeDetails?.routeName
            };
            setBreadcrumbStorage(updatedData);
            setData(updatedData);
        }
    };

    const navigateToOverview = (clickedSearch) => {
        const routeDetails = getBreadcrumbStorage();
        if (!routeDetails) {
            history.push(routes.Overview);
            return;
        }

        const routeData = Array.isArray(routeDetails?.routeData) ? routeDetails?.routeData.length && routeDetails?.routeData[0] : routeDetails?.routeData;

        if (!routeData || !routeData?.assets || routeData?.assets?.length === 0) {
            history.push(routes.Overview);
            return;
        }

        const clickedAssetId = clickedSearch.get('assetId');
        const selectedAsset = clickedAssetId
            ? routeData?.assets?.find(asset => asset?.assetSerialNumber === clickedAssetId)
            : routeData?.selectedAsset || routeData?.assets[0];

        if (selectedAsset) {
            routeData.selectedAsset = selectedAsset;
            setBreadcrumbStorage(routeData);
            history.push({
                pathname: routes.Overview,
                state: { selectedAsset: selectedAsset?.assetSerialNumber }
            });
            setSelectedAssetName(selectedAsset?.assetName);
        } else {
            history.push(routes.Overview);
        }
    };

    const resetBreadcrumbRoute = () => {
        updateBreadcrumbRoute();
        setIsCrossKPITab(false);
    };

    useEffect(() => {
        setSelectedAssetName(model.selectedAsset?.name);
        setTabOptions(getTabOptions(translate));
    }, [model.selectedAsset?.name, translate]);

    useEffect(() => {
        const isPowertrainName = data?.routeData?.powertrainName;
        const isAssetGroupName = data?.routeData?.assetGroupName;
        if (model?.assets && !isPowertrainName && !isAssetGroupName) {
            let updatedBreadcrumbData = { ...data, routeData: { ...data?.routeData, assets: model?.assets } };
            // REMOVE SELECTED ASSET IF IT IS NOT IN THE ASSETS LIST
            if (!model?.assets?.find((asset) => asset?.id === data?.routeData?.asset?.assetId)) {
                updatedBreadcrumbData = { ...updatedBreadcrumbData, routeData: { ...updatedBreadcrumbData?.routeData, asset: null, selectedAsset: null } };
            }
            setData(updatedBreadcrumbData);
            setBreadcrumbStorage(updatedBreadcrumbData);
        }
    }, [model?.assets]);

    useEffect(() => {
        if (model?.selectedPowertrain && isCrossKPITab) {
            const updatedData = {
                ...data, routeData: {
                    ...data?.routeData, powertrainName: model?.selectedPowertrain?.name,
                    powertrainId: model?.selectedPowertrain?.powertrainId,
                    assets: model?.selectedPowertrain?.assets
                }
            };
            setData(updatedData);
            setBreadcrumbStorage(updatedData);
        }
    }, [model?.selectedPowertrain]);

    useEffect(() => {
        if (model?.selectedOrganizationId) {
            const isPowertrainName = data?.routeData?.powertrainName;
            const isAssetGroupName = data?.routeData?.assetGroupName;
            if (isPowertrainName) {
                setData(prevData => ({
                    ...prevData, routeData: {
                        ...prevData?.routeData,
                        assets: model?.assets,
                        powertrainName: null,
                        powertrainId: null
                    }
                }));
            } else if (isAssetGroupName) {
                setData(prevData => ({
                    ...prevData, routeData: {
                        ...prevData?.routeData,
                        assets: model?.assets,
                        assetGroupName: null,
                        assetGroupId: null
                    }
                }));
            }
        }
    }, [model?.selectedOrganizationId]);

    useEffect(() => {
        const routeDetails = getBreadcrumbStorage();
        if (routeDetails) {
            setData(routeDetails);
        }
        if (location.state) {
            const updatedData = { ...routeDetails, ...location.state };
            setBreadcrumbStorage(updatedData);
            setData(updatedData);
        }
    }, [location.state]);

    useEffect(() => {
        updateBreadcrumbRoute();
    }, [isCrossKPITab, powertrainName, assetGroupName, routeData?.assetName, selectedAssetCrossAssetAnalysisName, model?.selectedAsset]);

    useEffect(() => {
        updateRouteData();
    }, [selectedAssetName]);

    const handleNavigate = () => {
        const routeDetails = getBreadcrumbStorage();
        setIsCrossKPITab(false);
        history.push({
            pathname: routes?.CrossKpi,
            search: assetId ? new URLSearchParams({ assetId }).toString() : '',
            state: {
                routeName: routeDetails?.routeName,
                routeData: routeData
            }
        });
        setIsNavigatingToCrossKPI(true);
    };

    useEffect(() => {
        const pathname = routeLocation.pathname;
        if (pathname?.includes('cross-asset-analysis')) {
            handleNavigate();
        } else if (pathname?.includes('detailed-information')) {
            setIsCrossKPITab(true);
        }
    }, [routeLocation.pathname]);

    useEffect(() => {
        if (isNavigatingToCrossKPI) {
            setIsNavigatingToCrossKPI(false);
        }
    }, [isNavigatingToCrossKPI]);

    const handleBreadcrumbClick = (clickedRoute) => {
        const clickedPathname = clickedRoute?.pathname || clickedRoute;
        const clickedSearch = clickedRoute.search ? new URLSearchParams(clickedRoute?.search) : new URLSearchParams();

        if (clickedPathname === routes.Overview) {
            navigateToOverview(clickedSearch);
        } else if (clickedPathname === routes?.DetailedInformation) {
            navigateToDetailedInformation(clickedSearch);
        } else if (clickedPathname === '') {
            resetBreadcrumbRoute();
        }
    };

    const options = isCrossKPITab && routeData?.assets
        ? routeData?.assets?.map((asset, i) => ({
            id: asset?.id || asset?.assetId,
            value: asset?.assetName + i || asset?.name + i,
            label: asset?.assetName || asset?.name,
            route: {
                pathname: history?.location?.pathname,
                search: new URLSearchParams({ assetId: asset?.id || asset?.assetId }).toString()
            }
        }))
        : [];

    return (
        <div className='general-page-content'>
            <div className='general-page-content__container'>
                <Container fluid className='px-4'>
                    <Row>
                        <Col>
                            <Stack direction='horizontal' gap={3}>
                                <Breadcrumbs
                                    routes={breadcrumbRoute}
                                    onBreadcrumbClick={handleBreadcrumbClick}
                                    options={options}
                                    selectedAsset={model?.selectedAsset}
                                />
                                {isCrossKPITab &&
                                    <GeneralButton
                                        className='general-page-content__nav-cross ms-auto'
                                        type='normal'
                                        text={translate('ABB.Powertrain.Frontend.condMonitoringOverviewCrossAssetLabel')}
                                        onClick={handleNavigate}
                                    />
                                }
                            </Stack>
                        </Col>
                    </Row>
                </Container>
            </div>
            <GeneralRouterTabs
                options={tabOptions}
                variant='primary'
            >
                <Switch>
                    <Route path={routes.DetailedInformation}>
                        <DetailedInformation />
                        <AssetPowertrainSelector isAssetDetailsNeeded autoSelectFirstAsset key='detailed-information' />
                    </Route>
                    <Route path={routes.CrossKpi}>
                        <CrossAssetAnalysis />
                        <AssetPowertrainSelector key='cross-asset' />
                    </Route>
                    <Route path={tabOptions.conditionBasedMaintenance.route}>
                        <div>
                            Work in progress...
                        </div>
                    </Route>
                    <Redirect from='*' to={routes.DetailedInformation} />
                </Switch>
            </GeneralRouterTabs>
        </div>
    );
};

PowertrainAssetDetailsComponent.propTypes = {
    model: PropTypes.shape({
        selectedAsset: PropTypes.shape({
            componentID: PropTypes.string,
            componentName: PropTypes.string,
        })
    }),
    location: PropTypes.object
};

export default PowertrainAssetDetailsComponent;
