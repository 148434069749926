import { put, call, takeLatest, select } from 'redux-saga/effects';
import { actionTypes } from './constants';
import { LimitConfigApi } from 'api';
import { notify } from 'sharedComponents/GeneralNotification';
import { NOTIFICATION_TYPES } from '../../../SharedComponents/GeneralNotification/constants';
import { translate } from 'helpers/translateHelper';
import { setSelectedTimeSeriesSuggestedData, setExpandedSignals, setDefaultLimitsData } from './actions';
import { customToBaseUOM, getMeasurementUnits } from 'helpers/unitHelper';
import { getTrendKPIsForAssetV2 } from '../commonActions';
import { handleDefaultUnitConversion } from './helper';
import { setIsDefaultLimitsSelected } from '../OperationalParametersV2/SingleChartView/Trend/LimitSelector/actions';

const getSelectedAsset = (state) => state.powertrainAssetDetails?.selectedAsset;

function* getLimitKPISaga(action) {
    try {
        const { assetId } = action.payload;
        const response = yield call(LimitConfigApi.getLimitKpi, assetId);
        const configList = response.map(group => {
            return {
                ...group,
                timeseries: group.timeseries.filter(series => series.isDefaultLimitEditable || series.isAdditionalLimitAllowed)
            };
        }).filter(group => group.timeseries.length > 0);
        yield put({ type: actionTypes.GET_LIMIT_KPI_SUCCESS, payload: configList });
    } catch (error) {
        yield put({ type: actionTypes.GET_LIMIT_KPI_FAILURE, error: error.message });
    }
}

function* getTimeSeries(action) {
    try {
        const { assetId } = action.payload;
        const response = yield call(LimitConfigApi.getTimeSeriesLimits, assetId);
        const temp = response.timeseries.map(item => ({
            ...item,
            uniqId: crypto.randomUUID(),
            displayName: item.appendCodeToTranslation ? translate(item.displayNameTextKey) ? `${item.timeseriesKey} ${translate(item.displayNameTextKey)}` : `${item.timeseriesKey} ${item.displayName}` : translate(item.displayNameTextKey) ? translate(item.displayNameTextKey) : item.displayName,
            limits: item.limits.map(limit => ({
                ...limit,
                uniqId: crypto.randomUUID()
            })
            )
        }));
        yield put({ type: actionTypes.GET_TIME_SERIES_SUCCESS, payload: { temp, assetId } });
    } catch (error) {
        yield put({ type: actionTypes.GET_TIME_SERIES_FAILURE, error: error.message });
    }
}

function* postLimitSaga(action) {
    const userMeasurementUnitGroups = getMeasurementUnits();
    try {
        const { assetId, configData } = action.payload;
        const asset = yield select(getSelectedAsset);
        const data = configData.map((ts) => {
            return {
                ...ts,
                limits: ts?.limits?.map((lmt) => {
                    const convertedLimitValue = +customToBaseUOM.convertWithGroups(ts?.timeseriesUnitGroupID, lmt?.limitValue, userMeasurementUnitGroups);
                    return {
                        ...lmt,
                        limitValue: convertedLimitValue % 1 === 0 ? convertedLimitValue : convertedLimitValue?.toFixed(2)
                    };
                })
            };
        });
        const response = yield call(LimitConfigApi.postLimitConfig, assetId, data);
        yield put({ type: actionTypes.POST_LIMIT_SUCCESS, payload: response });
        yield put({ type: actionTypes.GET_TIME_SERIES, payload: { assetId } });
        if (configData && configData?.length && configData[0]?.limits?.length === 0) {
            yield put(setExpandedSignals([]));
            notify(translate('ABB.Powertrain.Frontend.removeSignal'), NOTIFICATION_TYPES.SUCCESS);
        } else {
            notify(translate('ABB.Powertrain.Frontend.limitUpdated'), NOTIFICATION_TYPES.SUCCESS);
        }
        // UPDATE SIGNALS LIST WITH UPDATED LIMITS
        if (asset) { yield put(getTrendKPIsForAssetV2(asset)); }
        yield put(setIsDefaultLimitsSelected(false));
    } catch (error) {
        const { data } = error.response || {};
        const errorMessage = (data?.timeseries || []).reduce((acc, timeseriesItem) => {
            if (timeseriesItem.validationMessage) {
                acc.push(timeseriesItem.validationMessage);
            }

            timeseriesItem.limits.forEach(limit => {
                if (limit.validationMessage) {
                    acc.push(limit.validationMessage);
                }
            });

            return acc;
        }, []);

        const combinedErrorMessage = errorMessage.join(', \n') || error.message;

        notify(combinedErrorMessage, NOTIFICATION_TYPES.ERROR, true);
        yield put({ type: actionTypes.POST_LIMIT_FAILURE });
    }
}

function* getSuggestedLimitDataSaga(action) {
    try {
        const { assetId, timeseriesKey } = action.payload;
        const response = yield call(LimitConfigApi.getSuggestedLimits, { assetId, timeseriesKey });

        yield put(setSelectedTimeSeriesSuggestedData(response));
    } catch (error) {
        notify(error.message, NOTIFICATION_TYPES.ERROR);
        yield put(setSelectedTimeSeriesSuggestedData(null));
    }
}

function* getDefaultLimitsSaga(action) {
    try {
        const { assetId, timeseries, limitData } = action.payload;
        const response = yield call(LimitConfigApi.getDefaultLimits, assetId, timeseries);
        const data = handleDefaultUnitConversion(response, limitData);
        yield put(setDefaultLimitsData(data));
    } catch (error) {
        notify(error.message, NOTIFICATION_TYPES.ERROR);
        yield put(setDefaultLimitsData(null));
    }
}

export function* limitConfigWatchers() {
    yield takeLatest(actionTypes.GET_LIMIT_KPI, getLimitKPISaga);
    yield takeLatest(actionTypes.GET_TIME_SERIES, getTimeSeries);
    yield takeLatest(actionTypes.POST_LIMIT_REQUEST, postLimitSaga);
    yield takeLatest(actionTypes.GET_SUGGESTED_LIMIT_DATA, getSuggestedLimitDataSaga);
    yield takeLatest(actionTypes.GET_DEFAULT_LIMITS, getDefaultLimitsSaga);
}
