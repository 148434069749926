import { createSelector } from 'reselect';

const getDataLoggerCommands = ({ dataLoggerCommands }) => {
    return dataLoggerCommands?.loggerCommandsList?.data || [];
};

const getTriggerLoggerCommands = ({ dataLoggerCommands }) => {
    return dataLoggerCommands?.loggerCommandsList?.data?.dataLoggerCommands?.triggerLoggerCommands?.commands;
};

const getSpecialLoggerCommands = ({ dataLoggerCommands }) => {
    return dataLoggerCommands?.loggerCommandsList?.data?.dataLoggerCommands?.specialLoggerCommands?.commands;
};

const getDataLoggerCommandsLodingStatus = ({ dataLoggerCommands }) => {
    return dataLoggerCommands?.loggerCommandsList?.loadingStatus;
};

const getTriggerLoggerCommandsLoadingStatus = ({ dataLoggerCommands }) => {
    return dataLoggerCommands?.triggerLoggerCommands?.loadingStatus;
};

const getSpecialLoggerCommandsLoadingStatus = ({ dataLoggerCommands }) => {
    return dataLoggerCommands?.subscribeLoggerCommands?.loadingStatus;
};

const getSubscribeWarningTextKey = ({ dataLoggerCommands }) => {
    return dataLoggerCommands?.loggerCommandsList?.data?.dataLoggerCommands?.specialLoggerCommands?.warningTextKey;
};

const getAmcosWarningTextKey = ({ dataLoggerCommands }) => {
    return dataLoggerCommands?.loggerCommandsList?.data?.dataLoggerCommands?.specialLoggerCommands?.warningTextKey;
};

export const getModel = createSelector([
    getDataLoggerCommands,
    getTriggerLoggerCommands,
    getSpecialLoggerCommands,
    getDataLoggerCommandsLodingStatus,
    getTriggerLoggerCommandsLoadingStatus,
    getSpecialLoggerCommandsLoadingStatus,
    getSubscribeWarningTextKey,
    getAmcosWarningTextKey
],
    (
        dataLoggerCommands,
        triggerLoggerCommands,
        specialLoggerCommands,
        dataLoggerCommandsLodingStatus,
        triggerDataLoggerCommandsLoadingStatus,
        specialLoggerCommandsLoadingStatus,
        subscribeWarningTextKey,
        amcosWarningTextKey
    ) => {
        return {
            dataLoggerCommands,
            triggerLoggerCommands,
            specialLoggerCommands,
            dataLoggerCommandsLodingStatus,
            triggerDataLoggerCommandsLoadingStatus,
            specialLoggerCommandsLoadingStatus,
            subscribeWarningTextKey,
            amcosWarningTextKey
        };
    }
);
