import { createSelector } from 'reselect';
import { getLoadingStatuses } from 'helpers/selectors';

const getSelectedOrganization = ({ generalFilter }) => {
    return generalFilter?.selectedFilters?.organizations[0];
};

const getGatewayDetails = ({ powertrainTools }) => {
    return powertrainTools?.gateway;
};

const getGatewayType = ({ powertrainTools }) => {
    return powertrainTools?.gatewayType;
};

export const getModel = createSelector(
    [
        getSelectedOrganization,
        getGatewayDetails,
        getGatewayType,
        getLoadingStatuses
    ],
    (
        selectedOrganization,
        gatewayDetails,
        gatewayType,
        generalFilterLoadingStatuses
    ) => {
        return {
            selectedOrganization,
            gatewayDetails,
            gatewayType,
            generalFilterLoadingStatuses
        };
    }
);
