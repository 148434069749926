import React from 'react';
import EnvironmentSensorsHeader from './EnvironmentSensorsHeaderComponent';
import EnvironmentSensorsRow from './EnvironmentSensorsRowComponent';

const ConnectedAssetsEnvironmentSensorsComponent = ({ gatewayConnectedEnvironmentSensorData, searchQuery }) => {
    // Filtered list based on the search query
    const filteredGatewayConnectedEnvironmentSensorData = gatewayConnectedEnvironmentSensorData.filter((asset) =>
        asset?.name
            ?.toLowerCase()
            .includes(searchQuery?.toLowerCase() || '')
    );
    return (
        <div className='environment-sensor-list'>
            <div className='environment-sensor-list__body'>
                {gatewayConnectedEnvironmentSensorData?.length !== 0 && <EnvironmentSensorsHeader />}
                <EnvironmentSensorsRow gatewayConnectedEnvironmentSensorData={filteredGatewayConnectedEnvironmentSensorData} />
            </div>
        </div>
    );
};

export default ConnectedAssetsEnvironmentSensorsComponent;
