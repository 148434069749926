//import { apiCallStatus } from 'helpers/constants';
// eslint-disable-next-line import/no-unused-modules
import { actionTypes } from './constants';
import { getApiActionCreator } from 'helpers/actionHelper';
//import { ConditionIndicesApi } from 'api';

export const getAssetKPITrendChartListAction = getApiActionCreator(actionTypes.GET_ASSET_KPI_TREND_LIST);

// eslint-disable-next-line import/no-unused-modules
export const getAssetConditionIndicesRequest = (assetID, startDate, endDate) => ({
    type: actionTypes.LOAD_ASSET_KPI_TREND_LIST,
    assetID,
    startDate,
    endDate
});

export const zoomInToSelectedTrendKpiData = (assetID, startDate, endDate) => ({
    type: actionTypes.ZOOM_IN_TO_SELECTED_TREND_KPI_DATA,
    assetID,
    startDate,
    endDate,
});

export const reloadConditionInsights = (assetID) => ({
    type: actionTypes.RELOAD_CONDITION_INSIGHTS,
    assetID
});

export const setIsZoomed = (isZoomed) => ({
    type: actionTypes.SET_CONDITION_INSIGHTS_ZOOM,
    isZoomed
});

// eslint-disable-next-line import/no-unused-modules
/*export const getAssetKPITrendChartList = (assetID, startDate, endDate) => {
    return (dispatch) => {
        dispatch(getAssetKPITrendChartListAction(apiCallStatus.LOADING));
        ConditionIndicesApi?.getAssetCondtionInsights(assetID, startDate, endDate)
            .then(details => {
                dispatch(getAssetKPITrendChartListAction(apiCallStatus.SUCCESS, { ...details }));
            }, (err) => {
                dispatch(getAssetKPITrendChartListAction(apiCallStatus.ERROR, null, err));
            });

    };
};*/
