import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    DisclaimerBanner,
    InfoBanner,
    LoadingIndicator,
} from 'sharedComponents';
import { apiCallStatus } from 'helpers/constants';
import SensorFirmwareManagementDays from './SensorFirmwareManagementDays';

const SensorFirmwareManagementComponent = (
    {
        actions,
        model
    }
) => {
    const { maintenanceSchedule = {}, organizationID } = model || {};
    const { convertIDMaintenanceSchedule, clearStore } = actions || {};
    const { t: translate } = useTranslation();

    // Pass organizationID to convert ID API and get external ID.
    useEffect(() => {
        if (organizationID) {
            clearStore();
            convertIDMaintenanceSchedule(organizationID);
        }
    }, [organizationID]);

    return (
        <div className='tools_powertrain-gateway_management'>
            <div className='title'>
                <div className='title-box'>
                    {translate('ABB.Powertrain.Frontend.ptToolsSensorFirmwareManagement')}
                </div>
            </div>
            <div className='disclaimer-container'>
                <DisclaimerBanner text={translate('ABB.Powertrain.Frontend.disclaimerText')}
                    disableClose={true} />
                <div className='info-icon-wrapper'>
                    <InfoBanner text={translate('ABB.Powertrain.Frontend.smartSensorInfoText')} />
                </div>
            </div>
            {/* Handled for convert ID failed case */}
            {[apiCallStatus.SUCCESS, apiCallStatus.ERROR].includes(maintenanceSchedule?.loadingStatus) ?
                <SensorFirmwareManagementDays
                    actions={actions}
                    model={model}
                />
                : <LoadingIndicator />
            }
        </div>
    );
};

export default SensorFirmwareManagementComponent;
