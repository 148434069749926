import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';


const IconBatteryEmpty = ({ width = 22, height = 12, viewBox = '0 0 22 12', value, color = colors.pomegranate }) => {
	return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
		<title>{value}</title>
		<path d='M4 4H7V8H4V4ZM18 10H2V2H18V10ZM20 3V0H0V12H20V9H22V3H20Z' fill={color} />
	</svg>;
};
export default IconBatteryEmpty;

IconBatteryEmpty.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

