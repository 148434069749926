import React, { useEffect } from 'react';
import SensorFirmwareManagementComponent from './SensorFirmwareManagementComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { LoadingStatusWrapper } from 'sharedComponents/WithLoadingStatus';
import { getModel } from './selectors';
import * as actions from './actions';

import './style.scss';

const SensorFirmwareManagementContainer = (props) => {
	const {
        generalFilterLoadingStatuses,
		organizationID
    } = props.model;

	useEffect(() => {
        return () => {
            props.actions.clearStore();
        };
    }, []);

	return <LoadingStatusWrapper loadingStatus={[...generalFilterLoadingStatuses]}>
		<SensorFirmwareManagementComponent key={organizationID} {...props} />
	</LoadingStatusWrapper>;
};

const mapStateToProps = (state) => {
	const model = getModel(state);
	return {
		model
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
};

const SensorFirmwareManagementContainerConn = connect(
	mapStateToProps,
	mapDispatchToProps
)(SensorFirmwareManagementContainer);

export default SensorFirmwareManagementContainerConn;
