import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { GeneralDialog, LoadingStatusWrapper } from 'sharedComponents';
import { IconBellStrikeThrough } from 'svgIcons/MotionPortalIcons';
import { EditMutedAsset } from '../../../../UserProfileSettings/NotificationSettings/MutedAssets/EditMuteSettingsPopup';
import { formatUpdatedMuteSettings } from './helpers';
import './style.scss';
import { apiCallStatus } from 'helpers/constants';


const MuteAssetComponent = (props) => {
    const { t: translate } = useTranslation();
    const {
        selectedAsset,
        assetMuteSettings,
        assetMuteSettingsLoadingStatus,
        updateMuteSettingsLoadingStatus,
        selectedOrganization
    } = props.model;
    const { muteAsset } = props.actions;

    const [disabledForPeriod, setDisabledForPeriod] = useState(null);
    const [period, setPeriod] = useState(null);
    const [mutedGroups, setMutedGroups] = useState([]);
    const timePeriod = assetMuteSettings?.startDate && assetMuteSettings?.endDate ?
        {
            from: assetMuteSettings.startDate,
            to: assetMuteSettings.endDate
        } : null;
    const muteForTimePeriod = !!(assetMuteSettings?.startDate && assetMuteSettings?.endDate);

    const setSelectedAssetOptions = () => {
        setMutedGroups(assetMuteSettings?.notificationGroups || []);
        setDisabledForPeriod(muteForTimePeriod);
        setPeriod(timePeriod);
    };

    useEffect(() => {
        setSelectedAssetOptions();
    }, [assetMuteSettingsLoadingStatus]);

    const save = () => {
        muteAsset(
            formatUpdatedMuteSettings(
                selectedOrganization?.organizationID,
                selectedAsset.id,
                mutedGroups,
                period
            ),
            props.close
        );
    };

    // Verify atleast a muted group item is checked
    const checkIsListMuted = () => disabledForPeriod ? mutedGroups?.some(item => item?.muted) ?? false : true;

    return <GeneralDialog
        show={props.show}
        close={props.close}
        title={<>
            <IconBellStrikeThrough />{`${translate('ABB.Powertrain.Frontend.muteAssetTitle')} - ${selectedAsset?.name}`}
        </>}
        acceptButtonProps={{
            text: translate('ABB.Powertrain.Frontend.saveButton'),
            onClick: () => { save(); },
            disabled: assetMuteSettingsLoadingStatus === apiCallStatus.LOADING ||
                updateMuteSettingsLoadingStatus === apiCallStatus.LOADING ||
                !checkIsListMuted() ||
                isEqual(mutedGroups, assetMuteSettings?.notificationGroups) &&
                isEqual(disabledForPeriod, muteForTimePeriod) &&
                isEqual(period, timePeriod)
        }}
        cancelButtonProps={{
            text: translate('ABB.Powertrain.Frontend.cancelLabel'),
            onClick: () => { props.close(); }
        }}
        closeButton={true}
        modalClassName={'edit-muted-asset-popup'}
    >
        <LoadingStatusWrapper loadingStatus={[
            assetMuteSettingsLoadingStatus,
            updateMuteSettingsLoadingStatus
        ]}>
            <EditMutedAsset
                notificationGroups={assetMuteSettings?.notificationGroups}
                disabledForPeriod={disabledForPeriod}
                setDisabledForPeriod={setDisabledForPeriod}
                period={period}
                setPeriod={setPeriod}
                mutedGroups={mutedGroups}
                setMutedGroups={setMutedGroups}
            />
        </LoadingStatusWrapper>
    </GeneralDialog>;
};

MuteAssetComponent.propTypes = {
    show: PropTypes.bool,
    close: PropTypes.func,
    model: PropTypes.shape({
        assetMuteSettings: PropTypes.object,
        assetMuteSettingsLoadingStatus: PropTypes.string,
        updateMuteSettingsLoadingStatus: PropTypes.string,
        selectedAsset: PropTypes.object
    }),
    actions: PropTypes.shape({
        muteAsset: PropTypes.func
    })
};

export default MuteAssetComponent;
