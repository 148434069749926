import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Route, Redirect } from 'react-router';
import { GeneralRouterTabs } from 'sharedComponents';
import { getTabOptions } from './helpers';
import { ConditionIndicesOverview } from './ConditionIndicesOverview';
import { MaintenanceOverviewLazy } from './MaintenanceOverview';
import featureConfigHelper from 'helpers/featureConfigHelper';

const PowertrainConditionMonitoringComponent = () => {
    const { t: translate } = useTranslation();
    const featureConfig = new featureConfigHelper();
    const [tabOptions, setTabOptions] = useState(getTabOptions(translate));

    useEffect(() => {
        setTabOptions(getTabOptions(translate));
    }, [translate]);

    return (
        <div className='general-page-content'>
            <GeneralRouterTabs
                options={tabOptions}
                variant='primary'
                removeSearchFilter={true}
            >
                <Switch>
                    <Route Route path={tabOptions.conditionOverview.route}>
                        <ConditionIndicesOverview />
                    </Route>
                    {featureConfig.isCBMFeatureEnabled() &&
                        <Route path={tabOptions.maintenanceOverview.route}>
                            <MaintenanceOverviewLazy />
                        </Route>
                    }
                    <Route path={tabOptions.cbm.route}>
                        <div>
                            Work in progress...
                        </div>
                    </Route>
                    <Redirect from='*' to={tabOptions.conditionOverview.route} />
                </Switch>
            </GeneralRouterTabs>
        </div >
    );
};

export default PowertrainConditionMonitoringComponent;
