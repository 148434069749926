import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './actions';
import SwitchOrganizationComponent from './SwitchOrganizationComponent';
import { getModel } from './selectors';
import { setSelectedFilters, saveFiltersWithSync } from '../GeneralFilter/GeneralFilterModal/actions';
import { setSelectedFilters as setSelectedFiltersEdit } from '../GeneralFilter/GeneralFilterModal/GeneralFilterEdit/actions';
import { readNotificationListAction } from '../PowertrainOverview/Header/NotificationDropdown/actions';


const SwitchOrganizationContainer = (props) => {
    return <SwitchOrganizationComponent {...props} />;
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, saveFiltersWithSync, setSelectedFilters, setSelectedFiltersEdit, readNotificationListAction }, dispatch)
    };
};

const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const SwitchOrganizationContainerConn = connect(
    mapStateToProps,
    mapDispatchToProps
)(SwitchOrganizationContainer);

export default SwitchOrganizationContainerConn;
