import { createSelector } from 'reselect';
import { dateKind } from '../constants';
import { getMergedTrendKpiList, getUniqueYScaling } from '../helpers';

const getTrendData = ({ eventDetailsTrendSignal }) => {
    return eventDetailsTrendSignal.selectedTrendsData;
};

const getTrendDataLoadingStatus = ({ eventDetailsTrendSignal }) => {
    return eventDetailsTrendSignal.trend.loadingStatus;
};

const getSelectedEvent = ({ eventLog }) => {
    return eventLog.selectedEvent;
};

const getEnableUniqueScaling = ({ eventDetailsTrendSignal }) => {
    const selectedTrends = eventDetailsTrendSignal.selectedTrendsData;

    return getUniqueYScaling(selectedTrends);
};

const getShowUtcDates = ({ eventLog }) => {
    return eventLog.dateKind === dateKind.UTC;
};

const getIsZoomed = ({ eventDetailsTrendSignal }) => {
    return eventDetailsTrendSignal.trendChartZoom.isZoomed;
};

const getTrendKPIsList = createSelector([
    ({ eventDetailsTrendSignal }) => eventDetailsTrendSignal.trendKpis?.data,
], (
    trendKPIs,
) => {
    return getMergedTrendKpiList(trendKPIs);
});

export const getModel = createSelector([
    getTrendData,
    getTrendDataLoadingStatus,
    getSelectedEvent,
    getEnableUniqueScaling,
    getShowUtcDates,
    getIsZoomed,
    getTrendKPIsList
], (
    selectedTrendsData,
    trendDataLoadingStatus,
    event,
    enableUniqueScaling,
    showUtcDates,
    isZoomed,
    trendKPIsList
) => {
    return {
        selectedTrendsData,
        trendDataLoadingStatus,
        event,
        enableUniqueScaling,
        showUtcDates,
        isZoomed,
        trendKPIsList
    };
});
