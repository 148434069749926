export const ERROR_STATUS_UPDATE = 'overview/ERROR_STATUS_UPDATE';
export const ADD_SELECTED_LOGGER_SIGNAL = 'eventlist/assistance/ADD_SELECTED_LOGGER_SIGNAL';
export const REMOVE_SELECTED_LOGGER_SIGNAL = 'eventlist/assistance/REMOVE_SELECTED_LOGGER_SIGNAL';

export const LoggerTypes = {
	DataLogger: 0,
	FactoryLogger: 1,
	Blackbox: 2,
	APBULogger: 3,
	TransientLogger: 4
};

export const apiCallStatus = {
    LOADING: 'loading',
    SUCCESS: 'success',
    ERROR: 'error'
};

export const loggerChartContainerId = 'assistance-logger-signals';

export const DATA_LOGGER = 'DataLogger';
