import { actionTypes } from './constants';
import { getApiActionCreator } from 'helpers/actionHelper';

export const getGatewayInfoAction = getApiActionCreator(actionTypes.GET_GATEWAY_INFO_SETTINGS);

export const getGatewayInfo = (gatewayID) => {
    return {
        type: actionTypes.GET_GATEWAY_INFO,
        payload: {
            gatewayID
        }
    };
};

export const getGatewayConnectedAssetsAction = getApiActionCreator(actionTypes.GET_GATEWAY_CONNECTED_ASSETS_SETTINGS);

export const getGatewayConnectedAssets = (gatewayID) => {
    return {
        type: actionTypes.GET_GATEWAY_CONNECTED_ASSETS,
        payload: {
            gatewayID
        }
    };
};

export const clearStore = () => {
    return (dispatch) => {
        dispatch({ type: actionTypes.CLEAR_STORE });
    };
};
