import { createSelector } from 'reselect';
import { getFormattedAssets, getFormattedPowertrains } from '../../selectors';
import { getAssetsLoadingStatus, getLoadingStatuses, getPowertrainLoadingStatusv2 } from 'helpers/selectors';

const getActiveTab = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails.activeTab;
};

export const getModel = createSelector(
    [
        getFormattedAssets,
        getFormattedPowertrains,
        getLoadingStatuses,
        getAssetsLoadingStatus,
        getPowertrainLoadingStatusv2,
        getActiveTab
    ],
    (
        assets,
        powertrains,
        generalFilterLoadingStatuses,
        assetsLoadingStatus,
        powertrainsLoadingStatus,
        activeTab
    ) => {
        return {
            assets,
            powertrains,
            generalFilterLoadingStatuses,
            assetsLoadingStatus,
            powertrainsLoadingStatus,
            activeTab
        };
    }
);
