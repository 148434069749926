import { EventLogsApi } from 'api';
import { apiCallStatus } from 'helpers/constants';
import * as constants from './constants';
import { getApiActionCreator } from 'helpers/actionHelper';
import { downloadLoggerFile } from './helpers';


//Actions
const downloadLoggerAction = getApiActionCreator(constants.DOWNLOAD_LOGGER);

const clearLoggerFileAction = () => {
    return {
        type: constants.CLEAR_LOGGER_FILE,
    };
};

const clearLoggerFile = () => {
    return (dispatch) => {
        dispatch(clearLoggerFileAction());
    };
};

// Action Creators
export const downloadLogger = (logger, loggerId) => {
    return dispatch => {
        dispatch(downloadLoggerAction(apiCallStatus.LOADING));
            if (logger?.type === constants?.DATA_LOGGER || loggerId?.type === constants?.DATA_LOGGER) {
                EventLogsApi.downloadLogger(logger?.id)
                    .then((downloadData) => {
                        downloadLoggerFile(downloadData?.content, logger?.fileFullName);
                        dispatch(downloadLoggerAction(apiCallStatus.SUCCESS, downloadData?.content));
                        dispatch(clearLoggerFile());
                    })
                    .catch((err) => {
                        dispatch(downloadLoggerAction(apiCallStatus.ERROR, null, err));
                    });
            } else {
                EventLogsApi.downloadFile(logger?.id)
                .then((downloadData) => {
                    downloadLoggerFile(downloadData?.fileContent, logger?.name || logger?.fileFullName);
                    dispatch(downloadLoggerAction(apiCallStatus.SUCCESS, downloadData?.fileContent));
                    dispatch(clearLoggerFile());
                })
                .catch((err) => {
                    dispatch(downloadLoggerAction(apiCallStatus.ERROR, null, err));
                });
            }
     };
};
