import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './styles.scss';
import { getModel } from './selectors';
import * as actions from './actions';
import { GeneralTabs } from 'sharedComponents';
import { TrendKPIList } from '../TrendKPIList';
import { LimitConfigContainer } from '../../../../LimitConfigurationBeta';

const InternalTabs = (props) => {
    const { asset, isInternalTabLimitActive } = props.model;

    return (
        <div className='internal-tabs-operational-parameters'>
            <GeneralTabs
                options={{
                    operationalParameters: {
                        title: 'Operational parameters',
                        component: isInternalTabLimitActive ? <LimitConfigContainer asset={asset} /> : <TrendKPIList asset={asset} />
                    }
                }}
                defaultTab='operationalParameters'
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions }, dispatch)
    };
};

const InternalTabsContainerConn = connect(
    mapStateToProps,
    mapDispatchToProps
)(InternalTabs);

export default InternalTabsContainerConn;
