import React from 'react';
import { translate } from 'helpers/translateHelper';
import { getUTCToLocalTimeZone } from 'helpers/dateHelper';
import './styles.scss';

const DriveStatusChartToolTipComponent = ({ timeStamp, value }) => {
    const style1 = {
        backgroundColor: value?.color,
    };
    return <div className='custom-drive-chart-tooltip'>
        <div className='t-header'>
            {`${getUTCToLocalTimeZone(timeStamp, false, true)} (${translate('ABB.Powertrain.Frontend.UTC')})`}
        </div>
        <br />
        <div className='t-item'>
            <span className='symbol' style={style1} />
            <span>{`${translate('ABB.Powertrain.Frontend.conditionDriveStatusLabel')} ${translate(value?.label)}`}</span>
        </div>
    </div>;

};
export default DriveStatusChartToolTipComponent;
