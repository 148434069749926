import { createSelector } from 'reselect';
import { filterContracts } from '../../PowertrainOverview/helper';
import { getSelectedNotification } from '../../PowertrainOverview/Header/NotificationDropdown/selectors';

const getLoadingStatusAsset = ({ powertrainTools }) => {
    return powertrainTools?.subscriptionStatus?.subscriptionArr?.loadingStatus;
};

const getActiveTab = ({ powertrainTools }) => {
    return powertrainTools.subscriptionStatus.activeTab;
};
const getSubscriptionData = ({ powertrainTools, generalFilter }) => {
    return filterContracts(powertrainTools?.subscriptionStatus?.subscriptionArr?.data?.assetSubscriptionsInfos, generalFilter?.assets?.data);
};

export const getModel = createSelector(
    [
        getLoadingStatusAsset,
        getActiveTab,
        getSubscriptionData,
        getSelectedNotification
    ],
    (
        loadingStatusAsset,
        activeTab,
        contracts,
        selectedNotification
    ) => {
        return {
            loadingStatusAsset,
            activeTab,
            contracts,
            selectedNotification
        };
    }
);
