import React from 'react';
import { useTranslation } from 'react-i18next';

const EnvironmentSensorsRowComponent = ({ gatewayConnectedEnvironmentSensorData }) => {
    const { t: translate } = useTranslation();
    if (gatewayConnectedEnvironmentSensorData?.length === 0) {
        return <div className='empty-environment-sensor-list'>{translate('ABB.Powertrain.Frontend.emptyEnvironmentSensorsAssets')}</div>;
    }

    return gatewayConnectedEnvironmentSensorData.map((envSensorItem, idx) =>
        <div className='environment-sensor-list__row' key={envSensorItem?.assetId || idx}>
            <div className='environment-sensor-list__cell'>
                <div className='environment-name'>
                    <div className='environment-name__wrap'>
                        <span className='environment-name__text'>{envSensorItem?.name || ''}</span>
                        <span className='environment-name__slno'>{envSensorItem?.serialNumber || '-'}</span>
                    </div>
                </div>
            </div>
            <div className='environment-sensor-list__cell'>
                -
            </div>
        </div>
    );
};

export default EnvironmentSensorsRowComponent;
