import React from 'react';

const IconNewReport = () => {
    return <svg width='23' height='14' viewBox='0 0 23 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M0.232422 2C0.232422 0.89543 1.12785 0 2.23242 0H20.2324C21.337 0 22.2324 0.895431 22.2324 2V12C22.2324 13.1046 21.337 14 20.2324 14H2.23242C1.12785 14 0.232422 13.1046 0.232422 12V2Z' fill='#0051FF' />
        <path d='M2.78536 10V4.64H3.93736L6.24936 8.08V4.64H7.40136V10H6.24936L3.93736 6.568V10H2.78536ZM8.58223 10V4.64H12.3502V5.696H9.76623V6.736H12.0142V7.784H9.76623V8.944H12.4302V10H8.58223ZM14.4271 10L12.9311 4.64H14.2591L15.1071 8.368L15.9391 5.232H17.0991L17.9951 8.384L18.8351 4.64H20.1311L18.6351 10H17.4351L16.5071 6.88L15.6191 10H14.4271Z' fill='white' />
    </svg>;
};

export default IconNewReport;
