import React from 'react';
import { popupTypes } from 'helpers/constants';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { GeneralDialog, LoadingStatusWrapper } from 'sharedComponents';
import { getDefaultFilters } from '../GeneralFilter/GeneralFilterModal/helpers';
import { handleNotificationNavigation } from '../PowertrainOverview/Header/NotificationDropdown/helpers';

const SwitchOrganizationComponent = ({ ...props }) => {
    const { t: translate } = useTranslation();
    const history = useHistory();
    const { actions, model } = props;
    const notification = model.selectedNotification;
    const closeModal = () => {
        actions.setShowSwitchOrgModal(false);
        history.push({
            pathname: history.location.pathname,
            search: history.location.search
        });
    };

    const handleSwitchOrganization = () => {
        if (notification) {
            const selectedFilters = getDefaultFilters();
            const org = model.organizations.filter((org) => org.organizationId === notification?.organizationId);
            actions.setSelectedFilters({ ...selectedFilters, organizations: org });
            actions.setSelectedFiltersEdit({ ...selectedFilters, organizations: org });
            actions.saveFiltersWithSync();
            actions.readNotificationListAction(notification?.groupId);
            handleNotificationNavigation(notification, history);
        }
        closeModal();
    };

    return <GeneralDialog
        id='switch-organization-modal'
        show={model.showSwitchOrgModal}
        notificationType={popupTypes.INFO}
        closeButton={true}
        cancelButtonProps={{
            text: translate('ABB.Powertrain.Frontend.cancelLabel'),
        }}
        close={() => {
            closeModal();
        }}
        title={translate('ABB.Powertrain.Frontend.switchOrganizationTitle')}
        acceptButtonProps={{
            text: translate('ABB.Powertrain.Frontend.switchOrganizationTitle'),
            onClick: () => { handleSwitchOrganization(); }
        }}
    >
        <LoadingStatusWrapper>
            {translate('ABB.Powertrain.Frontend.switchOrganizationMsg')}
        </LoadingStatusWrapper>
    </GeneralDialog>;
};

export default SwitchOrganizationComponent;
