import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { MAX_DAYS_RANGE, PRESELECTED_DAYS, ITEMS_PER_PAGE } from './constants';
import { GeneralDialog, TimeSelectorRow, LoadingStatusWrapper } from 'sharedComponents';
import { GeneralTable, sortingDirections } from 'sharedComponents/GeneralTable';
import { getColumns } from './helpers';


import './styles.scss';
import { apiCallStatus } from 'helpers/constants';

const ParameterBackupModal = (props) => {

    const { t: translate } = useTranslation();

    const [from, setFrom] = useState();
    const [to, setTo] = useState();

    useEffect(() => {
        return () => {
            return props.actions.clearParameterFileData();
        };
    }, []);

    useEffect(() => {
        props.actions.getParameterFileListForAsset(props.model.selectedAsset?.id, from, to);
    }, [from, to]);

    const handleSelectDate = (from, to) => {
        if (from && to) {
            setFrom(from);
            setTo(to);
        }
    };

    const onDownloadFile = (fileId) => {
        props.actions.getParameterFileWithId(props.model.selectedAsset, fileId,
            {
                success: translate('ABB.Powertrain.Frontend.parameterBackupDownloadSuccessMessage'),
                error: translate('ABB.Powertrain.Frontend.parameterBackupErrorMessage'),
            });
    };

    const fileList = props.model.parameterFileList.data || [];

    return (
        <GeneralDialog
            modalClassName='parameter-backup-modal'
            show={props.show}
            close={props.close}
            title={props.title}
            closeButton={true}
        >
            <div className='modal-form parameter-backup-form'>
                <TimeSelectorRow
                    fromDate={from}
                    toDate={to}
                    handleSelectDate={handleSelectDate}
                    maxDaysBack={MAX_DAYS_RANGE}
                    preSelectedDays={PRESELECTED_DAYS}
                    numberOfMonths={2}
                    footerIntervalText={translate('ABB.Powertrain.Frontend.datePickerMax90DaysNote')}
                />
                <LoadingStatusWrapper useCustomAnchor loadingStatus={props.model.parameterFileListLoadingStatus} hideError>
                    {props.model.parameterFileListLoadingStatus !== apiCallStatus.LOADING ?
                        <GeneralTable
                            className='parameter-table'
                            tableKey='id'
                            columns={getColumns(translate, onDownloadFile)}
                            data={fileList}
                            emptyText={translate('ABB.Powertrain.Frontend.noDataLabel')}
                            scroll={{ x: 560, y: null }}
                            type='primary'
                            paginationProps={{ pageSize: ITEMS_PER_PAGE }}
                            initialSortingProps={{
                                column: 'timestamp',
                                direction: sortingDirections.DESC
                            }}
                        /> : null}
                </LoadingStatusWrapper>
            </div>
        </GeneralDialog>
    );
};

ParameterBackupModal.propTypes = {
    actions: PropTypes.object.isRequired,
    model: PropTypes.object.isRequired,
    isVisible: PropTypes.bool,
    close: PropTypes.func.isRequired
};

export default ParameterBackupModal;
