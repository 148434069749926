import { sensorTypes, PiezoSensor } from '../../components/PowertrainAssetDetails/DetailedInformation/OperationalParametersV2/VibrationFFTV2/constants';
import {
    baseToCustomUOM,
    getMeasurementUnitSymbol
} from 'helpers/unitHelper';
import map from 'lodash/map';


export const handleFFTDataConversion = (response) => {
    if (!response?.dataValues) {
        return response;
    }

    response.magnitudeUnitSymbol = getMeasurementUnitSymbol(response.amplitudeMeasurementUnitGroupID);
    response.frequencyUnitSymbol = getMeasurementUnitSymbol(response.frequencyMeasurementUnitGroupID);

    response.dataValues = map(response.dataValues, (dataValue) => {
        dataValue.sensorAxisDataValues = baseToCustomUOM.convertMeasurementMultiPrecision(
            dataValue.sensorAxisDataValues,
            {
                magnitude: { measurementUnitGroup: response.amplitudeMeasurementUnitGroupID, precision: 4 },
                frequency: { measurementUnitGroup: response.frequencyMeasurementUnitGroupID, precision: response.precision }
            }
        );

        return dataValue;
    });

    return response;
};

export const handleFFTV2DataConversion = (response) => {
    let dataValues = [];
    if (!response?.sensors) {
        return response;
    }

    // Get Piezzo sensor type
    const piezzoSensor = response?.sensors?.find(sensor => sensor.sensorType === sensorTypes.PIEZZO);

    // Get Acc sensor type
    const accSensor = response?.sensors?.find(sensor => sensor.sensorType === sensorTypes.ACC);

    dataValues = [
        {
            sensorAxisName: PiezoSensor?.axisName,
            sensorAxisDataValues: piezzoSensor?.dataValues[0]?.sensorAxisDataValues
        },
        ...accSensor.dataValues,
    ];

    response.magnitudeUnitSymbol = getMeasurementUnitSymbol(response.amplitudeMeasurementUnitGroupId);
    response.frequencyUnitSymbol = getMeasurementUnitSymbol(response.frequencyMeasurementUnitGroupId);

    response.dataValues = map(dataValues, (dataValue) => {
        dataValue.sensorAxisDataValues = baseToCustomUOM.convertMeasurementMultiPrecision(
            dataValue.sensorAxisDataValues,
            {
                magnitude: { measurementUnitGroup: response.amplitudeMeasurementUnitGroupId, precision: 4 },
                frequency: { measurementUnitGroup: response.frequencyMeasurementUnitGroupId, precision: response.precision }
            }
        );

        return dataValue;
    });

    return response;
};
