import endpoints, { formatEndpoint } from '../endpoints';
import { RestManagerWithoutBaseUrl } from '../RestManager';

class EventLogs {
	static getEventList(eventParameters) {
		const endpoint = formatEndpoint(endpoints.eventLogs.eventSearch);
		return RestManagerWithoutBaseUrl.post(endpoint, eventParameters);
	}

	static postEventComment(payload) {
		const endpoint = endpoints.eventLogs.eventComment;

		return RestManagerWithoutBaseUrl.post(endpoint, payload);
	}

	static deleteComment(id) {
		const endpoint = formatEndpoint(endpoints.eventLogs.deleteComment, { id });
		return RestManagerWithoutBaseUrl.delete(endpoint);
	}

	static getEventComments(id) {
		const endpoint = formatEndpoint(endpoints.eventLogs.eventComments, { id });
		return RestManagerWithoutBaseUrl.get(endpoint);
	}

	static getLoggerId(id) {
        const endpoint = formatEndpoint(endpoints.eventLogs.loggerId, {id});
        return RestManagerWithoutBaseUrl.get(endpoint);
    }

	static getLoggers(loggerId) {
		const endpoint = formatEndpoint(endpoints.eventLogs.logger.loggers, { loggerId });
		return RestManagerWithoutBaseUrl.get(endpoint);
	}

	static getEventLogLoggerSignals(loggerId, payload) {
		const endpoint = formatEndpoint(endpoints.eventLogs.logger.signal, {loggerId});
		return RestManagerWithoutBaseUrl.post(endpoint, payload);
	}

	static downloadLogger(loggerId) {
		const endpoint = formatEndpoint(endpoints.eventLogs.logger.downloadLogger, { loggerId });
		return RestManagerWithoutBaseUrl.get(endpoint);
	}

	static downloadFile(fileId) {
        const endpoint = formatEndpoint(endpoints.eventLogs.fileservice.downloadFile, {fileId });
        return RestManagerWithoutBaseUrl.get(endpoint);
    }
}

export default EventLogs;
