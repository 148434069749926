import React, { useEffect, useState } from 'react';
import CiamModule from 'ciam-modules';
import 'ciam-modules/style.css';
import authHelper from 'helpers/authHelper';
import { getCIAMEnviroment } from '../helpers';

const AssetManagementComponent = (props) => {
    const accessToken = authHelper.getAccessToken();
    const {
        selectedLanguage,
        selectedOrganization = {},
        environmentStageSelector
    } = props.model;
    const [ciamEnv, setCiamEnv] = useState(null);
    const ciamErrorHandler = (error) => {
        console.log('error', error);
    };

    useEffect(() => {
        if (environmentStageSelector) {
            const reqCIAMEnv = getCIAMEnviroment(environmentStageSelector);
            setCiamEnv(reqCIAMEnv);
        }
    }, [environmentStageSelector]);


    const { organizationID } = selectedOrganization;

    return organizationID && ciamEnv &&
        <div className='asset-management-wrapper'>
            <CiamModule
                key={organizationID}
                orgId={organizationID}
                lang={selectedLanguage || 'en'}
                module={'asset'}
                environment={ciamEnv}
                headerHeight={10}
                token={accessToken}
                errorCb={ciamErrorHandler}
            />
        </div>
        ;
};

export default AssetManagementComponent;
