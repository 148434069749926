import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ToggleButton, SidePanel } from 'sharedComponents';
import { AssetList } from './AssetList';
import Filter from './FilterComponent';
import { togglePowertrainAssets } from 'helpers/togglePowertrainAssetsHelper';
import { toggleTabsWhenSelectionChanges } from '../../helpers';
import { useQuerySetter } from 'sharedHooks/useQuery';
import { filterTabs } from 'helpers/constants';
import { useHistory } from 'react-router';


const CrossAssetAnalysisSidepanelComponent = ({ actions, model }) => {
    const { t: translate } = useTranslation();
    const history = useHistory();
    const query = useQuerySetter();

    const handleTabSelection = (tab) => {
        actions.setShoulScrollToSelection(true);
        actions.setActiveTabAction(tab);
        actions.setSearchboxFilterAction(null);
        history.replace(history.location.pathName);
    };

    const onAssetAssetFromPowertrainClick = useCallback((assetId) => { query.routeToSelectedAssetFromPowertrainList(assetId); actions.setShoulScrollToSelection(false); }, []);
    const onPowertrainClick = useCallback((powertrainId) => { query.routeToSelectedPowertrain(powertrainId); actions.setShoulScrollToSelection(false); actions?.setSelectedPowertrainID(powertrainId); }, []);
    const onAssetAssetClick = useCallback((assetId) => { query.routeToSelectedAssetFromAssetList(assetId); actions.setShoulScrollToSelection(false); actions?.setSelectedAsset(assetId); }, []);


    const handleAssetClick = useCallback((assetId) => {
        if (model.activeTab === filterTabs.POWERTRAINS) {
            onAssetAssetFromPowertrainClick(assetId);
        }
        else {
            console.log('assetId clicked from cross asset analysis', assetId);
            onAssetAssetClick(assetId);
        }
    }, [model.activeTab]);


    useEffect(() => {
        toggleTabsWhenSelectionChanges(query.queryParams, model.activeTab, actions.setActiveTabAction);
    }, [query.queryParams?.powertrainId]);


    return <SidePanel className='sidepanel-container'>
        <ToggleButton
            options={togglePowertrainAssets(translate)}
            onSelected={handleTabSelection}
            selectedValue={model.activeTab}
        />
        <Filter
            powertrains={model.powertrains}
            selectedPowertrain={model.selectedPowertrain}
            onPowertrainClick={onPowertrainClick}
            activeTab={model.activeTab}
            actions={actions}
        />
        <AssetList model={model} actions={actions} onAssetClick={handleAssetClick} />
    </SidePanel>;
};

CrossAssetAnalysisSidepanelComponent.propTypes = {
    actions: PropTypes.shape({
        setActiveTabAction: PropTypes.func,
        setSearchboxFilterAction: PropTypes.func,
        setGatewayTypeFilterAction: PropTypes.func,
        setSelectedPowertrainID: PropTypes.func,
        getKPIsForKPIType: PropTypes.func,
        getTrendKPIsForAssetV2: PropTypes.func,
        getAssetCapability: PropTypes.func
    }),
    model: PropTypes.shape({
        powertrains: PropTypes.any,
        assets: PropTypes.any,
        selectedAsset: PropTypes.any,
        selectedAssetCapability: PropTypes.any,
        selectedKPIType: PropTypes.any,
        generalFilterLoadingStatus: PropTypes.array,
        assetsLoadingStatus: PropTypes.any,
        powertrainsLoadingStatus: PropTypes.any,
        filters: PropTypes.any,
        activeTab: PropTypes.string
    })
};

export default CrossAssetAnalysisSidepanelComponent;
