const MAX_OP_LIMITS_MENU_HEIGHT = 300;
const MAX_SELECTED_LIMITS = 4;
const MAX_SHOW_SELECTED_LIMITS = 2;

export const opLimitsConstants = {
    MAX_OP_LIMITS_MENU_HEIGHT,
    MAX_SELECTED_LIMITS,
    MAX_SHOW_SELECTED_LIMITS
};

export const SELECTOR_ACTIONS = ['remove-value', 'select-option', 'deselect-option', 'clear'];
