import localStorageHelper from './localStorageHelper';

export const getBreadcrumbStorage = () => {
    const powertrainAssetDetails = localStorageHelper.getItem('powertrainAssetDetails');
    return powertrainAssetDetails || null;
};

export const setBreadcrumbStorage = (data) => {
    localStorageHelper.setItem('powertrainAssetDetails', data);
};
