
export const actionTypes = {
	GET_PARAMETER_FILE_LIST: 'detailedInformation/header/parameterbackup/GET_PARAMETER_FILE_LISTV2',
	GET_PARAMETER_FILE_LIST_REQUEST: 'detailedInformation/header/parameterbackup/GET_PARAMETER_FILE_LISTV2_REQUEST',
	GET_PARAMETER_FILE: 'detailedInformation/header/parameterbackup/GET_PARAMETER_FILEV2',
	GET_PARAMETER_FILE_REQUEST: 'detailedInformation/header/parameterbackup/GET_PARAMETER_FILEV2_REQUEST',
	CLEAR_PARAMETER_FILE_DATA: 'detailedInformation/header/parameterbackup/CLEAR_PARAMETER_FILEV2_DATA',
};

export const MAX_DAYS_RANGE = 90;
export const PRESELECTED_DAYS = 90;
export const ITEMS_PER_PAGE = 10;
