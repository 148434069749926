import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SearchBox } from 'sharedComponents/SearchBox';
import { map } from 'lodash';
import { Checkbox } from 'sharedComponents/Checkbox';

const CopyNotificationSettingsComponent = (props) => {
    const {
        organizationList,
        selectedOrganization,
        setSelectedOrganization,
        copySettingOptions,
        setCopySettingOptions
    } = props;
    const { t: translate } = useTranslation();
    const [searchboxFilter, setSearchBoxFilter] = useState('');
    const [filteredOrganizations, setFilteredOrganizations] = useState(organizationList ?? []);
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        return () => {
            setSelectedOrganization([]);
            setCopySettingOptions({
                organizationDigest: false,
                instantNotifications: false
            });
            setSearchBoxFilter('');
            setFilteredOrganizations(organizationList ?? []);
        };
    }, []);

    useEffect(() => {
        setFilteredOrganizations(organizationList);
    }, [organizationList]);

    const handleSearch = (value) => {
        const search = value?.toString()?.toLowerCase();
        setSearchBoxFilter(value);
        setFilteredOrganizations(organizationList.filter((organization) => organization?.organizationID?.toString().includes(search) ||
            organization?.guid?.toLowerCase().includes(search) || organization?.name?.toLowerCase().includes(search)
        ));
    };

    const handleSelectAll = useCallback(() => {
        setSelectAll(prev => {
            const newSelectAll = !prev;
            setSelectedOrganization(newSelectAll ? organizationList?.map(org => org?.organizationId) : []);
            return newSelectAll;
        });
    }, [organizationList, selectedOrganization]);

    useEffect(() => {
        if (organizationList?.length === selectedOrganization?.length) {
            setSelectAll(true);
        }
        else if (selectedOrganization?.length >= 1) {
            setSelectAll(null);
        }
        else {
            setSelectAll(false);
        }
    }, [organizationList, selectedOrganization]);

    const handleToggle = (id) => {
        let modifySelectedOrganizations = [...selectedOrganization];
        if (selectedOrganization.includes(id)) {
            modifySelectedOrganizations = selectedOrganization.filter((orgID) => orgID !== id);
        } else {
            modifySelectedOrganizations.push(id);
        }
        setSelectedOrganization(modifySelectedOrganizations);
    };

    return <>
        <div className='settings-to-copy'>
            <label>{translate('ABB.Powertrain.Frontend.settingsToCopyLabel')}</label>
            <div className='copy-setting-options'>
                {map(Object.keys(copySettingOptions), (optionKey) =>
                    <div key={optionKey} className='organization-item'>
                        <div className='organization-check-box'>
                            <Checkbox
                                checked={copySettingOptions[optionKey]}
                                onToggle={() => {
                                    setCopySettingOptions({
                                        ...copySettingOptions,
                                        [optionKey]: !copySettingOptions[optionKey]
                                    });
                                }}
                                width={'22px'}
                                height={'22px'}
                            />
                        </div>
                        <div
                            className='organization-title'
                            onClick={() => {
                                setCopySettingOptions({
                                    ...copySettingOptions,
                                    [optionKey]: !copySettingOptions[optionKey]
                                });
                            }}>
                            {translate(`ABB.Powertrain.Frontend.${optionKey === 'organizationDigest' ?
                                'notificationsEmailDigestTitle' : 'notificationsChannelInfoTitle'}`)
                            }
                        </div>
                    </div>
                )}
            </div>
        </div>
        <div className='copy-organizations-list'>
            <label>{translate('ABB.Powertrain.Frontend.copyToOrganizationsLabel')}</label>
            {organizationList?.length ? <>
                <div className='search-organizations'>
                    <SearchBox
                        placeholder={translate('ABB.Powertrain.Frontend.searchOrganizationPlaceholder')}
                        onChange={(value) => handleSearch(value)}
                        value={searchboxFilter}
                    />
                </div>
                {filteredOrganizations?.length ?
                    <div className='organization-list'>
                        <div className='copy-organization-list select-all'>
                            <Checkbox checked={selectAll} onToggle={handleSelectAll} width={'22px'} height={'22px'} />
                            <div className='asset-list-bulk asset-list-bulk--select-all'>{translate('ABB.Powertrain.Frontend.SelectAllAssets')}</div>
                        </div>
                        {map(filteredOrganizations, (organization) =>
                            <div key={organization?.organizationID} className='organization-item'>
                                <div className='organization-check-box'>
                                    <Checkbox
                                        checked={selectedOrganization.includes(organization?.organizationID)}
                                        onToggle={() => handleToggle(organization?.organizationID)}
                                        width={'22px'}
                                        height={'22px'}
                                    />
                                </div>
                                <div className='organization-title' onClick={() => handleToggle(organization?.organizationID)}>{organization?.name} ({organization?.guid || organization?.organizationID})</div>
                            </div>
                        )}
                    </div>
                    : <div className='no-matches-found'>{translate('ABB.Powertrain.Frontend.noOrganizationsAvailable')}</div>
                }
            </>
                : <div className='no-matches-found'>{translate('ABB.Powertrain.Frontend.noOrganizationsAvailable')}</div>
            }
        </div>
    </>;
};

CopyNotificationSettingsComponent.propTypes = {
    organizationList: PropTypes.any,
    selectedOrganization: PropTypes.array,
    setSelectedOrganization: PropTypes.func,
    copySettingOptions: PropTypes.object,
    setCopySettingOptions: PropTypes.func,
};

export default CopyNotificationSettingsComponent;
