import { takeLatest, call, put, select } from 'redux-saga/effects';
import { PowertrainApi } from 'api';
import { apiCallStatus } from 'helpers/constants';
import logger from 'helpers/logger';
import { actionTypes } from './constants';
import {
    getPowertrainsRequest,
    reloadOverviewPowertrainsRequest,
    getPowertrainsAction,
    //getOverviewPowertrainsAction,
    deletePowertrainAction
} from './actions';
import {
    formatGetPowertrainsRequest
} from './helpers';
//import { getExistingSiteIds } from 'helpers/sitesHelper';


function* getPowertrainsSaga({ siteIds, organizationIds, nameFilter, pageNumber }) {
    yield put(getPowertrainsAction(apiCallStatus.LOADING));
    try {
        const state = yield select();
        const ciamAdminFlag = state?.overview?.user?.data?.isAdminFromCIAM;
        const powertrains = yield call(
            PowertrainApi.getPowertrains,
            formatGetPowertrainsRequest(siteIds, organizationIds, nameFilter, pageNumber, !ciamAdminFlag)
        );
        yield put(getPowertrainsAction(apiCallStatus.SUCCESS, { powertrains, pageNumber }));
        logger.debug('Powertrains successfully loaded for page ' + pageNumber);
    } catch (err) {
        yield put(getPowertrainsAction(apiCallStatus.ERROR, [], err));
    }
}

function* deletePowertrainSaga({ powertrainId, organizationIds, siteIds, nameFilter }) {
    yield put(deletePowertrainAction(apiCallStatus.LOADING));
    try {
        yield call(PowertrainApi.deletePowertrain, powertrainId);
        yield put(deletePowertrainAction(apiCallStatus.SUCCESS, { powertrainId }));
        yield put(getPowertrainsRequest(siteIds, organizationIds, nameFilter, 1));
        logger.debug('Powertrain deleted successfully');

        //TO DO: edit when common powertrain list is used for configuration and overview
        yield put(reloadOverviewPowertrainsRequest());
    } catch (err) {
        yield put(deletePowertrainAction(apiCallStatus.ERROR, [], err));
    }
}


export function* powertrainConfigurationWatchers() {
    yield takeLatest(actionTypes.LOAD_POWERTRAINS_REQUEST, getPowertrainsSaga);
    yield takeLatest(actionTypes.DELETE_POWERTRAIN_REQUEST, deletePowertrainSaga);

    //TO DO: edit when common powertrain list is used for configuration and overview
    //yield takeLatest(actionTypes.RELOAD_OVERVIEW_POWERTRAINS_REQUEST, reloadOverviewPowertrainsSaga);
}
