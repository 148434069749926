import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { LoadingStatusWrapper } from 'sharedComponents/WithLoadingStatus';
import { ToggleButton } from 'sharedComponents/ToggleButton';
import { useQueryParams } from 'sharedHooks/useQuery';
import { IconShowFiltersButton } from 'svgIcons/MotionPortalIcons';
import { SearchBox } from 'sharedComponents/SearchBox';
import { Filter } from './FilterComponent';
import { AssetOverview } from './AssetOverview';
import { AssetGroupOverview } from './AssetGroupOverview';
import { PowertrainOverview } from './PowertrainOverview';
import {
    getToggleButtonOptions,
    getFilterVisibilityFromLocalStorage,
    setFilterVisibilityToLocalStorage,
    getOverviewNumber,
    isTabContentLoading
} from './helpers';
import { toggleButtonOptions } from './constants';
import './style.scss';
import { getBreadcrumbStorage } from 'helpers/breadcrumbHelper';
import useUserCapability from 'sharedHooks/useUserCapability';


const ConditionIndicesOverviewComponent = ({ model, actions }) => {
    const { t: translate } = useTranslation();
    const { assetId } = useQueryParams();
    const history = useHistory();
    const userCapabilities = useUserCapability();
    const hasConditionOverviewSubscriptionFilter = userCapabilities?.hasConditionOverviewSubscriptionFilter();
    const [showFilter, setShowFilter] = useState(getFilterVisibilityFromLocalStorage());
    const [powertrain, setPowertrain] = useState(null);
    const [assetGroup, setAssetGrouop] = useState(null);
    const [asset, setAsset] = useState(null);

    const {
        selectedFilters,
        searchBoxFilter,
        conditionFilter,
        assetTypeFilter,
        subscriptionTypeFilter,
        assetNumber,
        assetGroupNumber,
        assetGroupAssetNumber,
        powertrainNumber,
        powertrainAssetNumber,
        activeTab,
        generalFilterLoadingStatuses,
        assetOverviewLoadingStatus,
        assetGroupOverviewLoadingStatus,
        powertrainOverviewLoadingStatus,
        allAssets,
        subscriptionTypes,
        selectedOrganization
    } = model;

    const {
        setAssetTypeFilter,
        toggleConditionFilter,
        resetFilters,
        setSearchboxFilter,
        setSubscriptionTypeFilter,
        setActiveTab,
        getSubscriptionTypes
    } = actions;
    const organizationId = selectedOrganization?.organizationID;

    const filterVisibilityChange = (visibility) => {
        setShowFilter(visibility);
        setFilterVisibilityToLocalStorage(visibility);
    };

    const tabContentLoading = isTabContentLoading(
        activeTab,
        assetOverviewLoadingStatus,
        assetGroupOverviewLoadingStatus,
        powertrainOverviewLoadingStatus
    );

    useEffect(() => {
        if (hasConditionOverviewSubscriptionFilter && organizationId) {
            getSubscriptionTypes(organizationId);
        }
    }, [hasConditionOverviewSubscriptionFilter, organizationId]);

    useEffect(() => {
        if (assetId) {
            setActiveTab(toggleButtonOptions.ASSETS);
            setSearchboxFilter(assetId);
        }
    }, [assetId]);

    useEffect(() => {
        resetFilters();
    }, [selectedFilters]);

    useEffect(() => {
        const routeData = getBreadcrumbStorage();
        if (routeData) {
            if (routeData?.powertrainName) {
                setActiveTab(toggleButtonOptions.POWERTRAINS);
                setPowertrain(routeData);
            } else if (routeData?.assetGroupName) {
                setActiveTab(toggleButtonOptions.ASSETGROUPS);
                setAssetGrouop(routeData);
            } else {
                setActiveTab(toggleButtonOptions.ASSETS);
                setAsset(routeData);
            }
        }
    }, []);

    const renderSelectedTab = () => {
        switch (activeTab) {
            case toggleButtonOptions.ASSETGROUPS: {
                return <AssetGroupOverview
                    selectedFilters={selectedFilters}
                    searchBoxFilter={searchBoxFilter}
                    conditionFilter={conditionFilter}
                    assetTypeFilter={assetTypeFilter}
                    subscriptionTypeFilter={subscriptionTypeFilter}
                    generalFilterLoadingStatuses={generalFilterLoadingStatuses}
                    withVisibleFilter={showFilter}
                    assetGroup={assetGroup}
                    allAssets={allAssets}
                />;
            }
            case toggleButtonOptions.POWERTRAINS: {
                return <PowertrainOverview
                    selectedFilters={selectedFilters}
                    searchBoxFilter={searchBoxFilter}
                    conditionFilter={conditionFilter}
                    assetTypeFilter={assetTypeFilter}
                    subscriptionTypeFilter={subscriptionTypeFilter}
                    generalFilterLoadingStatuses={generalFilterLoadingStatuses}
                    withVisibleFilter={showFilter}
                    powertrain={powertrain}
                    allAssets={allAssets}
                />;
            }
            default: return <AssetOverview
                selectedFilters={selectedFilters}
                searchBoxFilter={searchBoxFilter}
                conditionFilter={conditionFilter}
                assetTypeFilter={assetTypeFilter}
                subscriptionTypeFilter={subscriptionTypeFilter}
                generalFilterLoadingStatuses={generalFilterLoadingStatuses}
                withVisibleFilter={showFilter}
                asset={asset}
                allAssets={allAssets}
            />;
        }
    };

    return <LoadingStatusWrapper loadingStatus={[
        ...generalFilterLoadingStatuses
    ]}>
        <div className='powertrain-assets-overview-container'>
            <div className='overview-title'>
                {`${translate('ABB.Powertrain.Frontend.conditionIndicesOverviewOverviewTitle')}`}
            </div>
            <div className='toggle-and-search-row'>
                <ToggleButton
                    options={getToggleButtonOptions(translate)}
                    onSelected={(tab) => {
                        setActiveTab(tab);
                    }}
                    selectedValue={activeTab}
                />
                <div className='right-section'>
                    <div className='nr-of-assets'>
                        {getOverviewNumber(activeTab, assetNumber, assetGroupNumber, assetGroupAssetNumber, powertrainNumber, powertrainAssetNumber, translate)}
                    </div>
                    <SearchBox
                        placeholder={translate('ABB.Powertrain.Frontend.nameOrSerialNoLabel')}
                        onChange={(value) => {
                            history.replace(history.location.pathname);
                            setSearchboxFilter(value);
                        }}
                        value={searchBoxFilter}
                    />
                    <button className='filter-icon' onClick={() => filterVisibilityChange(!showFilter)}>
                        <IconShowFiltersButton />
                    </button>
                </div>
            </div>
            {showFilter ? <Filter
                setAssetTypeFilter={setAssetTypeFilter}
                assetTypeFilter={assetTypeFilter}
                conditionFilter={conditionFilter}
                toggleConditionFilter={toggleConditionFilter}
                subscriptionTypes={subscriptionTypes}
                setSubscriptionTypeFilter={setSubscriptionTypeFilter}
                subscriptionTypeFilter={subscriptionTypeFilter}
                resetFilters={() => {
                    history.replace(history.location.pathname);
                    resetFilters();
                }}
                isDisabled={tabContentLoading}
                assetTypeFilterVisible={activeTab === toggleButtonOptions.ASSETS}
            /> : <br />}
            {renderSelectedTab()}
        </div>
    </LoadingStatusWrapper>;
};

ConditionIndicesOverviewComponent.propTypes = {
    model: PropTypes.shape({
        selectedFilters: PropTypes.object,
        searchBoxFilter: PropTypes.string,
        conditionFilter: PropTypes.array,
        assetTypeFilter: PropTypes.string,
        assetNumber: PropTypes.number,
        assetGroupNumber: PropTypes.number,
        assetGroupAssetNumber: PropTypes.number,
        powertrainNumber: PropTypes.number,
        powertrainAssetNumber: PropTypes.number,
        activeTab: PropTypes.string,
        generalFilterLoadingStatuses: PropTypes.array,
        assetOverviewLoadingStatus: PropTypes.string,
        assetGroupOverviewLoadingStatus: PropTypes.string,
        powertrainOverviewLoadingStatus: PropTypes.string
    }),
    actions: PropTypes.shape({
        setAssetTypeFilter: PropTypes.func,
        toggleConditionFilter: PropTypes.func,
        setSubscriptionTypeFilter: PropTypes.func,
        resetFilters: PropTypes.func,
        setSearchboxFilter: PropTypes.func,
        setActiveTab: PropTypes.func,
        getSubscriptionTypes: PropTypes.func
    })
};

export default ConditionIndicesOverviewComponent;
