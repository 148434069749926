import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navbar, Nav } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import { menuBarItems } from './constants';
import { UserProfileSettingsDropdown } from './UserProfileSettingsDropdown';
import { HelpMenu } from './HelpMenu';
import { NotificationDropdown } from './NotificationDropdown';
import { MainMenuDropdown } from './MainMenuDropdown';
import { IconMOPortalTitle } from 'svgIcons/MotionPortalIcons';
import useUserCapability from 'sharedHooks/useUserCapability';
import { SwitchOrganizationModal } from '../../SwitchOrganizationModal';
import { routes } from 'routes';
import { GeneralFilter } from '../../GeneralFilter';

const HeaderComponent = () => {
    const { t: translate } = useTranslation();
    const userCapabilityHelper = useUserCapability();
    const [menuBarFits, setMenuBarFits] = useState(window.innerWidth > 1160);
    const location = useLocation();

    const updateMenuVisibility = () => {
        setMenuBarFits(window.innerWidth > 1160);
    };

    useEffect(() => {
        window.addEventListener('resize', updateMenuVisibility, false);

        return () => {
            window.removeEventListener('resize', updateMenuVisibility, false);
        };
    }, []);

    const isActiveRoute = (route) => {
        if (route === routes.ConditionMonitoring) {
            return location.pathname.startsWith(routes.ConditionMonitoring) || location.pathname.startsWith(routes.PowertrainAndAssetDetails);
        }
        if (route === routes.Tools) {
            return location.pathname.startsWith(routes.Tools);
        }
        return location.pathname === route;
    };

    const renderMenuBarItems = () =>
        menuBarFits && <Nav>
            {
                menuBarItems.map((headerItem, key) =>
                    <Nav.Link
                        key={key}
                        as={NavLink}
                        to={headerItem.route}
                        isActive={() => isActiveRoute(headerItem.route)}
                        activeClassName='active'
                    >
                        {translate(headerItem.title)}
                    </Nav.Link>)
            }
        </Nav >;

    return (
        <div className='header-component'>
            <Navbar className='pt-navbar-container' fluid='true'>
                <Navbar.Brand>
                    <span className='mo-portal-title'>
                        <IconMOPortalTitle />
                    </span>
                </Navbar.Brand>
                <Navbar.Collapse>
                    {renderMenuBarItems()}
                    <Nav className='pt-navbar-right'>
                        <GeneralFilter />
                        {userCapabilityHelper.hasNotificationSettingsCapability() && <NotificationDropdown />}
                        <HelpMenu />
                        <UserProfileSettingsDropdown />
                        <MainMenuDropdown menuBarFits={menuBarFits} menuBarItems={menuBarItems} />
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <SwitchOrganizationModal />
        </div >
    );
};

export default HeaderComponent;
