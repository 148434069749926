import { takeLatest, call, put } from 'redux-saga/effects';
import { OrganizationManagementApi } from 'api';
import { apiCallStatus } from 'helpers/constants';
import logger from 'helpers/logger';
import { actionTypes } from './constants';
import {
    getMaintenanceScheduleAction,
    addMaintenanceScheduleAction,
    getMaintenanceSchedule,
    setExternalID
} from './actions';
import { notify } from 'sharedComponents/GeneralNotification';
import { NOTIFICATION_TYPES } from 'sharedComponents/GeneralNotification/constants';
import { translate } from 'helpers/translateHelper';


function* fetchMaintenanceSchedule({externalId}) {
    try {
        const maintenanceScheduleData = yield call(OrganizationManagementApi.getMaintenanceSchedule, externalId);
        const maintanenceFotaData = maintenanceScheduleData.find(data => data.maintenanceType === 'FOTA');
        yield put(getMaintenanceScheduleAction(apiCallStatus.SUCCESS, maintanenceFotaData ?? {}));
        logger.debug('Maintanence schedule successfully loaded for page ');
    } catch (err) {
        yield put(getMaintenanceScheduleAction(apiCallStatus.ERROR, [], err));
    }
}

function* addMaintenanceSchedule({ externalId, scheduleData, isForSave }) {
    yield put(addMaintenanceScheduleAction(apiCallStatus.LOADING));
    try {
        yield call(OrganizationManagementApi.addMaintenanceSchedule, externalId, scheduleData);
        yield put(addMaintenanceScheduleAction(apiCallStatus.SUCCESS, scheduleData));
        notify(translate(isForSave ?
            'ABB.Powertrain.Frontend.addMaintenanceScheduleSuccess'
            : 'ABB.Powertrain.Frontend.resetMaintenanceScheduleSuccess'), NOTIFICATION_TYPES.SUCCESS
        );
    } catch (err) {
        yield put(addMaintenanceScheduleAction(apiCallStatus.ERROR, [], err));
    }
}

function* convertIDMaintenanceSchedule({ organizationID }) {
    yield put(getMaintenanceScheduleAction(apiCallStatus.LOADING));
    try {
        const [convertIDMaintanenceData] = yield call(OrganizationManagementApi.convertIDMaintenanceSchedule, organizationID);
        const getExternalID = convertIDMaintanenceData?.convertID[0]?.smSeID ?? null;
        if (getExternalID) {
            yield put(setExternalID(getExternalID));
            yield put(getMaintenanceSchedule(getExternalID));
        } else {
            // Result is empty it means that the organization is not synced in SmSe BE.
            throw new Error('SmSe is currently not supported for your organization. Please contact support!');
        }
        logger.debug('Convert ID Maintenance schedule successfully');
    } catch (err) {
        yield put(getMaintenanceScheduleAction(apiCallStatus.ERROR, [], err));
    }
}

export function* SensorFirmwareManagementWatchers() {
    yield takeLatest(actionTypes.GET_MAINTENANCE_SCHEDULE_REQUEST, fetchMaintenanceSchedule);
    yield takeLatest(actionTypes.ADD_MAINTENANCE_SCHEDULE_REQUEST, addMaintenanceSchedule);
    yield takeLatest(actionTypes.CONVERT_ID_MAINTENANCE_SCHEDULE_REQUEST, convertIDMaintenanceSchedule);
}
