import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { GeneralInput, GeneralButton } from 'sharedComponents';
import './style.scss';
import { routes } from 'routes';


const AssetSearch = ({ searchAsset, onHide, assets }) => {
    const { t: translate } = useTranslation();
    const history = useHistory();
    const [assetSearchValue, setAssetSearchValue] = useState('');
    const setQueryParameter = (value) => {
        onHide();
        const valueWithoutSpace = value.trim();
        history.push({
            pathname: routes.AssetDetails,
            search: new URLSearchParams({ assetId: valueWithoutSpace }).toString()
        });
    };

    const clearQueryParameter = () => {
        history.push({
            pathname: history.location.pathname,
        });
    };

    return <div className='general-filter-asset-search'>
        <div className='general-filter-asset-search-label'>
            {translate('ABB.Powertrain.Frontend.generalFilterSearchAssetLbl')}
        </div>
        <div>
            <GeneralInput
                id='general-filter-asset-search'
                className='general-filter-asset-search-input'
                value={assetSearchValue}
                onChange={(value) => {
                    setAssetSearchValue(value);
                }}
                placeholder={translate('ABB.Powertrain.Frontend.generalFilterSearchAssetPlaceholder')}
                clearable={false}
            />
        </div>
        <GeneralButton
            type='primary'
            text={translate('ABB.Powertrain.Frontend.assetSearchButton')}
            className='general-filter-asset-search-button'
            onClick={() => {
                if (assetSearchValue?.length) {
                    clearQueryParameter();
                    const valueWithoutSpace = assetSearchValue.trim();
                    searchAsset(
                        valueWithoutSpace,
                        assets,
                        () => setQueryParameter(valueWithoutSpace),
                        clearQueryParameter,
                        [],
                        history // PASSING HISTORY FOR NAVIGATION FROM LEGACY ID TO MICRO-SERVICE ID ON ASSET SEARCH FROM GENERAL FILTER
                    );
                }
            }}
            disabled={!assetSearchValue?.length}
        />
    </div>;
};

AssetSearch.propTypes = {
    searchAsset: PropTypes.func,
    onHide: PropTypes.func,
    assets: PropTypes.array
};

export default AssetSearch;
