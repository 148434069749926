import { getApiActionCreator } from 'helpers/actionHelper';
import { apiCallStatus } from 'helpers/constants';
import { actionTypes } from './constants';
import {
    formatMultiGraphRequest,
    getMultiGraphConfig,
    formatMultiTrendData,
    setMultiTrendDataToLoadingStatus
} from './helpers';
import TimeSeries from 'api/TimeSeriesApi/timeSeries';


const getMultiTrendDataAction = getApiActionCreator(actionTypes.GET_MULTI_TREND_DATA);

export const getMultiChartConfig = (availableKPIs) => {
    return (dispatch) => {

        const multiChartKPIs = getMultiGraphConfig(availableKPIs);
        dispatch({
            type: actionTypes.SET_MULTICHART_CONFIG,
            data: multiChartKPIs
        });
    };
};

export const clearMultiChartData = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.CLEAR_MULTICHART_DATA,
        });
    };
};

// load chart data for all charts on the multi-graph page
export const loadMultiChartData = (allKPIs, kpisToDisplay, { from, to }, isZoomed = false) => {
    return (dispatch, getState) => {
        const state = getState();
        const selectedAsset = state.powertrainAssetDetails?.selectedAsset;

        dispatch(getMultiTrendDataAction(
            apiCallStatus.LOADING,
            setMultiTrendDataToLoadingStatus(state.operationalParametersV2?.charts?.multi?.trend, isZoomed, from, to, apiCallStatus.LOADING)
        ));
        if (kpisToDisplay.length) {
            const multiGraphRequest = formatMultiGraphRequest(kpisToDisplay, allKPIs, selectedAsset);

            return TimeSeries.getTrend(multiGraphRequest, from, to)
                .then((data) => {
                    dispatch(getMultiTrendDataAction(
                        apiCallStatus.SUCCESS,
                        formatMultiTrendData({ data, isZoomed, from, to }, apiCallStatus.SUCCESS)
                    ));
                })
                .catch((err) => {
                    dispatch(getMultiTrendDataAction(
                        apiCallStatus.ERROR,
                        setMultiTrendDataToLoadingStatus(state.operationalParametersV2?.charts?.multi?.trend, isZoomed, from, to, apiCallStatus.ERROR),
                        err));
                });
        }
    };
};
