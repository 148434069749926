import { actionTypes } from './constants';

export const getParameterFileListForAsset = (asset, timeStampFrom, timeStampTo) => {
	return {
		type: actionTypes?.GET_PARAMETER_FILE_LIST_REQUEST,
		asset,
		timeStampFrom,
		timeStampTo
	};
};

export const getParameterFileWithId = (asset, id, messages) => {
	return {
		type: actionTypes?.GET_PARAMETER_FILE_REQUEST,
		asset,
		id,
		messages
	};
};

export const clearParameterFileData = () => {
	return {
		type: actionTypes?.CLEAR_PARAMETER_FILE_DATA
	};
};
