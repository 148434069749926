import PropTypes from 'prop-types';
import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import { EventLog } from './EventLog';
import { GeneralRouterTabs, LoadingIndicator } from 'sharedComponents';
import { getTabId, getTabOptions, isSmSeAsset } from './helpers';
import { AssetDetails } from './AssetDetails';
import { ConditionInsights } from './ConditionInsights';
import { OperationalParameters as OperationalParametersV2 } from './OperationalParametersV2';
import { SensorProperties } from './SensorProperties';
import { LoadingStatusWrapper } from 'sharedComponents/WithLoadingStatus';
import { useNewCapability } from 'sharedHooks';
import { AdvancedAnalyticsLazy } from './AdvancedAnalytics';
import featureConfigHelper from 'helpers/featureConfigHelper';
// import { LimitConfigContainer } from './LimitConfigurationBeta';
import { OVER_ALL_STATUS } from './ConditionInsights/constants';


const Tabs = ({
    model: {
        asset,
        assetV2,
        newCapabilityLoadingStatus
    },
    actions: {
        setActiveTab,
        setIsDefaultLimitsSelected
    } }) => {
    const { t: translate } = useTranslation();
    const featureConfig = new featureConfigHelper();
    const newCapability = useNewCapability();
    const location = useLocation();
    const assetCapabilities = useMemo(() => ({
        isEventLogHidden: !newCapability.hasEventListCapability(),
        isAdvancedAnalyticsDisabled: !newCapability.hasCBMCapability(),
        isOperationalParameterV2Hidden: !newCapability.hasOpCapability() || !newCapability.hasTrendCapability(),
        isLimitConfigHidden: !newCapability.hasLimitConfigCapability(),
        hasConditionIndexNewCapability: !newCapability.hasConditionIndexCapability(),
        hasConditionDetailsCapability: !newCapability.hasConditionDetailsNewCapability(),
    }), [newCapability]);

    const [tabOptions, setTabOptions] = useState({});
    const [isFullyLoaded, setIsFullyLoaded] = useState(false);

    useEffect(() => {
        //Removing old capabilities check for now
        if (newCapability.isSet()) {
            if (assetV2?.data?.length > 0) {
                const getFilteredAsset = assetV2?.data?.find(assetList => assetList?.assetId === asset.id);
                setTabOptions(getTabOptions(translate, isSmSeAsset(asset), OVER_ALL_STATUS[getFilteredAsset?.condition], assetCapabilities, asset));
                setIsFullyLoaded(true);
            }
        }
    }, [newCapability, asset?.overallStatus, assetCapabilities, translate, assetV2?.data]);

    useEffect(() => {
        if (location && Object.keys(tabOptions).length > 0) {
            setActiveTab(getTabId(tabOptions, location?.pathname));
            setIsDefaultLimitsSelected(false);
        }
    }, [location, tabOptions, setActiveTab]);

    // Fallback while loading tabs
    if (!isFullyLoaded) {
        return <LoadingIndicator />;
    }

    return (
        <GeneralRouterTabs
            options={tabOptions}
            variant='panel'
        >
            <LoadingStatusWrapper loadingStatus={[newCapabilityLoadingStatus]}>
                {newCapability.isSet() &&
                    <Switch>
                        <Route id={tabOptions.assetDetails.tabID} path={tabOptions.assetDetails.route}>
                            <AssetDetails asset={asset} />
                        </Route>
                        <Route path={tabOptions.conditionInsights.route}>
                            <ConditionInsights asset={asset} />
                        </Route>
                        {!tabOptions.operationalParametersV2.hidden &&
                            <Route path={tabOptions.operationalParametersV2.route}>
                                <OperationalParametersV2 asset={asset} />
                            </Route>
                        }
                        {newCapability?.hasEventListCapability() && !tabOptions.eventLog.hidden &&
                            <Route path={tabOptions.eventLog.route}>
                                <EventLog asset={asset} />
                            </Route>
                        }
                        {/* COMMENTED AS LIMIT CONFIGURATION MOVED TO OPV2 */}
                        {/* {
                            <Route path={tabOptions.limitsConfiguration.route}>
                                <LimitConfigContainer asset={asset} />
                            </Route>
                        } */}
                        {!tabOptions.sensorProperties.hidden &&
                            <Route path={tabOptions.sensorProperties.route}>
                                <SensorProperties asset={asset} />
                            </Route>
                        }
                        {newCapability?.hasCBMCapability() && featureConfig.isCBMFeatureEnabled() &&
                            <Route path={tabOptions.advancedAnalytics.route}>
                                <AdvancedAnalyticsLazy asset={asset} />
                            </Route>
                        }
                        <Redirect from='*' to={{ pathname: tabOptions.assetDetails.route, search: location.search }} />
                    </Switch>
                }
            </LoadingStatusWrapper>
        </GeneralRouterTabs >
    );
};

Tabs.propTypes = {
    model: PropTypes.shape({
        asset: PropTypes.object,
        assetV2: PropTypes.object,
        capabilityLoadingStatus: PropTypes.any,
        newCapabilityLoadingStatus: PropTypes.any,
    }),
    actions: PropTypes.shape({
        setActiveTab: PropTypes.func
    })
};

export default Tabs;
