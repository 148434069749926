import endpoints, { formatEndpoint } from '../endpoints';
import { RestManagerWithoutBaseUrl } from '../RestManager';
import { handleUnitConversion } from './helper';

class LimitConfigActions {

    getLimitKpi(assetId) {
        const endpoint = formatEndpoint(endpoints.limitConfiguration.getLimitKpi, { assetId });
        return RestManagerWithoutBaseUrl.get(endpoint);
    }

    postLimitConfig(assetId, timeseries) {
        const endpoint = formatEndpoint(endpoints.limitConfiguration.postTimeseriesLimits);
        return RestManagerWithoutBaseUrl.put(endpoint, { assetId, timeseries });
    }

    getTimeSeriesLimits(assetId) {
        const endpoint = formatEndpoint(endpoints.limitConfiguration.getTimeseriesLimits, { assetId });
        return RestManagerWithoutBaseUrl.get(endpoint).then(handleUnitConversion);
    }

    getSuggestedLimits(payload) {
        const endpoint = formatEndpoint(endpoints.limitConfiguration.getSuggestedLimits, payload);
        return RestManagerWithoutBaseUrl.get(endpoint);
    }

    getDefaultLimits(assetId, timeseries) {
        const endpoint = formatEndpoint(endpoints.limitConfiguration.getDefaultLimits);
        return RestManagerWithoutBaseUrl.post(endpoint, { assetId, timeseries });
    }
}

export default new LimitConfigActions();
