import { apiCallStatus, filterTabs } from 'helpers/constants';
import { createSelector } from 'reselect';
import { filterAllAssets, filterPowertrainAssets } from './helpers';
import { getContractInfo } from '../../helpers';
import {
    //getAssetsLoadingStatus,
    getAssetsLoadingStatusV2,
    //getGeneralFilteredAssets,
    getGeneralFilteredAssetsV2,
    getGeneralFilteredPowertrains,
    getPowertrainLoadingStatusv2,
    getLoadingStatuses
} from 'helpers/selectors';

const selectKpiLoadingStatus = state => state.crossAssetAnalysisSidepanel?.kpiList?.loadingStatus;
export const selectShouldScrollToSelection = state => state.crossAssetAnalysisSidepanel.shouldScrollToSelection;

export const selectIsKPILoaded = createSelector(selectKpiLoadingStatus, kpiLoadingStatus =>
    kpiLoadingStatus === apiCallStatus.SUCCESS
);


const getFilters = ({ crossAssetAnalysisSidepanel }) => {
    return crossAssetAnalysisSidepanel;
};

const getActiveTab = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails.activeTab;
};

const getAssetKpiList = ({ crossAssetAnalysisSidepanel }) => {
    return crossAssetAnalysisSidepanel.assetKPILists;
};

const getSelectedKPIType = ({ chartView }) => {
    return chartView.selectedKPIType;
};

const getCrossAssetAnalysisActiveTab = ({ chartView }) => {
    return chartView.activeTab;
};

const getChartData = ({ chartView }) => {
    return chartView.chartData;
};


const getSelectedAsset = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails.selectedAsset;
};

const getSelectedPowertrain = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails.selectedPowertrain;
};

const getSelectedAssetCapability = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails?.newAssetCapability;
};

const capabilityLoadingStatus = ({ powertrainTools }) => {
    return powertrainTools?.subscriptionStatus?.subscriptionArr?.loadingStatus;
};

const getAssetDetails = ({ detailedInformation }) => {
    return detailedInformation.assetDetails?.data;
};

const selectContractInfo = ({ powertrainTools }) => {
    return powertrainTools?.subscriptionStatus?.subscriptionArr?.data?.assetSubscriptionsInfos[0]?.subscriptionInfos;
};

const getsubscription = createSelector([getAssetDetails, selectContractInfo, capabilityLoadingStatus],
    (assetDetails, contractInfo, loadingStatus) => {
        return {
            contractInfo: getContractInfo(assetDetails, contractInfo, loadingStatus)
        };
    }
);

const getFormattedAssets = createSelector(
    [getGeneralFilteredAssetsV2, getGeneralFilteredPowertrains, getActiveTab, getFilters, getSelectedPowertrain],
    (assets, powertrains, activeTab, filters, selectedPowertrain) => {
        return activeTab === filterTabs.POWERTRAINS ?
            filterPowertrainAssets(powertrains, selectedPowertrain) :
            filterAllAssets(assets, filters.searchboxFilter, filters.gatewayTypeFilter);
    }
);


export const getModel = createSelector(
    [
        getGeneralFilteredPowertrains,
        getFormattedAssets,
        getSelectedAsset,
        getSelectedAssetCapability,
        getSelectedKPIType,
        getLoadingStatuses,
        getAssetsLoadingStatusV2,
        getPowertrainLoadingStatusv2,
        getChartData,
        getCrossAssetAnalysisActiveTab,
        getAssetKpiList,
        getFilters,
        getActiveTab,
        getSelectedPowertrain,
        getsubscription,
        capabilityLoadingStatus
    ],
    (
        powertrains,
        assets,
        selectedAsset,
        selectedAssetCapability,
        selectedKPIType,
        generalFilterLoadingStatus,
        assetsLoadingStatus,
        powertrainsLoadingStatus,
        chartData,
        crossAssetAnalysisActiveTab,
        assetKpiLists,
        filters,
        activeTab,
        selectedPowertrain,
        subscription,
        capabilityLoadingStatus

    ) => {
        return {
            powertrains,
            assets,
            selectedAsset,
            selectedAssetCapability,
            selectedKPIType,
            generalFilterLoadingStatus,
            assetsLoadingStatus,
            powertrainsLoadingStatus,
            chartData,
            crossAssetAnalysisActiveTab,
            assetKpiLists,
            filters,
            activeTab,
            selectedPowertrain,
            subscription,
            capabilityLoadingStatus
        };
    }
);
