import React from 'react';
import { connect } from 'react-redux';

import { getModel } from './selectors';
import GeneralFilterNavBarComponent from './GeneralFilterNavBarComponent';


const GeneralFilterNavBarContainer = (props) => {
	return <GeneralFilterNavBarComponent {...props}/>;
};

const mapStateToProps = (state) => {
	const model = getModel(state);
	return {
		model
	};
};


export default connect(
	mapStateToProps,
	null
)(GeneralFilterNavBarContainer);
