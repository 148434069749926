import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { routes } from 'routes';
import { IconBell, IconSettings } from 'svgIcons/MotionPortalIcons';
import EmptyNotification from './EmptyNotification';
import NotificationItem from './NotificationItemComponent';
import NotificationCount from './NotificationCountComponent';
import { LoadingIndicator } from 'sharedComponents/PowertrainLoadingIndicator';
import { handleNotificationNavigation } from './helpers';
import { ScrollableList } from 'sharedComponents/ScrollableList';
import { NOTIFICATION_PAGE_SIZE } from './constants';
import { apiCallStatus, NOTIFICATION_TYPES } from 'helpers/constants';
import { notify } from 'sharedComponents/GeneralNotification';

const NotificationDropdownComponent = ({ ...props }) => {
    const { t: translate } = useTranslation();
    const dispatch = useDispatch();
    const { model, actions } = props;
    const history = useHistory();

    const renderIconWithCount = () => {
        return <div className='notification-icons-container'>
            <IconBell width={24} height={24} viewBox='0 0 24 24' />
            <NotificationCount className='ms-1' count={model?.allNotificationList?.length} color='primary' mainCount={true} />
        </div>;
    };

    const renderNotificationDefaultMessage = () => {
        return <div className='empty-notification-text mt-2'>
            {translate('ABB.Powertrain.Frontend.defaultNotificationMsg')}
        </div>;
    };

    const handleNavigationFromNotification = (item) => {
        // NOTIFY USER FOR ASSET NOT FOUND
        if (!model?.allAssets?.find((asset) => asset?.serialNumber === item?.assetSerialNumber || asset?.assetID === item?.assetId)) {
            notify(translate('ABB.Powertrain.Frontend.noAssetFound'), NOTIFICATION_TYPES.ERROR, true);
            return;
        }
        handleNotificationNavigation(item, history);
    };

    // SHOW SWITCH ORG MODAL
    const showSwitchOrgModal = () => {
        actions.setShowSwitchOrgModal(true);
    };

    const closeNotification = (item, navigation) => {
        dispatch(actions.readNotificationListAction(item?.groupId));
        if (navigation) {
            if (model?.currentOrganization?.length && model?.currentOrganization[0]?.organizationID !== item.organizationId) {
                actions.setSelectedNotification(item);
                showSwitchOrgModal();
                return;
            }
            handleNavigationFromNotification(item);
        }
    };

    const renderNotificationList = () => {
        return model?.notificationList?.map((item, i) => <Dropdown.Item key={`${item.groupId}_${i}`} as='div' className='p-0'><NotificationItem item={item} key={item.groupId} onClose={closeNotification} /></Dropdown.Item>);
    };

    return <DropdownButton
        role='dropdown-button'
        className='notification-dropdown-menu'
        autoClose={true}
        title={renderIconWithCount()}>
        <div className='d-flex justify-content-between px-3 mb-3'>
            <div className='notification-title'>{translate('ABB.Powertrain.Frontend.notificationLabel')}</div>
            <Dropdown.Item className='configure-notification py-0 w-auto' onClick={() => history.push(routes.NotificationSettings)}>
                <IconSettings /><div className='ms-1'>{translate('ABB.Powertrain.Frontend.configureNotificationLabel')}</div>
            </Dropdown.Item>
        </div>
        {model?.notificationLoadingStatus ? <LoadingIndicator /> :
            model?.notificationList?.length > 0 ?
                <ScrollableList
                    className={'border-0 notification-list-container pb-4'}
                    loadingStatus={model?.notificationLoadingStatus === apiCallStatus.LOADING ? apiCallStatus.LOADING : ''}
                    withLoading={true}
                    hasMore={model.hasMore}
                    pageSize={NOTIFICATION_PAGE_SIZE}
                    loadMore={() => { actions.loadNotificationListAction(); }}
                >
                    {renderNotificationList()}
                    {renderNotificationDefaultMessage()}
                </ScrollableList>
                : <EmptyNotification />
        }
    </DropdownButton >;
};

export default NotificationDropdownComponent;
