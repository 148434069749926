import { actionTypes } from './constants';
import { apiCallStatus } from 'helpers/constants';
import { getApiActionCreator } from 'helpers/actionHelper';
import { translate } from 'helpers/translateHelper';
import { notify } from 'sharedComponents/GeneralNotification';
import { NOTIFICATION_TYPES } from 'sharedComponents/GeneralNotification/constants';
import { ComponentApi } from 'api';
import { getSelectedAsset } from '../../selectors';
import { getSensorProperties } from '../../../sensorActions';
import { getAssetDataSourceType } from 'helpers/assetAndPowertrainFilterHelper';
import { getSensorTypeValueFromCode } from '../../../helpers';

const collectRawDataAction = getApiActionCreator(actionTypes.COLLECT_RAW_DATA);

export const collectRawData = () => {
    return async (dispatch, getState) => {
        const state = getState();
        const selectedAsset = getSelectedAsset(state);
        const sensorId = state?.detailedInformation?.assetDetails?.data?.details?.sensorId;
        const dataSource = getAssetDataSourceType(selectedAsset);
        const sensor = state.sensorProperties.sensorProperties.data;
        const sensorTypeId = getSensorTypeValueFromCode(sensor.sensorType);
        const sensorIdentifier = state?.detailedInformation?.assetDetails?.data?.details?.sensorIdentifier;
        dispatch(collectRawDataAction(apiCallStatus.LOADING));
        return await ComponentApi.collectRawData(sensorIdentifier, sensorTypeId, dataSource)
            .then((response) => {
                notify(translate('ABB.Powertrain.Frontend.ptNotificationMessageRequestSuccess'), NOTIFICATION_TYPES.SUCCESS);
                dispatch(collectRawDataAction(apiCallStatus.SUCCESS, response));
                dispatch(getSensorProperties(sensorId));
            })
            .catch((err) => {
                notify(translate('ABB.Powertrain.Frontend.ptNotificationMessageRequestError'), NOTIFICATION_TYPES.ERROR, true);
                dispatch(collectRawDataAction(apiCallStatus.ERROR, null, err));
            });
    };
};
