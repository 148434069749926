import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IconHelpCircle } from 'svgIcons/MotionPortalIcons';
import {
    getEnvironmentStageSelector,
} from 'helpers/selectors';
import { mainMenuLinks } from '../MainMenuDropdown/constants';

const HelpMenuComponent = () => {
    const { i18n } = useTranslation();
    const userLanguageCode = i18n.language;
    const environment = useSelector(getEnvironmentStageSelector);
    return (
        <div className='nav-item help-menu'>
            <a target='_blank' rel='noreferrer' href={mainMenuLinks.onlineHelpLinks[environment] + userLanguageCode}><IconHelpCircle width={24} height={24} viewBox='0 0 24 24' /></a>
        </div>
    );
};

export default HelpMenuComponent;
