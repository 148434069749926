import { useEffect } from 'react';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';


export const isAlreadyAdded = (label, assetLabels) => {
	return find(assetLabels, (assetLabel) => {
		return assetLabel.tagValue === label;
	}) && true;
};

export const formatSuggestedLabels = (suggestedLabels, assetLabels) => {
	const formattedLabels = [];

	forEach(suggestedLabels, (label) => {
		!isAlreadyAdded(label, assetLabels) && formattedLabels.push({
			value: label,
			label: label
		});
	});

	return orderBy(formattedLabels, 'label', 'asc');
};

export const useOutsideClickHandler = (ref, onOutsideClick) => {
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (ref.current && event && !ref.current.contains(event.target)) {
				onOutsideClick();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		document.addEventListener('touchend', handleClickOutside); // FOR TOUCH DEVICES
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
			document.removeEventListener('touchend', handleClickOutside); // FOR TOUCH DEVICES
		};
	}, [ref]);
};
