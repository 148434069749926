import React from 'react';
import PropTypes from 'prop-types';

const IconAssetOnline = ({ width = 16, height = 16,  viewBox = '0 0 16 16'}) => {
	return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M4.4807 5.1907L10.7907 11.5307L8.6307 13.6907C7.88407 14.4346 6.8986 14.8907 5.84832 14.9785C4.79805 15.0663 3.75051 14.7802 2.8907 14.1707L1.5307 15.5307L0.470703 14.4707L1.8307 13.1107C1.22118 12.2509 0.93507 11.2034 1.0229 10.1531C1.11073 9.1028 1.56684 8.11734 2.3107 7.3707L4.4807 5.1907ZM14.4707 0.470703L15.5307 1.5307L14.1707 2.8907C14.7802 3.75051 15.0663 4.79805 14.9785 5.84832C14.8907 6.8986 14.4346 7.88407 13.6907 8.6307L11.6907 10.6307L5.3707 4.3107L7.3707 2.3107C8.11734 1.56684 9.1028 1.11073 10.1531 1.0229C11.2034 0.93507 12.2509 1.22118 13.1107 1.8307L14.4707 0.470703Z' fill='#0051FF'/>
    </svg>;
};

IconAssetOnline.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string,
};

export default IconAssetOnline;
