import map from 'lodash/map';
import isArray from 'lodash/isArray';
import { getFlatAvailableKPIList } from '../../../commonTrendHelpers';

export const getOpTrendUniqueYScaling = (selectedTrends, availableTrendKPIs) => {
    let uniqueYScalingAvailable = false;
    const trendKPIList = getFlatAvailableKPIList(availableTrendKPIs);
    if (selectedTrends.length > 1) {
        const { kpiIdentifier } = selectedTrends[0];
        const unit = trendKPIList[kpiIdentifier]?.unit || '';
        uniqueYScalingAvailable = !selectedTrends.some(trendData => trendKPIList[trendData.kpiIdentifier]?.unit !== unit);
        return uniqueYScalingAvailable;
    }

    return uniqueYScalingAvailable;
};

export const createEmptyOpTrendDataObject = (measurements, componentObj) => {
    const measurementArray = isArray(measurements) ? measurements : [measurements];

    const trendArrays = map(measurementArray, (measurement) => {
        return {
            componentID: componentObj?.id,
            config: { ...measurement?.config },
            values: [],
        };
    });

    return trendArrays;
};
