import { takeLatest, call, put, all } from 'redux-saga/effects';
import { PowertrainApi } from 'api';
import { apiCallStatus } from 'helpers/constants';
import logger from 'helpers/logger';
import { translate } from 'helpers/translateHelper';
import { notify } from 'sharedComponents/GeneralNotification';
import { NOTIFICATION_TYPES } from 'sharedComponents/GeneralNotification/constants';
import { actionTypes } from './constants';
import {
    addPowertrainAction,
    editPowertrainAction,
    getAvailableAssetsAction
} from './actions';
import {
    formatAddPowertrainRequest,
    getPowertrainAssetIds,
    formatGetAssetsRequest
} from './helpers';
import {
    reloadOverviewPowertrainsRequest,
    setSearchboxFilter,
    setSelectedPowertrain
} from '../actions';


function* addPowertrainSaga({ powertrain }) {
    yield put(addPowertrainAction(apiCallStatus.LOADING));
    let powertrainId = null;

    try {
        powertrainId = yield call(PowertrainApi.addPowertrain, formatAddPowertrainRequest(powertrain));
        yield call(PowertrainApi.updatePowertrainAssets, powertrainId, { assetIds: getPowertrainAssetIds(powertrain) });
        yield put(setSearchboxFilter(powertrain.name));
        notify(translate('ABB.Powertrain.Frontend.powertrainWizardPowertrainAddSuccessMessage'), NOTIFICATION_TYPES.SUCCESS);
        logger.debug('Powertrain successfully added');

        //TO DO: edit when common powertrain list is used for configuration and overview
        yield put(reloadOverviewPowertrainsRequest());
    } catch (err) {
        yield put(addPowertrainAction(apiCallStatus.ERROR, [], err));
        if (powertrainId) {
            yield call(PowertrainApi.deletePowertrain, powertrainId);
        }
    }
}

function* editPowertrainSaga({ powertrain, powertrainDetailsChangedFlag, addedAssetsChangedFlag }) {
    yield put(editPowertrainAction(apiCallStatus.LOADING));
    try {
        yield all([
            powertrainDetailsChangedFlag && call(PowertrainApi.updatePowertrain, {
                name: powertrain.name,
                powertrainId: powertrain.powertrainId,
                description: ''
            }),
            addedAssetsChangedFlag && call(PowertrainApi.updatePowertrainAssets,
                powertrain.powertrainId,
                { assetIds: getPowertrainAssetIds(powertrain) }
            )
        ]);

        const editedPowertrain = yield call(PowertrainApi.getPowertrain, powertrain.powertrainId);
        yield put(editPowertrainAction(apiCallStatus.SUCCESS, {
            powertrain: editedPowertrain,
            powertrainId: powertrain.powertrainId
        }));
        yield put(setSelectedPowertrain(editedPowertrain));
        logger.debug('Powertrain successfully updated');

        //TO DO: edit when common powertrain list is used for configuration and overview
        yield put(reloadOverviewPowertrainsRequest());
    }
    catch (err) {
        yield put(editPowertrainAction(apiCallStatus.ERROR, [], err));
    }
}

function* getAvailableAssetsSaga({ siteId, organizationIds, pageNumber, filter }) {
    yield put(getAvailableAssetsAction(apiCallStatus.LOADING));
    try {
        const assets = yield call(PowertrainApi.getAvailableAssets, formatGetAssetsRequest(siteId, organizationIds, pageNumber, filter));
        yield put(getAvailableAssetsAction(apiCallStatus.SUCCESS, {
            assets, pageNumber
        }));
        logger.debug('Available assets successfully loaded');
    }
    catch (err) {
        yield put(getAvailableAssetsAction(apiCallStatus.ERROR, [], err));
    }
}

export function* powertrainWizardWatchers() {
    yield takeLatest(actionTypes.ADD_POWERTRAIN_REQUEST, addPowertrainSaga);
    yield takeLatest(actionTypes.EDIT_POWERTRAIN_REQUEST, editPowertrainSaga);
    yield takeLatest(actionTypes.GET_AVAILABLE_ASSETS_REQUEST, getAvailableAssetsSaga);
}
