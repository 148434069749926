import * as constants from '../constants';


const setFiltersAction = (filters) => {
    return {
        type: constants.SET_EVENT_LOG_FILTERS,
        filters
    };
};

const setDateKindAction = (dateKind) => {
    return {
        type: constants.SET_EVENT_LOG_DATE_KIND,
        dateKind
    };
};

const setDateSelectionAction = (dateSelection) => {
    return {
        type: constants.SET_DATE_SELECTION,
        dateSelection
    };
};

export const setFilters = (filters) => {
    return (dispatch) => {
        dispatch(setFiltersAction(filters));
    };
};

export const setDateKind = (dateKind) => {
    return (dispatch) => {
        dispatch(setDateKindAction(dateKind));
    };
};

export const setDateSelection = (dateSelection) => {
    return (dispatch) => {
        dispatch(setDateSelectionAction(dateSelection));
    };
};

export const maintainEventData = (data, selectedAsset, selectedOrganization, eventOptionType) => {
    return {
        type: constants.MAINTAIN_EVENT_DATA,
        data,
        selectedAsset,
        selectedOrganization,
        eventOptionType
    };
};
