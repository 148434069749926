import { actionTypes } from './constants';
import { getApiActionCreator } from 'helpers/actionHelper';

export const getMaintenanceScheduleAction = getApiActionCreator(actionTypes.GET_MAINTENANCE_SCHEDULE_SETTINGS);
export const addMaintenanceScheduleAction = getApiActionCreator(actionTypes.ADD_MAINTENANCE_SCHEDULE_SETTINGS);

export const getMaintenanceSchedule = (externalId) => ({
	type: actionTypes.GET_MAINTENANCE_SCHEDULE_REQUEST,
	externalId
});

export const addMaintenanceSchedule = (externalId, scheduleData, isForSave) => ({
	type: actionTypes.ADD_MAINTENANCE_SCHEDULE_REQUEST,
	externalId,
	scheduleData,
	isForSave
});

export const convertIDMaintenanceSchedule = (organizationID) => ({
	type: actionTypes.CONVERT_ID_MAINTENANCE_SCHEDULE_REQUEST,
	organizationID
});

export const setExternalID = (externalID) => ({
	type: actionTypes.SET_EXTERNAL_ID_FROM_CONVERT_ID,
	externalID
});

export const clearStore = () => {
    return (dispatch) => {
        dispatch({ type: actionTypes.CLEAR_STORE });
    };
};
