import PropTypes from 'prop-types';
import React from 'react';
import { getAssetIcon } from 'helpers/icons';
//import IconCondition from 'svgIcons/MotionPortalIcons/IconCondition';

const AssetListItem = ({
	asset,
	selectedComponentID,
	onAssetClick
}) => {

	return <div
		className={`asset-list-item ${selectedComponentID === asset?.id && 'selected'}`}
		onClick={() => onAssetClick(asset?.id)}
	>
		<div className='asset-icon-container'>{getAssetIcon(asset.assetType, { width: 20, height: 20 })}</div>
		<pre className='asset-name'>{asset?.name}</pre>
		{/*<IconCondition condition={asset?.overallStatus} />*/}
	</div>;
};

AssetListItem.propTypes = {
	asset: PropTypes.any,
	selectedComponentID: PropTypes.any,
	onAssetClick: PropTypes.func,
};

export default AssetListItem;
