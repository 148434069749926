import { instantReportTypes, unitTypesForReport } from '../../../Reports/constants';
import { generateReport } from '../../../Reports/ReportGenerator/actions';
import { ASSET_TYPES, oneYearInDays } from 'helpers/constants';
import { routes } from 'routes';
import { getAssetTypeId } from 'helpers/assetAndPowertrainFilterHelper';


export const navigateToReportingPage = (_, history, asset) => {
    // TODO: Used legacyAssetId as search params for reports search instead of assetID.
    history.push({ pathname: routes.Reports, search: new URLSearchParams({ assetID: asset.legacyAssetId, reportingPeriod: oneYearInDays }).toString(), state: asset });
};

export const generateInstantReport = (dispatch, history, asset) => {
    const reportType = getAssetTypeId(asset?.assetType) === ASSET_TYPES.MOTOR ? instantReportTypes[0] : instantReportTypes[1];
    dispatch(generateReport(reportType, asset, unitTypesForReport[0]));
    navigateToReportingPage(undefined, history, asset);
};
