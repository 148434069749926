import { takeLatest, call, put } from 'redux-saga/effects';
import { NotificationSettingsApi } from 'api';
import { apiCallStatus, NOTIFICATION_TYPES } from 'helpers/constants';
import logger from 'helpers/logger';
import { actionTypes } from './constants';
import {
    getNotificationSettingsAction,
    getMutedAssetListAction,
    copyNotificationSettingsAction
} from './actions';
import { getAssetMuteSettingsAction, updateAssetMuteSettingsAction } from './MutedAssets/actions';
import { translate } from 'helpers/translateHelper';
import { notify } from 'sharedComponents/GeneralNotification';


function* getNotificationSettingsSaga({ organizationID }) {
    yield put(getNotificationSettingsAction(apiCallStatus.LOADING));
    try {
        const response = yield call(NotificationSettingsApi.getNotificationSettings, organizationID);
        yield put(getNotificationSettingsAction(apiCallStatus.SUCCESS, response));
        logger.debug('Notification settings successfully loaded');
    } catch (err) {
        yield put(getNotificationSettingsAction(apiCallStatus.ERROR, [], err));
    }
}

export function* saveNotificationSettingsSaga({ organizationID, notificationSettings }) {
    yield put(getNotificationSettingsAction(apiCallStatus.LOADING));
    try {
        yield call(NotificationSettingsApi.saveNotificationSettings, organizationID, notificationSettings);
        const response = yield call(NotificationSettingsApi.getNotificationSettings, organizationID);
        yield put(getNotificationSettingsAction(apiCallStatus.SUCCESS, response));
        logger.debug('Notification settings successfully updated');
    } catch (err) {
        yield put(getNotificationSettingsAction(apiCallStatus.ERROR, [], err));
    }
}

export function* getMutedAssetListSaga({ organizationID }) {
    yield put(getMutedAssetListAction(apiCallStatus.LOADING));
    try {
        const response = yield call(NotificationSettingsApi.getMutedAssetList, [organizationID]);
        yield put(getMutedAssetListAction(apiCallStatus.SUCCESS, response));
        logger.debug('Muted asset list successfully loaded');
    } catch (err) {
        yield put(getMutedAssetListAction(apiCallStatus.ERROR, [], err));
    }
}

export function* updateMutedAssetSaga({ organizationID, assetId, notificationGroups, startDate, endDate, close }) {
    yield put(updateAssetMuteSettingsAction(apiCallStatus.LOADING));
    try {
        notificationGroups = notificationGroups.map(({ notificationGroupCode, muted }) => {
            return {
                notificationGroupCode,
                muted
            };
        });
        const response = yield call(
            NotificationSettingsApi.muteAsset,
            { organizationID, assetId, notificationGroups, startDate, endDate }
        );
        yield call(getMutedAssetListSaga, { organizationID });
        yield put(updateAssetMuteSettingsAction(apiCallStatus.SUCCESS, response));
        close();
    } catch (err) {
        yield put(updateAssetMuteSettingsAction(apiCallStatus.ERROR, [], err));
    }
}

export function* deleteMutedAssetsSaga({ organizationID, assetIDs }) {
    yield put(getMutedAssetListAction(apiCallStatus.LOADING));
    try {
        yield call(
            NotificationSettingsApi.deleteMutedAssets,
            assetIDs);
        const response = yield call(NotificationSettingsApi.getMutedAssetList, [organizationID]);
        yield put(getMutedAssetListAction(apiCallStatus.SUCCESS, response));
        logger.debug('Muted assets successfully deleted');
    } catch (err) {
        yield put(getMutedAssetListAction(apiCallStatus.ERROR, [], err));
    }
}

function* getMutedAssetSettingSaga({ assetID }) {
    yield put(getAssetMuteSettingsAction(apiCallStatus.LOADING));
    try {
        const response = yield call(NotificationSettingsApi.getAssetMuteSettings, [assetID]);
        yield put(getAssetMuteSettingsAction(apiCallStatus.SUCCESS, response));
    } catch (err) {
        yield put(getAssetMuteSettingsAction(apiCallStatus.ERROR, [], err));
    }
}

function* copyNotificationSettingsSaga(params) {
    yield put(copyNotificationSettingsAction(apiCallStatus.LOADING));
    try {
        const response = yield call(NotificationSettingsApi.copyNotificationSettings, params.payload);
        yield put(copyNotificationSettingsAction(apiCallStatus.SUCCESS, response));
        notify(translate('ABB.Powertrain.Frontend.CopyNotificationSuccessMessage'), NOTIFICATION_TYPES.SUCCESS);
    } catch (err) {
        yield put(copyNotificationSettingsAction(apiCallStatus.ERROR, [], err));
    }
}

export function* notificationSettingsWatchers() {
    yield takeLatest(actionTypes.LOAD_NOTIFICATION_SETTINGS_REQUEST, getNotificationSettingsSaga);
    yield takeLatest(actionTypes.SAVE_NOTIFICATION_SETTINGS_REQUEST, saveNotificationSettingsSaga);
    yield takeLatest(actionTypes.LOAD_MUTED_ASSETS_REQUEST, getMutedAssetListSaga);
    yield takeLatest(actionTypes.UPDATE_MUTED_ASSET_REQUEST, updateMutedAssetSaga);
    yield takeLatest(actionTypes.DELETE_MUTED_ASSETS_REQUEST, deleteMutedAssetsSaga);
    yield takeLatest(actionTypes.LOAD_ASSET_MUTE_SETTINGS_REQUEST, getMutedAssetSettingSaga);
    yield takeLatest(actionTypes.COPY_NOTIFICATION_SETTINGS_REQUEST, copyNotificationSettingsSaga);
}
