import map from 'lodash/map';
import forEach from 'lodash/forEach';
import isArray from 'lodash/isArray';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import { setColor } from '../../../../commonTrendHelpers';
import { MAX_TREND_SIGNALS } from '../../../../constants';
import { TREND_SELECT_HOURS_BEFORE_EVENT, TREND_SELECT_HOURS_AFTER_EVENT } from './constants';
import { getUTCDateString } from 'helpers/dateHelper';
import { getAssetDataSourceType } from 'helpers/assetAndPowertrainFilterHelper';


export const getTrendSignalDateInterval = (event) => {
    const fromDate = new Date(event.occurredOn);
    const hour = fromDate.getHours();
    fromDate.setHours(hour - TREND_SELECT_HOURS_BEFORE_EVENT);
    const toDate = new Date(event.occurredOn);
    toDate.setHours(hour + TREND_SELECT_HOURS_AFTER_EVENT);
    return {
        from: getUTCDateString(fromDate),
        to: getUTCDateString(toDate)
    };
};

export const createEmptyTrendDataObject = (measurements, componentObj) => {
    const measurementArray = isArray(measurements) ? measurements : [measurements];

    const trendArrays = map(measurementArray, (measurement) => {
        return {
            componentID: componentObj?.componentID,
            config: { ...measurement.config },
            dataSourceType: getAssetDataSourceType(componentObj),
            values: [],
        };
    });

    return trendArrays;
};

export const removeSelectedTrendKpiData = (selectedTrendDataArrays, unselectedKPI) => {
    const foundIndex = findIndex(selectedTrendDataArrays, (trendDataArray) => trendDataArray?.config?.timeseriesKey === unselectedKPI?.timeseriesKey);
    foundIndex > -1 && selectedTrendDataArrays.splice(foundIndex, 1);

    return selectedTrendDataArrays;
};

export const addSelectKpiData = (selectedTrendsData, newSelectedTrendKpiArray) => {
    if (!selectedTrendsData?.length && newSelectedTrendKpiArray?.length > 1) {
        selectedTrendsData = newSelectedTrendKpiArray;
        return setColor(selectedTrendsData);
    }

    const newSelectedTrend = newSelectedTrendKpiArray && newSelectedTrendKpiArray.length ? newSelectedTrendKpiArray[0] : [];
    if (!find(selectedTrendsData, (selectedTrend) => selectedTrend?.config?.timeseriesKey === newSelectedTrend?.config?.timeseriesKey)) {
        selectedTrendsData.push(newSelectedTrend);
        if (selectedTrendsData.length > MAX_TREND_SIGNALS) {
            selectedTrendsData.shift();
        }
    }
    selectedTrendsData = map(selectedTrendsData, (selectedTrend) => {
        const found = find(newSelectedTrendKpiArray, (newSelected) => newSelected?.config?.timeseriesKey === selectedTrend?.config?.timeseriesKey);
        if (found) {
            found.color = selectedTrend.color;
            return found;
        }
        else {
            return selectedTrend;
        }
    });
    selectedTrendsData?.length && setColor(selectedTrendsData);
    return selectedTrendsData;
};

export const getUniqueYScaling = (selectedTrends) => {
    let uniqueYScalingAvailable = false;

    if (selectedTrends.length > 1) {
        const unit = selectedTrends[0].config.unit || '';

        forEach(selectedTrends, (trendData) => {
            uniqueYScalingAvailable = trendData.config.unit === unit;
            return uniqueYScalingAvailable;
        });
    }

    return uniqueYScalingAvailable;
};

// Merge all type of trends list to a sigle array
export const getMergedTrendKpiList = (trendKPIs) => {
    const mergedTrendKpiList = [];
    trendKPIs?.forEach((kpi) => {
        const kpiObj = kpi?.data;
        const keys = Object.keys(kpiObj);
        keys.forEach(key => {
            const newObj = kpiObj[key];
            mergedTrendKpiList.push(newObj);
        });
    });
    return mergedTrendKpiList;
};

// Find signal index from selected KPI list for removing that signal.
export const getKPISignalIndexFromList = (selectedTrends, trendKPIsList, signalIndex) => {
    const getTrendKPI = getMergedTrendKpiList(trendKPIsList)[signalIndex];
    return findIndex(selectedTrends, (kpi) => kpi.config.timeseriesKey === getTrendKPI.timeseriesKey);
};
