import React from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippy.js/react';
import { Col, Row, Container } from 'react-bootstrap';
import moment from 'moment';

import { IconNotificationCondition, IconClose } from 'svgIcons/MotionPortalIcons';
import NotificationCount from './NotificationCountComponent';
import { GeneralButton } from 'sharedComponents/GeneralButton';
import { handleIconType, notificationGroupCodes } from './helpers';
import { isEmpty } from 'lodash';

const NotificationItemComponent = ({ onClose, item }) => {
    const handleNotificationDisplayText = () => {
        const notificationDisplayText = item?.notificationTypeName?.split('-');
        const notificationMessage = notificationDisplayText?.length > 1 ? <>{notificationDisplayText[0]?.replaceAll(' ', '')}: <b>{notificationDisplayText[1]}</b></> || '' : <b>{notificationDisplayText[0]}</b> || '';
        if (!isEmpty(item?.properties)) {
            switch (item?.notificationGroupCode) {
                case notificationGroupCodes.Event:
                    return <>{item?.properties?.eventSeverity || item?.notificationTypeName}{item?.properties?.eventMessage && <>: <b>{item?.properties?.eventMessage || ''}</b></>}</>;

                case notificationGroupCodes.Limits:
                    return item?.properties?.limitSeverity ? <>{item?.properties?.limitSeverity}{item?.properties?.timeseriesName && <>: <b>{item?.properties?.timeseriesName || ''}</b></>}</> : notificationMessage;

                case notificationGroupCodes.Condition:
                    return <>{notificationMessage} {item?.properties?.condition && item?.properties?.previousCondition && `(${item?.properties?.previousCondition}->${item?.properties?.condition})`}</>;

                // NEED TO HANDLE MAINTANENCE AND CONNECTION NOTIFICATION DISPLAY TEXT.
                // RIGHT WE DON'T HAVE DATA FOR IT. WILL ADDRESS IS LATER.
                case notificationGroupCodes.Maintenance:
                    return notificationMessage;
                case notificationGroupCodes.Connection:
                    return notificationMessage;
                default:
                    return notificationMessage;
            }
        } else {
            return notificationMessage;
        }
    };

    const renderTime = (time) => {
        const notificationLocalTime = moment.utc(time).local(); // CONVERTING UTC TO LOCAL TIME
        const duration = moment.duration(moment().diff(moment.utc(time).local()));
        const days = duration.asDays();
        const notificationLocalTimeDiff = notificationLocalTime?.fromNow();
        const notificationDateTime = moment.utc(time).local().format('DD/MM/YYYY'); // WILL CHANGE FORMAT IF NEEDED
        return days > 1 ? notificationDateTime : notificationLocalTimeDiff;
    };
    return (
        <Container className='notification-item mb-2'>
            <Row>
                <Col md={11}>
                    <Row onClick={() => { onClose(item, true); }}>
                        <Col md={1} className='d-flex flex-column py-1 px-2'>
                            <IconNotificationCondition width={15} height={15} condition={handleIconType(item?.notificationTypeCode, item?.properties?.conditionType)} /> {/* NEED TO CHECK CONDITION TYPE AND UPDATE NOTIFICATION ICON*/}
                            <NotificationCount
                                className='mt-auto'
                                count={item?.numberOfOccurances}
                                color='black'
                            />
                        </Col>
                        <Col md={11} className='px-2'>
                            <div className='text-truncate'>
                                <span className='notification-asset-type'>
                                    {item?.assetType}: {item?.assetName}
                                </span>
                            </div>
                            <div className='notification-description'>
                                <div className='notification-type text-wrap'>
                                    {/* {notificationDisplayText.length ? <>{notificationDisplayText[0]}: <b>{notificationDisplayText[1]}</b></> : item?.notificationTypeName} */}
                                    <Tippy
                                        content={handleNotificationDisplayText()}
                                        animation='scale'
                                        theme='light-border'
                                        hideOnClick={false}
                                        duration={100}
                                        placement='bottom-start'
                                    >
                                        <div>{handleNotificationDisplayText()}</div>
                                    </Tippy>
                                </div>
                            </div>
                            <div className='w-100 mt-1'>
                                <span className='notification-time-condition d-flex'>
                                    {renderTime(item?.timestamp)} - {item?.notificationGroupName} -
                                    <Tippy
                                        content={`${item?.organizationName || ''}/${item?.siteName || ''}`}
                                        animation='scale'
                                        theme='light-border'
                                        hideOnClick={false}
                                        duration={100}
                                        placement='bottom'>
                                        <div className='text-truncate w-100'>&nbsp;{item?.organizationName || ''}/{item?.siteName || ''}</div>
                                    </Tippy>
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col md={1} className='px-2'>
                    <GeneralButton
                        onClick={(e) => {
                            e.stopPropagation();
                            onClose(item, false);
                        }}
                        className='p-0'
                        icon={<IconClose width={15} height={15} />}
                    />
                </Col>
            </Row>
        </Container>
    );
};

NotificationItemComponent.propTypes = {
    onClose: PropTypes.func,
    item: PropTypes.object
};

export default NotificationItemComponent;
