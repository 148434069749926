import React, { memo, useEffect } from 'react';
import GatewayManagementComponent from './GatewayManagementComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { LoadingStatusWrapper } from 'sharedComponents/WithLoadingStatus';
import { getModel } from './selectors';
import * as actions from './actions';
import './style.scss';

const GatewayManagementContainer = memo((props) => {
    const {
        generalFilterLoadingStatuses,
    } = props.model;

    useEffect(() => {
        return () => {
            props.actions.clearStore();
        };
    }, []);

    return <LoadingStatusWrapper
        loadingStatus={[...generalFilterLoadingStatuses]}>
        < GatewayManagementComponent {...props} />;
    </LoadingStatusWrapper >;
});

const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
};

GatewayManagementContainer.displayName = 'GatewayManagementContainer';

const GatewayManagementContainerConn = connect(
    mapStateToProps,
    mapDispatchToProps
)(GatewayManagementContainer);

export default GatewayManagementContainerConn;
