import React, { useState } from 'react';
import Tippy from '@tippy.js/react';
import { GeneralButton } from 'sharedComponents/GeneralButton';
import { GeneralSelect } from 'sharedComponents/GeneralSelect';
import { ToggleButton } from 'sharedComponents/ToggleButton';
import { IconCondition, IconTrash, IconWarning } from 'svgIcons/MotionPortalIcons';
import { LEVEL, LIMIT_SYMBOL, LIMIT_VALUE_REGEX } from './constants';
import { translate } from 'helpers/translateHelper';
import { useDispatch } from 'react-redux';
import { deleteLimit, updateLimit } from '../actions';
import { Switch } from 'sharedComponents/SwitchComponent';
import colors from 'theme/_colors.scss';

export const options = [
    { label: 'Alarm', value: 'Alarm', prefixIcon: <IconCondition condition={3} />, prefixIconSelected: <IconCondition condition={3} /> },
    { label: 'Alert', value: 'Alert', prefixIcon: <IconCondition condition={2} />, prefixIconSelected: <IconCondition condition={2} /> }
];

const NotifyMeComponent = ({ item, handleUpdateLimit, translate }) => {
    return <div className='d-flex align-items-center'>
        <Switch className='me-2' checked={item?.notificationEnabled} onChange={(value) => { handleUpdateLimit(item?.uniqId, 'notificationEnabled', value); }} />{translate('ABB.Powertrain.Frontend.limitsNotifyMe')}
    </div>;
};

const Limit = ({ item, timeSeries }) => {
    const dispatch = useDispatch();
    const [focusedInput, setFocusedInput] = useState(null);

    const handleDeleteLimit = (item) => {
        dispatch(deleteLimit(item));
    };

    const handleUpdateLimit = (uniqId, field, value) => {
        dispatch(updateLimit(uniqId, field, value, timeSeries?.uniqId));
    };
    const handleFocusInput = (val) => {
        setFocusedInput(val);
    };
    const isDisabledLimit = false; // Enabling the default Limits to be Edited
    const toggleButtonOptions = isDisabledLimit ? options.filter((op) => item?.limitLevel === op.label) : options;
    const limitValueError = item?.limitValue === '' || item?.limitValue === null || item?.limitValue === undefined;
    return (
        <div className='limit-box'>
            <div className='limit-values-container'>
                {!item?.isUserLimit &&
                    <div className='d-flex align-items-center'>
                        <Tippy
                            content={item?.eventEnabled ?
                                translate('ABB.Powertrain.Frontend.eventEnabledLimitConfiguration') :
                                translate('ABB.Powertrain.Frontend.eventDisabledLimitConfiguration')}
                            animation='scale'
                            theme='light-border'
                            placement='top'
                            hideOnClick={false}
                            duration={100}
                        >
                            <div>
                                <Switch className='me-2' checked={item?.eventEnabled} onChange={(value) => { handleUpdateLimit(item?.uniqId, 'eventEnabled', value); }} />
                            </div>
                        </Tippy>
                    </div>}
                <div className='limit-left-values'>
                    <div className={`limit-level-container ${isDisabledLimit ? 'disabled' : ''}`}>
                        <ToggleButton
                            options={toggleButtonOptions}
                            selectedValue={isDisabledLimit ? '' : item?.limitLevel}
                            onSelected={(val) => handleUpdateLimit(item?.uniqId, 'limitLevel', val)}
                            buttonProps={{
                                disabled: isDisabledLimit
                            }}
                        />
                    </div>
                    <div className='limit-value-fields'>
                        <GeneralSelect
                            value={item?.limitDirection ? { label: item?.limitDirection === LEVEL.UpperLimit || item?.limitDirection === LEVEL.Upper ? LIMIT_SYMBOL.Greater : LIMIT_SYMBOL.Lower, value: item?.limitDirection } : { label: LIMIT_SYMBOL.Default, value: '', isDisabled: true }}
                            options={
                                [
                                    { label: '>', value: LEVEL.UpperLimit },
                                    { label: '<', value: LEVEL.LowerLimit }
                                ]
                            }
                            onChange={(e) => {
                                handleUpdateLimit(item?.uniqId, 'limitDirection', e.value);
                            }}
                            isDisabled={isDisabledLimit}
                            isSearchable={false}
                            showErrorIndicator={false}
                            error={!item?.limitDirection}
                        />
                        <div
                            className={`limit-value-uom ${isDisabledLimit ? 'disabled' : ''} ${timeSeries?.minValue && timeSeries?.maxValue && (item?.limitValue < timeSeries?.minValue || item?.limitValue > timeSeries?.maxValue) || item?.isSameLimit || limitValueError ? 'error' : ''} ${focusedInput === 'limitValue' ? 'highlight' : ''}`}>
                            <input
                                type='number'
                                placeholder={translate('ABB.Powertrain.Frontend.inputValue')}
                                value={item?.limitValue === 0 ? '0' : item?.limitValue || ''}
                                onFocus={() => { handleFocusInput('limitValue'); }}
                                onBlur={() => { handleFocusInput(null); }}
                                onChange={(e) => {
                                    const value = e.target.value;

                                    if (LIMIT_VALUE_REGEX.test(value)) {
                                        handleUpdateLimit(item?.uniqId, 'limitValue', value);
                                    }
                                }}
                                name='limitValue'
                                disabled={isDisabledLimit}
                            />
                            {item?.isSameLimit && <IconWarning color={colors.red} width={16} height={16} />}
                            {timeSeries?.unit && <div className='uom-text'>{timeSeries?.unit}</div>}
                        </div>
                        {/* COMMENTED THIS CODE FOR NOW */}
                        {/* <div className='ms-3 limit-label'>
                            // CHANGE WITH LIMIT LABEL FROM RESPONSE
                            {item?.isDefault ? <div>Default: nominal power</div> :
                                <div className={`limit-value-uom ${focusedInput === 'limitLabel' ? 'highlight' : ''}`}>
                                    <input
                                        type='text'
                                        placeholder={translate('ABB.Powertrain.Frontend.inputValue')}
                                        value={item?.limitLabel}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            handleUpdateLimit(item?.uniqId, 'limitLabel', value);
                                        }}
                                        name='limitLabel'
                                        onFocus={() => { handleFocusInput('limitLabel'); }}
                                        onBlur={() => { handleFocusInput(false); }}
                                    />
                                </div>}
                        </div> */}
                    </div>
                </div>
                <NotifyMeComponent item={item} handleUpdateLimit={handleUpdateLimit} translate={translate} />
                <div className='limit-right-values'>
                    {!item?.isDefault && !item?.isBasedOnDefault &&
                        <GeneralButton
                            className='limit-delete-button'
                            icon={<IconTrash width={15}
                                height={18}
                                style={{ marginRight: 0 }} />}
                            onClick={() => handleDeleteLimit(item)}>
                        </GeneralButton>
                    }
                </div>
            </div>
        </div>
    );
};

export default Limit;
