import { actionTypes } from './constants';
import { actionTypes as operationalParamActions } from '../../constants';
import { PlotApi } from 'api';
import { apiCallStatus } from 'helpers/constants';
import { getApiActionCreator } from 'helpers/actionHelper';
import { createParamMeasurementAssetObj } from '../../../../helpers';
import { getAssetTypeId } from 'helpers/assetAndPowertrainFilterHelper';

const histogramKpiAction = getApiActionCreator(actionTypes.GET_ASSET_HISTOGRAM_KPIS);
export const getHistogramKPIsForAsset = (assetObj, plotType) => {
    return (dispatch) => {
        dispatch(histogramKpiAction(apiCallStatus.LOADING));
        PlotApi.getPlotKPIList(assetObj, plotType)
            .then((data) => {
                dispatch(histogramKpiAction(apiCallStatus.SUCCESS, data));
            })
            .catch((err) => {
                dispatch(histogramKpiAction(apiCallStatus.ERROR, null, err));
            });
    };
};


const histogramDataAction = getApiActionCreator(actionTypes.GET_HISTOGRAM);
export const getHistogramForAsset = (from, to, assetObj, measurement) => {
    return (dispatch, getState) => {
        // Create parameter object for POST body content
        const param = createParamMeasurementAssetObj(measurement, assetObj);
        const originalAssetID = getState().powertrainAssetDetails?.selectedAsset?.componentID;

        dispatch(histogramDataAction(apiCallStatus.LOADING));

        PlotApi.getHistogram(param, from, to)
            .then((data) => {
                const assetID = getState().powertrainAssetDetails?.selectedAsset?.componentID;

                if (originalAssetID === assetID) {
                    dispatch(histogramDataAction(apiCallStatus.SUCCESS, data));
                }
            })
            .catch((err) => {
                dispatch(histogramDataAction(apiCallStatus.ERROR, null, err));
            });
    };
};

export const setSelectedDates = (from, to) => {
    return (dispatch) => {
        dispatch({ type: operationalParamActions.SET_DATE_SELECTION, dateSelection: { from, to } });
    };
};

export const setSelectedKpi = (histogramSelectedKpi) => {
    return (dispatch, getState) => {
        const asset = getState()?.powertrainAssetDetails?.selectedAsset;
        dispatch({
            type: actionTypes.SET_HISTOGRAM_KPI,
            histogramSelectedKpi: {
                ...histogramSelectedKpi,
                assetTypeID: getAssetTypeId(asset?.assetType)
            }
        });
    };
};

const removeSelectedKpiLegendAction = (signalIndex) => {
    return {
        type: actionTypes.REMOVE_TREND_KPI_SELECTION_LEGEND_HISTOGRAM,
        signalIndex
    };
};

// Action Creators
export const removeSelectedKpiLegend = (signalIndex) => {
    return (dispatch) => {
        dispatch(removeSelectedKpiLegendAction(signalIndex));
    };
};
