import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import { find, map } from 'lodash';
import clsx from 'clsx';
import { addTrendKpi, removeSelectedKpi } from '../../actions';
import SectionInfoButton from './SectionInfoButton';
import { Checkbox } from 'sharedComponents/Checkbox';
import { IconCondition } from 'svgIcons/MotionPortalIcons';
import { limitIndications } from 'helpers/constants';
import useUserCapability from 'sharedHooks/useUserCapability';
import { SearchBox } from 'sharedComponents/SearchBox';

const ParameterSection = (props) => {
    const { t: translate } = useTranslation();
    const userCapabilities = useUserCapability();
    const [searchboxFilter, setSearchboxFilter] = useState('');
    const {
        section,
        selectedTrendsData,
        asset
    } = props;
    const dispatch = useDispatch();

    if (!section || !section.data || section.data.length === 0) {
        return null;
    }
    const toggleMeasurement = (kpi, isSelected) => {
        if (isSelected) {
            dispatch(removeSelectedKpi(kpi));
        }
        else {
            dispatch(addTrendKpi(kpi, asset));
        }
    };
    const { data } = section;
    let kpiData = data;
    const serchBoxValue = searchboxFilter.toLowerCase();
    if (serchBoxValue !== '' && serchBoxValue !== null) {
        kpiData = data?.filter((kpi) => {
            return kpi?.label?.toLowerCase().includes(serchBoxValue);
        });
    }


    return <Col xl lg={12} className='measurement-list'>
        <div className='measurement-list-title'>
            <div className='measurment-list-buttons'>
                <div>{translate('ABB.Powertrain.Frontend.detailedInformationOperationalParametersTab')}</div>
                <SectionInfoButton section={section} />
            </div>
            <div className='signal-group-header-info'>
                <span>{selectedTrendsData.length}{' '}{translate('ABB.Powertrain.Frontend.selected')}</span>
                <div className='kpisignal__filter__search'>
                    <SearchBox
                        placeholder={translate('ABB.Powertrain.Frontend.searchSignal')}
                        onChange={(searchTerm) => setSearchboxFilter(searchTerm)}
                        value={searchboxFilter} />
                </div>
            </div>
        </div>
        <div className='measurement-list-head'>
            <div className='measurement-list-item'>
                <div className='data-command-checkbox'></div>
                <div className='data-label'>{translate('ABB.Powertrain.Frontend.operationalParametersNameLabel')}</div>
                <div className='data-value'>{translate('ABB.Powertrain.Frontend.opertaionalParametersLastMeasurementLabel')}</div>
            </div>
         </div>
        <div className='measurement-list-body'>
            {map(kpiData, (kpi) => {
                const isSelected = !!find(selectedTrendsData, (selectedTrendKPI) => kpi.timeseriesKey === selectedTrendKPI?.config?.timeseriesKey);
                return <div className={clsx('measurement-list-item', isSelected && 'selected-measurement-list-item')}
                    onClick={() => kpi?.isPlottableToTrendChart && toggleMeasurement(kpi, isSelected)}
                    key={kpi.timeseriesKey}>
                    <div className='data-command-checkbox'>
                        <Checkbox checked={isSelected}
                            disabled={!kpi?.isPlottableToTrendChart}
                        />
                    </div>
                    <div className='data-label'>
                        {
                            userCapabilities?.hasOperationalParametersLimitStatus() &&
                            <div className='condition-icon'>
                                {kpi?.limitStatus ?
                                    <IconCondition condition={limitIndications[kpi?.limitStatus]?.iconID} /> :
                                    null
                                }
                            </div>
                        }
                        <div>{kpi.label}</div>
                        {kpi?.unit && <div className='data-label__unit'>{kpi?.unit}</div>}
                    </div>
                    <div className='data-value'>{kpi.displayValue ?? '-'}</div>
                </div>;
            })}
        </div>
    </Col>;
};

ParameterSection.propTypes = {
    section: PropTypes.shape({
        data: PropTypes.array,
        sectionId: PropTypes.any
    }),
    capability: PropTypes.object,
    asset: PropTypes.object
};

export default ParameterSection;
