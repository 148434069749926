import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { components } from 'react-select';

const FilterSubscriptionCustomValue = ({ children, ...props }) => {
    const { t: translate } = useTranslation();
    const { selectProps, data } = props;
    const { value } = selectProps;

    if (value?.length > 1) {
        if (data === value[0]) {
            return (
                <components.MultiValueContainer {...props}>
                    <div className='overall-subscription'>
                        +{value?.length} {translate('ABB.Powertrain.Frontend.subscriptionsLabel')}
                    </div>
                </components.MultiValueContainer>
            );
        }
        return null;
    }

    return (
        <components.MultiValueContainer {...props}>
            {children}
        </components.MultiValueContainer>
    );
};

FilterSubscriptionCustomValue.propTypes = {
    selectProps: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.any)
    }),
    data: PropTypes.any,
    children: PropTypes.node
};

export default FilterSubscriptionCustomValue;
