import { createSelector } from 'reselect';
import { getMaintainSelectedLimits, getSelectedOrganization, getTrendDataSelector } from '../TrendChart/selectors';
import { getTrendKPIsList } from '../TrendKPIList/selectors';

const getAssetDetails = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails.selectedAsset;
};

const getSelectedTrendKPIs = ({ operationalParametersV2 }) => {
    return operationalParametersV2?.preselectedKPIs?.single?.trend;
};

const getSelectedLimits = ({ operationalParametersV2 }) => {
    return operationalParametersV2?.selectedLimits;
};

const getSelectedLimitsAction = ({ operationalParametersV2 }) => {
    return operationalParametersV2?.selectedLimitsAction;
};

const getAssetActiveTab = ({ detailedInformation }) => {
    return detailedInformation?.activeTab;
};

const getIsDefaultLimitsSelected = ({ operationalParametersV2 }) => {
    return operationalParametersV2?.isDefaultLimitsSelected;
};

export const getModel = createSelector([
    getAssetDetails,
    getSelectedTrendKPIs,
    getTrendKPIsList,
    getSelectedLimits,
    getSelectedLimitsAction,
    getAssetActiveTab,
    getTrendDataSelector,
    getIsDefaultLimitsSelected,
    getSelectedOrganization,
    getMaintainSelectedLimits
], (
    selectedAsset,
    selectedTrendKPIs,
    trendKPIsList,
    selectedLimits,
    selectedLimitsAction,
    assetActiveTab,
    selectedTrendsData,
    isDefaultLimitsSelected,
    selectedOrganization,
    maintainSelectedLimits
) => {
    return {
        selectedAsset,
        selectedTrendKPIs,
        trendKPIsList,
        selectedLimits,
        selectedLimitsAction,
        assetActiveTab,
        selectedTrendsData,
        isDefaultLimitsSelected,
        selectedOrganization,
        maintainSelectedLimits
    };
});
