import React from 'react';
//import IconCondition from 'svgIcons/MotionPortalIcons/IconCondition';

const PowerTrainListItemTitle = ({ systemName, /*overallStatus*/ }) => {
	return (
		<div className='powertrain-list-item'>
			<div className='powertrain-list-item-name'>{systemName}</div>
			{/*<IconCondition condition={overallStatus} />*/}
		</div>);
};

export default PowerTrainListItemTitle;
