import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'helpers/translateHelper';


import './style.scss';
import NotificationStatusBarContent from './NotificationStatusBarContent';
import { NOTIFICATION_BAR } from 'helpers/constants';

const NotificationStatusBar = ({ model: { assetSubscriptionInfo, subscription } }) => {
	const [isHide, setIshide] = useState(false);
	const [value, setValue] = useState(false);

	// REVERT TO THIS while adding the blue and yellow banner
	// useEffect(() => {
	// 	if (assetSubscriptionInfo) {
	// 		actions.setContractNotification(assetSubscriptionInfo);
	// 	} else {
	// 		actions.closeNotificationBar();
	// 		setIshide(isHide);

	// 	}
	// }, [assetSubscriptionInfo, isHide]);

	useEffect(() => {
		if (assetSubscriptionInfo === NOTIFICATION_BAR.FREE_FOR_RENEWAL || assetSubscriptionInfo === NOTIFICATION_BAR.FREE) {
			setIshide(true);
			setValue(false);
		} else {
			setIshide(false);
			setValue(true);
		}
	}, [assetSubscriptionInfo]);


	useEffect(() => {
		if (translate(subscription?.contractInfo?.message) === translate('ABB.Powertrain.Frontend.AssetIsUnmonitored')) {

			setValue(true);
			setIshide(false);
		}
	}, [subscription?.contractInfo]);


	const onClose = () => {
		// REVERT TO THIS while adding the blue and yellow banner
		// actions.closeNotificationBar(notification);
		// if (props.onClose) {
		// 	props.onClose(notification);
		// }
		setIshide(false);
		setValue(false);
	};

	return (
		assetSubscriptionInfo === NOTIFICATION_BAR.FREE_FOR_RENEWAL || assetSubscriptionInfo === NOTIFICATION_BAR.FREE || subscription?.contractInfo ? <NotificationStatusBarContent notification={assetSubscriptionInfo} onClose={onClose} isHide={isHide} contractInfo={subscription?.contractInfo} value={value} /> : null
	);

};

NotificationStatusBar.displayName = 'NotificationStatusBar';

NotificationStatusBar.propTypes = {
	contractInfo: PropTypes.shape({
		link: PropTypes.shape({
			description: PropTypes.string,
			url: PropTypes.string
		}),
		message: PropTypes.string,
		type: PropTypes.string,
	}),
	props: PropTypes.shape({
		onClose: PropTypes.func,
		closeNotification: PropTypes.func,
	})
};

export default NotificationStatusBar;

