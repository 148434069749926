import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';


const IconHelpCircle = ({ width = '24px', height = '24px', viewBox = '0 0 24 24' }) => {
    return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M13 17V19H11V17H13ZM12.201 5C13.2016 5.0281 14.1533 5.43886 14.86 6.14767C15.5668 6.85648 15.9748 7.80935 16 8.81C16.0023 9.50089 15.8115 10.1787 15.4493 10.767C15.0872 11.3554 14.5679 11.8309 13.95 12.14C13.7177 12.2576 13.4771 12.3579 13.23 12.44L13 12.53V15H11V12.33C11.0202 11.9032 11.1845 11.4959 11.466 11.1744C11.7475 10.853 12.1296 10.6364 12.55 10.56C12.71 10.5 12.88 10.44 13.05 10.36C13.3373 10.2162 13.5786 9.99472 13.7465 9.72077C13.9144 9.44682 14.0022 9.13131 14 8.81C13.9741 8.30501 13.749 7.83089 13.3741 7.49159C12.9992 7.1523 12.5051 6.97551 12 7C11.4949 6.97551 11.0008 7.1523 10.6259 7.49159C10.251 7.83089 10.0259 8.30501 10 8.81V9.38H8V8.81C8.02621 7.77467 8.4621 6.79202 9.21202 6.07772C9.96194 5.36343 10.9646 4.97583 12 5H12.201ZM12 1C5.925 1 1 5.925 1 12C1 18.075 5.925 23 12 23C18.075 23 23 18.075 23 12C23 9.08262 21.8411 6.28473 19.7782 4.22183C17.7153 2.15893 14.9174 1 12 1ZM12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 10.8181 3.23279 9.64778 3.68508 8.55585C4.13738 7.46392 4.80031 6.47177 5.63604 5.63604C6.47177 4.80031 7.46392 4.13738 8.55585 3.68508C9.64778 3.23279 10.8181 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.3869 21 12 21Z' fill={colors.darkBlack} />
    </svg>;
};

IconHelpCircle.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    viewBox: PropTypes.string
};

export default IconHelpCircle;
