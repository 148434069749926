import isPlainObject from 'lodash/isPlainObject';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
import { useTranslation } from 'react-i18next';

import { requestStatuses, responseStatuses, sensorFeatureKeys } from '../constants';

// eslint-disable-next-line no-unused-vars
const timeConvert = (minutes) => {

    if (minutes === null) {
        return '-';
    }

    const h = Math.floor(minutes / 60);
    const m = minutes % 60;
    let result = '';
    if (h !== 0) {
        const days = h / 24;
        if (days >= 1) {
            const hours = h - 24 * Math.floor(days);
            result = Math.floor(days) + 'd ' + hours + 'h ';
        }
        else {
            result = h + 'h ';
        }
    }
    if (m !== 0) {
        result += m + 'm';
    }

    return result;
};

const getWholeMinutes = (decimalMinutes) => {
    const { t: translate } = useTranslation();
    if (decimalMinutes === null) {
        return '-';
    }
    return Math.floor(decimalMinutes) + ' ' + translate('ABB.Powertrain.Frontend.sensorMinutesLabel');
};

const operatingModeTypes = {
    0: 'ABB.Powertrain.Frontend.StandardMode',
    1: 'ABB.Powertrain.Frontend.FlightMode',
    2: 'ABB.Powertrain.Frontend.ArmedMode',
    3: 'ABB.Powertrain.Frontend.DeepSleepMode'
};

const splitString = (string, size) => {
    if (typeof string === 'string') {
        const re = new RegExp('.{1,' + size + '}', 'g');
        return string.match(re);
    }
};

export const formatSensorFeatureValue = (feature, translate) => {
    switch (feature?.featureKeyName) {
        case sensorFeatureKeys.PowerSavingTimeZone: {
            const hours = feature?.featureValue / 3600;
            return 'UTC' + (hours < 0 ? '-' : '+') + hours.toString();
        }
        case sensorFeatureKeys.BatteryLevel:
            if (feature?.featureValue) {
                return feature?.featureValue + ' V';
            }
            else {
                return '-';
            }
        case sensorFeatureKeys.MeasuredCurrent:
            if (feature?.featureValue) {
                return feature?.featureValue + ' A';
            }
            else {
                return '-';
            }

        case sensorFeatureKeys.RunningStateCalibration:
            if (feature?.featureValue) {
                return feature?.featureValue + ' g RMS';
            }
            else {
                return '-';
            }

        case sensorFeatureKeys.ThreeAxisVibrationRawData:
        case sensorFeatureKeys.EnableSpeedSync:
            if (feature?.featureValue !== null) {
                return feature?.featureValue === 1 ? translate('ABB.Powertrain.Frontend.sensorFeatureEnabledLabel') :
                    translate('ABB.Powertrain.Frontend.sensorFeatureDisabledLabel');
            }
            return '-';
        case sensorFeatureKeys.SensorMeasurementInterval:
            return getWholeMinutes(feature?.featureValue);
        case sensorFeatureKeys.AccelerometerRangeConfiguration:
            if (feature?.featureValue === 0) {
                return translate('ABB.Powertrain.Frontend.sensorFeatureAutoLabel');
            }
            if (feature?.featureValue) {
                return feature?.featureValue + ' g';
            }
            else {
                return '-';
            }
        case sensorFeatureKeys.JoinKey:
            return splitString(feature?.featureValue, 8).join('-');
        case sensorFeatureKeys.SensorRSSIValue:
            return feature?.featureValue && feature?.featureValue?.rssiValue ? feature?.featureValue?.rssiValue + ' dBm' : '-';
        default:
            return !isPlainObject(feature?.featureValue) && feature?.featureValue || '-';
    }
};

export const getOperatingModeTypeLabel = (operatingModeTypeID) => {
    return operatingModeTypes[Number(operatingModeTypeID)] + 'Label';
};


export const getRequestStatusText = (featureValue, translate) => {
    switch (featureValue) {
        case requestStatuses.NOT_REQUESTED_INITIAL: return translate('ABB.Powertrain.Frontend.notRequestedLabel');
        case requestStatuses.NOT_REQUESTED: return translate('ABB.Powertrain.Frontend.notRequestedLabel');
        case requestStatuses.PENDING: return translate('ABB.Powertrain.Frontend.pendingLabel');
        case requestStatuses.COMPLETED: return translate('ABB.Powertrain.Frontend.completedLabel');
        case requestStatuses.ERROR: return translate('ABB.Powertrain.Frontend.errorLabel');
        default: return null;
    }
};

export const getResponseStatusText = (featureValue, translate) => {
    switch (featureValue) {
        case responseStatuses.COMPLETED_SUCCESSFULLY: return translate('ABB.Powertrain.Frontend.lbl_sf_completed_successfully');
        case responseStatuses.COULD_NOT_CONNECT_TO_THE_SENSOR: return translate('ABB.Powertrain.Frontend.lbl_sf_could_not_connect');
        case responseStatuses.INTERNAL_ERROR: return translate('ABB.Powertrain.Frontend.lbl_sf_internal_error');
        case responseStatuses.NO_MEASUREMENT_AVAILABLE: return translate('ABB.Powertrain.Frontend.lbl_sf_no_measurements');
        case responseStatuses.SENSOR_NOT_FOUND: return translate('ABB.Powertrain.Frontend.lbl_sf_sensor_not_found');
        case responseStatuses.WRONG_PASSKEY: return translate('ABB.Powertrain.Frontend.lbl_sf_wrong_passkey');
        default: return null;
    }
};

export const getVisibleFeatures = (sensorFeatures) => {
    const sortedSensorFeatures = sortBy(sensorFeatures, el => el.sequenceNo);
    return filter(sortedSensorFeatures, el => el.isVisible);
};

export const getColumns = (translate) => {
    return [
        {
            title: translate('ABB.Powertrain.Frontend.MsrDetailsType'),
            dataIndex: 'SensorName',
            key: 'SensorName',
            isSorting: true,
            width: 150,
        },
        {
            title: translate('ABB.Powertrain.Frontend.MsrDetailsPosition'),
            dataIndex: 'SensorPosition',
            key: 'SensorPosition',
            width: 250,
            isSorting: true
        },
        {
            title: translate('ABB.Powertrain.Frontend.MsrDetailsChannel'),
            dataIndex: 'ChannelId',
            key: 'ChannelId',
            width: 150,
            isSorting: true
        },
    ];
};


