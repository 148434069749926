import endpoints, { formatEndpoint } from '../endpoints';
import { RestManager, RestManagerWithoutBaseUrl } from '../RestManager';
import { handleFFTDataConversion, handleFFTV2DataConversion } from './assetDetailsUoMHelper';


class AssetDetails {
    getAssetPictures(assetID) {
        const endpoint = formatEndpoint(endpoints.assetDetails.pictures.getAssetNameplatePictures, { assetID });
        return RestManager.get(endpoint);
    }

    getAssetPicturesV2(assetID) {
        const endpoint = formatEndpoint(endpoints.assetDetails.pictures.getAssetNameplatePicturesV2, { assetID });
        return RestManagerWithoutBaseUrl.get(endpoint);
    }

    generateUploadURL(assetID, fileName) {
        const endpoint = formatEndpoint(endpoints.assetDetails.pictures.generateUploadURL, { assetID });
        return RestManagerWithoutBaseUrl.post(endpoint, { fileName });
    }

    uploadAssetPicture(assetID, pictureId, fileName, uploadUrl, pictureType) {
        const endpoint = formatEndpoint(endpoints.assetDetails.pictures.uploadAssetPicture, { assetID });
        return RestManagerWithoutBaseUrl.put(endpoint, { pictureId, fileName, uploadUrl, pictureType });
    }

    uploadToSignedUrl(url, data, headers) {
        return fetch(url, {
            method: 'PUT',
            headers: headers,
            body: data
        });
    }

    getAssetInfo(assetID) {
        const endpoint = formatEndpoint(endpoints.assetDetails.getAssetInfo, { assetID });
        return RestManager.get(endpoint);
    }

    //  SENSOR PROPERTIES START
    getSensorProperties(id) {
        const endpoint = formatEndpoint(endpoints.sensor.getSensorProperties, { id });
        return RestManagerWithoutBaseUrl.get(endpoint);

    }

    getSensorGatewayInfo(sensorId) {
        const endpoint = formatEndpoint(endpoints.sensor.getSensorGatewayInfo, { sensorId });
        return RestManagerWithoutBaseUrl.get(endpoint);
    }

    getSensorFeatures(firmwareID, sensorIdentifier, sensorTypeID) {
        const endpoint = formatEndpoint(endpoints.assetDetails.sensorDetails.getSensorFeatures, { firmwareID, sensorIdentifier, sensorTypeID });
        return RestManagerWithoutBaseUrl.get(endpoint);
    }

    getGatewayInfo(assetID) {
        const endpoint = formatEndpoint(endpoints.assetDetails.sensorDetails.getGatewayInfo, { assetID });
        return RestManager.get(endpoint);
    }
    //  SENSOR PROPERTIES END

    getFFTData(assetID, dataSource) {
        const endpoint = formatEndpoint(endpoints.assetDetails.fftData.getFFTData, { assetID, dataSource });
        return RestManager.get(endpoint).then(response => handleFFTDataConversion(response));
    }

    // Microservice endpoint
    getFFTV2Data(assetID) {
        const endpoint = formatEndpoint(endpoints.assetDetails.fftV2Data.getFFTV2Data, { assetID });
        return RestManagerWithoutBaseUrl.get(endpoint).then(response => handleFFTV2DataConversion(response));
    }

    downloadRawData(assetID, assetName, dataSource) {
        const endpoint = formatEndpoint(endpoints.assetDetails.fftData.downloadRawData, { assetID, assetName, dataSource });
        return RestManager.get(endpoint);
    }

    downloadRawDataV2(assetID) {
        const endpoint = formatEndpoint(endpoints.assetDetails.fftV2Data.downloadRawDataV2, { assetID });
        return RestManagerWithoutBaseUrl.get(endpoint);
    }

    deleteAssetImage(assetID, pictureId) {
        const endpoint = formatEndpoint(endpoints.assetDetails.pictures.deleteAssetPicture, { assetID, pictureId });
        return RestManagerWithoutBaseUrl.delete(endpoint);
    }

    getAssetOverview(assetID) {
        const endpoint = formatEndpoint(endpoints.assetDetails.getAssetOverview, { assetID });
        return RestManagerWithoutBaseUrl.get(endpoint);
    }
}

export default new AssetDetails();
