import React from 'react';
import { useTranslation } from 'react-i18next';

const GatewayManagementEmptyComponent = () => {
    const { t: translate } = useTranslation();

    return (
        <div className='empty-gateway'>
            <p className='empty-gateway__title'>{translate('ABB.Powertrain.Frontend.noGatewayToShowMessage')}</p>
            <p className='empty-gateway__description'>{translate('ABB.Powertrain.Frontend.selectGatewayMessage')}</p>
        </div>
    );
};

export default GatewayManagementEmptyComponent;
