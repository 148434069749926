import { apiCallStatus } from 'helpers/constants';
import { sensorActionTypes } from './constants';
import { getApiActionCreator } from 'helpers/actionHelper';
import { AssetDetailsApi } from 'api';

const getSensorFeaturesAction = getApiActionCreator(sensorActionTypes.GET_SENSOR_FEATURES);
const getSensorPropertiesAction = getApiActionCreator(sensorActionTypes.GET_SENSOR_PROPERTIES);
const getSensorGatewayInfoAction = getApiActionCreator(sensorActionTypes.GET_SENSOR_GATEWAYINFO);
const getGatewayInfoAction = getApiActionCreator(sensorActionTypes.GET_GATEWAY_INFO);

export const getGatewayInfo = (assetID) => {
    return (dispatch) => {
        dispatch(getGatewayInfoAction(apiCallStatus.LOADING));
        AssetDetailsApi.getGatewayInfo(assetID)
            .then((data) => {
                dispatch(getGatewayInfoAction(apiCallStatus.SUCCESS, data));
            },
                (err) => {
                    dispatch(getGatewayInfoAction(apiCallStatus.ERROR, null, err));
                });
    };
};

export const getSensorProperties = (sensorId) => {
    if (sensorId) {
        return (dispatch) => {
            dispatch(getSensorPropertiesAction(apiCallStatus.LOADING));
            AssetDetailsApi.getSensorProperties(sensorId)
                .then((data) => {
                    dispatch(getSensorPropertiesAction(apiCallStatus.SUCCESS, data));
                    dispatch(getSensorFeaturesAction(apiCallStatus.SUCCESS, data.sensorFeatures));
                },
                    (err) => {
                        dispatch(getSensorPropertiesAction(apiCallStatus.ERROR, null, err));
                    });
        };
    }
};

export const getSensorGatewayInfo = (sensorId) => {
    if (sensorId) {
        return (dispatch) => {
            dispatch(getSensorPropertiesAction(apiCallStatus.LOADING));
            AssetDetailsApi.getSensorGatewayInfo(sensorId)
                .then((data) => {
                    dispatch(getSensorGatewayInfoAction(apiCallStatus.SUCCESS, data));
                },
                    (err) => {
                        dispatch(getSensorGatewayInfoAction(apiCallStatus.ERROR, null, err));
                    });
        };
    }
};

