import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippy.js/react';
import { useTranslation } from 'react-i18next';
import {
    IconBroadcast,
    IconAssetOffline,
    IconAssetOnline,
} from 'svgIcons/MotionPortalIcons';
import { LoadingIndicator } from 'sharedComponents';
import { apiCallStatus } from 'helpers/constants';
import { assetOnlineStatus } from './constants';
import { getTimeSinceDateString } from 'helpers/dateHelper';

const GatewayListManagementComponent = ({ gatewayDetails, onSelectedGatewayData }) => {
    const { t: translate } = useTranslation();
    const { gatewayList = [], loadingStatus } = gatewayDetails || {};
    const [activeIndex, setActiveIndex] = useState(null);

    const handleSelectedGateway = (id, status, timeStamp) => {
        onSelectedGatewayData({ id, status, timeStamp });
        setActiveIndex(id === activeIndex ? null : id);
    };

    if (loadingStatus === apiCallStatus.ERROR) {
        // Handle failure state (optional)
        return <div>{translate('ABB.Powertrain.Frontend.errorGatewayList')}</div>;
    }

    return (
        <div className='gateway-list-management'>
            {loadingStatus === apiCallStatus.SUCCESS ?
                <ul className='gateway-list'>
                    {gatewayList.length === 0 ?
                        <li className='gateway-list__empty-label'>
                            {translate('ABB.Powertrain.Frontend.emptyGatewayResultsMessage')}
                        </li>
                        : gatewayList.length === 0 ?
                            <li className='gateway-list__empty-label'>
                                {translate('ABB.Powertrain.Frontend.emptyGatewaySearchResultMessage')}
                            </li>
                            :
                            gatewayList.map((gatewayItem) =>
                                <li
                                    key={gatewayItem?.gatewayIdentifier}
                                    className={`gateway-list__item ${activeIndex === gatewayItem?.gatewayIdentifier ? 'gateway-list__item--active' : ''}`}
                                >
                                    <div
                                        className='content-left'
                                        onClick={() => handleSelectedGateway(
                                            gatewayItem?.gatewayIdentifier,
                                            gatewayItem?.connectivityStatus,
                                            gatewayItem.statusChangeTimestamp
                                        )}
                                    >
                                        <IconBroadcast />
                                        <div className='gateway-details'>
                                            <span className='gateway-details__name'>
                                                {gatewayItem?.gatewayName || ''}
                                            </span>
                                            <span className='gateway-details__serialno'>
                                                {gatewayItem?.gatewaySerialNumber || ''}
                                            </span>
                                        </div>
                                    </div>
                                    <Tippy
                                        content={
                                            <div>
                                                <div className='asset-overview__online-text'>
                                                    {translate(
                                                        'ABB.Powertrain.Frontend.gatewayStatusLabel'
                                                    )}{' '}
                                                    {gatewayItem?.connectivityStatus ===
                                                        assetOnlineStatus.ONLINE
                                                        ? translate(
                                                            'ABB.Powertrain.Frontend.gatewayStatusOnlineLabel'
                                                        )
                                                        : translate(
                                                            'ABB.Powertrain.Frontend.gatewayStatusOfflinLabel'
                                                        )}
                                                </div>
                                                <div className='asset-overview__last-measurement'>
                                                    {translate('ABB.Powertrain.Frontend.labelLastStatusChange')}{' '}
                                                    {getTimeSinceDateString(
                                                        gatewayItem.statusChangeTimestamp
                                                    )}
                                                </div>
                                            </div>
                                        }
                                        interactive
                                        interactiveBorder={10}
                                        animation='scale'
                                        theme='light-border'
                                        trigger='click'
                                        placement='right'
                                    >
                                        <div className='info-icon'>
                                            {gatewayItem?.connectivityStatus === assetOnlineStatus.ONLINE ?
                                                <IconAssetOnline />
                                                :
                                                <IconAssetOffline />
                                            }
                                        </div>
                                    </Tippy>
                                </li>
                            )
                    }
                </ul>
                : <LoadingIndicator />
            }
        </div>
    );
};

GatewayListManagementComponent.propTypes = {
    gatewayDetails: PropTypes.shape({
        gatewayList: PropTypes.arrayOf(
            PropTypes.shape({
                gatewayIdentifier: PropTypes.number,
                gatewayName: PropTypes.string,
                gatewaySerialNumber: PropTypes.string,
                connectivityStatus: PropTypes.string,
                statusChangeTimestamp: PropTypes.string,
            })
        ),
        loadingStatus: PropTypes.string,
    }),
    searchQueries: PropTypes.string,
};

export default GatewayListManagementComponent;
