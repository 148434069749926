import { takeLatest, call, put, all } from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import {
    OrganizationManagementApi,
    HeaderApi
} from 'api';
import { apiCallStatus } from 'helpers/constants';
import logger from 'helpers/logger';
import { translate } from 'helpers/translateHelper';
import { isAssetInCurrentContext } from 'helpers/assetAndPowertrainFilterHelper';
import { notify } from 'sharedComponents/GeneralNotification';
import { NOTIFICATION_TYPES } from 'sharedComponents/GeneralNotification/constants';
import { actionTypes } from '../constants';
import {
    reloadContextAction,
    getSitesAction,
    getAssetGroupsAction,
    setSelectedFilters
} from '../actions';
import {
    formatSelectedFilters,
} from './helpers';
import {
    formatSiteAssetGroupRequest,
    getDefaultFilters,
    formatAssetsRequest
} from '../helpers';


export function* reloadContextSaga({ searchValue, assets, callback, clearQueryParameter, defaultOrganizations, history }) { // GETTING HISTORY FOR NAVIGATION FROM LEGACY ID TO MICRO-SERVICE ID ON ASSET SEARCH FROM GENERAL FILTER
    let organization = null;
    yield put(reloadContextAction(apiCallStatus.LOADING));
    try {
        organization = yield call(OrganizationManagementApi.getOrganizationByAsset, searchValue);

        if (!!organization && isAssetInCurrentContext(searchValue, assets)) {
            callback && callback();
            yield put(reloadContextAction(apiCallStatus.SUCCESS));
            return;
        }

        if (organization) {
            logger.debug(`Organization found for filter value: ${searchValue}`);
            organization.organizationID = organization.organizationId;
            const assetList = yield call(HeaderApi.getAvailableAssets, formatAssetsRequest([organization]));
            const [sites, assetGroups] = yield all([
                call(HeaderApi.getSites, formatSiteAssetGroupRequest([organization])),
                call(HeaderApi.getAssetGroups, formatSiteAssetGroupRequest([organization]))
            ]);
            yield all([
                put(getSitesAction(apiCallStatus.SUCCESS, sites)),
                put(getAssetGroupsAction(apiCallStatus.SUCCESS, assetGroups)),
                put(setSelectedFilters(formatSelectedFilters(organization))),
            ]);
            const searchedAsset = assetList.find((asset) =>
                asset?.ciamSerialNumber?.toString()?.toLowerCase() === searchValue?.toString()?.toLowerCase());
            yield put(reloadContextAction(apiCallStatus.SUCCESS));
            callback && callback();
            // NAVIGATING TO ASSET DETAILS USING MICRO-SERVICE ID
            if (searchedAsset && !isEmpty(searchedAsset) && searchedAsset?.id) {
                history.push({
                    pathname: history?.location?.pathname,
                    search: new URLSearchParams({ assetId: searchedAsset?.id }).toString()
                });
            }
        } else if (defaultOrganizations && defaultOrganizations?.length) {
            logger.debug(`Organization NOT found for filter value: ${searchValue}, will use default organization`);
            notify(translate('ABB.Powertrain.Frontend.noAssetFound'), NOTIFICATION_TYPES.ERROR, true);
            clearQueryParameter();

            const [sites, assetGroups] = yield all([
                call(HeaderApi.getSites, formatSiteAssetGroupRequest(defaultOrganizations)),
                call(HeaderApi.getAssetGroups, formatSiteAssetGroupRequest(defaultOrganizations))
            ]);
            yield all([
                put(getSitesAction(apiCallStatus.SUCCESS, sites)),
                put(getAssetGroupsAction(apiCallStatus.SUCCESS, assetGroups)),
                put(setSelectedFilters({
                    ...getDefaultFilters(),
                    organizations: defaultOrganizations,
                }))
            ]);
            yield put(reloadContextAction(apiCallStatus.SUCCESS));
        } else {
            logger.debug(`Organization not found for filter value: ${searchValue}`);
            notify(translate('ABB.Powertrain.Frontend.noAssetFound'), NOTIFICATION_TYPES.ERROR, true);
            clearQueryParameter();
            yield put(reloadContextAction(apiCallStatus.SUCCESS));
        }
    } catch (err) {
        logger.debug(err);
        yield put(reloadContextAction(apiCallStatus.SUCCESS));
    }
}

export function* reloadContextWatchers() {
    yield takeLatest(actionTypes.ASSET_SEARCH_REQUEST, reloadContextSaga);
}
