import React, { useState, Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Tippy from '@tippy.js/react';
import { LoadingIndicator, RouterTabs } from 'sharedComponents';
import { apiCallStatus } from 'helpers/constants';
import { getTimeSinceDateString } from 'helpers/dateHelper';
import { getTabOptions } from './helpers';
import { GatewayInfo } from './GatewayInfo';
import { ConnectedAssets } from './ConnectedAssets';
import { assetOnlineStatus, gatewayRouteURL } from './constants';
import {
    IconBroadcast,
    IconAssetOffline,
    IconAssetOnline,
} from 'svgIcons/MotionPortalIcons';

const history = createBrowserHistory();

const GatewayDetailsComponent = ({ model, actions, ...props }) => {
    const { t: translate } = useTranslation();
    const [tabOptions, setTabOptions] = useState(getTabOptions(translate));
    const { gatewayInformation, gatewayConnectedAssets } = model;
    const { getGatewayInfo, getGatewayConnectedAssets } = actions || {};
    const { gatewayID, gatewayStatus, statusChangeTimestamp } = props;

    useEffect(() => {
        if (gatewayID) {
            getGatewayInfo(gatewayID);
            getGatewayConnectedAssets(gatewayID);
        }
    }, [gatewayID, getGatewayInfo, getGatewayConnectedAssets]);

    useEffect(() => {
        setTabOptions(getTabOptions(translate));
    }, [translate]);

    const getBasePath = () => {
        const match = location?.pathname?.match(new RegExp(`^(\\/[^/]+)?\\/${gatewayRouteURL}\\/`));
        return match?.[1] || '';
    };

    const basePath = getBasePath();

    const handleTabChange = (route) => {
        if (route === tabOptions[0].route) {
            getGatewayInfo(gatewayID);
        } else {
            getGatewayConnectedAssets(gatewayID);
        }
    };

    if (gatewayInformation?.loadingStatus === apiCallStatus.LOADING) {
        return <LoadingIndicator />;
    }

    if (gatewayInformation?.loadingStatus === apiCallStatus.ERROR) {
        return <div>{translate('ABB.Powertrain.Frontend.errorGatewayInfo')}</div>;
    }

    return gatewayInformation?.loadingStatus === apiCallStatus.SUCCESS &&
        <>
            <div className='right-pane__header'>
                <div className='header__left-content'>
                    <IconBroadcast width={32} height={32} />
                    <span className='title'>{gatewayInformation?.gatewayInfo?.gatewayName || '-'}</span>
                </div>
                <div className='header__right-content'>
                    <button className='connect-cloud-btn'>
                        <Tippy
                            content={
                                <div>
                                    <div className='asset-overview__online-text'>
                                        {translate('ABB.Powertrain.Frontend.gatewayStatusLabel')} {' '}
                                        {gatewayStatus === assetOnlineStatus.ONLINE
                                            ? translate('ABB.Powertrain.Frontend.gatewayStatusOnline')
                                            : translate('ABB.Powertrain.Frontend.assetOverviewAssetOfflineLabel')}
                                    </div>
                                    <div className='asset-overview__last-measurement'>
                                        {translate('ABB.Powertrain.Frontend.labelLastStatusChange')} {' '}
                                        {getTimeSinceDateString(statusChangeTimestamp)}
                                    </div>
                                </div>
                            }
                            interactive
                            interactiveBorder={10}
                            animation='scale'
                            theme='light-border'
                            trigger='click'
                            placement='right'
                        >
                            <div className='info-icon'>
                                {gatewayStatus === assetOnlineStatus.ONLINE ?
                                    <IconAssetOnline />
                                    :
                                    <IconAssetOffline />
                                }
                            </div>
                        </Tippy>
                    </button>
                    <span className='connected-cloud'>
                        {gatewayStatus === assetOnlineStatus.ONLINE
                            ? translate('ABB.Powertrain.Frontend.gatewayDetailsConnectedCloud')
                            : translate('ABB.Powertrain.Frontend.gatewayDetailsDisconnectedCloud')}
                    </span>
                </div>
            </div>
            <div className='gateway-content-details'>
                <Router history={history}>
                    <RouterTabs
                        tabs={tabOptions}
                        onChange={handleTabChange}
                        defaultTab={tabOptions[0].route}
                        getBasePath={basePath}
                    >
                        <Suspense fallback={<LoadingIndicator />}>
                            <Switch>
                                <Route exact path={`${basePath}${tabOptions[0].route}`}>
                                    <ConnectedAssets gatewayConnectedAssetsData={gatewayConnectedAssets} />
                                </Route>
                                <Route exact path={`${basePath}${tabOptions[1].route}`}>
                                    <GatewayInfo gatewayInformation={gatewayInformation} />
                                </Route>
                                <Redirect from='*' to={`${basePath}${tabOptions[0].route}`} />
                            </Switch>
                        </Suspense>
                    </RouterTabs>
                </Router>
            </div>
        </>;
};

export default GatewayDetailsComponent;
