import endpoints, { formatEndpoint } from '../endpoints';
import { RestManagerWithoutBaseUrl } from '../RestManager';

class DataLoggerCommandsApi {
    getDataLoggerCommands(assetId) {
        const endpoint = formatEndpoint(endpoints.dataLoggerCommands.getDataLoggerCommandsList, { assetId });
        return RestManagerWithoutBaseUrl.get(endpoint);
    }

    triggerDataLoggerCommands(assetId, loggerType, commandId) {
        const endpoint = formatEndpoint(endpoints.dataLoggerCommands.triggerDataLoggerCommands, { assetId, loggerType });
        return RestManagerWithoutBaseUrl.post(endpoint, { commandId });
    }

    subscribeUnsubscribeDataLoggerCommands(assetId, loggerType, commandId) {
        const endpoint = formatEndpoint(endpoints.dataLoggerCommands.subscribeUnsubscribeDataLoggerCommands, { assetId, loggerType });
        return RestManagerWithoutBaseUrl.post(endpoint, { commandId });
    }
}

export default new DataLoggerCommandsApi();
