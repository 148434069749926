import React from 'react';
import PropTypes from 'prop-types';
import { MAX_NOTIFICATION } from './constants';

const NotificationCountComponent = ({ count = 1, className, color = 'primary', mainCount = false }) => {
    const renderCount = () => {
        if (count >= MAX_NOTIFICATION) { return `${MAX_NOTIFICATION}+`; }
        if (count > 1) { return `x ${count}`; }
    };
    const renderMainCount = () => {
        if (count >= MAX_NOTIFICATION) { return `${MAX_NOTIFICATION}+`; }
        if (count >= 1) { return `x ${count}`; }
    };
    return mainCount && count >= 1 ? <div className={`notification-badge ${className ? className : ''} ${color}-badge`}>{renderMainCount()}</div> :
        count > 1 && <div className={`notification-badge ${className ? className : ''} ${color}-badge`}>{renderCount()}</div>;
};

NotificationCountComponent.propTypes = {
    count: PropTypes.number,
    color: PropTypes.string,
    className: PropTypes.string,
    mainCount: PropTypes.bool
};

export default NotificationCountComponent;
