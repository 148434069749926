import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContextMenu } from 'sharedComponents';
import { Container, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router';
import useHeaderMenus from './useHeaderMenus';
import { getAssetIcon } from 'helpers/icons';
import { actionTypes, assetMenuItems } from './constants';
import { actionTypes as dataLoggerActionTypes } from './DataLogger/constants';
import { gatewayTypes, NotificationTypeCode } from 'helpers/constants';
import isEmpty from 'lodash/isEmpty';

import './style.scss';

const HeaderComponent = (props) => {
    const { asset } = props;
    const assetId = asset?.id;
    const dataLoggerCommands = useSelector((state) => state?.dataLoggerCommands?.loggerCommandsList);

    const dispatch = useDispatch();
    const history = useHistory();
    const [headerMenus, selectedHeaderMenu] = useHeaderMenus(asset?.componentType?.typeName, asset, dataLoggerCommands);

    useEffect(() => {
        if (asset?.gatewayType === gatewayTypes.NETA21 || asset?.gatewayType === gatewayTypes.EGW02) {
            dispatch({ type: dataLoggerActionTypes.GET_DATA_LOGGER_COMMANDS_REQUEST, assetId });
        }
        return () => dispatch({ type: actionTypes.CLEAR_STORE });
    }, [asset?.id]);

    useEffect(() => {
        selectedHeaderMenu?.menuAction && selectedHeaderMenu.menuAction(dispatch, history, asset);
    }, [selectedHeaderMenu]);

    const toggleSelection = (selectedMenuItemId) => {
        dispatch({ type: actionTypes.SELECT_MENU_ITEM, selectedMenuItem: selectedMenuItemId });
        dispatch({ type: actionTypes.CLOSE_DIALOGUE, data: { reName: null } });
    };

    // OPEN CHANGED_PARAMETER_LIST_V2 MODAL WHEN NOTIFICATION IS CLICKED
    useEffect(() => {
        const selectedNotification = history?.location?.state?.notificationData;
        if (selectedNotification && selectedNotification?.notificationTypeCode === NotificationTypeCode.AssetNameplatePropertyChanged) {
            if (headerMenus?.find((hm) => hm?.id === assetMenuItems.CHANGED_PARAMETER_LIST_V2)?.disabled) {
                toggleSelection(assetMenuItems.CHANGED_PARAMETER_LIST_V2);
            }
        }
    }, [history?.location?.state]);

    return <>
        <Container className='detailed-information-header' fluid>
            <Row className='asset-information'>
                <Col md='auto'>{getAssetIcon(asset?.assetType, { width: '24', height: '24' })}</Col>
                <Col className='asset-name'>{asset?.name}</Col>
                <Col md='auto'>
                    {
                        //asset?.isUserAccessible &&
                        !isEmpty(headerMenus) &&
                        <ContextMenu id='headingContextMenu'
                            options={headerMenus}
                            onSelect={toggleSelection} />
                    }
                </Col>
            </Row>
        </Container>
        {
            selectedHeaderMenu?.modalContentComponent &&
            <selectedHeaderMenu.modalContentComponent
                show={!!selectedHeaderMenu}
                close={() => toggleSelection(null)}
                title={selectedHeaderMenu.title}
                asset={asset}
            />
        }
    </>;
};

export default HeaderComponent;
