import React, { useEffect, useState } from 'react';
import { forEach, transform, isEmpty, findIndex } from 'lodash';
import isNumber from 'lodash/isNumber';
import { translate, getTranslatedKPINameV2 } from 'helpers/translateHelper';
import colors from 'theme/_colors.scss';

import { afterChartReDrawStringData, chartLegendItemClick } from '../../../../helpers';
import { renderToStaticMarkup } from 'react-dom/server';
import { TrendTooltip } from 'sharedComponents/ChartComponents/Trend';
import { ChartNoDataText } from 'sharedComponents/ChartComponents';
import { defaultBoostThreshold } from 'helpers/constants';
import { RemovableLegend } from 'sharedComponents/ChartComponents/CommonLegend';
import { LEVEL, LIMIT_DIRECTION, LIMIT_LEVELS, LIMIT_NAME } from '../../../LimitConfigurationBeta/components/constants';
import { LIMIT_LINE_MARKERS, LIMIT_LINES_ORDER } from './constants';
import { getMergedTrendKpiList } from '../../../commonTrendHelpers';
import { multiChartOptions } from '../../constants';

const getChartConfig = removeKPIData => {
    return {
        title: {
            text: null
        },
        chart: {
            zoomType: 'x',
            height: null,
            events: {
                render: function (chartInstance) {
                    afterChartReDrawStringData(chartInstance.target.container.id, removeKPIData);
                }
            },
            marginBottom: 115
        },
        boost: {
            allowForce: false //Workaround for highchart bug. Allows correctly disabling boost, when zooming in.
        },
        time: {
            useUTC: false
        },
        xAxis: {
            type: 'datetime',
            id: 'x',
            dateTimeLabelFormats: {
                millisecond: '%H:%M:%S',
            },
            crosshair: {
                enabled: true,
                width: 2,
                color: colors.tundora,
            },
            minPadding: 0,
            maxPadding: 0
        },
        legend: {
            useHTML: true,
            layout: 'horizontal',
            align: 'right',
            enabled: true,
            reversed: false,
            itemWidth: 300,
            itemDistance: 16,
            itemMarginTop: 10,
            maxHeight: 85,
            itemStyle: {
                fontSize: '14px',
                fontFamily: 'ABBvoice-Regular',
                letterSpacing: '0',
                lineHeight: '17px',
                textAlign: 'center',
                textOverflow: 'ellipsis',
            },
            symbolRadius: 0,
            squareSymbol: false,
            symbolHeight: 8,
            symbolWidth: 8,
            y: 4
        },
        credits: {
            enabled: false,
        },
        tooltip: {
            shared: true,
            valueDecimals: 2,
            useHTML: true,
            backgroundColor: null,
            borderWidth: 0,
            dateTimeLabelFormats: {
                day: '%d.%m.%Y<br/>%H:%M',
                hour: '%d.%m %H:%M',
            },
        },
        plotOptions: {
            series: {
                turboThreshold: 0,
                animation: false,
                boostThreshold: 0
            }
        },
        lang: {
            noData: 'No data'
        },
        noData: {
            useHTML: true
        },
        exporting: {
            enabled: false,
            buttons: {
                contextButton: {
                    menuItems: ['downloadPNG'],
                    verticalAlign: 'bottom',
                    x: 10,
                    y: 0
                }
            }
        }
    };
};

const getMeasurements = (config, measurements, showRangeData) => {
    const averages = [];
    const rangedata = [];
    forEach(measurements, (measurement) => {
        if (measurement.timestamp !== null) {
            const date = new Date(measurement.timestamp).getTime();
            averages.push([date, measurement?.value ? measurement.value + config.offset
                : measurement.value]);
            if (showRangeData) {
                rangedata.push([date,
                    isNumber(measurement?.min) ? measurement.min + config.offset : null,
                    isNumber(measurement?.max) ? measurement.max + config.offset : null]);
            }
        }
    });

    return { averages, rangedata };
};

// TEMPORARY COMMENT FOR REMOVE LIMIT LINE FROM OPv2
// const getUserLimitsSeries = (userLimits, config, seriesDataPoints) => {
//     let series = [];
//     if (userLimits && userLimits.length) {
//         series = map(userLimits, (ul, index) => {
//             return {
//                 name: ul.label || config.displayName || '',
//                 id: `ul_${index}`,
//                 showInLegend: false,
//                 color: ul.color || colors.teak,
//                 marker: { enabled: false },
//                 lineWidth: 1,
//                 dashStyle: 'Solid',
//                 data: transform(seriesDataPoints, (acc, sd) => {
//                     const [date, value] = sd;
//                     if (value !== null) {
//                         acc.push([date, ul.value]);
//                     }
//                 }, []),
//                 unit: config && config.unit ? config.unit : '',
//                 tooltip: {
//                     valueSuffix: ` ${config && config.unit ? config.unit : ''}`,
//                 },
//             };
//         });
//     }
//     return series;
// };

const generateMarkerData = (averages, limitValue) => {
    if (averages?.length) {
        return averages.map((average) => {
            return [average[0], limitValue];
        });
    }
};

// Get selected series index from trendKPIsList and then assigned it as an id to series for maintain unique ID.
const getSeriesIndexFromList = (kpiConfig, trendKPIsList) => {
    return findIndex(trendKPIsList, (kpi) => kpi.timeseriesKey === kpiConfig.measurementTypeIdentifier);
};

const addLimitsInSeries = (limit, limtsDataSeries, unit = '') => {
    // const symbol = limit?.direction === LEVEL.UpperLimit || limit?.direction === LEVEL.Upper ? LIMIT_SYMBOL.Greater : LIMIT_SYMBOL.Lower; // UN-COMMENT THIS LINE IF SYMBOL NEEDED. IMPORT LEVEL AND LIMIT_SYMBOL
    const direction = limit?.direction === LEVEL.UpperLimit || limit?.direction === LEVEL.Upper ? LIMIT_DIRECTION.Upper : LIMIT_DIRECTION.Lower;
    const selectedLimitName = translate(LIMIT_NAME[`${direction}${limit?.level}`]);
    return {
        data: limtsDataSeries,
        name: selectedLimitName,
        showInLegend: false,
        color: limit?.level === LIMIT_LEVELS.Alarm ? colors.pomegranate : colors.orangePeel,
        id: `${limit?.level}_${limit?.direction}_${crypto.randomUUID()}`,
        lineWidth: 1,
        dashStyle: 'Solid',
        unit: unit,
        marker: {
            enabled: false,
            symbol: LIMIT_LINE_MARKERS[`${direction}${limit?.level}`]
        },
        tooltip: {
            valueSuffix: ` ${unit}`,
            isUserLimit: limit?.isUserLimit,
        },
        limitValue: limit?.limitValue
    };
};

const getSeries = (selectedKpisData, userLimits, yAxisTitleVisible, selectedLimits, availableTrendKPIs, multiChart) => {
    const ret = { yAxis: [], seriesData: [], allSeriesLength: 0 };

    transform(selectedKpisData, (acc, item, index) => {
        const config = item.config ? item.config : {};
        if (isEmpty(config)) {
            return { yAxis: [], seriesData: [], allSeriesLength: 0 };
        }
        const unit = config && config.unit || '';
        const precision = config && config.precision;
        const name = getTranslatedKPINameV2(config);
        const trendDataValues = item.values;
        const trendKPIs = getMergedTrendKpiList(availableTrendKPIs);
        const seriesId = `series_${getSeriesIndexFromList(config, trendKPIs)}`;
        const yAxisId = `y_${index}`;
        const { averages, rangedata } = getMeasurements(config, trendDataValues, selectedKpisData?.length === 1);
        acc.yAxis.push({
            labels: {
                format: '{value} ' + unit,
                style: {
                    color: item.color
                }
            },
            id: yAxisId,
            title: {
                text: name,
                enabled: yAxisTitleVisible,
                style: {
                    color: item.color
                }
            },
            opposite: index % 2 !== 0,
            visible: true
        });

        acc.seriesData.push({
            name: `${name}`,
            data: averages,
            tooltip: {
                valueSuffix: ` ${unit}`,
                valueDecimals: precision
            },
            color: item.color,
            marker: {
                enabled: true,
                //symbol: 'square',
                radius: 2
            },
            lineWidth: 1,
            id: seriesId,
            yAxis: yAxisId,
            events: {
                legendItemClick: chartLegendItemClick
            },
        });
        if (rangedata.length) {
            acc.seriesData.push(
                {
                    name: `${name}`,
                    data: rangedata,
                    type: 'arearange',
                    id: 'rangeSeries',
                    lineWidth: 2,
                    radius: 2,
                    linkedTo: ':previous',
                    fillOpacity: 0.3,
                    opacity: 0.3,
                    color: item.color,
                    zIndex: 0,
                    tooltip: {
                        valueSuffix: ` ${unit}`,
                    },
                    marker: {
                        enabled: true,
                        radius: 2
                    }
                }
            );
        }

        // PLOT LINES ALL DEFAULT+CUSTOM LIMITS IN MULTI TRENDS CHART
        if (multiChart === multiChartOptions.MULTI && config?.thresholdList?.length) {
            config?.thresholdList?.map((sl) => {
                if (!sl?.isUserLimit) {
                    const limtsDataSeries = generateMarkerData(averages, +sl?.limitValue);
                    if (limtsDataSeries?.length) {
                        const seriesWithLimits = addLimitsInSeries(sl, limtsDataSeries, unit);
                        acc?.seriesData?.push(seriesWithLimits);
                    }
                }
            });
        }
        // PLOT LINES FOR SELECTED LIMITS IN SINGLE TRENDS CHART
        if (multiChart !== multiChartOptions.MULTI && selectedKpisData?.length === 1) {
            if (selectedLimits?.length) {
                selectedLimits.map((sl) => {
                    const limtsDataSeries = generateMarkerData(averages, +sl?.limitValue);
                    if (limtsDataSeries?.length) {
                        const seriesWithLimits = addLimitsInSeries(sl, limtsDataSeries, unit);
                        acc?.seriesData?.push(seriesWithLimits);
                    }
                });
            }

            // COMMENTED OLD THRESHOLD LINES
            // if (config?.highErrorThresholdList?.length > 0) {
            //     acc?.seriesData?.push({
            //         data: generateMarkerData(averages, config?.highErrorThreshold),
            //         name: translate(
            //             'ABB.Powertrain.Frontend.High_alarm'
            //         ),
            //         showInLegend: false,
            //         color: colors.pomegranate,
            //         id: `highErrorThresholdSeries_${crypto.randomUUID()}`,
            //         lineWidth: 1,
            //         dashStyle: 'Solid',
            //         unit: config && config.unit ? config.unit : '',
            //         marker: {
            //             enabled: false,
            //         },
            //         tooltip: {
            //             valueSuffix: ` ${config.unit || unit}`,
            //         },
            //     });
            // }
            // if (config?.highWarningThresholdList?.length > 0) {
            //     acc?.seriesData?.push({
            //         data: generateMarkerData(averages, config?.highWarningThreshold),
            //         name: translate(
            //             'ABB.Powertrain.Frontend.High_alert'
            //         ),
            //         showInLegend: false,
            //         color: colors.orangePeel,
            //         id: `highWarningThresholdList_${crypto.randomUUID()}`,
            //         lineWidth: 1,
            //         dashStyle: 'Solid',
            //         unit: config && config.unit ? config.unit : '',
            //         marker: {
            //             enabled: false,
            //         },
            //         tooltip: {
            //             valueSuffix: ` ${config.unit || unit}`,
            //         },
            //     });
            // }
            // if (config?.lowErrorThresholdList?.length > 0) {
            //     acc?.seriesData?.push({
            //         data: generateMarkerData(averages, config?.lowErrorThreshold),
            //         name: translate(
            //             'ABB.Powertrain.Frontend.Low_alarm'
            //         ),
            //         showInLegend: false,
            //         color: colors.pomegranate,
            //         id: `lowErrorThresholdList_${crypto.randomUUID()}`,
            //         lineWidth: 1,
            //         dashStyle: 'Solid',
            //         unit: config && config.unit ? config.unit : '',
            //         marker: {
            //             enabled: false,
            //         },
            //         tooltip: {
            //             valueSuffix: ` ${config.unit || unit}`,
            //         },
            //     });
            // }
            // if (config?.lowWarningThresholdList?.length > 0) {
            //     acc?.seriesData?.push({
            //         data: generateMarkerData(averages, config?.lowWarningThreshold),
            //         name: translate(
            //             'ABB.Powertrain.Frontend.Low_alert'
            //         ),
            //         showInLegend: false,
            //         color: colors.orangePeel,
            //         id: `lowWarningThresholdList_${crypto.randomUUID()}`,
            //         lineWidth: 1,
            //         dashStyle: 'Solid',
            //         unit: config && config.unit ? config.unit : '',
            //         marker: {
            //             enabled: false,
            //         },
            //         tooltip: {
            //             valueSuffix: ` ${config.unit || unit}`,
            //         },
            //     });
            // }
        }
        acc?.seriesData?.sort((a, b) => LIMIT_LINES_ORDER.indexOf(a.name) - LIMIT_LINES_ORDER.indexOf(b.name));
        acc?.seriesData?.sort((a, b) => a.name === b.name && b.limitValue - a.limitValue); // SORT LIMIT VALUES IN DESC ORDER IF SAME LIMIT TYPE AND DIRECTION
    }, ret);

    const allSeriesLength = ret.seriesData?.length ? ret.seriesData.reduce((count, series) => series.data?.length + count, 0) : 0;

    return { ...ret, allSeriesLength };
};

const useChartConfig = (selectedKpisData, userLimits, yAxisTitleVisible = true, removeKPIData, multiChart, selectedLimits, availableTrendKPIs) => {
    const [options, setOptions] = useState({});
    const chartConfig = getChartConfig(removeKPIData);

    useEffect(() => {
        const { yAxis, seriesData, allSeriesLength } = getSeries(selectedKpisData, userLimits, yAxisTitleVisible, selectedLimits, availableTrendKPIs, multiChart);
        if (yAxis.length === 0 && seriesData.length === 0) {
            return;
        }

        chartConfig.yAxis = yAxis;
        chartConfig.series = seriesData;
        chartConfig.plotOptions.series.boostThreshold = allSeriesLength > defaultBoostThreshold ? 1 : 0;
        chartConfig.lang.noData = renderToStaticMarkup(
            <ChartNoDataText
                title={translate('ABB.Powertrain.Frontend.chartNoMeasurementData')}
                text={translate('ABB.Powertrain.Frontend.chartNoMeasurementDataUserGuide')} />);
        chartConfig.enableNoDataZoom = true;
        chartConfig.tooltip.formatter = function () {
            return renderToStaticMarkup(<TrendTooltip points={this.points} timeStamp={this.x} />);
        };
        chartConfig.legend.labelFormatter = function () {
            return renderToStaticMarkup(<RemovableLegend
                signalSourceName={this.userOptions.component}
                legend={this}
                multiChart={multiChart}
            />);
        };
        setOptions({ ...chartConfig });
    }, [selectedKpisData, userLimits]);

    return options;
};

export default useChartConfig;
