import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { IconPTUser } from 'svgIcons/MotionPortalIcons';
import { useUserCapability } from 'sharedHooks';
import { getMenuOptions } from '../../../UserProfileSettings/helper';
import authHelper from 'helpers/authHelper';

const UserProfileSettingsDropdownComponent = () => {
    const { t: translate } = useTranslation();
    const history = useHistory();
    const userCapabilityHelper = useUserCapability();
    const profileOptions = getMenuOptions(userCapabilityHelper);

    const user = useSelector(({ overview }) => overview?.user?.data);

    const handleOptionSelection = (option) => {
        history.push(option.route);
    };

    const renderUserProfileMenuItems = () =>
        profileOptions.map((option) => !option?.hidden &&
            <Dropdown.Item as={NavLink}
                key={option.id}
                onClick={() => handleOptionSelection(option)}
                to={option.route}>
                {translate(option.title)}
            </Dropdown.Item>);

    return <DropdownButton
        role='dropdown-button'
        className='user-dropdown-menu'
        title={<div className='icon-div'>
            <IconPTUser width={24} height={24} viewBox='0 0 24 24' />
        </div>}>
        <div className='user-name-email'>
            <div className='user-name'>{user?.firstName} {user?.lastName}</div>
            <div className='user-email'>{user?.email}</div>
        </div>
        {renderUserProfileMenuItems()}
        <div className='dropdown-item' onClick={() => authHelper.logout()}>
            {translate('ABB.Powertrain.Frontend.logout')}
        </div>
    </DropdownButton >;
};

UserProfileSettingsDropdownComponent.propTypes = {
    translate: PropTypes.func
};

export default UserProfileSettingsDropdownComponent;
