import find from 'lodash/find';
import startsWith from 'lodash/startsWith';

const defaultCulture = 'en-US';
const dateFormats = {
	'ar-SA': {
		short: 'dd/MM',
		standard: 'dd/MM/YY'
	},
	'bg-BG': {
		short: 'dd.M',
		standard: 'dd.M.YYYY'
	},
	'ca-ES': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'zh-TW': {
		short: 'M/dd',
		standard: 'YYYY/M/dd'
	},
	'cs-CZ': {
		short: 'dd.M',
		standard: 'dd.M.YYYY'
	},
	'da-DK': {
		short: 'dd-MM',
		standard: 'dd-MM-YYYY'
	},
	'de-DE': {
		short: 'dd.MM',
		standard: 'dd.MM.YYYY'
	},
	'el-GR': {
		short: 'dd/M',
		standard: 'dd/M/YYYY'
	},
	'en-US': {
		short: 'M/dd',
		standard: 'M/dd/YYYY'
	},
	'fi-FI': {
		short: 'dd.M',
		standard: 'dd.M.YYYY'
	},
	'fr-FR': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'he-IL': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'hu-HU': {
		short: 'MM.dd.',
		standard: 'YYYY.MM.dd.'
	},
	'is-IS': {
		short: 'dd.M',
		standard: 'dd.M.YYYY'
	},
	'it-IT': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'ja-JP': {
		short: 'MM/dd',
		standard: 'YYYY/MM/dd'
	},
	'ko-KR': {
		short: 'MM-dd',
		standard: 'YYYY-MM-dd'
	},
	'nl-NL': {
		short: 'dd-M',
		standard: 'dd-M-YYYY'
	},
	'nb-NO': {
		short: 'dd.MM',
		standard: 'dd.MM.YYYY'
	},
	'pl-PL': {
		short: 'MM-dd',
		standard: 'YYYY-MM-dd'
	},
	'pt-BR': {
		short: 'dd/M',
		standard: 'dd/M/YYYY'
	},
	'ro-RO': {
		short: 'dd.MM',
		standard: 'dd.MM.YYYY'
	},
	'ru-RU': {
		short: 'dd.MM',
		standard: 'dd.MM.YYYY'
	},
	'hr-HR': {
		short: 'dd.M',
		standard: 'dd.M.YYYY'
	},
	'sk-SK': {
		short: 'dd. M',
		standard: 'dd. M. YYYY'
	},
	'sq-AL': {
		short: 'MM-dd',
		standard: 'YYYY-MM-dd'
	},
	'sv-SE': {
		short: 'MM-dd',
		standard: 'YYYY-MM-dd'
	},
	'th-TH': {
		short: 'dd/M',
		standard: 'dd/M/YYYY'
	},
	'tr-TR': {
		short: 'dd.MM',
		standard: 'dd.MM.YYYY'
	},
	'ur-PK': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'id-ID': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'uk-UA': {
		short: 'dd.MM',
		standard: 'dd.MM.YYYY'
	},
	'be-BY': {
		short: 'dd.MM',
		standard: 'dd.MM.YYYY'
	},
	'sl-SI': {
		short: 'dd.M',
		standard: 'dd.M.YYYY'
	},
	'et-EE': {
		short: 'dd.MM',
		standard: 'dd.MM.YYYY'
	},
	'lv-LV': {
		short: 'MM.dd.',
		standard: 'YYYY.MM.dd.'
	},
	'lt-LT': {
		short: 'MM.dd',
		standard: 'YYYY.MM.dd'
	},
	'fa-IR': {
		short: 'MM/dd',
		standard: 'MM/dd/YYYY'
	},
	'vi-VN': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'hy-AM': {
		short: 'dd.MM',
		standard: 'dd.MM.YYYY'
	},
	'az-Latn-AZ': {
		short: 'dd.MM',
		standard: 'dd.MM.YYYY'
	},
	'eu-ES': {
		short: 'MM/dd',
		standard: 'YYYY/MM/dd'
	},
	'mk-MK': {
		short: 'dd.MM',
		standard: 'dd.MM.YYYY'
	},
	'af-ZA': {
		short: 'MM/dd',
		standard: 'YYYY/MM/dd'
	},
	'ka-GE': {
		short: 'dd.MM',
		standard: 'dd.MM.YYYY'
	},
	'fo-FO': {
		short: 'dd-MM',
		standard: 'dd-MM-YYYY'
	},
	'hi-IN': {
		short: 'dd-MM',
		standard: 'dd-MM-YYYY'
	},
	'ms-MY': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'kk-KZ': {
		short: 'dd.MM',
		standard: 'dd.MM.YYYY'
	},
	'ky-KG': {
		short: 'dd.MM',
		standard: 'dd.MM.YY'
	},
	'sw-KE': {
		short: 'M/dd',
		standard: 'M/dd/YYYY'
	},
	'uz-Latn-UZ': {
		short: 'dd/MM',
		standard: 'dd/MM YYYY'
	},
	'tt-RU': {
		short: 'dd.MM',
		standard: 'dd.MM.YYYY'
	},
	'pa-IN': {
		short: 'dd-MM',
		standard: 'dd-MM-YY'
	},
	'gu-IN': {
		short: 'dd-MM',
		standard: 'dd-MM-YY'
	},
	'ta-IN': {
		short: 'dd-MM',
		standard: 'dd-MM-YYYY'
	},
	'te-IN': {
		short: 'dd-MM',
		standard: 'dd-MM-YY'
	},
	'kn-IN': {
		short: 'dd-MM',
		standard: 'dd-MM-YY'
	},
	'mr-IN': {
		short: 'dd-MM',
		standard: 'dd-MM-YYYY'
	},
	'sa-IN': {
		short: 'dd-MM',
		standard: 'dd-MM-YYYY'
	},
	'mn-MN': {
		short: 'MM.dd',
		standard: 'YY.MM.dd'
	},
	'gl-ES': {
		short: 'dd/MM',
		standard: 'dd/MM/YY'
	},
	'kok-IN': {
		short: 'dd-MM',
		standard: 'dd-MM-YYYY'
	},
	'syr-SY': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'dv-MV': {
		short: 'dd/MM',
		standard: 'dd/MM/YY'
	},
	'ar-IQ': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'zh-CN': {
		short: 'M/dd',
		standard: 'YYYY/M/dd'
	},
	'de-CH': {
		short: 'dd.MM',
		standard: 'dd.MM.YYYY'
	},
	'en-GB': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'es-MX': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'fr-BE': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'it-CH': {
		short: 'dd.MM',
		standard: 'dd.MM.YYYY'
	},
	'nl-BE': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'nn-NO': {
		short: 'dd.MM',
		standard: 'dd.MM.YYYY'
	},
	'pt-PT': {
		short: 'dd-MM',
		standard: 'dd-MM-YYYY'
	},
	'sr-Latn-CS': {
		short: 'dd.M',
		standard: 'dd.M.YYYY'
	},
	'sv-FI': {
		short: 'dd.M',
		standard: 'dd.M.YYYY'
	},
	'az-Cyrl-AZ': {
		short: 'dd.MM',
		standard: 'dd.MM.YYYY'
	},
	'ms-BN': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'uz-Cyrl-UZ': {
		short: 'dd.MM',
		standard: 'dd.MM.YYYY'
	},
	'ar-EG': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'zh-HK': {
		short: 'dd/M',
		standard: 'dd/M/YYYY'
	},
	'de-AT': {
		short: 'dd.MM',
		standard: 'dd.MM.YYYY'
	},
	'en-AU': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'es-ES': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'fr-CA': {
		short: 'MM-dd',
		standard: 'YYYY-MM-dd'
	},
	'sr-Cyrl-CS': {
		short: 'dd.M',
		standard: 'dd.M.YYYY'
	},
	'ar-LY': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'zh-SG': {
		short: 'dd/M',
		standard: 'dd/M/YYYY'
	},
	'de-LU': {
		short: 'dd.MM',
		standard: 'dd.MM.YYYY'
	},
	'en-CA': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'es-GT': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'fr-CH': {
		short: 'dd.MM',
		standard: 'dd.MM.YYYY'
	},
	'ar-DZ': {
		short: 'dd-MM',
		standard: 'dd-MM-YYYY'
	},
	'zh-MO': {
		short: 'dd/M',
		standard: 'dd/M/YYYY'
	},
	'de-LI': {
		short: 'dd.MM',
		standard: 'dd.MM.YYYY'
	},
	'en-NZ': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'es-CR': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'fr-LU': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'ar-MA': {
		short: 'dd-MM',
		standard: 'dd-MM-YYYY'
	},
	'en-IE': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'es-PA': {
		short: 'MM/dd',
		standard: 'MM/dd/YYYY'
	},
	'fr-MC': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'ar-TN': {
		short: 'dd-MM',
		standard: 'dd-MM-YYYY'
	},
	'en-ZA': {
		short: 'MM/dd',
		standard: 'YYYY/MM/dd'
	},
	'es-DO': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'ar-OM': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'en-JM': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'es-VE': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'ar-YE': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'en-029': {
		short: 'MM/dd',
		standard: 'MM/dd/YYYY'
	},
	'es-CO': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'ar-SY': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'en-BZ': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'es-PE': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'ar-JO': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'en-TT': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'es-AR': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'ar-LB': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'en-ZW': {
		short: 'M/dd',
		standard: 'M/dd/YYYY'
	},
	'es-EC': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'ar-KW': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'en-PH': {
		short: 'M/dd',
		standard: 'M/dd/YYYY'
	},
	'es-CL': {
		short: 'dd-MM',
		standard: 'dd-MM-YYYY'
	},
	'ar-AE': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'es-UY': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'ar-BH': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'es-PY': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'ar-QA': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'es-BO': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'es-SV': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'es-HN': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'es-NI': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'es-PR': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'am-ET': {
		short: 'dd/M',
		standard: 'dd/M/YYYY'
	},
	'tzm-Latn-DZ': {
		short: 'dd-MM',
		standard: 'dd-MM-YYYY'
	},
	'iu-Latn-CA': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'sma-NO': {
		short: 'dd.MM',
		standard: 'dd.MM.YYYY'
	},
	'mn-Mong-CN': {
		short: 'M/dd',
		standard: 'YYYY/M/dd'
	},
	'gd-GB': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'en-MY': {
		short: 'dd/M',
		standard: 'dd/M/YYYY'
	},
	'prs-AF': {
		short: 'dd/MM',
		standard: 'dd/MM/YY'
	},
	'bn-BD': {
		short: 'dd-MM',
		standard: 'dd-MM-YY'
	},
	'wo-SN': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'rw-RW': {
		short: 'M/dd',
		standard: 'M/dd/YYYY'
	},
	'qut-GT': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'sah-RU': {
		short: 'MM.dd',
		standard: 'MM.dd.YYYY'
	},
	'gsw-FR': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'co-FR': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'oc-FR': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'mi-NZ': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'ga-IE': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'se-SE': {
		short: 'MM-dd',
		standard: 'YYYY-MM-dd'
	},
	'br-FR': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'smn-FI': {
		short: 'dd.M',
		standard: 'dd.M.YYYY'
	},
	'moh-CA': {
		short: 'M/dd',
		standard: 'M/dd/YYYY'
	},
	'arn-CL': {
		short: 'dd-MM',
		standard: 'dd-MM-YYYY'
	},
	'ii-CN': {
		short: 'M/dd',
		standard: 'YYYY/M/dd'
	},
	'dsb-DE': {
		short: 'dd. M',
		standard: 'dd. M. YYYY'
	},
	'ig-NG': {
		short: 'dd/M',
		standard: 'dd/M/YYYY'
	},
	'kl-GL': {
		short: 'dd-MM',
		standard: 'dd-MM-YYYY'
	},
	'lb-LU': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'ba-RU': {
		short: 'dd.MM',
		standard: 'dd.MM.YY'
	},
	'nso-ZA': {
		short: 'MM/dd',
		standard: 'YYYY/MM/dd'
	},
	'quz-BO': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'yo-NG': {
		short: 'dd/M',
		standard: 'dd/M/YYYY'
	},
	'ha-Latn-NG': {
		short: 'dd/M',
		standard: 'dd/M/YYYY'
	},
	'fil-PH': {
		short: 'M/dd',
		standard: 'M/dd/YYYY'
	},
	'ps-AF': {
		short: 'dd/MM',
		standard: 'dd/MM/YY'
	},
	'fy-NL': {
		short: 'dd-M',
		standard: 'dd-M-YYYY'
	},
	'ne-NP': {
		short: 'M/dd',
		standard: 'M/dd/YYYY'
	},
	'se-NO': {
		short: 'dd.MM',
		standard: 'dd.MM.YYYY'
	},
	'iu-Cans-CA': {
		short: 'dd/M',
		standard: 'dd/M/YYYY'
	},
	'sr-Latn-RS': {
		short: 'dd.M',
		standard: 'dd.M.YYYY'
	},
	'si-LK': {
		short: 'MM-dd',
		standard: 'YYYY-MM-dd'
	},
	'sr-Cyrl-RS': {
		short: 'dd.M',
		standard: 'dd.M.YYYY'
	},
	'lo-LA': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'km-KH': {
		short: 'MM-dd',
		standard: 'YYYY-MM-dd'
	},
	'cy-GB': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'bo-CN': {
		short: 'M/dd',
		standard: 'YYYY/M/dd'
	},
	'sms-FI': {
		short: 'dd.M',
		standard: 'dd.M.YYYY'
	},
	'as-IN': {
		short: 'dd-MM',
		standard: 'dd-MM-YYYY'
	},
	'ml-IN': {
		short: 'dd-MM',
		standard: 'dd-MM-YY'
	},
	'en-IN': {
		short: 'dd-MM',
		standard: 'dd-MM-YYYY'
	},
	'or-IN': {
		short: 'dd-MM',
		standard: 'dd-MM-YY'
	},
	'bn-IN': {
		short: 'dd-MM',
		standard: 'dd-MM-YY'
	},
	'tk-TM': {
		short: 'dd.MM',
		standard: 'dd.MM.YY'
	},
	'bs-Latn-BA': {
		short: 'dd.M',
		standard: 'dd.M.YYYY'
	},
	'mt-MT': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'sr-Cyrl-ME': {
		short: 'dd.M',
		standard: 'dd.M.YYYY'
	},
	'se-FI': {
		short: 'dd.M',
		standard: 'dd.M.YYYY'
	},
	'zu-ZA': {
		short: 'MM/dd',
		standard: 'YYYY/MM/dd'
	},
	'xh-ZA': {
		short: 'MM/dd',
		standard: 'YYYY/MM/dd'
	},
	'tn-ZA': {
		short: 'MM/dd',
		standard: 'YYYY/MM/dd'
	},
	'hsb-DE': {
		short: 'dd. M',
		standard: 'dd. M. YYYY'
	},
	'bs-Cyrl-BA': {
		short: 'dd.M',
		standard: 'dd.M.YYYY'
	},
	'tg-Cyrl-TJ': {
		short: 'dd.MM',
		standard: 'dd.MM.YY'
	},
	'sr-Latn-BA': {
		short: 'dd.M',
		standard: 'dd.M.YYYY'
	},
	'smj-NO': {
		short: 'dd.MM',
		standard: 'dd.MM.YYYY'
	},
	'rm-CH': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'smj-SE': {
		short: 'MM-dd',
		standard: 'YYYY-MM-dd'
	},
	'quz-EC': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'quz-PE': {
		short: 'dd/MM',
		standard: 'dd/MM/YYYY'
	},
	'hr-BA': {
		short: 'dd.M',
		standard: 'dd.M.YYYY.'
	},
	'sr-Latn-ME': {
		short: 'dd.M',
		standard: 'dd.M.YYYY'
	},
	'sma-SE': {
		short: 'MM-dd',
		standard: 'YYYY-MM-dd'
	},
	'en-SG': {
		short: 'dd/M',
		standard: 'dd/M/YYYY'
	},
	'ug-CN': {
		short: 'M-dd',
		standard: 'YYYY-M-dd'
	},
	'sr-Cyrl-BA': {
		short: 'dd.M',
		standard: 'dd.M.YYYY'
	},
	'es-US': {
		short: 'M/dd',
		standard: 'M/dd/YYYY'
	}
};

const getCultureKey = (culture) => {
	return find(Object.keys(dateFormats), (item) => {
		return startsWith(item, culture);
	});
};

const standard = (culture) => {
	let cultureKey = getCultureKey(culture);
	cultureKey = cultureKey ? cultureKey : defaultCulture;
	return dateFormats[cultureKey].standard;
};

export const customformat = (culture) => {
	const format = standard(culture);
	return format.replace('dd', 'D');
};
