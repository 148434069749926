import { AssetDetailsApi } from 'api';
import * as actionTypes from './constants';
import { apiCallStatus, NOTIFICATION_TYPES } from 'helpers/constants';
import { getApiActionCreator } from 'helpers/actionHelper';
import { notify } from 'sharedComponents/GeneralNotification';
import { translate } from 'helpers/translateHelper';

export const getAssetPicturesAction = getApiActionCreator(actionTypes.GET_ASSET_PICTURES);
export const uploadAssetPicturesAction = getApiActionCreator(actionTypes.UPLOAD_ASSET_PICTURES);
export const deleteAssetPictureAction = getApiActionCreator(actionTypes.DELETE_ASSET_PICTURE);
export const getAssetPictures = (assetID) => {
	return (dispatch) => {
		dispatch(getAssetPicturesAction(apiCallStatus.LOADING));
		AssetDetailsApi.getAssetPicturesV2(assetID)
			.then((pictures) => {
				dispatch(getAssetPicturesAction(apiCallStatus.SUCCESS, pictures));
			}, (err) => {
				dispatch(getAssetPicturesAction(apiCallStatus.ERROR, [], err));
			});
	};
};

export const setShowDeleteConfirm = (data) => ({
	type: actionTypes.SHOW_CONFIRM_DELETE_ASSET_PICTURE,
	data
});

export const deleteAssetImage = (assetID, pictureId) => {
	return async (dispatch) => {
		dispatch(deleteAssetPictureAction(apiCallStatus.LOADING));
		return await AssetDetailsApi.deleteAssetImage(assetID, pictureId)
			.then((response) => {
				response && dispatch(getAssetPictures(assetID));
				dispatch(setShowDeleteConfirm(false));
				notify(translate('ABB.Powertrain.Frontend.deleteAssetPictureSuccessText'), NOTIFICATION_TYPES.SUCCESS);
				dispatch(deleteAssetPictureAction(apiCallStatus.SUCCESS, null));
			}, (err) => {
				notify(translate('ABB.Powertrain.Frontend.deleteAssetPictureErrorText'), NOTIFICATION_TYPES.ERROR, true);
				dispatch(setShowDeleteConfirm(false));
				dispatch(deleteAssetPictureAction(apiCallStatus.ERROR, null, err));
			});
	};
};

export const uploadAssetPictureRequest = (
	assetID,
	fileName,
	pictureId,
	pictureType,
	file
) => ({
	type: actionTypes.UPLOAD_ASSET_PICTURE_REQUEST,
	assetID,
	fileName,
	pictureId,
	pictureType,
	file,
});

export const clearAssetPictures = () => {
	return {
		type: actionTypes.CLEAR_ASSET_PICTURES
	};
};

