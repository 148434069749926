export const formatSensorDetailsData = (sensorProperties,
    isFirmwareUpdateEnforced, translate) => {

    const sensorDetails = [
        {
            label: translate('ABB.Powertrain.Frontend.sensorIdentifier'),
            value: sensorProperties?.serialNumber
        },
        {
            label: translate('ABB.Powertrain.Frontend.sensorType'),
            value: sensorProperties?.sensorTypeDescription
        },
        {
            label: translate('ABB.Powertrain.Frontend.hardwareRevision'),
            value: sensorProperties?.hardwareRevisionName
        },
        sensorProperties?.subscription && {
            label: translate('ABB.Powertrain.Frontend.subscriptionExpirationDate'),
            value: sensorProperties?.subscription?.expirationDate
        },
        sensorProperties?.subscription && {
            label: translate('ABB.Powertrain.Frontend.subscriptionType'),
            value: sensorProperties?.subscription?.subscriptionDisplayName
        },
        {
            label: translate('ABB.Powertrain.Frontend.Externalreferenceid'),
            value: sensorProperties?.referenceId
        },
    ];
    return sensorDetails;
};
