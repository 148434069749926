import React from 'react';
import PropTypes from 'prop-types';

const IconAssetOffline = ({ width = 16, height = 16,  viewBox = '0 0 16 16'}) => {
	return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M3.83094 6.7907L9.21094 12.1707L7.50094 13.8907C7.14747 14.2434 6.72797 14.5229 6.2664 14.7134C5.80483 14.9039 5.31026 15.0015 4.81094 15.0007C4.06601 14.9994 3.33713 14.7842 2.71094 14.3807L1.54094 15.5807L0.460938 14.5207L1.65094 13.3207C1.15892 12.5878 0.935885 11.7072 1.01974 10.8285C1.1036 9.94981 1.48918 9.12726 2.11094 8.5007L3.83094 6.7907ZM14.4809 0.470703L15.5409 1.5307L14.2709 2.7807C14.7923 3.54997 15.0296 4.47678 14.942 5.40196C14.8544 6.32714 14.4474 7.19296 13.7909 7.8507L12.6309 9.0007L11.3109 7.7007L9.49094 9.5307L8.43094 8.4707L10.2009 6.6407L9.30094 5.7507L7.47094 7.5507L6.47094 6.4807L8.29094 4.6807L7.00094 3.3707L8.13094 2.2207C8.78992 1.56573 9.65635 1.16059 10.5815 1.07482C11.5066 0.989061 12.4328 1.22803 13.2009 1.7507L14.4809 0.470703Z' fill='#696969'/>
    </svg>;
};

IconAssetOffline.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string,
};

export default IconAssetOffline;
