import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';
import GatewayManagementEmptyComponent from '../../GatewayManagementEmptyComponent';

const GatewayInfoComponent = ({ gatewayInformation }) => {
    const { t: translate } = useTranslation();
    const [gatewayInfoFirstColData, setGatewayInfoFirstColData] = useState([]);
    const [gatewayInfoSecondColData, setGatewayInfoSecondColData] = useState([]);

    useEffect(() => {
        if (gatewayInformation?.gatewayInfo?.gatewayPropertyItems &&
            gatewayInformation?.gatewayInfo?.gatewayPropertyItems?.length > 0
        ) {
            const { gatewaySerialNumber, gatewayPropertyItems } = gatewayInformation.gatewayInfo;
            const modifiedGatewayInfo = [
                {
                    propertyKey: translate('ABB.Powertrain.Frontend.gatewaySerialNumber'),
                    propertyName: translate('ABB.Powertrain.Frontend.gatewaySerialNumber'),
                    propertyValue: gatewaySerialNumber
                },
                ...gatewayPropertyItems
            ];
            const midIndex = Math.ceil(modifiedGatewayInfo.length / 2);
            setGatewayInfoFirstColData(modifiedGatewayInfo.slice(0, midIndex));
            setGatewayInfoSecondColData(modifiedGatewayInfo.slice(midIndex));
        }
    }, [gatewayInformation?.gatewayInfo?.gatewayPropertyItems]);

    if (gatewayInformation?.gatewayInfo?.gatewayPropertyItems?.length === 0) {
        return <GatewayManagementEmptyComponent />;
    }

    const renderSection = (gatewayInfoColData) =>
        <div className='gateway-info__container'>
            <ul className='gateway-info__lists'>
                {gatewayInfoColData.map((gatewayInfoItem, idx) =>
                    <li className='gateway-info__item' key={idx}>
                        <div className='data__label'>{gatewayInfoItem.propertyName}</div>
                        <div className='data__value'>{gatewayInfoItem.propertyValue}</div>
                    </li>
                )}
            </ul>
        </div>;

    return (
        <div className='gateway-info'>
            {renderSection(gatewayInfoFirstColData)}
            {renderSection(gatewayInfoSecondColData)}
        </div>
    );
};

export default GatewayInfoComponent;
