import { apiCallStatus, ASSET_TYPES, CAPABILITIES } from 'helpers/constants';
import { translate } from 'helpers/translateHelper';
import { getInitialEndDate, getInitialStartDate } from 'sharedComponents/TimeSelectorRow/helpers';
import { sensorTypes } from './constants';


export const updateReportDownloadProgress = (reportList, reportID, loadingStatus) => {
    return reportList.map(report => {
        if (report.reportId === reportID) {
            if (loadingStatus === apiCallStatus.LOADING) {
                report.downloadInProgress = true;
            } else if (loadingStatus === apiCallStatus.SUCCESS || loadingStatus === apiCallStatus.ERROR) {
                report.downloadInProgress = false;
            }
        }
        return report;
    });
};

const getAssetTypeLabel = (assetTypeId) => {
    const getAssetTypeFromID = (value) => Object.keys(ASSET_TYPES).find(key => ASSET_TYPES[key] === value);
    const assetTypeName = getAssetTypeFromID(assetTypeId);
    return assetTypeName ? translate(`ABB.Powertrain.Frontend.${assetTypeName.toLowerCase()}Label`) : '-';
};

// eslint-disable-next-line import/no-unused-modules
export const hasDeleteCapability = (capabilities, neededCapability) => {
    let hasCapability = false;
    if (capabilities?.assetCapabilitiesInfos?.length) {
        for (const assetCapabilities of capabilities.assetCapabilitiesInfos) {
            for (const capabilityInfo of assetCapabilities.capabilityInfos) {
                if (capabilityInfo?.capabilityCode === neededCapability && capabilityInfo?.isAvailable) {
                    hasCapability = true;
                    break;
                }
            }
        }
    }
    return hasCapability;
};

export const hasCapability = (assetId, capabilities, neededCapability) => {
    let hasCapability = false;
    let found = false;
    if (capabilities?.assetCapabilitiesInfos?.length) {
        for (const assetCapabilities of capabilities.assetCapabilitiesInfos) {
            if (assetId === assetCapabilities.assetId) {
                for (const capabilityInfo of assetCapabilities.capabilityInfos) {
                    if (capabilityInfo?.capabilityCode === neededCapability && capabilityInfo?.isAvailable) {
                        hasCapability = true;
                        found = true;
                        break;
                    }
                }
                if (found) {
                    break;
                }
            }
        }
    }
    return hasCapability;
};

export const isSensorTypeMSR = (asset, sensorOfAssets) => {
    let isMatching = false;

    if (sensorOfAssets) {
        for (const sensorInfo of sensorOfAssets) {
            if (asset.id === sensorInfo.assetId && sensorInfo.sensorType === sensorTypes.MSR) {
                isMatching = true;
                break;
            }
        }
    }
    return isMatching;
};

export const mapReports = (reportList, capabilities) => {
    return reportList?.map(report => {
        report.reportTypeName = translate(report.reportType?.reportTypeTranslationCode) || report.reportType?.reportTypeDisplayName;
        report.reportStatusName = translate(`ABB.Powertrain.Frontend.reportsStatus${report.reportStateId}`);
        report.downloadInProgress = false;
        report.assetTypeName = getAssetTypeLabel(report.reportType?.assetTypeId);
        report.canDelete = hasDeleteCapability(capabilities, CAPABILITIES.deleteReport);
        return report;
    });
};

export const getPreselectedDates = (daysBack = 90) => ({
    from: getInitialStartDate(daysBack),
    to: getInitialEndDate(daysBack)
});
