import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import { Col } from 'react-bootstrap';

import { apiCallStatus } from 'helpers/constants';
import { LoadingStatusWrapper } from 'sharedComponents/WithLoadingStatus';
import {
    formatSuggestedLabels,
    useOutsideClickHandler,
    isAlreadyAdded
}
    from './helpers';
import { IconAdd, IconAddFilled } from 'svgIcons/MotionPortalIcons';
import { GeneralButton, HorizontalScroll } from 'sharedComponents';
import colors from 'theme/_colors.scss';


const AssetLabelsComponent = (props) => {
    const {
        organizationID,
        suggestedLabels,
        suggestedLoadingStatus,
        assetLabels,
        assetLabelsLoadingStatus,
        addLabelsLoadingStatus,
        removeLabelsLoadingStatus
    } = props.model;

    const {
        getAssetLabels,
        getSuggestedAssetLabels,
        addLabelToAsset,
        removeLabelFromAsset,
    } = props.actions;
    const { t: translate } = useTranslation();
    const {
        asset
    } = props;

    const [showSuggestedLabels, setShowSuggestedLabels] = useState(false);
    const ref = useRef(null);
    let creatableInputRef = null;

    useEffect(() => {
        props.asset && getAssetLabels(props.asset.id);
    }, []);

    useEffect(() => {
        if (showSuggestedLabels && suggestedLoadingStatus === apiCallStatus.SUCCESS) {
            creatableInputRef.focus();
        }
    }, [showSuggestedLabels, suggestedLoadingStatus]);

    useOutsideClickHandler(ref, setShowSuggestedLabels);

    const renderLabels = () => {
        return map(orderBy(assetLabels, 'tagValue', 'asc'), (label) => {
            return <li key={label.tagId} className='asset-label'>
                <div>{label.tagValue}</div>
                <div onClick={
                    () => removeLabelFromAsset(
                        asset.id,
                        label.tagId)
                } >
                    <IconAdd width={'20px'} height={'20px'} showCircle={false} />
                </div>
            </li>;
        });
    };

    if (!asset) {
        return null;
    }

    const Input = props => <components.Input {...props} maxLength={50} />;

    return <Col md={12} ref={ref} className='asset-labels-container'>
        {showSuggestedLabels ? <CreatableSelect
            defaultMenuIsOpen={true}
            menuIsOpen={true}
            closeMenuOnSelect={true}
            className='label-select'
            createOptionPosition={'first'}
            formatCreateLabel={(label) => label}
            allowCreateWhileLoading={true}
            placeholder={translate('ABB.Powertrain.Frontend.addLabelPlaceholder')}
            ref={ref => {
                creatableInputRef = ref;
            }}
            onChange={(e) => {
                setShowSuggestedLabels(false);
                // if e.value not in assetLabels.labelValue items
                !isAlreadyAdded(e.value, assetLabels) && addLabelToAsset(
                    asset?.id,
                    e.value);
            }}
            components={{ Input }}
            options={formatSuggestedLabels(suggestedLabels, assetLabels)}
            noOptionsMessage={() => translate('ABB.Powertrain.Frontend.emptyTagsDropdownLabel')}
            creatable={true}
            isLoading={suggestedLoadingStatus === apiCallStatus.LOADING}
        /> : <div className='add-label-button-wrapper' >
            <GeneralButton
                className='add-label-button'
                icon={<IconAddFilled color={colors.white}
                    width={'20px'}
                    height={'20px'} />}
                type='primary'
                text={translate('ABB.Powertrain.Frontend.addLabelButton')}
                disabled={false}
                onClick={() => {
                    setShowSuggestedLabels(true);
                    getSuggestedAssetLabels(organizationID);
                }} />
        </div>}
        <div className='label-list'>
            <LoadingStatusWrapper
                useCustomAnchor
                transparent
                loadingStatus={[addLabelsLoadingStatus, removeLabelsLoadingStatus, assetLabelsLoadingStatus]}>
                <HorizontalScroll>
                    {renderLabels()}
                </HorizontalScroll>
            </LoadingStatusWrapper>
        </div>
    </Col >;
};

AssetLabelsComponent.propTypes = {
    organizationID: PropTypes.number,
    suggestedLabels: PropTypes.array,
    assetLabels: PropTypes.array,
    assetLabelsLoadingStatus: PropTypes.string,
    suggestedLoadingStatus: PropTypes.string,
    getSuggestedAssetLabels: PropTypes.func,
    addLabelToAsset: PropTypes.func,
    removeLabelFromAsset: PropTypes.func
};

export default AssetLabelsComponent;
