import React, { useEffect, memo } from 'react';
import GatewayDetailsComponent from './GatewayDetailsComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { LoadingStatusWrapper } from 'sharedComponents/WithLoadingStatus';
import { getModel } from './selectors';
import * as actions from './actions';

const GatewayDetailsContainer = memo((props) => {
    const {
        generalFilterLoadingStatuses,
    } = props.model;

    useEffect(() => {
        return () => {
            props.actions.clearStore();
        };
    }, []);

    return <LoadingStatusWrapper
        loadingStatus={[...generalFilterLoadingStatuses]}>
        <GatewayDetailsComponent {...props} />
    </LoadingStatusWrapper >;
});

const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
};

GatewayDetailsContainer.displayName = 'GatewayDetailsContainer';

const GatewayDetailsContainerConn = connect(
    mapStateToProps,
    mapDispatchToProps
)(GatewayDetailsContainer);

export default GatewayDetailsContainerConn;
