import React from 'react';
import PropTypes from 'prop-types';
import { getAssetIcon } from 'helpers/icons';
import { filter } from 'lodash';
import { renderThreshold, renderTrend, renderAssetLimitsSummary, filteredPowertrain, filteredAssetGroup } from './helpers';
import { newConditionIndices } from 'helpers/constants';
import { IconCondition } from 'svgIcons/MotionPortalIcons';
import { useTranslation } from 'react-i18next';
import { assetActive } from './constants';
import { useHistory } from 'react-router';
import { routes } from 'routes';


const AssetRowComponent = ({
    asset,
    selectedAsset,
    onSelectedItem,
    allAssets,
    onAssetToggle,
    showThreshold,
    showTrend,
    isHorizontalScrollActive,
    powertrainList,
    selectedPowertrain,
    assetGroupList,
    selectedAssetGroup
}) => {
    const { t: translate } = useTranslation();
    const history = useHistory();

    const handleAssetNavigation = (e, selectedAssetId) => {
        const filteredAsset = allAssets && filter(allAssets, (asset) => {
            return asset.id === selectedAssetId;
        });
        const assetId = filteredAsset[0]?.id;
        const filteredPowertrainList = filteredPowertrain(powertrainList, selectedPowertrain);
        const filteredAssetGroupList = filteredAssetGroup(assetGroupList, selectedAssetGroup);
        if (assetId) {
            let routeData;
            if (selectedPowertrain && filteredPowertrainList?.length) {
                routeData = filteredPowertrainList;
                history.push({
                    pathname: routes?.OperationalParametersV2,
                    search: new URLSearchParams({ assetId }).toString(),
                    state: {
                        routeName: translate('ABB.Powertrain.Frontend.ptConditionMonitoringPowertrainsAssetsOverviewTab'),
                        routeData: routeData
                    }
                });
            } else if (selectedAssetGroup && filteredAssetGroupList?.length) {
                routeData = filteredAssetGroupList;
                history.push({
                    pathname: routes?.OperationalParametersV2,
                    search: new URLSearchParams({ assetId }).toString(),
                    state: {
                        routeName: translate('ABB.Powertrain.Frontend.ptConditionMonitoringPowertrainsAssetsOverviewTab'),
                        routeData: routeData
                    }
                });
            } else if (asset) {
                const assets = allAssets.map((asset) => {
                    return {
                        ...asset,
                        assetName: asset?.componentName,
                        assetSerialNumber: asset?.serialNumber,
                    };
                });
                routeData = { asset, assets };
                history.push({
                    pathname: routes?.OperationalParametersV2,
                    search: new URLSearchParams({ assetId }).toString(),
                    state: {
                        routeName: translate('ABB.Powertrain.Frontend.ptConditionMonitoringPowertrainsAssetsOverviewTab'),
                        routeData: routeData
                    }
                });
            }
        }
        e.stopPropagation();
    };

    const handleAssetClick = (asset, e) => {
        onSelectedItem(asset);
        onAssetToggle();

        if (selectedAsset && selectedAsset?.assetId !== asset?.assetId) {
            onAssetToggle();
        } else {
            onSelectedItem(asset);
        }
        e.stopPropagation();
    };

    return <div className={`item ${selectedAsset?.assetId === asset?.assetId ? assetActive.ACTIVE : ''}`} onClick={(e) => handleAssetClick(asset, e)} onDoubleClick={(e) => handleAssetNavigation(e, selectedAsset?.assetId)}>
        <div className={`cell-fixed ${isHorizontalScrollActive ? 'horizontal-scroll-active' : ''}`}>
            <div className='item-status'>
                <IconCondition condition={newConditionIndices[asset?.condition]?.iconID} />
            </div>
            <div className='item-name'>
                <div className='item-icon'>
                    {getAssetIcon(asset.assetType, { width: 20, height: 20 })}
                </div>
                <div className='name'>{asset.assetName}</div>
            </div>
        </div>
        <div className='ci-summary cell cell--first-cell'>
            {asset?.conditionIndex?.condition === newConditionIndices.Unsupported.conditionIndexID ? translate('ABB.Powertrain.Frontend.conditionNotSupported') : asset?.conditionIndex?.condition}
        </div>
        <div className='limit-Summary cell'>
            {renderAssetLimitsSummary(asset)}
        </div>
        {showThreshold ? renderThreshold(asset) : ''}
        {showTrend ? renderTrend(asset) : ''}
    </div>;
};

AssetRowComponent.propTypes = {
    asset: PropTypes.shape({
        assetId: PropTypes.number,
        assetType: PropTypes.string,
        assetName: PropTypes.string
    }),
    selectedAsset: PropTypes.object,
    onSelectedItem: PropTypes.func,
    limitSummary: PropTypes.object,
    showThreshold: PropTypes.bool,
    showTrend: PropTypes.bool,
    isHorizontalScrollActive: PropTypes.bool
};

export default AssetRowComponent;
