import React from 'react';
import UserManagementComponent from './UserManagementComponent';
import { connect } from 'react-redux';
import { LoadingStatusWrapper } from 'sharedComponents/WithLoadingStatus';
import { getModel } from './selectors';

import './style.scss';

const UserManagementContainer = (props) => {

    return <LoadingStatusWrapper
        loadingStatus={
            props.model.generalFilterLoadingStatuses
        }>
        <UserManagementComponent {...props} />
    </LoadingStatusWrapper>;
};


const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};


const UserManagementContainerConn = connect(
    mapStateToProps,
)(UserManagementContainer);

export default UserManagementContainerConn;
