import React from 'react';
import DrivesListHeader from './DrivesListHeaderComponent';
import DrivesListRow from './DrivesListRowComponent';

const ConnectedAssetsDrivesComponent = ({ gatewayConnectedDriveData, searchQuery }) => {
    const showHeader = gatewayConnectedDriveData?.length === 0 ? false : true;
    // Filtered list based on the search query for both name and serialNumber
    const filteredGatewayConnectedDriveData = gatewayConnectedDriveData.filter((asset) => {
        const nameMatches = asset?.name?.toLowerCase().includes(searchQuery?.toLowerCase() || '');
        const serialNumberMatches = asset?.serialNumber?.toLowerCase().includes(searchQuery?.toLowerCase() || '');
        return nameMatches || serialNumberMatches;
    });
    return (
        <div className='drives-list'>
            <div className='drives-list__body'>
                {showHeader && <DrivesListHeader />}
                <DrivesListRow gatewayConnectedDriveData={filteredGatewayConnectedDriveData} />
            </div>
        </div>
    );
};

export default ConnectedAssetsDrivesComponent;
