import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { apiCallStatus } from 'helpers/constants';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import useLoadingSummary from 'sharedComponents/WithLoadingStatus/useLoadingSummary';
import { useQueryParams } from 'sharedHooks/useQuery';
import { formatConditionIndexOverviewRequest } from '../helpers';

/**
 * Handles assets and powertrains selection depending on query parameters inside the url
 *
 * @param actions - Redux Actions
 * @param isAssetDetailsNeeded - Flag to enable fetching of assetDetails when asset selection changes
 * @param autoSelectFirstAsset - Flag to enable autoselection of first asset in the powertrain list.
 * @param model - State object. Includes: Loading info, formatted powertrains, selected assets/ powertrains
 *
 * @returns null
 */
const AssetPowertrainSelectorComponent = ({
    actions,
    model: {
        generalFilterLoadingStatus,
        powertrainsLoadingStatus,
        assetsLoadingStatus,
        contextLoadingStatus,
        formattedPowertrains,
        selectedPowertrain,
        selectedAsset,
        //newSelectedAssetCapability
    },
    isAssetDetailsNeeded = false,
    autoSelectFirstAsset = false
}) => {

    const { powertrainId, assetId } = useQueryParams();
    //const newCapabilityHelper = new CapabilityHelper(newSelectedAssetCapability, selectedAsset);
    const history = useHistory();
    const { selectedFilters, assetTypeFilter, conditionFilter, searchBoxFilter } = useSelector(state => ({
        selectedFilters: state?.generalFilter?.selectedFilters,
        assetTypeFilter: state?.conditionIndices?.filter?.assetTypeFilter,
        conditionFilter: state?.conditionIndices?.filter?.conditionFilter,
        searchBoxFilter: state?.conditionIndices?.filter?.searchBox
    }));

    const loadingSummary = useLoadingSummary(
        null, [
        ...generalFilterLoadingStatus,
        powertrainsLoadingStatus,
        assetsLoadingStatus,
        contextLoadingStatus
    ]);

    const isLoading = () => loadingSummary !== apiCallStatus.SUCCESS;

    useEffect(() => {
        if (isLoading()) { return; }

        actions.getSelectedPowertrainById(powertrainId || null, formattedPowertrains);

    }, [powertrainId, loadingSummary]);

    useEffect(() => {
        //if (isLoading()) { return; }

        if (powertrainId && assetId) {
            actions.getSelectedAssetFromPowertrainById(assetId);
        } else {
            actions.getSelectedAssetById(assetId || null);
        }
    }, [assetId, loadingSummary]);

    useEffect(() => {
        if (selectedFilters?.organizations?.length > 0) {
            actions.getAssetConditionIndicesRequest(formatConditionIndexOverviewRequest(
                1,
                selectedFilters,
                searchBoxFilter,
                conditionFilter,
                assetTypeFilter
            ));
        }
    }, [selectedFilters, searchBoxFilter, conditionFilter, assetTypeFilter]);

    const selectFirstAsset = (selectedPowertrain, powertrainId, assetId) => {
        if (selectedPowertrain?.assets?.length && powertrainId && powertrainId === selectedPowertrain?.powertrainId?.toString() && !assetId) {
            const newQuery = new URLSearchParams({ powertrainId: powertrainId, assetId: selectedPowertrain?.assets[0]?.id });
            history.push({ pathname: history?.location?.pathname, search: newQuery?.toString() });
        }
    };

    useEffect(() => {
        if (isLoading()) { return; }

        if (autoSelectFirstAsset) {
            selectFirstAsset(selectedPowertrain, powertrainId, assetId);
        }
    }, [selectedPowertrain, loadingSummary]);


    useEffect(() => {
        if (selectedAsset) {
            const assetIdParams2 = { assetIds: [selectedAsset ? selectedAsset?.id : assetId], type: null, statusCodes: [], maxEndDate: null };
            if (assetId) {
                actions.getApiAssetCapability({ id: selectedAsset?.id ? selectedAsset?.id : assetId }); // NEED TO CHECK BLANK PAGE ISSUE
                actions?.getAssetDetailsV2(selectedAsset?.id ? selectedAsset?.id : assetId);
            }
            if (isAssetDetailsNeeded) {
                actions.getSubscriptionInfo(assetIdParams2);
            }
        }
    }, [selectedAsset?.id]);

    return null;
};


AssetPowertrainSelectorComponent.propTypes = {
    model: PropTypes.shape({
        generalFilterLoadingStatus: PropTypes.array,
        powertrainsLoadingStatus: PropTypes.string,
        assetsLoadingStatus: PropTypes.string,
        contextLoadingStatus: PropTypes.string,
        formattedPowertrains: PropTypes.array,
        selectedPowertrain: PropTypes.object,
        selectedAsset: PropTypes.object,
        newSelectedAssetCapability: PropTypes.object,
        assets: PropTypes.array,
    }),
    actions: PropTypes.shape({
        getAssetDetails: PropTypes.func,
        getAssetDetailsV2: PropTypes.func,
        setSelectedPowertrain: PropTypes.func,
        setSelectedAsset: PropTypes.func,
        getNewCapability: PropTypes.func,
        getSelectedPowertrainById: PropTypes.func,
        getSelectedAssetFromPowertrainById: PropTypes.func,
        getSelectedAssetById: PropTypes.func,
        resetAssetAndPowerTrainSelection: PropTypes.func,
        getAssetCapability: PropTypes.func,
        getApiAssetCapability: PropTypes.func,
        getSubscriptionInfo: PropTypes.func,
        getAssetCapabilityV2: PropTypes.func
    }),
    isAssetDetailsNeeded: PropTypes.bool,
    autoSelectFirstAsset: PropTypes.bool
};


export default AssetPowertrainSelectorComponent;
