import { createSelector } from 'reselect';


const getPowertrainOverviewList = ({ conditionIndices }) => {
    return conditionIndices?.powertrains?.data;
};

const getPowertrainOverviewLoadingStatus = ({ conditionIndices }) => {
    return conditionIndices?.powertrains?.loadingStatus;
};

const getPageNumber = ({ conditionIndices }) => {
    return conditionIndices?.powertrains?.pageNumber;
};

const getHasMore = ({ conditionIndices }) => {
    return conditionIndices?.powertrains?.hasMore;
};
const getShowThreshold = ({ conditionIndices }) => {
    return conditionIndices?.powertrains?.showThreshold;
};
const getShowTrend = ({ conditionIndices }) => {
    return conditionIndices?.powertrains?.showTrend;
};

export const getModel = createSelector(
    [
        getPowertrainOverviewList,
        getPowertrainOverviewLoadingStatus,
        getPageNumber,
        getShowThreshold,
        getShowTrend,
        getHasMore
    ],
    (
        powertrainOverviewList,
        powertrainOverviewLoadingStatus,
        pageNumber,
        showThreshold,
        showTrend,
        hasMore
    ) => {
        return {
            powertrainOverviewList,
            powertrainOverviewLoadingStatus,
            pageNumber,
            showThreshold,
            showTrend,
            hasMore
        };
    }
);
