import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getModel } from './selectors';
import { useIsDirty, usePrevious } from 'sharedHooks';
import GeneralFilterEditComponent from './GeneralFilterEditComponent';
import * as actions from './actions';
import { isDifferentOrganizationSelection } from '../helpers';
import useLoadingSummary from 'sharedComponents/WithLoadingStatus/useLoadingSummary';

import './style.scss';
import { apiCallStatus } from 'helpers/constants';

const GeneralFilterEditContainer = (props) => {

	const {
		model: { selectedFilters: editedFilters, isAdmin, isCIAMSyncFlag },
		actions
	} = props;

	const {
		organizationsLoadingStatus,
		sitesLoadingStatus,
		assetGroupsLoadingStatus
	} = props.model.loadingData;
	const [isDirtyPage] = useIsDirty([editedFilters]);
	const prevSelectedOrgs = usePrevious(editedFilters.organizations);
	useEffect(() => {
		props.setIsDirtyPage(isDirtyPage);
	}, [isDirtyPage]);

	useLoadingSummary((summary) => {
		props.setIsLoading(!summary || summary === apiCallStatus.LOADING);
	}, [organizationsLoadingStatus, sitesLoadingStatus, assetGroupsLoadingStatus]);

	useEffect(() => {
		props.actions.populateStore();
		return () => {
			props.actions.clearStore();
		};
	}, []);

	// user changes organization selections
	useEffect(() => {
		if (editedFilters?.organizations?.length
			&& prevSelectedOrgs?.length
			&& isDifferentOrganizationSelection(prevSelectedOrgs, editedFilters.organizations)) {
			actions.getSitesAndAssetGroups(editedFilters.organizations);
			let refreshFlag = true;
			if (isAdmin) {
				if (isCIAMSyncFlag) { refreshFlag = true; }
				else { refreshFlag = false; }
			}
			actions.getAvailableAssets(editedFilters.organizations, refreshFlag);
		}
	}, [editedFilters.organizations]);
	return <GeneralFilterEditComponent {...props} />;
};

const mapStateToProps = (state) => {
	const model = getModel(state);
	return {
		model
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(GeneralFilterEditContainer);
