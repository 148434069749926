import { actionTypes, NOTIFICATION_PAGE_SIZE } from './constants';
import { getDataChunkForPage } from './helpers';

const initialState = {
    notificationList: [],
    allNotificationList: [],
    notificationLoadingStatus: false,
    currentPage: 1,
    hasMore: false,
    selectedNotification: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_NOTIFICATION_LIST:
            return {
                ...state,
                notificationLoadingStatus: true
            };
        case actionTypes.SET_NOTIFICATION_LIST: {
            const list = getDataChunkForPage(action.payload, initialState.currentPage, NOTIFICATION_PAGE_SIZE);
            const checkHasMore = list.length <= NOTIFICATION_PAGE_SIZE;
            return {
                ...state,
                notificationLoadingStatus: false,
                allNotificationList: [...action.payload],
                notificationList: list,
                hasMore: checkHasMore,
                currentPage: initialState.currentPage
            };
        }
        case actionTypes.LOAD_NOTIFICATION: {
            const nextPage = state.currentPage + 1;
            const list = getDataChunkForPage([...state.allNotificationList], nextPage, NOTIFICATION_PAGE_SIZE);
            const checkHasMore = list.length !== 0;
            return {
                ...state,
                notificationList: [...state.notificationList, ...list],
                currentPage: state.hasMore ? nextPage : state.currentPage,
                hasMore: checkHasMore
            };
        }
        case actionTypes.SET_SELECTED_NOTIFICATION:
            return {
                ...state,
                selectedNotification: action.payload
            };

        case actionTypes.READ_NOTIFICATION: {
            const newNotificationList = state.allNotificationList?.filter((n) => n.groupId !== action.groupId);
            const list = getDataChunkForPage(newNotificationList, state.currentPage, NOTIFICATION_PAGE_SIZE);
            const checkHasMore = list.length <= NOTIFICATION_PAGE_SIZE;
            return {
                ...state,
                notificationList: list,
                allNotificationList: newNotificationList,
                hasMore: checkHasMore,
            };
        }
        default:
            return state;
    }
}
