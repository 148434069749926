import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './style.scss';
import IconCopy from 'svgIcons/MotionPortalIcons/IconCopy';
import { GeneralDialog } from 'sharedComponents/GeneralDialog';
import { LoadingStatusWrapper } from 'sharedComponents/WithLoadingStatus';
import { apiCallStatus } from 'helpers/constants';
import CopyNotificationSettingsComponent from './CopyNotificationSettingsComponent';
import { cloneDeep } from 'lodash';

const CopyNotificationSettingsDialog = (props) => {
    const {
        showCopyNotification,
        setShowCopyNotification,
        organizationID,
        copyNotificationSettings,
        copySettingsDetails,
        organizationList
    } = props;
    const { t: translate } = useTranslation();
    const [selectedOrganization, setSelectedOrganization] = useState([]);
    const [copySettingOptions, setCopySettingOptions] = useState({
        organizationDigest: false,
        instantNotifications: false
    });

    useEffect(() => {
        if (copySettingsDetails?.loadingStatus === apiCallStatus.SUCCESS) {
            setShowCopyNotification(false);
        }
    }, [copySettingsDetails?.loadingStatus]);

    const handleApplyCopySettings = () => {
        const payload = {
            sourceOrganizationId: organizationID,
            destinationOrganizationIds: selectedOrganization,
            copyDigestSettings: copySettingOptions.organizationDigest,
            copyInstantSettings: copySettingOptions.instantNotifications
        };
        copyNotificationSettings(payload);
    };

    // Restrict current organization display in copy to organization list.
    const getOrganizationList = () => cloneDeep(organizationList)?.filter((org) => org.organizationID !== organizationID);

    return <GeneralDialog
        show={showCopyNotification}
        size={'lg'}
        close={() => setShowCopyNotification(false)}
        title={<>
            <IconCopy />
            <div>{translate('ABB.Powertrain.Frontend.notificationCopySettingsTitle')}</div>
        </>}
        closeButton={true}
        acceptButtonProps={{
            text: translate('ABB.Powertrain.Frontend.apply'),
            onClick: handleApplyCopySettings,
            disabled: !copySettingOptions?.organizationDigest && !copySettingOptions?.instantNotifications ||
                !selectedOrganization?.length || copySettingsDetails.loadingStatus === apiCallStatus.LOADING
        }}
        cancelButtonProps={{
            text: translate('ABB.Powertrain.Frontend.cancelLabel'),
            onClick: () => { setShowCopyNotification(false); }
        }}
        modalClassName={'copy-notification-settings-modal'}
    >
        <LoadingStatusWrapper
            loadingStatus={[
                copySettingsDetails.loadingStatus,
            ]}
            useCustomAnchor
            transparent
        >
            <CopyNotificationSettingsComponent
                {...props}
                selectedOrganization={selectedOrganization}
                setSelectedOrganization={setSelectedOrganization}
                copySettingOptions={copySettingOptions}
                setCopySettingOptions={setCopySettingOptions}
                organizationList={getOrganizationList()}
            />
        </LoadingStatusWrapper>
    </GeneralDialog>;
};

CopyNotificationSettingsDialog.propTypes = {
    organizationID: PropTypes.number,
    showCopyNotification: PropTypes.bool,
    setShowCopyNotification: PropTypes.func,
    copyNotificationSettings: PropTypes.func,
    copySettingsDetails: PropTypes.object,
};

export default CopyNotificationSettingsDialog;
