import { isNil, forEach } from 'lodash';
import { baseToCustomUOM, getMeasurementUnits, getMeasurementUnitSymbol } from 'helpers/unitHelper';
import { convertUtcToLocalDateTime } from 'helpers/dateHelper';
import { qualityFlagsTimeSeries } from 'helpers/constants';
import { setMaxPrecision } from 'helpers/formatters';
import { isWholeNumber, roundNumber } from 'helpers/numberHelper';

const convertCalibrationUoM = (
    measurementTypeUnitGroupID,
    measurementCalibration,
    measurementUnitGroups,
    convertFn
) => {
    if (!isNaN(parseFloat(measurementCalibration))) {
        const zeroRefValue = convertFn(measurementTypeUnitGroupID, 0, measurementUnitGroups);
        const convertedCalibrationValue = convertFn(measurementTypeUnitGroupID, measurementCalibration, measurementUnitGroups);

        return convertedCalibrationValue - zeroRefValue;
    }

    return measurementCalibration;
};
// eslint-disable-next-line import/no-unused-modules
export const handleGetMeasurementTypesConversion = (measurementTypeGroups) => {
    const userMeasurementUnitGroups = getMeasurementUnits();
    forEach(measurementTypeGroups, (measurementTypeGroupItem) => {
        forEach(measurementTypeGroupItem.timeseries, (measurementTypeItem) => {
            const valueObj = baseToCustomUOM.convertAndFormatWithGroups(
                measurementTypeItem.timeseriesUnitGroupID,
                measurementTypeItem.timeseriesLastValue,
                userMeasurementUnitGroups);
            if (measurementTypeItem.isCalibrationEnabled) {
                measurementTypeItem.measurementCalibration = setMaxPrecision(
                    convertCalibrationUoM(
                        measurementTypeItem.timeseriesUnitGroupID,
                        measurementTypeItem.measurementCalibration,
                        userMeasurementUnitGroups,
                        baseToCustomUOM.convertWithGroups),
                    isNil(measurementTypeItem.timeseriesPrecision) ? 4 : measurementTypeItem.timeseriesPrecision);
            }
            // LIMIT VALUE UNIT CONVERSION
            if (measurementTypeItem?.thresholdList && measurementTypeItem?.thresholdList?.length) {
                forEach(measurementTypeItem?.thresholdList, (limit) => {
                    const limitObj = baseToCustomUOM?.convertAndFormatWithGroups(
                        measurementTypeItem.timeseriesUnitGroupID,
                        limit?.limitValue,
                        userMeasurementUnitGroups);
                    limit.limitValue = limitObj?.measurementValue % 1 === 0 ? limitObj?.measurementValue : limitObj?.measurementValue?.toFixed(2);
                });
            }
            measurementTypeItem.timeseriesLastValue = valueObj.measurementValue;
            measurementTypeItem.unit = valueObj.measurementUnit;
        });
    });

    return measurementTypeGroups;
};

const convertAndFormatWithGroups = (timeseriesUnitGroupID, measurementValue, userMeasurementUnitGroups, timeseriesPrecision) => {
    const convertedValue = baseToCustomUOM?.convertAndFormatWithGroups(
                                        timeseriesUnitGroupID,
                                        measurementValue,
                                        userMeasurementUnitGroups);
    const formattedValue = isWholeNumber(convertedValue?.measurementValue) ? convertedValue?.measurementValue :
                        roundNumber(convertedValue?.measurementValue, timeseriesPrecision)
                            .toFixed(timeseriesPrecision);
    return formattedValue;
};

export const formatKPITrendResponse = (res) => {
    return res.map(trendData => {
        const unitGroupId = trendData.config && trendData.config.timeseriesUnitGroupID;
        trendData.config.unit = getMeasurementUnitSymbol(unitGroupId);
        trendData.values = baseToCustomUOM.convertMeasurementList(
            trendData.values,
            { value: unitGroupId, min: unitGroupId, max: unitGroupId },
            trendData.config.timeseriesPrecision
        );
        const userMeasurementUnitGroups = getMeasurementUnits();
        const minValueObj = convertAndFormatWithGroups(trendData?.config?.timeseriesUnitGroupID, trendData?.minValue, userMeasurementUnitGroups, trendData?.config?.timeseriesPrecision);
        const maxValueObj = convertAndFormatWithGroups(trendData?.config?.timeseriesUnitGroupID, trendData?.maxValue, userMeasurementUnitGroups, trendData?.config?.timeseriesPrecision);
        const avgValueObj = convertAndFormatWithGroups(trendData?.config?.timeseriesUnitGroupID, trendData?.avgValue, userMeasurementUnitGroups, trendData?.config?.timeseriesPrecision);

        trendData.minValue = minValueObj;
        trendData.maxValue = maxValueObj;
        trendData.avgValue = avgValueObj;

        trendData.values = trendData.values.map(v => {
            const metrics = v.quality === qualityFlagsTimeSeries.Success
                ? {
                    date: convertUtcToLocalDateTime(v.date),
                    max: v.max,
                    min: v.min,
                    value: v.value
                }
                : {
                    max: null,
                    min: null,
                    value: null
                };

            return {
                ...v,
                ...metrics
            };
        });

        return trendData;
    });
};
