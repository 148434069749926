import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { IconClose, IconRefresh } from 'svgIcons/MotionPortalIcons';

import { GeneralFilterEditComponent } from './GeneralFilterEdit';
import GeneralFilterViewComponent from './GeneralFilterViewComponent';
import { GeneralButton, InfoButtonWithOnlineHelp } from 'sharedComponents';

import useValidateFilterState from './useValidateFilterState';
import useLoadDefaultFilters from './useLoadDefaultFilters';

import './style.scss';
import useLoadingSummary from 'sharedComponents/WithLoadingStatus/useLoadingSummary';
import { apiCallStatus } from 'helpers/constants';
import { setBreadcrumbStorage } from 'helpers/breadcrumbHelper';

const GeneralFilterComponent = (props) => {

    const { t: translate } = useTranslation();
    const history = useHistory();
    const {
        organizationsLoadingStatus,
        sitesLoadingStatus,
        assetGroupsLoadingStatus,
        contextLoadingStatus
    } = props.model.loadingData;
    const isAdmin = props.model.isAdmin;

    const [isEditMode, setEditMode] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [isDirtyPage, setIsDirtyPage] = useState(false);

    useLoadingSummary((summary) => {
        setIsLoading(!summary || summary === apiCallStatus.LOADING);
    }, [organizationsLoadingStatus, sitesLoadingStatus, assetGroupsLoadingStatus, contextLoadingStatus]);

    useLoadDefaultFilters(props);
    useValidateFilterState(props);

    const onHide = () => {
        setEditMode(false);
        setIsLoading(false);
        props.onHide();
    };

    const onSave = (isFromSync = false) => {
        setEditMode(false);
        setIsDirtyPage(false);
        props.actions.clearStore();
        if (isAdmin && isFromSync) {
            props.actions?.saveFiltersWithSync();
        } else {
            props.actions.saveFilters();
        }
        props.onHide();
        history.push({
            pathname: history.location.pathname
        });
        setBreadcrumbStorage({});
    };

    const onRequestCIAMSync = () => {
        if (isEditMode) {
            onSave();
            props?.actions?.setCIAMSyncFlag(true);
        } else {
            props?.actions?.launchCIAMSyncForAdmin(
                props?.model?.selectedFilters?.organizations,
                true
            );
        }
        onHide();
    };
    const renderSyncButton = (isEdit) => {
        return (
            <div>
                <GeneralButton
                    type='normal'
                    icon={IconRefresh({ width: '14px', height: '14px' })}
                    text={isEdit ? translate('ABB.Powertrain.Frontend.applySyncFromCIAM') : translate('ABB.Powertrain.Frontend.syncFromCIAM')}
                    onClick={onRequestCIAMSync}
                />
                <InfoButtonWithOnlineHelp infoText={translate('ABB.Powertrain.Frontend.syncFromCIAMInfo')} withoutLink={true} />
            </div>
        );
    };

    return <Modal
        show={props.show}
        aria-labelledby='general-filter-modal-title'
        id='general-filter-modal'
        className='general-filter-modal-container'
        backdropClassName='general-filter-modal-backdrop'
        onHide={onHide}
        backdrop='static'
    >
        <Modal.Header>
            <div className='modal-title' id='general-filter-modal-title'>
                <span className='title-text'>{translate('ABB.Powertrain.Frontend.organizationFilter')}</span>
                <InfoButtonWithOnlineHelp infoText={translate('ABB.Powertrain.Frontend.generalFilterInfo')} />
            </div>
            <div className='general-filter-collapse-btn' onClick={onHide} onKeyDown={onHide} role='modal-hide-icon'>
                <IconClose color='black' width={18} height={18} />
            </div>
        </Modal.Header>

        <Modal.Body>

            {isEditMode ?
                <GeneralFilterEditComponent
                    selectedFilters={props.model.selectedFilters}
                    setIsLoading={setIsLoading}
                    setIsDirtyPage={setIsDirtyPage}
                /> :
                <GeneralFilterViewComponent
                    loadingData={props.model.loadingData}
                    selectedFilters={props.model.selectedFilters}
                    searchAsset={props.actions.searchAssetRequest}
                    onHide={onHide}
                    assets={props.model.assets}
                />
            }
        </Modal.Body>
        <Modal.Footer {...(isAdmin ? { className: 'footer_container' } : {})} >
            {isAdmin ? renderSyncButton(isEditMode) : null}
            {isEditMode ?
                <div>
                    <GeneralButton type='discreet' text={translate('ABB.Powertrain.Frontend.generalFilterCancelButton')} disabled={isLoading} onClick={() => setEditMode(false)} />
                    <GeneralButton type='primary' text={translate('ABB.Powertrain.Frontend.generalFilterApplyButton')} disabled={isLoading || !isDirtyPage} onClick={() => { onSave(false); }} />
                </div>
                : <div>
                    <GeneralButton type='discreet' text={translate('ABB.Powertrain.Frontend.generalFilterCloseButton')} onClick={onHide} />
                    <GeneralButton type='primary' text={translate('ABB.Powertrain.Frontend.generalFilterEditButton')} disabled={isLoading} onClick={() => setEditMode(true)} />
                </div>
            }
        </Modal.Footer>
    </Modal>;
};

GeneralFilterComponent.propTypes = {
    actions: PropTypes.shape({
        saveFilters: PropTypes.func
    }),
    model: PropTypes.shape({
        environment: PropTypes.any,
        loadingData: PropTypes.object,
        selectedFilters: PropTypes.object,
        user: PropTypes.shape({
            languageCode: PropTypes.any
        }),
        assets: PropTypes.array
    }),
    onHide: PropTypes.func,
    show: PropTypes.bool,
};

export default GeneralFilterComponent;
