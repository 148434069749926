import { actionTypes } from './constants';
import { getApiActionCreator } from 'helpers/actionHelper';


export const getNotificationSettingsAction = getApiActionCreator(actionTypes.GET_NOTIFICATION_SETTINGS);
export const getMutedAssetListAction = getApiActionCreator(actionTypes.GET_MUTED_ASSETS);
export const copyNotificationSettingsAction = getApiActionCreator(actionTypes.COPY_NOTIFICATION_SETTINGS);

export const getNotificationSettings = (organizationID) => ({
    type: actionTypes.LOAD_NOTIFICATION_SETTINGS_REQUEST,
    organizationID
});

export const saveNotificationSettings = (organizationID, notificationSettings) => ({
    type: actionTypes.SAVE_NOTIFICATION_SETTINGS_REQUEST,
    organizationID,
    notificationSettings
});

export const selectNotificationGroup = (selectedGroup) => ({
    type: actionTypes.SELECT_NOTIFICATION_GROUP,
    selectedGroup
});

export const selectMutedAsset = (assetId = null) => ({
    type: actionTypes.SELECT_MUTED_ASSET,
    assetId
});

export const deleteMutedAssets = (organizationID, assetIDs) => ({
    type: actionTypes.DELETE_MUTED_ASSETS_REQUEST,
    organizationID,
    assetIDs
});

export const clearStore = () => {
    return (dispatch) => {
        dispatch({ type: actionTypes.CLEAR_STORE });
    };
};

export const copyNotificationSettings = (payload) => ({
    type: actionTypes.COPY_NOTIFICATION_SETTINGS_REQUEST,
    payload
});
