export const actionTypes = {
    SET_ACTIVE_TAB: 'maintenanceOverview/SET_ACTIVE_TAB',
    SET_FILTERS: 'maintenanceOverview/SET_FILTERS',
    CLEAR_STORE: 'maintenanceOverview/CLEAR_STORE',
    GET_ASSETS: 'maintenanceOverview/GET_ASSETS',
    GET_POWERTRAINS: 'maintenanceOverview/GET_POWERTRAINS',
    GET_ASSETS_FOR_CBM: 'maintenanceOverview/GET_ASSETS',
    GET_POWERTRAINS_FOR_CBM: 'maintenanceOverview/GET_POWERTRAINS'
};

export const CLASSNAME_DUE = {
    OVER_DUE: 'overdue',
    DUE: 'due'
};
