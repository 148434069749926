import { createSelector } from 'reselect';

const getAssetInfo = ({ sensorProperties }) => {
	return sensorProperties.assetInfo;
};

const getSensorFeatures = ({ sensorProperties }) => {
	return sensorProperties.sensorFeatures;
};

const getSensorProperties = ({ sensorProperties }) => {
	return sensorProperties.sensorProperties;
};

const getSensorGatewayInfo = ({ sensorProperties }) => {
	return sensorProperties?.sensorGatewayInfo;
};

const getSensorTypeId = ({ sensorProperties }) => {
	return sensorProperties.sensorProperties.data.sensorTypeId;
};

const getGatewayInfo = ({ sensorProperties }) => {
	return sensorProperties.gatewayInfo;
};

const getAssetDetails = ({ detailedInformation }) => {
	return {
		loadingStatus: detailedInformation.assetDetails.loadingStatus,
		details: detailedInformation.assetDetails.data?.details
	};
};

export const getModel = createSelector([
	getAssetInfo,
	getSensorProperties,
	getSensorGatewayInfo,
	getSensorFeatures,
	getSensorTypeId,
	getGatewayInfo,
	getAssetDetails
], (
	assetInfo,
	sensorProperties,
	sensorGatewayInfo,
	sensorFeatures,
	sensorTypeId,
	gatewayInfo,
	assetDetails
) => {
	return {
		assetInfo,
		sensorProperties,
		sensorGatewayInfo,
		sensorFeatures,
		sensorTypeId,
		gatewayInfo,
		assetDetails
	};
});


