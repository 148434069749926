import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getModel } from './selectors';
import * as actions from './actions';
import PowertrainOverviewComponent from './PowertrainOverviewComponent';
import { userLoggedIn } from './helper';
import authHelper from 'helpers/authHelper';

import './style.scss';

const PowertrainOverviewContainer = (props) => {
    const organizationID = props?.model?.selectedGeneralFilter?.organizations[0]?.organizationID;
    const location = useLocation();

    useEffect(() => {
        props.actions.checkTokenExpiration();
    }, [location.pathname]);


    const redirectToLogin = () => {
        authHelper.redirectToLogin(location.pathname + location.search);
    };

    useEffect(() => {
        props.model.userManager?.getUser().then((userCredentials) => {
            if (userLoggedIn(userCredentials, location.pathname)) {
                props.actions.checkTokenExpiration().then(() => props.actions.getUser());
                props.actions.getUserCapabilities(organizationID);
            } else {
                redirectToLogin();
            }
        });
    }, [organizationID]);

    return props.model.user &&
        props.model.userCapabilitiesLoadingStatus &&
        <PowertrainOverviewComponent {...props} />;
};

PowertrainOverviewContainer.propTypes = {
    actions: PropTypes.shape({
        checkTokenExpiration: PropTypes.func,
        getUser: PropTypes.func,
        getUserCapabilities: PropTypes.func
    }),
    model: PropTypes.shape({
        userManager: PropTypes.object,
        user: PropTypes.object,
        userCapabilitiesLoadingStatus: PropTypes.string,
        selectedGeneralFilter: PropTypes.object
    })
};

const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
};

const PowertrainOverviewContainerConn = connect(
    mapStateToProps,
    mapDispatchToProps
)(PowertrainOverviewContainer);

export default PowertrainOverviewContainerConn;
