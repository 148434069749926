import { createSelector } from 'reselect';

import { formatParameterFileList } from './helpers';


const getChangedParameterFileListV2 = ({ changedParametersV2 }) => {
	return changedParametersV2 && {
		...changedParametersV2.getChangedParameterFileListV2,
		data: formatParameterFileList(changedParametersV2.changedParameterFileListV2.data)
	};
};

const getSelectedDrive = ({ powertrainAssetDetails }) => {
	return powertrainAssetDetails.selectedAsset;
};

const getChangedParametersListLoading = ({ changedParametersV2 }) => {
	return changedParametersV2 && changedParametersV2.changedParameterFileListV2 && changedParametersV2.changedParameterFileListV2.loadingStatus;
};

export const getModel = createSelector([
	getChangedParameterFileListV2,
	getSelectedDrive,
	getChangedParametersListLoading
],
	(
		changedParameterFileList,
		selectedDrive,
		changedParametersListLoading
	) => {
		return {
			changedParameterFileList,
			selectedDrive,
			changedParametersListLoading
		};
	});
