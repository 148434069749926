import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import { NotificationChannels } from './NotificationChannels';
import { MutedAssets } from './MutedAssets';
import { notificationSettingsTabs } from './constants';
import { getTabOptions } from './helpers';
import './style.scss';
import IconCopy from 'svgIcons/MotionPortalIcons/IconCopy';
import useUserCapability from 'sharedHooks/useUserCapability';


const NotificationSettingsComponent = (props) => {
    const { t: translate } = useTranslation();
    const [selectedTab, setSelectedTab] = useState(notificationSettingsTabs.NOTIFICATION_CHANNELS);
    const [showCopyNotification, setShowCopyNotification] = useState(false);
    const userCapabilities = useUserCapability();

    const {
        notificationSettings,
        organization,
        organizationList,
        copySettingsDetails
    } = props.model;

    const {
        saveNotificationSettings,
        selectNotificationGroup,
        deleteMutedAssets,
        copyNotificationSettings,
    } = props.actions;

    const tabOptions = getTabOptions();

    const renderSelectedTabContent = () => {
        const notificationChannelsComponent = <NotificationChannels
            notificationSettings={notificationSettings}
            saveNotificationSettings={saveNotificationSettings}
            selectNotificationGroup={selectNotificationGroup}
            organizationID={organization?.organizationID}
            showCopyNotification={showCopyNotification}
            setShowCopyNotification={setShowCopyNotification}
            organizationList={organizationList}
            copyNotificationSettings={copyNotificationSettings}
            copySettingsDetails={copySettingsDetails}
        />;
        switch (selectedTab) {
            case notificationSettingsTabs.NOTIFICATION_CHANNELS: {
                return notificationChannelsComponent;
            }
            case notificationSettingsTabs.MUTED_ASSETS: {
                return <MutedAssets
                    organization={organization}
                    deleteMutedAssets={deleteMutedAssets}
                    notificationSettings={notificationSettings}
                />;
            }
            default: return notificationChannelsComponent;
        }
    };

    return <div className='user-profile-settings-content notification-settings'>
        <div className='user-profile-settings-content-header'>
            <div>{`${translate('ABB.Powertrain.Frontend.userProfileNotificationSettings')} - ${organization?.name || ''}`}</div>
            {selectedTab === notificationSettingsTabs.NOTIFICATION_CHANNELS && userCapabilities?.hasCopyOrganizationNotificationSettings() &&
                <div className='copy-notification-settings' onClick={() => setShowCopyNotification(true)}>
                    <IconCopy />
                    <span className='copy-settings-label'>{translate('ABB.Powertrain.Frontend.notificationCopySettings')}</span>
                </div>
            }
        </div>
        <div>

            <div className='notification-settings-tabs'>
                {
                    map(tabOptions, (opt) => {
                        return <button
                            key={opt.value}
                            id={notificationSettingsTabs[opt.value]}
                            className={`notification-settings-tab ${opt.value === selectedTab ? 'selected' : ''}`}
                            onClick={() => setSelectedTab(opt.value)}
                        >
                            {opt.label}
                        </button>;
                    })
                }
            </div>
            {renderSelectedTabContent()}
        </div>
    </div >;
};

NotificationSettingsComponent.propTypes = {
    model: PropTypes.shape({
        notificationSettings: PropTypes.object,
        organization: PropTypes.object
    }),
    actions: PropTypes.shape({
        saveNotificationSettings: PropTypes.func,
        selectNotificationGroup: PropTypes.func,
        deleteMutedAssets: PropTypes.func
    })
};

export default NotificationSettingsComponent;
