import { getApiActionCreator } from 'helpers/actionHelper';
import { actionTypes } from './constants';
import { ComponentApi } from 'api/index';
import { apiCallStatus } from 'helpers/constants';

export const getNotificationListAction = () => {
    return {
        type: actionTypes.GET_NOTIFICATION_LIST
    };
};

export const setNotificationListAction = (payload) => {
    return {
        type: actionTypes.SET_NOTIFICATION_LIST,
        payload
    };
};

export const readNotificationListAction = (groupId) => {
    return {
        type: actionTypes.READ_NOTIFICATION,
        groupId
    };
};

export const loadNotificationListAction = () => {
    return {
        type: actionTypes.LOAD_NOTIFICATION
    };
};

export const setSelectedNotification = (payload) => {
    return {
        type: actionTypes.SET_SELECTED_NOTIFICATION,
        payload
    };
};

const getAssetDetailsAction = getApiActionCreator(actionTypes.GET_ASSET_DETAILS);

export const getAssetDetails = (asset) => {
    return (dispatch) => {
        dispatch(getAssetDetailsAction(apiCallStatus.LOADING));
        ComponentApi.getAssetDetailsV2(asset)
            .then(details => {
                dispatch(getAssetDetailsAction(apiCallStatus.SUCCESS, { ...details, details: { ...details?.details } }));
            }, (err) => {
                dispatch(getAssetDetailsAction(apiCallStatus.ERROR, null, err));
            });
    };
};
