import endpoints, { formatEndpoint } from '../endpoints';
import { RestManager, RestManagerWithoutBaseUrl } from '../RestManager';
import { assetObjectToComponentRequest } from './../helpers';

class ParameterFiles {
	static getParameterFileList(asset, from, to) {
		const endpoint = formatEndpoint(endpoints.parameterFile.getList, { from, to });
		return RestManager.post(endpoint, assetObjectToComponentRequest(asset));
	}

	static getParameterFileListV2(assetId, timeStampFrom, timeStampTo) {
		const endpoint = formatEndpoint(endpoints.parameterFile.getListV2, { assetId });
		return RestManagerWithoutBaseUrl.post(endpoint, {timeStampFrom, timeStampTo});
	}

	static getParameterFile(asset, id) {
		const endpoint = formatEndpoint(endpoints.parameterFile.download, { id });
		return RestManager.post(endpoint, assetObjectToComponentRequest(asset));
	}

	static getParameterFileV2(fileId) {
		const endpoint = formatEndpoint(endpoints.parameterFile.downloadV2, { fileId });
		return RestManagerWithoutBaseUrl.get(endpoint);
	}

	static getChangedParameterFileList(asset, from, to) {
		const endpoint = formatEndpoint(endpoints.parameterFile.changedParameters, { from, to });
		return RestManager.post(endpoint, assetObjectToComponentRequest(asset));
	}

	static getChangedParameterFileListV2(assetId, from, to) {
		const endpoint = formatEndpoint(endpoints.parameterFile.changedParametersV2, { assetId, from, to });
		return RestManagerWithoutBaseUrl.get(endpoint);
	}
}

export default ParameterFiles;
