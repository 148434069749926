import { labelBounds } from './constants';
import colors from 'theme/_colors.scss';
import { getTranslatedKPIName } from 'helpers/translateHelper';


const getLabel = (labelItem) => {
    let label = '';
    switch (labelItem.visualize) {
        case labelBounds.Both:
            label = `${labelItem.start} ... ${labelItem.end}`;
            break;
        case labelBounds.LowerThanUpper:
            label = `< ${labelItem.end}`;
            break;
        case labelBounds.GreaterThanLower:
            label = `> ${labelItem.start}`;
            break;
        default:
            label = `${labelItem.start} ... ${labelItem.end}`;
            break;
    }
    return label;
};

const addCategory = (categories, newValue) => {
    const c = categories.find(c => c.label === newValue.label);
    if (c) {
        return categories;
    } else {
        categories.push(newValue);
    }
    return categories.sort((a, b) => a.start - b.start);
};

export const convertHistorgramData = (chartData) => {
    let name = '';
    let xUnit = null;
    let yUnit = null;
    const components = [];
    let categories = [];
    let maxValue = 0;
    if (chartData && chartData.length && chartData.length > 0) {
        name = getTranslatedKPIName(chartData[0].kpi);

        chartData.map(cd => {
            if (cd.data && cd.data.length) {
                if (cd.data[0]?.values?.length) {
                    xUnit = cd.data[0].config.xUnit;
                    yUnit = cd.data[0].config.yUnit;
                }
                if (cd.data[0].values) {
                    const bins = cd.data[0].values;
                    bins.forEach((bin) => {
                        bin.label = getLabel(bin);
                        addCategory(categories, bin);
                        maxValue = maxValue < bin.value ? bin.value : maxValue;
                    });
                }
            }
        });
        categories = categories.map(c => c.label);

        chartData.map((cd, index) => {
            const data = [];
            if (cd.data && cd.data.length && cd.data[0] && cd.data[0].values) {
                const values = cd.data[0].values;
                if (values.length) {
                    categories.forEach(c => {
                        const d = values.find(v => v.label === c);
                        data.push(d ? d.value : null);
                    });
                }
                components.push({
                    id: index,
                    component: cd?.component?.name || '',
                    data,
                    color: cd.color || colors.classicRose,
                    name,
                    type: 'column',
                    xAxis: 'xAxis',
                    yAxis: 'yAxis'
                });
            }
        });
    }

    return { xUnit, yUnit, components, categories, maxValue };
};
