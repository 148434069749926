import { takeLatest, call, put } from 'redux-saga/effects';
import { ConditionIndicesApi } from 'api';
import { apiCallStatus } from 'helpers/constants';
import { actionTypes } from './constants';
import {
    getAssetKPITrendChartListAction,
} from './actions';
import { getConditionStatus, getDateRange } from './helpers';
import { setAssetConditionInsightStatus } from '../actions';

function* getAssetConditionInsightsSaga({ assetID, startDate, endDate }) {
    yield put(getAssetKPITrendChartListAction(apiCallStatus.LOADING));
    try {
        const response = yield call(
            ConditionIndicesApi.getAssetConditionInsights,
            assetID, startDate, endDate
        );
        yield put(getAssetKPITrendChartListAction(apiCallStatus.SUCCESS, {
            assetID: response?.assetId,
            assetConditionStatus: response?.currentOverallCondition,
            trendChartKPIS: response?.KPIs

        }));
        yield put(setAssetConditionInsightStatus({
            isConditionInsightAvailable: true,
            conditionStatus: getConditionStatus(response?.currentOverallCondition),
            assetId: response?.assetId
        }));
    } catch (err) {
        yield put(getAssetKPITrendChartListAction(apiCallStatus.ERROR, [], err));
    }
}

function* zoomInToSelectedTrendKpiDataSaga({ assetID, startDate, endDate }) {
    yield put({
        type: actionTypes.ADD_SELECTED_TREND_KPI_DATA,
        data: {
            isZoomed: true,
            from: startDate,
            to: endDate
        }
    });
    yield call(getAssetConditionInsightsSaga, {
        assetID,
        startDate,
        endDate
    });
}

function* reloadConditionInsightsSaga({ assetID }) {
    try {
        const dateRange = getDateRange();
        const { from, to } = dateRange;

        yield put(getAssetKPITrendChartListAction(apiCallStatus.LOADING));

        yield call(getAssetConditionInsightsSaga, {
            assetID,
            startDate: from,
            endDate: to
        });

        yield put({
            type: actionTypes.RELOAD_CONDITION_INSIGHTS_SUCCESS,
            data: {
                isZoomed: false,
                from,
                to
            }
        });
    } catch (err) {
        yield put(getAssetKPITrendChartListAction(apiCallStatus.ERROR, [], err));
    }
}

function* setConditionInsightsZoomSaga({ isZoomed }) {
    try {
        yield put({
            type: actionTypes.SET_CONDITION_INSIGHTS_ZOOM_SUCCESS,
            data: {
                isZoomed
            }
        });
    } catch (err) {
        yield put(getAssetKPITrendChartListAction(apiCallStatus.ERROR, [], err));
    }
}

export function* assetConditionalInsightsWatchers() {
    yield takeLatest(actionTypes.LOAD_ASSET_KPI_TREND_LIST, getAssetConditionInsightsSaga);
    yield takeLatest(actionTypes.ZOOM_IN_TO_SELECTED_TREND_KPI_DATA, zoomInToSelectedTrendKpiDataSaga);
    yield takeLatest(actionTypes.RELOAD_CONDITION_INSIGHTS, reloadConditionInsightsSaga);
    yield takeLatest(actionTypes.SET_CONDITION_INSIGHTS_ZOOM, setConditionInsightsZoomSaga);
}
