import moment from 'moment';

// Detect user's 12 or 24 hours timeformat
export const detectTimeFormat = () => {
    return new Intl.DateTimeFormat(navigator.language, {
        hour: 'numeric',
        hour12: false,
    }).resolvedOptions().hour12;
};

export const dayOfWeek = {
    Mon: 'Monday',
    Tue: 'Tuesday',
    Wed: 'Wednesday',
    Thu: 'Thursday',
    Fri: 'Friday',
    Sat: 'Saturday',
    Sun: 'Sunday'
};

// Create date object for UTC and local
const createDateObject = (timeString, dayOfWeek, asUTC) => {
    const [hours, minutes, seconds] = timeString.split(':');
    if (asUTC) {
        return moment.utc().set({ hour: hours, minute: minutes, second: seconds, day: dayOfWeek });
    }
    return moment().set({ hour: hours, minute: minutes, second: seconds, day: dayOfWeek });
};

// Convert time string to both UTC and Local.
export const convertTimeString = (timeString, dayOfWeek, asUTC = true) => {
    const dateObject = createDateObject(timeString, dayOfWeek, asUTC);
    let convertedTime = '';
    if (asUTC) {
        convertedTime = dateObject.local().format('HH:mm:ss dddd');
    } else {
        convertedTime = dateObject.utc().format('HH:mm:ss dddd');
    }
    return convertedTime.split(' ');
};

// Calculate duration between start time and end time
export const calculateDuration = (localStartTime, localStartDayOfWeek, localEndTime, localEndDayOfWeek) => {
    const startTimeObj = createDateObject(localStartTime, localStartDayOfWeek, false);
    const endTimeObj = createDateObject(localEndTime, localEndDayOfWeek, false);
    // Week start from Sunday.So, added 7 day for Sunday end time.
    if (startTimeObj.day() !==0 && endTimeObj.day() === 0) {
        endTimeObj.add(7, 'days');
    }
    const duration = moment.duration(endTimeObj.diff(startTimeObj));
    return parseInt(duration.asHours()).toString().padStart(2, 0) + ':' + (parseInt(duration.asMinutes()) % 60).toString().padStart(2, 0);
};

// Convert duration to UTC endTime
export const convertDurationToTime = (startTime, startDayOfWeek, endTime) => {
    const [hours, minutes] = endTime.split(':');
    const startTimeObj = createDateObject(startTime, startDayOfWeek, true);
    const getEndTime =  startTimeObj.add(parseInt(hours), 'hours').add(parseInt(minutes), 'minutes');
    return getEndTime.format('HH:mm:ss dddd').split(' ');
};

// Convert time format based on user's device.
export const convertUserTimeFormat = (dateTime) => {
    if (dateTime) {
        const is12HoursFormat = detectTimeFormat();
        let hours = parseInt(dateTime.substr(0, 2));
        if (is12HoursFormat && hours >= 12) {
            hours = hours - 12;
        }
        return (hours < 10 ? '0' : '') + hours + dateTime.substr(2, 3);
    }
    return;
};

export const get24TimeFormat = (dateTime, period) => {
    if (dateTime) {
        const is12HoursFormat = detectTimeFormat();
        let hours = parseInt(dateTime.substr(0, 2));
        if (is12HoursFormat && period.toLowerCase() === 'pm') {
            hours = hours + 12;
        }
        return (hours < 10 ? '0' : '') + hours + dateTime.substr(2, 3) + ':' + '00';
    }
    return;
};

export const getDayPeriod = (dateTime) => {
    let resultFormat = 'AM';
    if (dateTime) {
        const dataArray = dateTime.split(':');
        const hours = parseInt(dataArray[0]);
        if (hours >= 12) {
            resultFormat = 'PM';
        }
    }
    return resultFormat;
};

// Reset to default time UTC Sun 00:00 2h
export const defaultMaintenanceData = () => {
    const [startTime, startDayOfWeek] = convertTimeString('00:00:00', 'Sunday');
    const [endTime, endDayOfWeek] = convertTimeString('02:00:00', 'Sunday');
    return {
        maintenanceType: 'FOTA',
        startDayOfWeek: startDayOfWeek,
        endDayOfWeek: endDayOfWeek,
        startTime: convertUserTimeFormat(startTime),
        startPeriod: getDayPeriod(startTime),
        endTime: calculateDuration(startTime, startDayOfWeek, endTime, endDayOfWeek),
    };
};
