import React from 'react';
import { IconAssetOnline, IconAssetOffline } from 'svgIcons/MotionPortalIcons';
import './style.scss';


const assetOnlineStatus = {
    ONLINE: 'Online',
    OFFLINE: 'Offline'
};

export const formatSensorGatewayInfo = (sensorGatewayInfo, translate) => {

    const sensorDetails = [
        {
            label: translate('ABB.Powertrain.Frontend.GatewayUniqueId'),
            value: sensorGatewayInfo?.uniqueId ? sensorGatewayInfo?.uniqueId : '-'
        },
        {
            label: translate('ABB.Powertrain.Frontend.GatewayManfacturer'),
            value: sensorGatewayInfo?.manufacturer ? sensorGatewayInfo?.manufacturer : '-'
        },
        {
            label: translate('ABB.Powertrain.Frontend.GatewayDataConnectionStatus'),
            value: sensorGatewayInfo?.dataConnectionStatus === assetOnlineStatus.ONLINE ? <div className='gateway_asset_icon gateway_asset_icon_online'><IconAssetOnline width={18} height={18} /></div> : <div className='gateway_asset_icon gateway_asset_icon_offline'><IconAssetOffline width={18} height={18} /></div>
        },
    ];
    return sensorDetails;
};
