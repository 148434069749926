import { takeLatest, call, put } from 'redux-saga/effects';
import { GatewayManagementApi } from 'api';
import { apiCallStatus } from 'helpers/constants';
import logger from 'helpers/logger';
import { actionTypes } from './constants';
import { getGatewayInfoAction, getGatewayConnectedAssetsAction } from './actions';

function* getGatewayInfo(action) {
    const gatewayID = action.payload?.gatewayID;
    yield put(getGatewayInfoAction(apiCallStatus.LOADING));
    try {
        const response = yield call(GatewayManagementApi.getGatewayDetails, gatewayID);
        yield put(getGatewayInfoAction(apiCallStatus.SUCCESS, response));
        logger.debug('Gateway Details successfully loaded for page ');
    } catch (err) {
        yield put(getGatewayInfoAction(apiCallStatus.ERROR, [], err));
    }
}

function* getGatewayConnectedAssets(action) {
    const gatewayID = action.payload?.gatewayID;
    yield put(getGatewayConnectedAssetsAction(apiCallStatus.LOADING));
    try {
        const response = yield call(GatewayManagementApi.getGatewayConnectAssets, gatewayID);
        yield put(getGatewayConnectedAssetsAction(apiCallStatus.SUCCESS, response));
        logger.debug('Gateway Details successfully loaded for page ');
    } catch (err) {
        yield put(getGatewayConnectedAssetsAction(apiCallStatus.ERROR, [], err));
    }
}

// eslint-disable-next-line import/no-unused-modules
export function* GatewayManagementInfoWatchers() {
    yield takeLatest(actionTypes.GET_GATEWAY_INFO, getGatewayInfo);
}

// eslint-disable-next-line import/no-unused-modules
export function* GatewayManagementConnectedAssetsWatchers() {
    yield takeLatest(actionTypes.GET_GATEWAY_CONNECTED_ASSETS, getGatewayConnectedAssets);
}
