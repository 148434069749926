import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import { getLoggerTableColumnConfig } from './helpers';

import { GeneralTable, LoadingStatusWrapper } from 'sharedComponents';
import { DATA_LOGGER } from './constants';

import './style.scss';

const LoggerDownloadComponent = ({ model, actions }) => {
    const { t: translate } = useTranslation();
    const { loggerId, loggers } = model;
    const { getLoggers } = actions;
    const selectedEvent = useSelector(({ eventLog }) => {
        return eventLog?.selectedEvent;
    });

    useEffect(() => {
        if (loggerId && loggerId.length) {
            const isDataLogger = loggerId.some(logger => logger['type'] === DATA_LOGGER);
            if (isDataLogger) {
                getLoggers(loggerId);
            }
        }
    }, [loggerId, getLoggers]);

    const hasLoggers = selectedEvent.info.hasDataLoggers || selectedEvent.info.hasBinaryLogger;

    const tableData = loggers?.length ? loggers : loggerId?.length && hasLoggers ? loggerId : [];

    return <Container fluid className='logger-download-container'>
       <LoadingStatusWrapper loadingStatus={[model?.loggersLoadingStatus, model?.loggerFileLoadingStatus]}>
            <GeneralTable
                scroll={{ x: 720, y: null }}
                tableKey='id'
                columns={getLoggerTableColumnConfig(translate, actions?.downloadLogger, tableData, model?.dateKind, ...loggerId)}
                data={tableData}
                emptyText={translate('ABB.Powertrain.Frontend.noInformationAvailableLabel')}
                paginationProps={{ pageSize: 12 }}
            />
        </LoadingStatusWrapper>
    </Container>;
};

LoggerDownloadComponent.propTypes = {
    selectedAsset: PropTypes.object.isRequired,
    model: PropTypes.shape({
        loggers: PropTypes.array,
        dateKind: PropTypes.number,
        loggersLoadingStatus: PropTypes.string,
        loggerFileLoadingStatus: PropTypes.string,
    }),
    actions: PropTypes.shape({
        downloadLogger: PropTypes.func.isRequired
    }),

};

export default LoggerDownloadComponent;
