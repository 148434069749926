import { call, put, takeLatest } from 'redux-saga/effects';
import { sortBy } from 'lodash';
import moment from 'moment';
import { getApiActionCreator } from 'helpers/actionHelper';
import { apiCallStatus } from 'helpers/constants';
import logger from 'helpers/logger';
import { downloadFile, b64toBlob } from 'helpers/download-file-util';
import { ParameterFilesApi } from 'api/index';

import { actionTypes } from './constants';

const getParameterFileList = getApiActionCreator(actionTypes.GET_PARAMETER_FILE_LIST);
const getParameterFile = getApiActionCreator(actionTypes.GET_PARAMETER_FILE);

function* getParameterFileListForAssetSaga({ asset, timeStampFrom, timeStampTo }) {
    try {
        if (!asset || !timeStampFrom || !timeStampTo) {
            yield put(getParameterFileList(apiCallStatus.SUCCESS, []));
        } else {
            // Let the UI know that data fetching is ongoing
            yield put(getParameterFileList(apiCallStatus.LOADING));

            const response = yield call(ParameterFilesApi.getParameterFileListV2, asset, timeStampFrom, timeStampTo);
            logger.debug('Parameter file list loaded successfully with following params: v2', asset);
            if (response) {
                let data = response ? response : [];
                data = sortBy(data, paramterFile => new moment(paramterFile.timestamp)).reverse();
                yield put(getParameterFileList(apiCallStatus.SUCCESS, data));
            }
        }
    } catch (err) {
        yield put(getParameterFileList(apiCallStatus.ERROR, [], err));
    }
}

function* getParameterFileWithIdSaga({ asset, id, messages }) {
    try {
        if (!asset || !id) {
            yield put(getParameterFile(apiCallStatus.SUCCESS, []));
        } else {
            // Let the UI know that data fetching is ongoing
            yield put(getParameterFile(apiCallStatus.LOADING));

            const response = yield call(ParameterFilesApi.getParameterFileV2, id);
            logger.debug('Parameter file loaded successfully with following params: v2', asset);
            if (response && response.content) {
                yield put(getParameterFile(apiCallStatus.SUCCESS, response));
                const fileBlob = b64toBlob(response.content);
                downloadFile(response.filename, fileBlob);
            }
            else {
                throw new Error(messages.error);
            }
        }
    } catch (err) {
        yield put(getParameterFile(apiCallStatus.ERROR, [], err));
    }
}


export function* parameterFileRequestWatchers() {
    yield takeLatest(actionTypes.GET_PARAMETER_FILE_LIST_REQUEST, getParameterFileListForAssetSaga);
    yield takeLatest(actionTypes.GET_PARAMETER_FILE_REQUEST, getParameterFileWithIdSaga);
}
