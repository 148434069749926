import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes';
import { filter } from 'lodash';
import Tippy from '@tippy.js/react';
import CapabilityHelper from 'helpers/newCapabilityHelper';
import { getTimeSinceDateString } from 'helpers/dateHelper';

import { GeneralButton } from 'sharedComponents/GeneralButton';
import { IconCondition, IconAssetOnline, IconAssetOffline } from 'svgIcons/MotionPortalIcons';
import { LoadingStatusWrapper, ScrollableList } from 'sharedComponents';
import { newConditionIndices } from 'helpers/constants';
import { getAssetIcon } from 'helpers/icons';

import { assetCondition, MAX_WORDS, MAX_TAGS, assetOnlineStatus } from './constants';
import './style.scss';

const AssetOverviewDetailsComponent = ({ actions, selectedAsset, model, data, selectedPowertrain, selectedAssetGroup, asset }) => {
    const history = useHistory();
    const { t: translate } = useTranslation();
    const { allAssets, newSelectedAssetCapability, productTypeCode, assetLabels, assetOnline, connectivityType, subscriptionLoadingStatus, lastStatusChangeRecievedTimestamp, assetDetailsLabelsLoadingStatus } = model;
    const subscriptionType = model?.subscriptionStatus?.[0]?.subscriptionInfos?.[0]?.type ?? '-';
    const { assetId, assetSerialNumber, assetName, assetType, conditionIndex, condition, limits } = selectedAsset;
    /*const filteredAsset = filter(allAssets, (asset) => {
        return asset.id === selectedAsset.assetId;
    });*/
    const newCapabilityHelper = new CapabilityHelper(newSelectedAssetCapability, { id: selectedAsset?.assetId });

    const tagOptions = assetLabels?.slice(0, MAX_TAGS);
    const maxWords = MAX_WORDS;
    const tagContent = tagOptions?.map((tag) => {
        if (tag.tagValue.length <= maxWords) {
            return <li className='asset-overview__tag' key={tag.tagId}>{tag.tagValue}</li>;
        } else {
            return <li className='asset-overview__tag' key={tag.tagId}>
                <Tippy
                    content={tag.tagValue}
                    placement='bottom-end'
                    theme='light-border'>
                    <span>{tag.tagValue.slice(0, maxWords) + '...'}</span>
                </Tippy>
            </li>;
        }
    });

    const filteredPowertrain = filter(data, (powertrain) => {
        return selectedPowertrain === powertrain.powertrainId;
    });

    const filteredAssetGroup = filter(data, (assetGroup) => {
        return selectedAssetGroup === assetGroup.assetGroupId;
    });

    useEffect(() => {
        const assetIdParams = { assetIds: [assetId], type: null, statusCodes: [], maxEndDate: null };
        actions.getSubscriptionInfo(assetIdParams);
        actions.getApiAssetCapability({ id: assetId });
        actions.getAssetOverview(assetId);
        actions.getAssetLabels(assetId);
    }, [assetId]);

    const handleAssetNavigation = () => {
        //const assetId = filteredAsset[0]?.componentID;
        const assetId = selectedAsset.assetId;
        // console.log('The Component ID From is: ', assetId);
        // console.log('The OverAll Selected Asset From is: ', selectedAsset);
        // history.push({ pathname: routes.DetailedInformation, search: new URLSearchParams({ assetId }).toString() });
        const checkOpCapability = newCapabilityHelper.hasOpCapability();
        let routeData;
        if (selectedPowertrain && filteredPowertrain.length) {
            routeData = filteredPowertrain;
            history.push({
                pathname: checkOpCapability ? routes.OperationalParametersV2 : routes.DetailedInformation,
                search: new URLSearchParams({ assetId }).toString(),
                state: {
                    routeName: translate('ABB.Powertrain.Frontend.ptConditionMonitoringPowertrainsAssetsOverviewTab'),
                    routeData: routeData
                }
            });
        }
        if (selectedAssetGroup && filteredAssetGroup.length) {
            routeData = filteredAssetGroup;
            history.push({
                pathname: checkOpCapability ? routes.OperationalParametersV2 : routes.DetailedInformation,
                search: new URLSearchParams({ assetId }).toString(),
                state: {
                    routeName: translate('ABB.Powertrain.Frontend.ptConditionMonitoringPowertrainsAssetsOverviewTab'),
                    routeData: routeData
                }
            });
        }
        if (asset) {
            const assets = allAssets.map((asset) => {
                return {
                    ...asset,
                    assetName: asset?.componentName,
                    assetSerialNumber: asset?.serialNumber,

                };
            });
            routeData = { asset, assets };
            history.push({
                pathname: checkOpCapability ? routes.OperationalParametersV2 : routes.DetailedInformation,
                search: new URLSearchParams({ assetId }).toString(),
                state: {
                    routeName: translate('ABB.Powertrain.Frontend.ptConditionMonitoringPowertrainsAssetsOverviewTab'),
                    routeData: routeData
                }
            });
        }

    };

    const renderHeader = () => {
        return <div className='asset-overview-params-header'>
            <div className='header-item'>
                {translate('ABB.Powertrain.Frontend.assetOverviewKPILabel')}
            </div>
            <div className='header-item'>
                {translate('ABB.Powertrain.Frontend.assetOverviewDescriptionLabel')}
            </div>
        </div>;
    };

    const getConditionDescription = (condition) => {
        switch (condition) {
            case assetCondition.CONDITION_ALARM:
                return `${translate('ABB.Powertrain.Frontend.assetOverviewAlarmLabel')}: ${translate('ABB.Powertrain.Frontend.assetOverviewLimitCrossingLabel')}`;
            case assetCondition.CONDITION_POOR:
                return `${translate('ABB.Powertrain.Frontend.assetOverviewAlarmLabel')}: ${translate('ABB.Powertrain.Frontend.assetOverviewThresholdCrossingLabel')}`;
            case assetCondition.CONDITION_ALERT:
                return `${translate('ABB.Powertrain.Frontend.assetOverviewAlertLabel')}: ${translate('ABB.Powertrain.Frontend.assetOverviewLimitCrossingLabel')}`;
            case assetCondition.CONDITION_TOLERABLE:
                return `${translate('ABB.Powertrain.Frontend.assetOverviewAlertLabel')}: ${translate('ABB.Powertrain.Frontend.assetOverviewThresholdCrossingLabel')}`;
            default:
                return '';
        }
    };

    const renderGoodCondition = () => {
        return (
            <div className='render-good__condition'>
                <IconGoodCondition />
                <p>{translate('ABB.Powertrain.Frontend.assetOverviewAssetAreGoodCondition')}</p>
            </div>
        );
    };

    const renderNoConditionIndices = () => {
        return (
            <div className='render-no__condition-indices'>
                <IconGoodCondition />
                <p>{translate('ABB.Powertrain.Frontend.assetOverviewNoAlarmAlertConditionIndices')}</p>
            </div>
        );
    };

    const renderNoAssetLimits = () => {
        return (
            <div className='render-no__asset-limits'>
                <IconGoodCondition />
                <p>{translate('ABB.Powertrain.Frontend.assetOverviewNoAlarmAlertLimitCrossing')}</p>
            </div>
        );
    };

    const renderConditionItem = (condition) => {
        return (
            <div className='asset-overview-params__list' key={condition.id}>
                <div className='asset-overview-params__kpi'>
                    <span className='asset-overview-params__kpi-icon'>{condition.kpi.icon}</span>
                    {condition.kpi.name}
                </div>
                <div className='asset-overview-params__description'>{condition.description}</div>
            </div>
        );
    };

    const renderConditionList = (data, isLimits) => {
        if (!data || !Array.isArray(data) || !data.length) {
            return isLimits ? renderNoAssetLimits() : renderNoConditionIndices();
        }

        const filteredConditions = data.filter(item =>
            isLimits
                ? item.condition === assetCondition.CONDITION_ALARM || item.condition === assetCondition.CONDITION_ALERT
                : item.condition === assetCondition.CONDITION_POOR || item.condition === assetCondition.CONDITION_TOLERABLE
        );

        if (!filteredConditions.length) {
            return isLimits ? renderNoAssetLimits() : renderNoConditionIndices();
        }

        const conditionData = filteredConditions.map(condition => {
            let iconCondition;
            if (isLimits) {
                iconCondition = condition.condition === assetCondition.CONDITION_ALARM ? assetCondition.CONDITION_POOR : assetCondition.CONDITION_TOLERABLE;
            } else {
                iconCondition = condition.condition;
            }

            const conditionLimitsName = condition?.timeseriesName && !isNaN(condition?.timeseriesKey) ? `${condition?.timeseriesKey} ${translate(condition?.timeseriesNameTextKey)}` : translate(condition?.timeseriesNameTextKey);

            return {
                id: condition?.timeseriesKey || condition?.key,
                kpi: {
                    icon: <IconCondition condition={newConditionIndices[iconCondition]?.iconID} />,
                    name: conditionLimitsName || condition?.name
                },
                description: getConditionDescription(condition?.condition, isLimits)
            };
        });

        return (
            <>
                {renderHeader()}
                <ScrollableList>
                    {conditionData.map(renderConditionItem)}
                </ScrollableList>
            </>
        );
    };

    const renderLimits = () => renderConditionList(limits?.Limits, true);
    const renderConditionIndices = () => renderConditionList(conditionIndex?.KPIs, false);

    const renderAssetOnline = () => {
        return (
            <Tippy
                className='info-button-with-online-help-tippy'
                interactive={true}
                content={
                    <div>
                        <div className='asset-overview__online-text'>
                            {assetOnline === assetOnlineStatus.ONLINE ?
                                translate('ABB.Powertrain.Frontend.assetOverviewAssetConnectedSinceLabel') : lastStatusChangeRecievedTimestamp ?
                                translate('ABB.Powertrain.Frontend.assetOverviewAssetDisconnectedSinceLabel') : translate('ABB.Powertrain.Frontend.assetOverviewAssetDisconnectedLabel')}
                        </div>
                        {lastStatusChangeRecievedTimestamp ? <div className='asset-overview__last-measurement'>
                            {translate('ABB.Powertrain.Frontend.lastStatusUpdate')} {getTimeSinceDateString(lastStatusChangeRecievedTimestamp)}
                        </div> : null}
                    </div>
                }
                interactiveBorder={10}
                theme='light-border'
                trigger='mouseenter click'
                placement='top'
                maxWidth='400px'
                popperOptions={{
                    modifiers: {
                        flip: {
                            enabled: true
                        }
                    }
                }}
            >
                <button className={`asset-overview__asset ${assetOnline === assetOnlineStatus.ONLINE ? 'asset-overview__asset-online' : 'asset-overview__asset-offline'} `}>
                    {assetOnline === assetOnlineStatus.ONLINE ? <IconAssetOnline /> : <IconAssetOffline />}
                </button>
            </Tippy>
        );
    };

    return (
        <LoadingStatusWrapper loadingStatus={[subscriptionLoadingStatus, assetDetailsLabelsLoadingStatus]}>
            <div className='asset-overview'>
                <div className='asset-overview__header'>
                    <div className='asset-overview__header-row'>
                        <h1 className='asset-overview__heading'>
                            <span className='asset-overview__asset-icon'>
                                {getAssetIcon(assetType, { width: 20, height: 20 })}
                            </span>
                            <span className='asset-overview__heading-name' title={assetName}>{assetName}</span>
                        </h1>
                        {renderAssetOnline()}
                        <GeneralButton className='asset-overview__view-asset' type='primary' text='View asset' onClick={() => handleAssetNavigation()} />
                    </div>
                    <ul className='asset-overview__details'>
                        <li>{translate('ABB.Powertrain.Frontend.assetOverviewAssetIDLabel')}: {assetId}</li>
                        <li>{translate('ABB.Powertrain.Frontend.assetOverviewSerialNumberLabel')}: {assetSerialNumber ?? '-'}</li>
                        <li>{translate('ABB.Powertrain.Frontend.assetOverviewProductTypeCodeLabel')}: {productTypeCode?.productType ?? '-'}</li>
                        <li>{translate('ABB.Powertrain.Frontend.assetOverviewSubscriptionLabel')}: {subscriptionType}</li>
                    </ul>
                    <div className='asset-overview__connection-type'>
                        {translate('ABB.Powertrain.Frontend.assetOverviewConnectivityType')}: {connectivityType ? connectivityType : translate('ABB.Powertrain.Frontend.assetOverviewNoGateway')}
                    </div>
                    {newCapabilityHelper.hasLabelingCapability() && <ul className='asset-overview__tags'>
                        {tagContent}
                    </ul>}
                </div>
                <div className={`asset-overview-params__container ${assetLabels?.length ? 'asset-overview-params__label': ''}`}>
                    {condition === assetCondition.CONDITION_GOOD ? renderGoodCondition()
                        : <>
                            <div className='asset-overview-params'>
                                <div className='asset-overview-params__header'>
                                    <h2 className='asset-overview-params__heading'>{translate('ABB.Powertrain.Frontend.assetOverviewConditionIndicesText')}</h2>
                                </div>
                                <div className='asset-overview-params__table'>
                                    {renderConditionIndices()}
                                </div>
                            </div>
                            <div className='asset-overview-params'>
                                <div className='asset-overview-params__header'>
                                    <h2 className='asset-overview-params__heading'>{translate('ABB.Powertrain.Frontend.assetOverviewLimitsText')}</h2>
                                </div>
                                <div className='asset-overview-params__table'>
                                    {renderLimits()}
                                </div>
                            </div>
                        </>}
                </div>
            </div>
        </LoadingStatusWrapper>
    );
};

const IconGoodCondition = () => {
    return (
        <svg width='29' height='28' viewBox='0 0 29 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M12.6816 16L8.68164 12L6.68164 14L12.6816 20L22.6816 10L20.6816 8L12.6816 16ZM14.6816 0C6.94964 0 0.681641 6.268 0.681641 14C0.681641 21.732 6.94964 28 14.6816 28C22.4136 28 28.6816 21.732 28.6816 14C28.6816 10.287 27.2066 6.72601 24.5811 4.1005C21.9556 1.475 18.3947 0 14.6816 0ZM14.6816 26C8.05464 26 2.68164 20.627 2.68164 14C2.68164 7.373 8.05464 2 14.6816 2C21.3086 2 26.6816 7.373 26.6816 14C26.6816 17.1826 25.4174 20.2348 23.1669 22.4853C20.9165 24.7357 17.8642 26 14.6816 26Z' fill='#696969' />
        </svg>
    );
};

AssetOverviewDetailsComponent.propTypes = {
    actions: PropTypes.shape({
        getAssetDetails: PropTypes.func,
        getSubscriptionInfo: PropTypes.func,
        getApiAssetCapability: PropTypes.func
    })
};

export default AssetOverviewDetailsComponent;
