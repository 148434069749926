import { translate } from 'helpers/translateHelper';
import { commandIdOptions, toggleOptions, toggleTriggerOptions, unsubscribeDisableName } from './constants';

export const getsubscriptionToggleOptions = (translate) => {
    return [{
        label: translate('ABB.Powertrain.Frontend.dataLoggerTriggerSubscribeLabel'),
        value: toggleOptions?.SUBSCRIBE
    },
    {
        label: translate('ABB.Powertrain.Frontend.dataLoggerTriggerUnsubscribeLabel'),
        value: toggleOptions?.UNSUBSCRIBE
    }];
};

export const getStartStopToggleOptions = (translate) => {
    return [{
        label: translate('ABB.Powertrain.Frontend.dataLoggerTriggerLabel'),
        value: toggleTriggerOptions?.TRIGGER
    },
    {
        label: translate('ABB.Powertrain.Frontend.dataLoggerStopLabel'),
        value: toggleTriggerOptions?.STOP
    }];
};

export const updatedLoggerCommands = (commands) => {
    return commands.reduce((accCommands, currCommand) => {
        const commandExisting = accCommands?.find(command =>
            command?.commandDisplayName === currCommand?.commandDisplayName &&
            command?.commandType === currCommand?.commandType &&
            command?.loggerType === currCommand?.loggerType
        );

        if (commandExisting) {
            commandExisting?.commandId?.push(currCommand?.commandId);
        } else {
            accCommands.push({
                ...currCommand,
                commandId: [currCommand?.commandId]
            });
        }

        return accCommands;
    }, []);
};

export const getNotificationMessage = (commandId, commandDisplayName) => {
    const notificationMessages = {
        SUBSCRIBE: 'ABB.Powertrain.Frontend.dataLoggerSubscriptionLabel',
        UNSUBSCRIBE: 'ABB.Powertrain.Frontend.dataLoggerUnSubscriptionLabel',
        ENABLE: 'ABB.Powertrain.Frontend.dataLoggerTypeTriggeredLabel',
        DISABLE: 'ABB.Powertrain.Frontend.dataLoggerTypeStoppedLabel',
        TRIGGER: 'ABB.Powertrain.Frontend.dataLoggerTypeTriggeredLabel'
    };
    const commandType = Object.keys(commandIdOptions).find(key => commandId.includes(commandIdOptions[key]));

    const infoCommand = commandType === unsubscribeDisableName.UNSUBSCRIBE || commandType === unsubscribeDisableName.DISABLE ? '' : translate('ABB.Powertrain.Frontend.dataLoggerDownloadFromEventLogLabel');

    return commandType ? `${commandDisplayName} ${translate(notificationMessages[commandType])} ${infoCommand}` : null;
};
