import { createSelector } from 'reselect';

const getUserManager = ({ portal }) => {
    return portal.userManager;
};

const getUser = ({ overview }) => {
    return overview.user.data;
};

const getUserCapabilitiesLoadingStatus = ({ overview }) => {
    return overview?.userCapabilities?.loadingStatus;
};

const getSelectedGeneralFilter = ({ generalFilter }) => {
    return generalFilter?.selectedFilters;

};

export const getModel = createSelector([
    getUserManager,
    getUser,
    getUserCapabilitiesLoadingStatus,
    getSelectedGeneralFilter
], (
    userManager,
    user,
    userCapabilitiesLoadingStatus,
    selectedGeneralFilter
) => {
    return {
        userManager,
        user,
        userCapabilitiesLoadingStatus,
        selectedGeneralFilter
    };
});
