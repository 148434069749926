export const formatUpdatedMuteSettings = (organizationId, id, notificationGroups, period) => {
    notificationGroups = notificationGroups.map(({notificationGroupCode, muted}) => {
        return {
            notificationGroupCode,
            muted
        };
    });
    return {
        organizationId: organizationId,
        assetId: id,
        notificationGroups,
        startDate: period?.from || null,
        endDate: period?.to || null
    };
};
