import React, { useState, useEffect } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import map from 'lodash/map';

import { chartColors, defaultBoostThreshold } from 'helpers/constants';
import { ChartNoDataText } from 'sharedComponents/ChartComponents';
import { translate } from 'helpers/translateHelper';
import colors from 'theme/_colors.scss';
import { chartLegendItemClick } from '../../../helpers';
import FFTTooltip from './Tooltip/FFTTooltip';
import { getMeasurementUnitSymbol } from 'helpers/unitHelper';
import { PiezoSensor } from './constants';

const chartConfig = {
    title: {
        text: null
    },
    chart: {
        zoomType: 'x',
        height: null,
        events: {},
    },
    colors: chartColors,
    boost: {
        allowForce: false //Workaround for highchart bug. Allows correctly disabling boost, when zooming in.
    },
    time: {
        useUTC: false
    },
    xAxis: [
        {
            id: 'x1',
            crosshair: {
                enabled: true,
                width: 2,
                color: colors.tundora,
            }
        },
        {
            id: 'x2',
            opposite: true
        }
    ],
    yAxis: {},
    legend: {
        align: 'right',
        enabled: true,
        symbolRadius: 0,
        squareSymbol: false,
        symbolHeight: 8,
        symbolWidth: 8
    },
    credits: {
        enabled: false,
    },
    tooltip: {
        shared: true,
        valueDecimals: 2,
        useHTML: true,
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        shadow: false,
        borderWidth: 0,
        dateTimeLabelFormats: {
            day: '%d.%m.%Y<br/>%H:%M',
            hour: '%d.%m %H:%M',
        },
    },
    plotOptions: {
        series: {
            turboThreshold: 0,
            animation: false,
            boostThreshold: 0,
            events: {
                legendItemClick: chartLegendItemClick
            }
        }
    },
    lang: {
        noData: 'No data'
    },
    noData: {
        useHTML: true
    },
    exporting: {
        enabled: false,
        buttons: {
            contextButton: {
                menuItems: ['downloadPNG'],
                verticalAlign: 'bottom',
                x: 10,
                y: 0
            }
        }
    }
};

const getFormattedData = (fftData, spectrumRangeSelection) => {

    const {
        dataValues: data,
        magnitudeUnitSymbol,
        frequencyUnitSymbol
    } = fftData ? fftData : {};

    const formattedData = [];
    let seriesLength = 0;

    if (data) {
        data.forEach((item, index) => {
            if (spectrumRangeSelection === PiezoSensor.id && item.sensorAxisName === PiezoSensor.axisName
                || spectrumRangeSelection !== PiezoSensor.id && item.sensorAxisName !== PiezoSensor.axisName) {
                const seriesData = map(item.sensorAxisDataValues, (dataValues) => { return [dataValues.frequency, dataValues.magnitude]; });
                seriesLength += seriesData.length;

                formattedData.push({
                    name: item.sensorAxisName === PiezoSensor.axisName ? PiezoSensor.id : item.sensorAxisName,
                    data: seriesData,
                    lineWidth: 1,
                    id: index,
                    tooltip: {
                        headerFormat: '',
                        pointFormat: ` <b>${frequencyUnitSymbol}:</b> {point.x} <br /><b>${magnitudeUnitSymbol}:</b> {point.y}`
                    },
                    connectNulls: true,
                    color: item.sensorAxisName === PiezoSensor.axisName ? chartColors[0] : chartColors[index]
                });
            }
        });
    }
    return { formattedData, seriesLength };
};


const useChartConfig = (fftData, spectrumRangeSelection) => {
    const [options, setOptions] = useState({});

    useEffect(() => {
        const { formattedData, seriesLength } = getFormattedData(fftData, spectrumRangeSelection) || {};
        chartConfig.series = formattedData;
        chartConfig.plotOptions.series.boostThreshold = seriesLength > defaultBoostThreshold ? 1 : 0;
        chartConfig.xAxis[0].title = { text: `${translate('ABB.Powertrain.Frontend.FFTxAxisText')} [${fftData?.frequencyUnitSymbol}]` };
        chartConfig.xAxis[1].title = { text: `${translate('ABB.Powertrain.Frontend.opFFTRevolutionsPerMinuteText')} [${getMeasurementUnitSymbol(14).toUpperCase()}]` };
        chartConfig.yAxis.title = { text: `${translate('ABB.Powertrain.Frontend.FFTyAxisText')} [${fftData?.magnitudeUnitSymbol}]` };
        chartConfig.lang.noData = renderToStaticMarkup(
            <ChartNoDataText
                title={translate('ABB.Powertrain.Frontend.chartNoMeasurementData')}
                text={translate('ABB.Powertrain.Frontend.chartNoMeasurementDataUserGuide')} />);
        chartConfig.enableNoDataZoom = true;
        chartConfig.tooltip.formatter = function () {
            return renderToStaticMarkup(<FFTTooltip points={this.points}
                frequencyUnitSymbol={fftData?.frequencyUnitSymbol}
                magnitudeUnitSymbol={fftData?.magnitudeUnitSymbol}
                precision={fftData?.precision}
                timeStamp={this.x} />);
        };
        setOptions({ ...chartConfig });
    }, [fftData, spectrumRangeSelection]);

    return options;
};

export default useChartConfig;
