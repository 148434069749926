import React from 'react';
import PropTypes from 'prop-types';

import colors from 'theme/_colors.scss';
import { IconClose } from 'svgIcons/MotionPortalIcons';
import { getNotificationIcons } from './helper';

const NotificationBanner = ({ message, onClose, isHide = true, type = 'info' }) => {
    return <div className={`notification-banner w-100 mt-2 ${isHide ? '' : 'd-none'}`}>
        <div className='d-flex align-items-center'>
            <div className='notification-icon-container'>
                {getNotificationIcons(type)}
            </div>
            <div className='notification-banner-text ms-2'>{message}</div>
        </div>
        <div
            id='closeNotifyButton'
            role='presentation'
            onClick={onClose}
        >
            <IconClose
                color={colors.nero}
                strokeWidth='6' />
        </div>
    </div>;
};

NotificationBanner.propTypes = {
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    onClose: PropTypes.func,
    isHide: PropTypes.bool,
    type: PropTypes.string
};


export default NotificationBanner;
