import { createSelector } from 'reselect';
import { dataSources } from 'helpers/constants';
import { getAssetDataSourceType } from 'helpers/assetAndPowertrainFilterHelper';
import { getActiveTab, getMultiChart } from '../selectors';


const getDateSelection = ({ overview }) => {
    return overview.dateSelection;
};

const hasRawData = ({ operationalParametersV2 }) => {
    return operationalParametersV2?.FFT?.data && Object.hasOwn(operationalParametersV2.FFT.data, 'dataValues')
        && operationalParametersV2.FFT.data.dataValues.length !== 0;
};

const hasRawDataV2 = ({ operationalParametersV2 }) => {
    return operationalParametersV2?.FFTV2?.data && Object.hasOwn(operationalParametersV2.FFTV2.data, 'dataValues')
        && operationalParametersV2.FFTV2.data.dataValues.length !== 0;
};

const getIsCMDAsset = ({ powertrainAssetDetails }) => {
    const dataSourceType = getAssetDataSourceType(powertrainAssetDetails?.selectedAsset);
    return dataSourceType === dataSources?.CMD4;
};

export const getModel = createSelector([
    getDateSelection,
    hasRawData,
    getActiveTab,
    getMultiChart,
    getIsCMDAsset,
    hasRawDataV2
],
    (
        dateSelection,
        hasRawData,
        activeTab,
        multiChart,
        isCMDAsset,
        hasRawDataV2
    ) => {
        return {
            dateSelection,
            hasRawData,
            activeTab,
            multiChart,
            isCMDAsset,
            hasRawDataV2
        };
    });


