import axios from 'axios';
import { isObjectLike } from 'lodash';
import config from '../../config/appConfig';
import authHelper from 'helpers/authHelper';
import logger from 'helpers/logger';
import localStorageHelper from 'helpers/localStorageHelper';
import { getEndpointHeaders, getUserHeaders } from './headerMapper';
import { GLOBAL_ERROR_STATUS_CODES, SESSION_ERROR_STATUS_CODES } from './constants';
import { history } from '../../boot-client';

//Get user specific headers
export const addUserHeaders = (request) => {
    const originalRequest = request;
    const apiToken = authHelper.getAuthToken();
    const accessToken = authHelper.getAccessToken();
    const user = localStorageHelper.getItem('user');
    originalRequest.headers.common = getUserHeaders(apiToken, user, accessToken);

    return originalRequest;
};


function getInstance(baseUrl) {
    const instance = axios.create({
        baseURL: baseUrl,
        timeout: 180000
    });

    instance.interceptors.request.use(addUserHeaders,
        (error) => {
            logger.setLogToStorage(error);
            return Promise.reject(error);
        });

    instance.interceptors.response.use(
        (result) => {
            if (result?.data?.response) {
                return result.data.response;
            }
            if (result?.data) {
                return result.data;
            }
            else {
                const error = {};
                error.message = 'Missing response object!';
                logger.setLogToStorage(error);
            }
        },
        (error) => {
            const response = error?.response;

            if (!error.statusCode) {
                error.statusCode = response?.status;
            }
            if (response?.data) {
                const respData = isObjectLike(response.data) ? response.data : { message: response.data };
                error = {
                    ...error,
                    ...respData
                };
            }
            if (SESSION_ERROR_STATUS_CODES.indexOf(error.statusCode) !== -1) {
                authHelper.forceLogoutUser();
                authHelper.redirectToLogin(history.location.pathname);
                logger.setLogToStorage('Unauthorized user or expired token');
                return Promise.reject(error);
            }
            else if (GLOBAL_ERROR_STATUS_CODES.indexOf(error.statusCode) !== -1) {
                logger.setLogToStorage('Global ERROR: ' + error.statusCode);
                return Promise.reject(error);
            }
            else {
                logger.error(error);
                logger.setLogToStorage(error);
                return Promise.reject(error);
            }
        }
    );

    return instance;
}

class RestManager {
    constructor(instance) {
        this.instance = instance;
    }

    async get(endpoint) {
        return await this.instance.get(endpoint.url, { headers: getEndpointHeaders(endpoint) });
    }

    async post(endpoint, params) {
        return await this.instance.post(endpoint.url, params, { headers: getEndpointHeaders(endpoint) });
    }

    async patch(endpoint, params) {
        return await this.instance.patch(endpoint.url, params, { headers: getEndpointHeaders(endpoint) });
    }

    async put(endpoint, params) {
        return await this.instance.put(endpoint.url, params, { headers: getEndpointHeaders(endpoint) });
    }

    async delete(endpoint, data) {
        const headers = getEndpointHeaders(endpoint);

        return await this.instance.delete(endpoint.url, { headers, data });
    }

    async putBlob(endpoint, params) {
        return await this.instance.put(endpoint.url, params, { headers: { ...getEndpointHeaders(endpoint), 'x-ms-blob-type': 'BlockBlob' } });
    }
}

export const withPromise = (callbackFn) => {
    return new Promise((resolve) => {
        resolve(callbackFn());
    });
};

//Create multiple instances to handle different basepath for different endpoints.
// Eg. microservices might not have apiBasePath
// but gray backend might still use "pt-api" as a prefix

export const RestManagerWithBaseUrl = new RestManager(getInstance(config.apiUrl));
export const RestManagerWithoutBaseUrl = new RestManager(getInstance(config.apiUrlWithoutBase));
export const RestManagerWithSmartSensorUrl = new RestManager(getInstance(config.apiUrlWithSmartSensor));
export const RestManagerWithCmdUrl = new RestManager(getInstance(config.apiUrlWithCMD));

