import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { some } from 'lodash';

import { popupTypes } from 'helpers/constants';
import { getTranslatedKPINameV2 } from 'helpers/translateHelper';
import { GeneralDialog, LoadingIndicator, RadioButton } from 'sharedComponents';
import { getColumnConfigSuggestedLimits, limitTypes } from './helper';
import { LEVEL, LIMIT_SYMBOL } from './constants';
import { options } from './Limit';

const SuggestLimitModal = (props) => {
    const { t: translate } = useTranslation();
    const dispatch = useDispatch();
    const { model, close, actions } = props;
    const { selectedTimeSeriesForSuggestion, suggestedLimit } = model;

    const [selectedLimitType, setSelectedLimitType] = useState(limitTypes[1]);

    const mainTitle = `${translate('ABB.Powertrain.Frontend.suggestLimitTitle')} | ${getTranslatedKPINameV2(selectedTimeSeriesForSuggestion)}`;
    const noSuggestionsFoundTitle = `${translate('ABB.Powertrain.Frontend.noSuggestionsFoundTitle')} | ${getTranslatedKPINameV2(selectedTimeSeriesForSuggestion)}`;
    const columnConfig = getColumnConfigSuggestedLimits(translate, selectedTimeSeriesForSuggestion);
    const hasInvalidLimits = [selectedTimeSeriesForSuggestion]?.some(item =>
        some(item.limits, limit =>
            !limit.limitLevel || limit.limitValue === undefined || limit.limitValue === null || limit.limitValue === '' || !limit.limitDirection || limit.isSameLimit
        )
    );
    const isCurrentLimitModified = selectedLimitType === limitTypes[0] ? selectedTimeSeriesForSuggestion?.limits?.find(item => item?.isModified) : true;

    const handleCurrentUpdateLimit = (uniqId, field, value) => {
        dispatch(actions.updateCurrentLimit(uniqId, field, value));
    };

    const handleLimitTypeChange = (key) => {
        setSelectedLimitType(key);
        dispatch(actions.resetSuggestedCurrentLimits(suggestedLimit?.data));
    };

    const handleUpdateTimeSeries = () => {
        switch (selectedLimitType) {
            case limitTypes[0]: {// UPDATE CURRENT LIMIT
                const currentLimits = selectedTimeSeriesForSuggestion?.limits?.filter((lmt) => lmt?.hasCurrentLimit);
                const updatedTimeSeries = {
                    ...selectedTimeSeriesForSuggestion,
                    limits: currentLimits
                };
                dispatch(actions.updateSelectedTimeSeriesSuggestedData(updatedTimeSeries, selectedLimitType));
                break;
            }
            case limitTypes[1]: // UPDATE SUGGESTED LIMIT
                dispatch(actions.updateSelectedTimeSeriesSuggestedData(selectedTimeSeriesForSuggestion, selectedLimitType));
                break;
            default:
                break;
        }
        close();
    };

    const renderLimits = (limit) => {
        const isDisabled = selectedLimitType === limitTypes[1];
        const findIcon = options.find((o) => limit?.limitLevel === o.label);
        const disableLimit = selectedTimeSeriesForSuggestion?.isDefaultLimitEditable === false && limit?.isDefault;

        return <div className='suggest-limit-item' key={limit?.uniqId}>
            <div className='suggest-limit-item-title'>{findIcon?.prefixIcon} {limit?.limitLevel}</div>
            {limit?.hasCurrentLimit ?
                <div className={`limit-value-uom current-limit ${isDisabled || disableLimit ? 'disabled' : ''}`}>
                    <div className='suggest-limit-item-direction'>{limit?.limitDirection === LEVEL.UpperLimit || limit?.limitDirection === LEVEL.Upper ? LIMIT_SYMBOL.Greater : LIMIT_SYMBOL.Lower}</div>
                    <input
                        type='number'
                        placeholder={translate('ABB.Powertrain.Frontend.inputValue')}
                        value={limit?.limitValue === 0 ? '0' : limit?.limitValue || ''}
                        onChange={(e) => handleCurrentUpdateLimit(limit?.uniqId, 'limitValue', e.target.value)}
                        name={limit?.uniqId}
                        disabled={isDisabled || disableLimit}
                    />
                    <div className='uom-text'>{selectedTimeSeriesForSuggestion?.unit}</div>
                </div>
                : <div className='flex-1'></div>}
            <div className='limit-value-uom suggested-limit disabled'>
                <div className='suggest-limit-item-direction'>{limit?.suggestedLimit?.limitDirection === LEVEL.UpperLimit || limit?.suggestedLimit?.limitDirection === LEVEL.Upper ? LIMIT_SYMBOL.Greater : LIMIT_SYMBOL.Lower}</div>
                <input
                    value={limit?.suggestedLimit?.limitValue}
                    disabled={true}
                />
                <div className='uom-text'>{selectedTimeSeriesForSuggestion?.unit}</div>
            </div>
        </div>;
    };

    return (
        <GeneralDialog
            id='suggested-limits-modal'
            modalClassName='suggested-limits-modal'
            notificationType={suggestedLimit?.isLoading ? null : !suggestedLimit?.data?.calculatedLimit && popupTypes.ERROR}
            show={model.showSuggestLimitModal}
            close={close}
            title={suggestedLimit?.isLoading ? '' : suggestedLimit?.data?.calculatedLimit ? mainTitle : noSuggestionsFoundTitle}
            cancelButtonProps={suggestedLimit?.data?.calculatedLimit && {
                text: translate('ABB.Powertrain.Frontend.cancelLabel'),
            }}
            closeButton={true}
            acceptButtonProps={suggestedLimit?.data?.calculatedLimit ? {
                text: translate('ABB.Powertrain.Frontend.apply'),
                onClick: () => handleUpdateTimeSeries(),
                disabled: !suggestedLimit?.data?.calculatedLimit || suggestedLimit?.isLoading || hasInvalidLimits || !isCurrentLimitModified,
            } : {
                text: translate('ABB.Powertrain.Frontend.closeBtnLbl'),
                onClick: () => close(),
            }}
        >
            <div>
                {suggestedLimit?.isLoading ? <LoadingIndicator /> :
                    suggestedLimit?.data?.calculatedLimit ?
                        <>
                            <div className='suggest-limit-table'>
                                <div className='suggest-limit-header'>
                                    {columnConfig.map((column) => {
                                        const isChecked = selectedLimitType === column.key;
                                        return column.visible && <div className={`header-item ${isChecked ? 'selected' : ''}`} key={column.key}>
                                            {column.selectable ?
                                                <RadioButton
                                                    id={column.key}
                                                    checked={isChecked}
                                                    onClick={() => { handleLimitTypeChange(column.key); }}
                                                    label={column.title}
                                                />
                                                : column.title}
                                        </div>;
                                    })}
                                </div>
                                {selectedTimeSeriesForSuggestion?.limits?.length > 0 &&
                                    selectedTimeSeriesForSuggestion?.limits?.map(limit => renderLimits(limit))
                                }
                            </div>
                        </>
                        : <> {translate('ABB.Powertrain.Frontend.noSuggestionsFoundMsg')} </>}
            </div>
        </GeneralDialog>
    );
};

SuggestLimitModal.propTypes = {
    close: PropTypes.any,
    confirm: PropTypes.any,
    show: PropTypes.any,
    selectedSignal: PropTypes.any
};

export default SuggestLimitModal;
