import { call, put, takeEvery, takeLeading } from 'redux-saga/effects';

import { actionTypes } from './constants';
import { NotificationSettingsApi } from 'api';
import { notify } from 'sharedComponents/GeneralNotification';
import { NOTIFICATION_TYPES } from '../../../SharedComponents/GeneralNotification/constants';

function* getNotificationListSaga() {
    try {
        const response = yield call(NotificationSettingsApi.getNotificationList);
        if (response?.messages) {
            yield put({ type: actionTypes.SET_NOTIFICATION_LIST, payload: response?.messages });
        }
    } catch (error) {
        yield put({ type: actionTypes.SET_NOTIFICATION_LIST, payload: [] });
        notify(error.message, NOTIFICATION_TYPES.ERROR, true);
    }
}

function* readNotificationListSaga({ groupId }) {
    try {
        yield call(NotificationSettingsApi.readNotification, groupId);
    } catch (error) {
        notify(error.message, NOTIFICATION_TYPES.ERROR, true);
    }
}

export function* notificationPanelWatchers() {
    yield takeEvery(actionTypes.GET_NOTIFICATION_LIST, getNotificationListSaga);
    yield takeLeading(actionTypes.READ_NOTIFICATION, readNotificationListSaga);
}
