import { actionTypes } from './GeneralFilterModal/constants';
import { getDefaultFilters } from './GeneralFilterModal/helpers';
import { apiCallStatus } from 'helpers/constants';


export const initialState = {
    organizations: {
        data: [],
        loadingStatus: null
    },
    sites: {
        data: [],
        loadingStatus: null
    },
    assetGroups: {
        data: [],
        loadingStatus: null
    },
    selectedFilters: getDefaultFilters(),
    refeshCIAMSync: false,
    contextLoadingStatus: apiCallStatus.SUCCESS,
    syncSmartSensorBEFlag: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ASSETS: {
            return {
                ...state,
                assets: action
            };
        }
        case actionTypes.GET_POWERTRAINS: {
            return {
                ...state,
                powertrains: action
            };
        }
        case actionTypes.GET_ORGANIZATIONS: {
            return {
                ...state,
                organizations: { ...state.organizations, ...action }
            };
        }
        case actionTypes.GET_SITES: {
            return {
                ...state,
                sites: {
                    ...state.sites,
                    loadingStatus: action.loadingStatus,
                    data: action.data
                }
            };
        }
        case actionTypes.GET_ASSET_GROUPS: {
            return {
                ...state,
                assetGroups: { ...state.assetGroups, ...action }
            };
        }
        case actionTypes.SET_SELECTED_FILTERS: {
            return {
                ...state,
                selectedFilters: { ...state.selectedFilters, ...action.selectedFilters }
            };
        }
        case actionTypes.SET_RELOAD_CONTEXT_LOADING_STATUS: {
            return {
                ...state,
                contextLoadingStatus: action.loadingStatus
            };
        }
        case actionTypes.SET_CIAM_SYNC_FLAG: {
            return {
                ...state,
                refeshCIAMSync: action.refeshCIAMSync
            };
        }
        case actionTypes.SET_SYNC_SMSE_BE_FLAG: {
            return {
                ...state,
                syncSmartSensorBEFlag: action.flag
            };
        }
        default:
            return state;
    }
};

export default reducer;
