import { routes } from 'routes';

export const getTabOptions = (translate) => {
    const tabsOptions = [
        {
            title: translate('ABB.Powertrain.Frontend.gatewayManagementDrives'),
            route: routes.GatewayManagementConnectedAssetsDrives
        },
        {
            title: translate('ABB.Powertrain.Frontend.gatewayManagementEnvironmentSensors'),
            route: routes.GatewayManagementConnectedAssetsEnvironmentSensors,
        }
    ];

    return tabsOptions;
};

export const MAX_SEARCH_LENGTH = 50;
