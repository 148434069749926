import { actionTypes } from './constants';
import { apiCallStatus } from 'helpers/constants';

const initialState = {
    gatewayList: [],
    error: null,
    loadingStatus: null
};

export function reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.CLEAR_STORE: {
            return initialState;
        }
        case actionTypes.GET_GATEWAY_LIST_SETTINGS:
            if (action.loadingStatus === apiCallStatus.LOADING) {
                return {
                    ...state,
                    loadingStatus: apiCallStatus.LOADING,
                    error: null
                };
            } else if (action.loadingStatus === apiCallStatus.SUCCESS) {
                return {
                    ...state,
                    loadingStatus: apiCallStatus.SUCCESS,
                    gatewayList: action.data,
                    error: null
                };
            } else if (action.loadingStatus === apiCallStatus.ERROR) {
                return {
                    ...state,
                    loadingStatus: apiCallStatus.ERROR,
                    gatewayList: [],
                    error: action.error
                };
            }
            break;
        default:
            return state;
    }
}

const initialGatewayTypeState = {
    gatewayType: [],
    error: null,
    loadingStatus: null
};

export function gatewayTypeReducer(state = initialGatewayTypeState, action) {
    switch (action.type) {
        case actionTypes.CLEAR_STORE: {
            return initialGatewayTypeState;
        }
        case actionTypes.GET_GATEWAY_TYPE_SETTINGS:
            if (action.loadingStatus === apiCallStatus.LOADING) {
                return {
                    ...state,
                    loadingStatus: apiCallStatus.LOADING,
                    error: null
                };
            } else if (action.loadingStatus === apiCallStatus.SUCCESS) {
                return {
                    ...state,
                    loadingStatus: apiCallStatus.SUCCESS,
                    gatewayType: action.data,
                    error: null
                };
            } else if (action.loadingStatus === apiCallStatus.ERROR) {
                return {
                    ...state,
                    loadingStatus: apiCallStatus.ERROR,
                    gatewayType: [],
                    error: action.error
                };
            }
            break;
        default:
            return state;
    }
}
