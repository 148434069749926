import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { filter, map } from 'lodash';
import { Stack } from 'react-bootstrap';
import { ToggleButton } from 'sharedComponents';

import { loggerCommands, toggleTriggerOptions } from '../constants';
import DataLoggerHistoryTooltip from '../DataLoggerHistoryTooltip';
import { getStartStopToggleOptions, updatedLoggerCommands } from '../helpers';
import { apiCallStatus } from 'helpers/constants';

const BlackboxLoggerComponent = ({ model, actions, asset }) => {
    const { t: translate } = useTranslation();
    const { specialLoggerCommands, triggerDataLoggerCommandsLoadingStatus, specialLoggerCommandsLoadingStatus } = model;

    const filteredBlackboxCommands = filter(specialLoggerCommands, blackboxCommand => blackboxCommand?.commandType === loggerCommands.STARTSTOP);

    const triggerBlackboxLogger = (selectedTriggerOption, loggerCommand) => {
        const [triggerCommandId, stopCommandId] = loggerCommand.commandId;
        if (selectedTriggerOption === toggleTriggerOptions.TRIGGER) {
            actions?.subscribeUnsubscribeDataLoggerCommands({
                assetId: asset?.id,
                loggerType: loggerCommand?.loggerType,
                commandId: triggerCommandId,
                commandDisplayName: loggerCommand?.commandDisplayName
            });
        } else if (selectedTriggerOption === toggleTriggerOptions.STOP) {
            actions?.subscribeUnsubscribeDataLoggerCommands({
                assetId: asset?.id,
                loggerType: loggerCommand?.loggerType,
                commandId: stopCommandId,
                commandDisplayName: loggerCommand?.commandDisplayName
            });
        }
    };

    const updatedBlackboxCommands = updatedLoggerCommands(filteredBlackboxCommands);

    return (
        map(updatedBlackboxCommands, (blackboxCommand, i) => {
            return (
                <Stack direction='horizontal' key={i}>
                    <div className='data-logger__type py-2'>{blackboxCommand?.commandDisplayName}</div>
                    <div className='py-1 d-flex ms-auto'>
                        {
                            blackboxCommand?.commandType === loggerCommands.STARTSTOP &&
                            <div className='d-flex ms-auto'>
                                <ToggleButton
                                    buttonProps={{
                                        disabled: triggerDataLoggerCommandsLoadingStatus === apiCallStatus.LOADING ||
                                        specialLoggerCommandsLoadingStatus === apiCallStatus.LOADING
                                    }}
                                    options={getStartStopToggleOptions(translate)}
                                    onSelected={(selectedTriggerOption) => triggerBlackboxLogger(selectedTriggerOption, blackboxCommand)}
                                />
                                <div className='ml-2 d-none'>
                                    <DataLoggerHistoryTooltip command={'{Trigger | Stop}'} userName={'{user name}'} />
                                </div>
                            </div>
                        }
                    </div>
                </Stack>
            );
        })
    );
};

BlackboxLoggerComponent.propTypes = {
    model: PropTypes.object,
    actions: PropTypes.object,
    asset: PropTypes.object
};

export default BlackboxLoggerComponent;
