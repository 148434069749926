import { sortBy } from 'lodash';
import { createSelector } from 'reselect';
import { mapAssetGroups } from './helpers';
import { mapUnknownCountry } from 'helpers/filterHelper';
import { getOrganizations } from 'helpers/selectors';


const getRawSites = ({ generalFilter }) => {
    return generalFilter.sites.data;
};

const getAssetGroups = createSelector([
    ({ generalFilter }) => generalFilter.assetGroups.data,
], (
    rawAssetGroups,
) => {
    const mappedGroups = mapAssetGroups(rawAssetGroups);
    return mappedGroups;
});

const getSelectedFilterCountries = createSelector([
    ({ generalFilter }) => generalFilter.selectedFilters.countries,
], (
    countries,
) => {
    return mapUnknownCountry(countries);
});

const getSelectedFilterSites = createSelector([
    ({ generalFilter }) => generalFilter.selectedFilters.sites,
], (
    sites,
) => {
    return sortBy(sites, (o) => (o.siteName || '').toLowerCase());
});

const getSelectedFilterOrganizations = createSelector([
    ({ generalFilter }) => generalFilter.selectedFilters.organizations,
], (
    organizations,
) => {
    return sortBy(organizations, (o) => (o.organizationName || '').toLowerCase());
});

const getSelectedFilters = createSelector([
    ({ generalFilter }) => generalFilter.selectedFilters,
    getSelectedFilterOrganizations,
    getSelectedFilterCountries,
    getSelectedFilterSites
], (
    selectedFilters,
    organizations,
    countries,
    sites
) => {
    return {
        ...selectedFilters,
        organizations,
        countries,
        sites
    };
});

const getLoadingStatuses = ({ generalFilter }) => {
    return {
        organizationsLoadingStatus: generalFilter.organizations.loadingStatus,
        sitesLoadingStatus: generalFilter.sites.loadingStatus,
        assetGroupsLoadingStatus: generalFilter.assetGroups.loadingStatus,
        contextLoadingStatus: generalFilter.contextLoadingStatus
    };
};

const getEnvironment = ({ portal }) =>
    portal.environmentProperties.data && portal.environmentProperties.data.environment;

const getAssets = ({ overview }) => {
    return overview?.assets?.data;
};

const isAdmin = ({ overview }) => {
    return overview?.user?.data?.isAdminFromCIAM;
};

const isCIAMSyncFlag = ({ generalFilter }) => {
    return generalFilter?.refeshCIAMSync;
};

const getSyncSmartSensorBEFlag = ({ generalFilter }) => {
    return generalFilter?.syncSmartSensorBEFlag;
};

export const getModel = createSelector([
    getOrganizations,
    getRawSites,
    getAssetGroups,
    getSelectedFilters,
    getEnvironment,
    getLoadingStatuses,
    getAssets,
    isAdmin,
    isCIAMSyncFlag,
    getSyncSmartSensorBEFlag
], (
    organizations,
    rawSites,
    assetGroups,
    selectedFilters,
    environment,
    loadingData,
    assets,
    isAdmin,
    isCIAMSyncFlag,
    syncSmartSensorBEFlag
) => {
    return {
        organizations,
        rawSites,
        assetGroups,
        selectedFilters,
        environment,
        loadingData,
        assets,
        isAdmin,
        isCIAMSyncFlag,
        syncSmartSensorBEFlag
    };
});
