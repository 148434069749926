import { getOrganizations } from 'helpers/selectors';
import { createSelector } from 'reselect';

const getShowSwitchOrgModal = ({ switchOrganization }) => {
    return switchOrganization.showSwitchOrgModal;
};

const getCurrentOrganization = ({ generalFilter }) => {
    return generalFilter?.selectedFilters?.organizations;
};

const getSelectedNotification = ({ notificationPanel }) => {
    return notificationPanel?.selectedNotification;
};

const getAssets = ({ overview }) => {
    return overview?.assets?.data || [];
};

const getLoadingStatuses = ({ generalFilter }) => {
    return generalFilter.contextLoadingStatus;
};

export const getModel = createSelector(
    [
        getShowSwitchOrgModal,
        getCurrentOrganization,
        getSelectedNotification,
        getAssets,
        getLoadingStatuses,
        getOrganizations
    ],
    (
        showSwitchOrgModal,
        currentOrganization,
        selectedNotification,
        assets,
        loadingStatuses,
        organizations
    ) => {
        return {
            showSwitchOrgModal,
            currentOrganization,
            selectedNotification,
            assets,
            loadingStatuses,
            organizations
        };
    }
);
