import React from 'react';
import PropTypes from 'prop-types';

import { Expandable } from 'sharedComponents';
import { translate } from 'helpers/translateHelper';
import LimitTitle from './LimitTitle';
import Limit from './Limit';
import { IconCaretRight, IconDropDownMo } from 'svgIcons/MotionPortalIcons';

const PersonalLimit = ({ timeSeries, userLimits, isExpanded, expandedItem, setExpandedItem, setIsExpanded, checkIsSmseAsset, expandItemName, ...props }) => {
    const { model } = props;
    const notificationCount = userLimits.filter((limit) => limit?.notificationEnabled)?.length;

    const handleExpandPersonalLimits = (signalUniqId) => {
        let expandedSignals = [...expandedItem];
        if (expandedSignals?.includes(signalUniqId)) {
            expandedSignals = expandedSignals.filter((id) => signalUniqId !== id);
        } else {
            expandedSignals?.push(signalUniqId);
        }
        setExpandedItem(expandedSignals);
    };

    return <div className='personal-limit-container'>
        <Expandable
            disabled={!timeSeries?.displayName}
            style={{ border: '1px solid #bababa' }}
            open={expandedItem?.includes(`P_${expandItemName}`)}
            onClick={() => handleExpandPersonalLimits(`P_${expandItemName}`)}
            key={`P_${expandItemName}`}
            collapsedIcon={<IconCaretRight />}
            expandedIcon={<IconDropDownMo />}
            title={
                <LimitTitle
                    {...props}
                    item={timeSeries}
                    setExpandedItem={setExpandedItem}
                    open={!timeSeries?.displayName ? false : isExpanded || expandedItem?.includes(`P_${expandItemName}`)}
                    expandedItem={expandedItem}
                    setIsExpanded={setIsExpanded}
                    isUserLimit={true}
                    showDiscard={userLimits?.filter((l) => l?.isModified)?.length > 0}
                    notificationCount={notificationCount}
                    checkIsSmseAsset={checkIsSmseAsset}
                    handleExpandSignal={handleExpandPersonalLimits}
                />
            }
        >
            <div className='content-container' id={timeSeries?.uniqId}>
                {userLimits?.length === 0 &&
                    <div className='limit-type-container'>
                        <div className='no-limits'>
                            {translate('ABB.Powertrain.Frontend.noLimitsMessage')}
                        </div>
                    </div>
                }
                {userLimits?.length > 0 &&
                    userLimits?.map(limit =>
                        <Limit key={limit.uniqId} item={limit} timeSeries={timeSeries} kpiList={model.limitKPI.kpiList} />
                    )
                }
            </div>
        </Expandable>
    </div>;
};

PersonalLimit.propTypes = {
    filteredList: PropTypes.array,
    isExpanded: PropTypes.bool,
    expandedItem: PropTypes.object,
    setExpandedItem: PropTypes.func,
    setIsExpanded: PropTypes.func
};


export default PersonalLimit;
