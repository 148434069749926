import { createSelector } from 'reselect';
import { getLoadingStatuses } from 'helpers/selectors';

const getGatewayInformation = ({ powertrainTools }) => {
    return powertrainTools?.gatewayInformation;
};

const getGatewayConnectedAssets = ({ powertrainTools }) => {
    return powertrainTools?.gatewayConnectedAssets;
};


export const getModel = createSelector(
    [
        getGatewayInformation,
        getGatewayConnectedAssets,
        getLoadingStatuses
    ],
    (
        gatewayInformation,
        gatewayConnectedAssets,
        generalFilterLoadingStatuses
    ) => {
        return {
            gatewayInformation,
            gatewayConnectedAssets,
            generalFilterLoadingStatuses
        };
    }
);
