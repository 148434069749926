import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import { useTranslation } from 'react-i18next';

import { formatAndSortImages, checkAssetImageType } from './helpers';
import { GeneralDialog, LoadingIndicator, GeneralButtonDropDown, GeneralButton } from 'sharedComponents';
import IconUpload from 'svgIcons/MotionPortalIcons/IconUpload';
import { LoadingStatusWrapper } from 'sharedComponents/WithLoadingStatus';
import { notify } from 'sharedComponents/GeneralNotification';
import { NOTIFICATION_TYPES } from 'sharedComponents/GeneralNotification/constants';
import colors from 'theme/_colors.scss';
import { MAX_ALLOWED_PICTURE_SIZE, ALLOWED_PICTURE_FORMAT, PICTURE_TYPES } from './constants';
import { useNewCapability } from 'sharedHooks';
import { IconDelete } from 'svgIcons/MotionPortalIcons';

const ImageViewerModal = (props) => {
    const { t: translate } = useTranslation();
    const { images, show, closeFunction, placeholder, selectedIndex, loadingStatus, uploadImageHandler, deleteAssetImage } = props;
    const [imagesInGallery, setImagesInGallery] = useState(null);
    const [pictureType, setPictureType] = useState(PICTURE_TYPES?.AssetImage);
    const inputFile = useRef(null);
    const capability = useNewCapability();
    const isUploadImageVisible = capability.hasAssetPictureCapability();

    useEffect(() => {
        if (show && images) {
            setImagesInGallery(formatAndSortImages(images, translate));
        }
    }, [images, show]);
    const onAssetImageTypeHandler = (type) => {
        setPictureType(type);
        if (type) { inputFile?.current?.click(); }

    };
    const onAssetImageUploadHandler = (event) => {
        const target = event?.target;
        if (target?.files && target?.files[0]) {
            if (checkAssetImageType(target?.files[0]?.name)) {
                if (target?.files[0]?.size <= MAX_ALLOWED_PICTURE_SIZE) {
                    uploadImageHandler(target?.files[0], pictureType);
                    target.value = '';
                } else {
                    target.value = '';
                    notify(translate('ABB.Powertrain.Frontend.assetImageUploadSizeError'), NOTIFICATION_TYPES.ERROR, true);
                }
            } else {
                target.value = '';
                notify(translate('ABB.Powertrain.Frontend.assetImageUploadTypeError'), NOTIFICATION_TYPES.ERROR, true);
            }
        }
    };
    const renderUploadButton = () => {
        return (
            <div className={isUploadImageVisible ? 'd-flex flex-row-reverse' : 'd-flex disabled-capability'}>
                <div className='p-2'>
                    <GeneralButtonDropDown
                        type='primary'
                        icon={IconUpload({
                            width: '14px',
                            height: '14px',
                            fill: colors.white
                        })}
                        text={translate('ABB.Powertrain.Frontend.assetPictureUploadLabel')}
                        options={[
                            { label: translate('ABB.Powertrain.Frontend.lbl-asset-image'), value: PICTURE_TYPES?.AssetImage },
                            { label: translate('ABB.Powertrain.Frontend.lbl-nameplate-image'), value: PICTURE_TYPES?.NameplateImage },
                            { label: translate('ABB.Powertrain.Frontend.lbl-other-image'), value: PICTURE_TYPES?.Attachment },
                        ]}
                        onSelect={(selectedPictureType) => {
                            onAssetImageTypeHandler(selectedPictureType);
                        }}
                    />
                    <input type='file' id='file' ref={inputFile} style={{ display: 'none' }} accept={ALLOWED_PICTURE_FORMAT} onChange={onAssetImageUploadHandler} />
                </div>
            </div>
        );
    };
    const renderThumbImage = (img) => {
        return (
            <div className='asset-image-thumbnail d-flex flex-column'>
                <img className='rounded-top' src={img?.thumbnail} />
                <div className='asset-image-thumbnail-description px-1 d-flex align-items-center justify-content-between rounded-bottom'>
                    <span>{img?.description}</span>
                    <GeneralButton
                        className='px-0'
                        icon={IconDelete({
                            width: '12px',
                            height: '12px',
                            fill: colors.black
                        })}
                        onClick={() => { deleteAssetImage(img); }} />
                </div>
            </div>
        );
    };
    const renderImageGalleryContent = () => {
        if (!imagesInGallery) {
            return <LoadingIndicator />;
        }
        const imageCount = imagesInGallery.length;

        switch (imageCount) {
            case 0:
                return (
                    <div className='image-viewer-modal-no-image-default'>
                        <h3>{translate('ABB.Powertrain.Frontend.asset-no-images-yet')}</h3>
                        {placeholder}
                    </div>
                );

            case 1:
                return (
                    <div>
                        <ImageGallery
                            items={imagesInGallery}
                            showPlayButton={false}
                            lazyLoad={true}
                            disableThumbnailScroll={false}
                            showNav={false}
                            showThumbnails={true}
                            renderThumbInner={renderThumbImage}
                        />

                    </div>
                );

            default:
                return (
                    <ImageGallery
                        startIndex={selectedIndex}
                        items={imagesInGallery}
                        showPlayButton={false}
                        lazyLoad={true}
                        disableThumbnailScroll={false}
                        renderThumbInner={renderThumbImage}
                    />
                );
        }
    };

    return <GeneralDialog
        id='asset-nameplate-image-viewer-modal'
        show={show}
        close={closeFunction}
        title={translate('ABB.Powertrain.Frontend.ttl-asset-nameplate-images')}
        closeButton={true}
    >
        <LoadingStatusWrapper loadingStatus={loadingStatus} transparent={true} fullScreen={true}>
            {renderImageGalleryContent()}
            {renderUploadButton()}
        </LoadingStatusWrapper>
    </GeneralDialog>;
};

ImageViewerModal.propTypes = {
    images: PropTypes.array,
    loadingStatus: PropTypes.string,
    show: PropTypes.bool,
    closeFunction: PropTypes.func,
    placeholder: PropTypes.node,
    selectedIndex: PropTypes.number,
    uploadImageHandler: PropTypes.func,
    setGalleryCurrentIndex: PropTypes.func,
    deleteAssetImage: PropTypes.func
};


export default ImageViewerModal;
