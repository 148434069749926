export const actionTypes = {
    GET_SENSOR_FEATURES: 'sensorProperties/GET_SENSOR_FEATURES',
    GET_SENSOR_PROPERTIES: 'sensorProperties/GET_SENSOR_PROPERTIES',
    GET_SENSOR_GATEWAYINFO: 'sensorProperties/GET_SENSOR_GATEWAYINFO',
    GET_ASSET_INFO: 'sensorProperties/GET_ASSET_INFO',
    GET_GATEWAY_INFO: 'sensorProperties/GET_GATEWAY_INFO',
    CLEAR_STORE: 'sensorProperties/CLEAR_STORE',
    GET_ASSET_DETAILS: 'detailedInformation/GET_ASSET_DETAILS',
};

export const ITEMS_PER_PAGE = 15;
