import React, { useEffect, useState } from 'react';
import { forEach, map, transform } from 'lodash';
import isNumber from 'lodash/isNumber';
import { translate, getTranslatedKPIName } from 'helpers/translateHelper';
import colors from 'theme/_colors.scss';
import { afterChartReDrawStringData } from '../../helpers';
import { renderToStaticMarkup } from 'react-dom/server';
import { TrendTooltip } from 'sharedComponents/ChartComponents/Trend';
import { ChartNoDataText } from 'sharedComponents/ChartComponents';
import { defaultBoostThreshold } from 'helpers/constants';
import {
    getMeasurementUnitSymbol
} from 'helpers/unitHelper';
import ConditionIndicatorTooltip from './ConditionIndicatorTooltip';
import {
    CHART_PLOT_LINE_DASH_STYLE,
    CHART_TREND_EVENT_TOLERABLE_INDICATOR,
    CHART_TREND_EVENT_POOR_INDICATOR,
    CHART_TREND_EVENT_TOLERABLE_INDICATOR_TOOLTIP,
    CHART_TREND_EVENT_POOR_INDICATOR_TOOLTIP,
    CHART_CONDITION_TREND_STATUS_POOR,
    CHART_SERIES_CONDITION_INDICATOR_NAME,
    ALERT_LINE_COLOR,
    MARKER_FILL_COLOR,
    ALARM_LINE_COLOR,
    TREND_LINE_COLOR,
    CONDITION_STATUS_LABEL,
    TREND_TYPE_TOOLTIP_SERIES_NAME,
    CHART_CONDITION_TREND_STATUS_GOOD
} from './constants';


const getChartConfig = (removeKPIData, title, assetDetails) => {
    return {
        title: {
            text: null
        },
        chart: {
            zoomType: 'x',
            height: null,
            events: {
                render: function (chartInstance) {
                    afterChartReDrawStringData(chartInstance.target.container.id, removeKPIData);
                },
                beforePrint: function () {
                    this.exportSVGElements[0].hide();
                    this.setTitle({
                        text: `${assetDetails?.details?.assetName} - ${title}`,
                        style: {
                            fontFamily: 'ABBvoice-Regular',
                            fontWeight: 'bold',
                        },
                        align: 'left',
                        x: 50,
                        y: -10,
                    });
                    this.update({
                        xAxis: {
                            labels: {
                                style: { fontFamily: 'ABBvoice-Regular' }
                            }
                        },
                        yAxis: {
                            labels: {
                                style: { fontFamily: 'ABBvoice-Regular' }
                            }
                        },
                        legend: {
                            itemStyle: { fontFamily: 'ABBvoice-Regular' }
                        }
                    });
                },
                afterPrint: function () {
                    this.exportSVGElements[0].show();
                    this.setTitle({
                        text: '',
                    });
                }
            },
            style: {
                fontFamily: 'ABBvoice-Regular',
            },
            spacingTop: 70,
            marginRight: 25,
        },
        boost: {
            allowForce: false //Workaround for highchart bug. Allows correctly disabling boost, when zooming in.
        },
        time: {
            useUTC: false
        },
        xAxis: {
            type: 'datetime',
            id: 'x',
            dateTimeLabelFormats: {
                millisecond: '%H:%M:%S',
                day: '%e. %b',
                month: '%b \'%y'
            },
            crosshair: {
                enabled: true,
                width: 2,
                color: colors.tundora,
            },
            labels: {
                rotation: 50,
                color: '#000000',
                style: {
                    fontWeight: 'bold',
                    fontColor: '#000000'
                },
            },
            lineColor: '#000000',
            lineWidth: 2,
            tickPixelInterval: 25,
            tickColor: '#000000',
            tickWidth: 2


        },
        legend: {
            useHTML: true,
            layout: 'horizontal',
            align: 'right',
            itemStyle: {
                fontFamily: 'ABBvoice-Regular',
                fontSize: '14px',
            },
            itemDistance: 25,
            itemMarginTop: 10,
            enabled: false,
            symbolRadius: 0,
            squareSymbol: false,
            symbolHeight: 8,
            symbolWidth: 8
        },
        credits: {
            enabled: false,
        },
        tooltip: {
            shared: true,
            valueDecimals: 2,
            useHTML: true,
            backgroundColor: 'rgba(255, 255, 255, 0.6)',
            shadow: false,
            padding: 0,
            borderWidth: 0,
            dateTimeLabelFormats: {
                day: '%d.%m.%Y<br/>%H:%M',
                hour: '%d.%m %H:%M',
            },
        },
        plotOptions: {
            series: {
                turboThreshold: 0,
                animation: false,
                boostThreshold: 0
            }
        },
        navigation: {
            buttonOptions: {
                align: 'right',
            },
            menuStyle: {
                borderColor: '#fff',
                boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.08)',
                borderRadius: '6px'
            },
            menuItemStyle: {
                padding: '6px 12px',
                fontSize: '14px',
                fontFamily: 'ABBvoice-Regular',
            },
            menuItemHoverStyle: {
                backgroundColor: '#ebebeb',
                color: '#1f1f1f'
            }
        },
        lang: {
            printChart: translate('ABB.Powertrain.Frontend.printChart'),
            downloadPNG: translate('ABB.Powertrain.Frontend.saveAsImage'),
        },
        noData: {
            useHTML: true
        },
        exporting: {
            enabled: true,
            scale: 2,
            sourceWidth: 1300,
            sourceHeight: 600,
            fallbackToExportServer: false,
            allowHTML: true,
            buttons: {
                contextButton: {
                    menuItems: ['downloadPNG', 'printChart'],
                    verticalAlign: 'top',
                    x: 0,
                    y: -70
                }
            },
            chartOptions: {
                title: {
                    text: `${assetDetails?.details?.assetName} - ${title}`,
                    align: 'left',
                    x: 50,
                    y: -10,
                    style: {
                        fontFamily: 'Verdana, sans-serif',
                        fontWeight: 'bold'
                    }
                },
                xAxis: {
                    labels: {
                        style: {
                            fontFamily: 'Verdana, sans-serif',
                        }
                    }
                },
                yAxis: {
                    labels: {
                        style: {
                            fontFamily: 'Verdana, sans-serif',
                        }
                    }
                }
            }
        }
    };
};

const getMeasurements = (config, measurements, showRangeData) => {
    const averages = [];
    const rangedata = [];
    forEach(measurements, (measurement) => {
        if (measurement.timestamp !== null) {
            const date = new Date(measurement.timestamp).getTime();
            averages.push([date, measurement?.value ? measurement.value + config.offset
                : measurement.value]);
            if (showRangeData) {
                rangedata.push([date,
                    isNumber(measurement?.min) ? measurement.min + config.offset : null,
                    isNumber(measurement?.max) ? measurement.max + config.offset : null]);
            }
        }
    });

    return { averages, rangedata };
};

const getUserLimitsSeries = (userLimits, config, seriesDataPoints) => {
    let series = [];
    if (userLimits && userLimits.length) {
        series = map(userLimits, (ul, index) => {
            return {
                name: ul.label || config.displayName || '',
                id: `ul_${index}`,
                showInLegend: false,
                color: ul.color || colors.teak,
                marker: { enabled: false },
                lineWidth: 1,
                dashStyle: 'Solid',
                data: transform(seriesDataPoints, (acc, sd) => {
                    const [date, value] = sd;
                    if (value !== null) {
                        acc.push([date, ul.value]);
                    }
                }, []),
                unit: config && config.unit ? config.unit : '',
                tooltip: {
                    valueSuffix: ` ${config && config.unit ? config.unit : ''}`,
                },
            };
        });
    }
    return series;
};

const getPlotLines = (plotLineList) => {
    let bandLineList = [];
    if (plotLineList && plotLineList?.length) {
        bandLineList = map(plotLineList, (ul) => {
            return {
                value: ul?.value,
                color: ul?.color,
                dashStyle: CHART_PLOT_LINE_DASH_STYLE,
                width: 1.2,
                zIndex: 10
            };

        });
    }
    return bandLineList;
};

const getPlotBandsWithMaxMin = (plotBandsList, yAxis, mainAxis) => {

    if (plotBandsList?.length) {
        map(plotBandsList, (ul) => {
            mainAxis?.addPlotBand({
                from: ul?.from === null ? yAxis?.min : ul?.from,
                to: ul?.to === null ? yAxis?.max : ul?.to,
                color: ul?.color
            });

        });
    }
};
const getThresholdLabel = (isPoor) => {
    return {
        enabled: true,
        useHTML: true,
        formatter: function () {
            return isPoor ? CHART_TREND_EVENT_POOR_INDICATOR : CHART_TREND_EVENT_TOLERABLE_INDICATOR;
        }
    };
};

const getConditionIndicators = (condtionIndicators) => {
    if (condtionIndicators?.length) {
        return map(condtionIndicators, (ul) => {
            return {
                y: ul?.trendValue,
                x: new Date(ul?.timestamp)?.getTime(),
                dataLabels: ul?.newCondition !== CHART_CONDITION_TREND_STATUS_GOOD &&
                    getThresholdLabel(ul?.newCondition === CHART_CONDITION_TREND_STATUS_POOR)
            };
        });
    }
};

const getConditionImage = (isPoor) => {
    return isPoor ? CHART_TREND_EVENT_POOR_INDICATOR_TOOLTIP : CHART_TREND_EVENT_TOLERABLE_INDICATOR_TOOLTIP;
};
const getConditionTypeLabel = (KpiType) => {
    return translate(`ABB.Powertrain.Frontend.${CONDITION_STATUS_LABEL[KpiType]}`);
};

const getConditionIndicatorsV2 = (condtionIndicators) => {
    if (condtionIndicators?.length) {
        return map(condtionIndicators, (ul) => {
            return {
                y: ul?.trendValue,
                x: new Date(ul?.timestamp)?.getTime(),
                customObject: ul?.newCondition !== CHART_CONDITION_TREND_STATUS_GOOD &&
                    getConditionImage(ul?.newCondition === CHART_CONDITION_TREND_STATUS_POOR),
                KpiTypeLabel: getConditionTypeLabel(ul?.kpiType)
            };
        });
    }
};

const generateMarkerData = (averages, limitValue) => {
    if (averages?.length) {
        return averages.map((average) => {
            return [average[0], limitValue];
        });
    }
};


const getSeries = (
    selectedKpisData,
    userLimits,
    yAxisTitleVisible,
    warningLevels,
    warningLines,
    conditionTrendIndicators,
    highErrorValue,
    highAlertLimitValue,
    lowErrorValue,
    lowAlertValue,
    unitGroupId,
    valueType
) => {
    const ret = { yAxis: [], seriesData: [], allSeriesLength: 0 };

    transform(
        selectedKpisData,
        (acc, item, index) => {
            const config = item.config ? item.config : {};
            const unit = config && config.unit || '';
            const convertedUnit = getMeasurementUnitSymbol(unitGroupId);
            const precision = config && config.precision;
            const name = getTranslatedKPIName(config);
            const trendDataValues = item.values;
            const yAxisId = `y_${index}`;
            const { averages, rangedata } = getMeasurements(
                config,
                trendDataValues,
                selectedKpisData?.length === 1
            );
            let isPlotBandAdded = false;
            acc.yAxis.push({
                labels: {
                    format: '{value} ' + convertedUnit || unit,
                    style: {
                        color: item.color,
                    },
                },
                id: yAxisId,
                title: {
                    text: name,
                    enabled: yAxisTitleVisible,
                    style: {
                        color: item.color,
                    },
                },
                plotLines: getPlotLines(warningLines),
                opposite: index % 2 !== 0,
                visible: true,
                events: {
                    setExtremes: function (e) {
                        if (!isPlotBandAdded) {
                            getPlotBandsWithMaxMin(warningLevels, e?.target, this);
                            isPlotBandAdded = true;
                        }
                    },
                },
            });
            acc.seriesData.push({
                name: `${name} (${valueType})`,
                data: averages,
                tooltip: {
                    valueSuffix: ` ${convertedUnit || unit}`,
                    valueDecimals: precision,
                },
                color: TREND_LINE_COLOR,
                marker: {
                    enabled: false,
                    radius: 1,
                    symbol: 'circle',
                    fillColor: MARKER_FILL_COLOR,
                    lineWidth: 0.2,
                    lineColor: TREND_LINE_COLOR,
                },
                lineWidth: 1,
                id: `${crypto.randomUUID()}`,
                yAxis: yAxisId,
            });
            if (rangedata.length) {
                acc.seriesData.push({
                    name: `${name}`,
                    data: rangedata,
                    type: 'arearange',
                    id: 'rangeSeries',
                    lineWidth: 2,
                    radius: 2,
                    linkedTo: ':previous',
                    fillOpacity: 0.3,
                    opacity: 0.3,
                    color: item.color,
                    zIndex: 0,
                    tooltip: {
                        valueSuffix: ` ${convertedUnit || unit}`,
                        isRange: true
                    },
                    marker: {
                        enabled: false,
                        radius: 1,
                        symbol: 'circle',
                        fillColor: MARKER_FILL_COLOR,
                        lineWidth: 0.2,
                        lineColor: item.color,
                    },
                });
            }
            acc?.seriesData?.push({
                name: CHART_SERIES_CONDITION_INDICATOR_NAME,
                id: `${crypto.randomUUID()}`,
                yAxis: yAxisId,
                lineWidth: 0,
                states: {
                    hover: {
                        lineWidthPlus: 0,
                    },
                },
                marker: {
                    enabled: false,
                    radius: 1,
                    symbol: 'circle',
                    fillColor: MARKER_FILL_COLOR,
                    lineColor: '#000000',
                    lineWidth: 0.2,
                },
                xAxis: 'x',
                tooltip: {
                    enabled: true,
                    valueSuffix: `${convertedUnit || unit}`,
                },
                data: getConditionIndicators(conditionTrendIndicators),
            });
            if (highErrorValue) {
                acc?.seriesData?.push({
                    data: generateMarkerData(averages, highErrorValue),
                    name: translate(
                        'ABB.Powertrain.Frontend.conditionTrendTooltipPoor'
                    ),
                    color: ALARM_LINE_COLOR,
                    xAxis: 'x',
                    id: `${crypto.randomUUID()}`,
                    yAxis: yAxisId,
                    linkedTo: ':previous',
                    lineWidth: 0,
                    states: {
                        hover: {
                            lineWidthPlus: 0,
                        },
                    },
                    marker: {
                        enabled: false,
                        radius: 1,
                        symbol: 'circle',
                        fillColor: MARKER_FILL_COLOR, // Set fill color to transparent
                        lineColor: ALARM_LINE_COLOR,
                        lineWidth: 0.2,
                    },
                    tooltip: {
                        valueSuffix: ` ${convertedUnit || unit}`,
                    },
                });
            }
            if (lowErrorValue || lowErrorValue === 0) {
                acc?.seriesData?.push({
                    data: generateMarkerData(averages, lowErrorValue),
                    name: translate(
                        'ABB.Powertrain.Frontend.conditionTrendTooltipPoor'
                    ),
                    color: ALARM_LINE_COLOR,
                    xAxis: 'x',
                    id: `${crypto.randomUUID()}`,
                    yAxis: yAxisId,
                    linkedTo: ':previous',
                    lineWidth: 0,
                    states: {
                        hover: {
                            lineWidthPlus: 0,
                        },
                    },
                    marker: {
                        enabled: false,
                        radius: 1,
                        symbol: 'circle',
                        fillColor: MARKER_FILL_COLOR, // Set fill color to transparent
                        lineColor: ALARM_LINE_COLOR,
                        lineWidth: 0.2,
                    },
                    tooltip: {
                        valueSuffix: ` ${convertedUnit || unit}`,
                    },
                });
            }
            if (highAlertLimitValue) {
                acc?.seriesData?.push({
                    data: generateMarkerData(averages, highAlertLimitValue),
                    name: translate(
                        'ABB.Powertrain.Frontend.conditionTrendTooltipTolerable'
                    ),
                    color: ALERT_LINE_COLOR,
                    xAxis: 'x',
                    id: `${crypto.randomUUID()}`,
                    yAxis: yAxisId,
                    linkedTo: ':previous',
                    lineWidth: 0,
                    states: {
                        hover: {
                            lineWidthPlus: 0,
                        },
                    },
                    marker: {
                        enabled: false,
                        radius: 1,
                        symbol: 'circle',
                        fillColor: MARKER_FILL_COLOR, // Set fill color to transparent
                        lineColor: ALERT_LINE_COLOR,
                        lineWidth: 0.2,
                    },
                    tooltip: {
                        valueSuffix: ` ${convertedUnit || unit}`,
                    },
                });
            }
            if (lowAlertValue || lowAlertValue === 0) {
                acc?.seriesData?.push({
                    data: generateMarkerData(averages, lowAlertValue),
                    name: translate(
                        'ABB.Powertrain.Frontend.conditionTrendTooltipTolerable'
                    ),
                    color: ALERT_LINE_COLOR,
                    xAxis: 'x',
                    id: `${crypto.randomUUID()}`,
                    yAxis: yAxisId,
                    linkedTo: ':previous',
                    lineWidth: 0,
                    states: {
                        hover: {
                            lineWidthPlus: 0,
                        },
                    },
                    marker: {
                        enabled: false,
                        radius: 1,
                        symbol: 'circle',
                        fillColor: MARKER_FILL_COLOR, // Set fill color to transparent
                        lineColor: ALERT_LINE_COLOR,
                        lineWidth: 0.2,
                    },
                    tooltip: {
                        valueSuffix: ` ${convertedUnit || unit}`,
                    },
                });
            }
            acc?.seriesData?.push({
                data: getConditionIndicatorsV2(conditionTrendIndicators),
                name: TREND_TYPE_TOOLTIP_SERIES_NAME,
                color: ALERT_LINE_COLOR,
                xAxis: 'x',
                id: `${crypto.randomUUID()}`,
                yAxis: yAxisId,
                linkedTo: ':previous',
                lineWidth: 0,
                states: {
                    hover: {
                        lineWidthPlus: 0,
                    },
                },
                marker: {
                    enabled: false,
                    radius: 1,
                    symbol: 'circle',
                    fillColor: MARKER_FILL_COLOR, // Set fill color to transparent
                    lineColor: ALERT_LINE_COLOR,
                    lineWidth: 0.2,
                },
                tooltip: {
                    valueSuffix: ` ${convertedUnit || unit}`,
                },
            });

            if (userLimits?.length) {
                acc.seriesData = [
                    ...acc.seriesData,
                    ...getUserLimitsSeries(userLimits, config, averages),
                ];
            }
        },
        ret
    );

    const allSeriesLength = ret.seriesData?.length
        ? ret.seriesData.reduce(
            (count, series) => series.data?.length + count,
            0
        )
        : 0;

    return { ...ret, allSeriesLength };
};

const useChartConfig = (
    selectedKpisData,
    userLimits,
    yAxisTitleVisible = true,
    removeKPIData,
    warningLevels,
    warningLines,
    conditionTrendIndicators,
    highErrorValue,
    highAlertLimitValue,
    lowErrorValue,
    lowAlertValue,
    unitGroupId,
    title,
    assetDetails,
    valueType
) => {
    const [options, setOptions] = useState({});
    const [isChartPloted, setIsChartPloted] = useState(false);
    const chartConfig = getChartConfig(removeKPIData, title, assetDetails);

    useEffect(() => {
        if (selectedKpisData) {
            setIsChartPloted(false);
        }
    }, [selectedKpisData]);

    useEffect(() => {
        if (!isChartPloted) {
            const { yAxis, seriesData, allSeriesLength } = getSeries(
                selectedKpisData,
                userLimits,
                yAxisTitleVisible,
                warningLevels,
                warningLines,
                conditionTrendIndicators,
                highErrorValue,
                highAlertLimitValue,
                lowErrorValue,
                lowAlertValue,
                unitGroupId,
                valueType
            );
            chartConfig.yAxis = yAxis;
            chartConfig.series = seriesData;
            chartConfig.plotOptions.series.boostThreshold =
                allSeriesLength > defaultBoostThreshold ? 1 : 0;
            chartConfig.lang.noData = renderToStaticMarkup(
                <ChartNoDataText
                    title={translate(
                        'ABB.Powertrain.Frontend.chartNoMeasurementData'
                    )}
                    text={translate(
                        'ABB.Powertrain.Frontend.chartNoMeasurementDataUserGuide'
                    )}
                />
            );
            chartConfig.enableNoDataZoom = true;
            chartConfig.tooltip.formatter = function () {
                const customIndicator = this.points?.filter((point) => point?.series?.name === TREND_TYPE_TOOLTIP_SERIES_NAME && point?.y === this?.y);
                if (customIndicator && customIndicator?.length > 0) {
                    const kpiPoint = this?.points?.length > 0 ? this?.points[0] : null;
                    return renderToStaticMarkup(
                        <ConditionIndicatorTooltip
                            points={customIndicator}
                            timeStamp={this.x}
                            indicatorType={customIndicator[0]?.point?.customObject}
                            kpiPoint={kpiPoint}
                            label={customIndicator[0]?.point?.KpiTypeLabel} />
                    );
                }
                return renderToStaticMarkup(
                    <TrendTooltip
                        points={this.points?.filter(
                            (point) => point?.series?.name !== CHART_SERIES_CONDITION_INDICATOR_NAME
                        )}
                        timeStamp={this.x}
                        isUTC={true}
                    />
                );
            };
            setIsChartPloted(true);
            setOptions({ ...chartConfig });
        }
    }, [
        selectedKpisData,
        userLimits,
        warningLevels,
        warningLines,
        conditionTrendIndicators,
        highErrorValue,
        highAlertLimitValue,
        valueType
    ]);

    return options;
};
export default useChartConfig;
