import { actionTypes } from './constants';


const initialState = {
    maintenanceSchedule: {
        data: {},
        error: null,
        loadingStatus: null
    },
    externalID: null,
};

export function reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_MAINTENANCE_SCHEDULE_SETTINGS:
            return {
                ...state,
                maintenanceSchedule: {
                    ...state.maintenanceSchedule,
                    ...action
                }
            };
        case actionTypes.SET_EXTERNAL_ID_FROM_CONVERT_ID:
            return {
                ...state,
                externalID: action.externalID
            };
        case actionTypes.ADD_MAINTENANCE_SCHEDULE_SETTINGS:
            return {
                ...state,
                maintenanceSchedule: { ...state.maintenanceSchedule, ...action },
            };
        case actionTypes.CLEAR_STORE:
            return initialState;
        default:
            return state;
    }
}

