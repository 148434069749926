import React from 'react';
import PropTypes from 'prop-types';
const IconSiteList = ({ width = 16, height = 16 }) => {
    return <svg width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M13 2V7H11V4L6 6.5V4L0 7V12C0 12.5304 0.210714 13.0391 0.585786 13.4142C0.960859 13.7893 1.46957 14 2 14H14C14.5304 14 15.0391 13.7893 15.4142 13.4142C15.7893 13.0391 16 12.5304 16 12V2H13ZM5 12H3V10H5V12ZM9 12H7V10H9V12ZM13 12H11V10H13V12Z' fill='#0F0F0F' />
    </svg>;
};
IconSiteList.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
export default IconSiteList;
