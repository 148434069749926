import * as actionTypes from './constants';
import { apiCallStatus } from 'helpers/constants';
import { getApiActionCreator } from 'helpers/actionHelper';

export const getChangedParameterListV2Action = getApiActionCreator(actionTypes.GET_CHANGED_PARAMETER_LIST_V2);

export const getChangedParameterFileListV2 = (asset, from, to) => ({
	type: actionTypes.GET_CHANGED_PARAMETER_LIST_V2_REQUEST,
	asset,
	from,
	to
});

export const clearChangedParameterFileData = () => {
	return (dispatch) => {
		dispatch(getChangedParameterListV2Action(apiCallStatus.SUCCESS, []));
	};
};
