import React, { useEffect } from 'react';
import DetailedInformationComponent from './DetailedInformationComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { LoadingStatusWrapper } from 'sharedComponents/WithLoadingStatus';
import { getModel } from './selectors';
import * as actions from './actions';
import { setIsDefaultLimitsSelected } from './OperationalParametersV2/SingleChartView/Trend/LimitSelector/actions';

import './style.scss';

const DetailedInformationContainer = (props) => {

    useEffect(() => {
        return () => {
            props.actions.clearStore();
        };
    }, []);

    return <LoadingStatusWrapper
        loadingStatus={
            props.model.generalFilterLoadingStatuses.organizationsLoadingStatus
        }>
        <DetailedInformationComponent {...props} />
    </LoadingStatusWrapper>;
};


const mapStateToProps = (state) => {
    const model = getModel(state);
    return {
        model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...actions, setIsDefaultLimitsSelected }, dispatch)
    };
};

const DetailedInformationContainerConn = connect(
    mapStateToProps,
    mapDispatchToProps
)(DetailedInformationContainer);

export default DetailedInformationContainerConn;
