
export const actionTypes = {
	GET_MAINTENANCE_SCHEDULE_SETTINGS: 'sensorfirmwaremanagement/edit/GET_MAINTENANCE_SCHEDULE_SETTINGS',
	ADD_MAINTENANCE_SCHEDULE_SETTINGS: 'sensorfirmwaremanagement/edit/ADD_MAINTENANCE_SCHEDULE_SETTINGS',
	CONVERT_ID_MAINTENANCE_SCHEDULE_SETTINGS: 'sensorfirmwaremanagement/edit/CONVERT_ID_MAINTENANCE_SCHEDULE_SETTINGS',
	CLEAR_STORE: 'sensorfirmwaremanagement/edit/CLEAR_STORE',
	SET_EXTERNAL_ID_FROM_CONVERT_ID: 'SET_EXTERNAL_ID_FROM_CONVERT_ID',

	//saga
	GET_MAINTENANCE_SCHEDULE_REQUEST: 'sensorfirmwaremanagement/edit/GET_MAINTENANCE_SCHEDULE_REQUEST',
	ADD_MAINTENANCE_SCHEDULE_REQUEST: 'sensorfirmwaremanagement/edit/ADD_MAINTENANCE_SCHEDULE_REQUEST',
	CONVERT_ID_MAINTENANCE_SCHEDULE_REQUEST: 'sensorfirmwaremanagement/edit/CONVERT_ID_MAINTENANCE_SCHEDULE_REQUEST'
};
