import { createSelector } from 'reselect';


const getAssetOverviewList = ({ conditionIndices }) => {
    return conditionIndices?.assets?.data;
};

const getAssetOverviewLoadingStatus = ({ conditionIndices }) => {
    return conditionIndices?.assets?.loadingStatus;
};

const getPageNumber = ({ conditionIndices }) => {
    return conditionIndices?.assets?.pageNumber;
};

const getHasMore = ({ conditionIndices }) => {
    return conditionIndices?.assets?.hasMore;
};
const getShowThreshold = ({ conditionIndices }) => {
    return conditionIndices?.assets?.showThreshold;
};
const getShowTrend = ({ conditionIndices }) => {
    return conditionIndices?.assets?.showTrend;
};

export const getModel = createSelector(
    [
        getAssetOverviewList,
        getAssetOverviewLoadingStatus,
        getPageNumber,
        getShowThreshold,
        getShowTrend,
        getHasMore
    ],
    (
        assetOverviewList,
        assetOverviewLoadingStatus,
        pageNumber,
        showThreshold,
        showTrend,
        hasMore
    ) => {
        return {
            assetOverviewList,
            assetOverviewLoadingStatus,
            pageNumber,
            showThreshold,
            showTrend,
            hasMore
        };
    }
);
