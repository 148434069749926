import React, { useEffect, useState, Suspense } from 'react';
// import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch, useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { GeneralDialog, GeneralNotificationContainer, LoadingIndicator } from 'sharedComponents';
import { popupTypes } from 'helpers/constants';
import useWindowSize from 'sharedHooks/useWindowSize';
import { minimumSupportedWindowWidth, portraitModeNotSupportedText } from './constants';

import { PowertrainOverview } from '../PowertrainOverview';
import { PowertrainConditionMonitoring } from '../PowertrainConditionMonitoring';
import { PowertrainTools } from '../PowertrainTools';
import { PowertrainAssetDetails } from '../PowertrainAssetDetails';
import { UserProfileSettings } from '../UserProfileSettings';
import { Login } from '../Login';
import { TokenExchange } from '../TokenExchange';
import { Logout } from '../Logout';
import { routes } from 'routes';
import './style.scss';
import { ReportsLazy } from '../Reports';
import featureConfigHelper from 'helpers/featureConfigHelper';
// import UserCapabilityHelper from '../../helpers/userCapabilityHelper';
// import { loginUrls } from '../Login/constants';


const PortalComponent = ({ defaultPage }) => {
    const { t: translate } = useTranslation();
    const featureConfig = new featureConfigHelper();
    const [showPortraitModeNotification, setShowPortraitModeNotification] = useState(false);
    const windowSize = useWindowSize();
    const history = useHistory();
    // const capabilities = useSelector(state => state?.overview?.userCapabilities);
    // const [isCapabilityChecked, setIsCapabilityChecked] = useState(false);

    // useEffect(() => {
    //     if (capabilities?.data?.capabilityInfos?.length > 0) {
    //         const userCapabilities = new UserCapabilityHelper(capabilities);
    //         if (!userCapabilities?.hasConditionMonitoringCapability()) {
    //             window.location.href = loginUrls.powertrainProductionSite;
    //         } else {
    //             setIsCapabilityChecked(true);
    //         }
    //     }
    // }, [capabilities]);

    useEffect(() => {
        history.replace(
            {
                pathname: history.location.pathname.replace('/2.0', ''),
                search: history.location.search
            });
    }, []);

    useEffect(() => {
        if (windowSize[0] && windowSize[0] < minimumSupportedWindowWidth) {
            setShowPortraitModeNotification(true);
        } else {
            showPortraitModeNotification && setShowPortraitModeNotification(false);
        }

    }, [windowSize]);

    return <div className='portal-component'>
        <div className='notification-section'>
            <GeneralNotificationContainer />
        </div>
        <GeneralDialog
            modalClassName='minimum-res-warning-modal'
            backdropClassName='minimum-res-warning-modal-backdrop'
            show={showPortraitModeNotification}
            close={() => setShowPortraitModeNotification(false)}
            title={translate ?
                translate('ABB.Powertrain.Frontend.portalPortraitModeNotificationTitle') :
                portraitModeNotSupportedText
            }
            acceptButtonProps={{
                text: translate ? translate('ABB.Powertrain.Frontend.okLabel') : 'OK',
                onClick: () => setShowPortraitModeNotification(false)
            }}
            notificationType={popupTypes.WARNING}
            persistent={true}
        />

        <Switch>
            {/* Unauthorized Routes */}
            <Route path='/Login' component={Login} exact={true} />
            <Route path='/signin-oidc' component={TokenExchange} exact={true} />
            <Route path='/signout-callback-oidc' component={Logout} exact={true} />

            {process.env.NODE_ENV === 'development' && <Route path='/sandbox' component={require('../Sandbox').default} />}

            {/* Authorized Routes */}

            <Route>
                <PowertrainOverview>
                    <Suspense fallback={<LoadingIndicator />}>
                        <Switch>
                            <Route path={routes.ConditionMonitoring} component={PowertrainConditionMonitoring} />
                            <Route path={routes.PowertrainAndAssetDetails} component={PowertrainAssetDetails} />
                            <Route path={routes.Tools} component={PowertrainTools} />
                            {featureConfig.isReportsFeatureEnabled() &&
                                <Route path={routes.Reports} component={ReportsLazy} />}
                            <Route path={routes.User} component={UserProfileSettings} />
                            <Redirect from='*' to={defaultPage} />
                        </Switch>
                    </Suspense >
                </PowertrainOverview>

            </Route>
        </Switch>
    </div>;
};

PortalComponent.propTypes = {
    defaultPage: PropTypes.any
};


export default PortalComponent;
