import { actionTypes } from './constants';

export const setShowLimitSetting = (data) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.SET_SHOW_INTERNAL_TAB_LIMIT_SETTING, data });
    };
};

export const setClickedConfigKpi = (data) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.SET_CLICKED_CONFIG_KPI, data });
    };
};
