import { createSelector } from 'reselect';
import { getAllAssets } from 'helpers/selectors';

const getNotificationList = ({ notificationPanel }) => {
    return notificationPanel?.notificationList;
};

const getAllNotificationList = ({ notificationPanel }) => {
    return notificationPanel?.allNotificationList;
};

const getNotificationListLoadingStatus = ({ notificationPanel }) => {
    return notificationPanel?.notificationLoadingStatus;
};

const getCurrentOrganization = ({ generalFilter }) => {
    return generalFilter?.selectedFilters?.organizations;
};

const checkHasMoreNotifications = ({ notificationPanel }) => {
    return notificationPanel?.hasMore;
};

export const getSelectedNotification = ({ notificationPanel }) => {
    return notificationPanel?.selectedNotification;
};

export const getModel = createSelector(
    [
        getNotificationList,
        getAllNotificationList,
        getNotificationListLoadingStatus,
        getCurrentOrganization,
        checkHasMoreNotifications,
        getAllAssets
    ],
    (
        notificationList,
        allNotificationList,
        notificationLoadingStatus,
        currentOrganization,
        hasMore,
        allAssets
    ) => {
        return {
            notificationList,
            allNotificationList,
            notificationLoadingStatus,
            currentOrganization,
            hasMore,
            allAssets
        };
    }
);
