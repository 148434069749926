import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/_colors.scss';

const IconSettings = ({ width = 14, height = 14, viewBox = '0 0 16 16', color = colors.black }) => {
    return <svg width={width} height={height} viewBox={viewBox} fill={color} xmlns='http://www.w3.org/2000/svg'>
        <path d='M16 9.14V6.86L14.08 6.45C13.9345 5.86995 13.7054 5.31414 13.4 4.8L14.46 3.15L12.85 1.54L11.2 2.6C10.6859 2.29457 10.13 2.06551 9.55 1.92L9.14 0H6.86L6.45 1.92C5.86995 2.06551 5.31414 2.29457 4.8 2.6L3.15 1.54L1.54 3.15L2.6 4.8C2.29457 5.31414 2.06551 5.86995 1.92 6.45L0 6.86V9.14L1.92 9.55C2.06551 10.13 2.29457 10.6859 2.6 11.2L1.54 12.85L3.15 14.46L4.8 13.4C5.31414 13.7054 5.86995 13.9345 6.45 14.08L6.86 16H9.14L9.55 14.08C10.13 13.9345 10.6859 13.7054 11.2 13.4L12.85 14.46L14.46 12.85L13.4 11.2C13.7054 10.6859 13.9345 10.13 14.08 9.55L16 9.14ZM8 10.86C7.24148 10.86 6.51403 10.5587 5.97767 10.0223C5.44132 9.48597 5.14 8.75852 5.14 8C5.14 7.24148 5.44132 6.51403 5.97767 5.97767C6.51403 5.44132 7.24148 5.14 8 5.14C8.75852 5.14 9.48597 5.44132 10.0223 5.97767C10.5587 6.51403 10.86 7.24148 10.86 8C10.86 8.75852 10.5587 9.48597 10.0223 10.0223C9.48597 10.5587 8.75852 10.86 8 10.86Z' fill={colors.black} />
    </svg>;
};

IconSettings.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    viewBox: PropTypes.string,
    color: PropTypes.string
};

export default IconSettings;
