import find from 'lodash/find';
import {
    apiCallStatus,
    userCapabilities,
} from 'helpers/constants';

export default class UserCapabilityHelper {

    constructor(capability) {
        if (capability) {
            this.userCapabilityList = capability?.data?.capabilityInfos;
            this.isUserCapabilityLoading = capability.loadingStatus && capability.loadingStatus === apiCallStatus.LOADING;
        }
    }

    hasCapability(capabilityToCheck) {
        let hasCapability = false;
        if (this.userCapabilityList) {
            const foundCapability = find(this.userCapabilityList, (capability) => capability.capabilityCode === capabilityToCheck);
            if (foundCapability) {
                hasCapability = foundCapability.isAvailable === true;
            }
        }
        return hasCapability;
    }

    isLoading() {
        return this.isUserCapabilityLoading;
    }

    isSet() {
        return !!this.userCapabilityList;
    }

    hasConditionMonitoringCapability() {
        return this.hasCapability(userCapabilities.conditionOverview);
    }

    hasNotificationSettingsCapability() {
        return this.hasCapability(userCapabilities.notificationSettings);
    }

    hasfleetOverviewReportDelete() {
        return this.hasCapability(userCapabilities.fleetOverviewReportDelete);
    }

    hasOperationalParametersLimitStatus() {
        return this.hasCapability(userCapabilities?.operationalParametersLimitStatus);
    }

    hasGatewayAdminViewCapability() {
        return this.hasCapability(userCapabilities.gatewayAdminView);
    }

    hasConditionOverviewSubscriptionFilter() {
        return this.hasCapability(userCapabilities.conditionOverviewSubscriptionFilter);
    }

    hasCiamViewCapability() {
        return this.hasCapability(userCapabilities.ciamView);
    }

    hasOperationalParametersMinMax() {
        return this.hasCapability(userCapabilities.operationalParametersMinMax);
    }

    hasGatewayMaintenanceModeCapability() {
        return this.hasCapability(userCapabilities.gatewayMaintenanceMode);
    }

    hasCopyOrganizationNotificationSettings() {
        return this.hasCapability(userCapabilities.copyOrganizationNotificationSettings);
    }

    hasMultiGraphTabViewCapability() {
        return this.hasCapability(userCapabilities.multiChartView);
    }

    hasParameterBackupLegacyView() {
        return this.hasCapability(userCapabilities.parameterBackupLegacyView);
    }

    hasParameterBackupView() {
        return this.hasCapability(userCapabilities.parameterBackupView);
    }

    hasChangedParameterListLegacyView() {
        return this.hasCapability(userCapabilities.changedParameterListLegacyView);
    }

    hasChangedParameterListView() {
        return this.hasCapability(userCapabilities.changedParameterListView);
    }

    hasVibrationFFTLegacyUserCapability() {
        return this.hasCapability(userCapabilities.fftChartLegacy);
    }

    hasVibrationFFTV2UserCapability() {
        return this.hasCapability(userCapabilities.fftChart);
    }
}

