import { getGeneralFilteredAssets } from 'helpers/selectors';
import { createSelector } from 'reselect';

const getIssueTypes = ({ reportIssueModal }) => {
    return reportIssueModal.issueTypes.data;
};

const getIssueTypesLoadingStatus = ({ reportIssueModal }) => {
    return reportIssueModal.issueTypes.loadingStatus;
};

const getReportIssueLoadingStatus = ({ reportIssueModal }) => {
    return reportIssueModal.reportData.loadingStatus;
};

const getSelectedGeneralFilterAsset = ({ generalFilter }) => {
    return generalFilter?.selectedFilters;
};

export const getModel = createSelector(
    [
        getGeneralFilteredAssets,
        getIssueTypes,
        getIssueTypesLoadingStatus,
        getReportIssueLoadingStatus,
        getSelectedGeneralFilterAsset
    ],
    (
        assets,
        issueTypes,
        issueTypesLoadingStatus,
        reportIssueLoadingStatus,
        selectedGeneralFilterAsset
    ) => {
        return {
            assets,
            issueTypes,
            issueTypesLoadingStatus,
            reportIssueLoadingStatus,
            selectedGeneralFilterAsset
        };
    }
);
