import authHelper from 'helpers/authHelper';

export const getUserHeaders = (apiToken, user, accessToken) => {

	const userHeaders = {
		'CS_Token': apiToken?.SSPToken ? apiToken.SSPToken : '',
		'Lang': user?.languageCode ? user.languageCode : 'en',
		'authorization': accessToken ? `Bearer ${accessToken}` : '',
		'CiamEnabled': true
	};

	return userHeaders;
};

/**
 * Creates generic header attributes and additional endpoint specific ones
 */
export const getEndpointHeaders = (endpoint) => {
	const apiToken = authHelper?.getAuthToken();

	const newHeader = {
		'Cache-Control': 'no-cache',
		'X-Requested-With': 'XMLHttpRequest',
		...endpoint.version && { 'API-Version': endpoint.version },
		...endpoint.featureCode && { 'FeatureCode': endpoint.featureCode },
        ...endpoint.authTokenAsCsToken && apiToken?.SSPToken && { 'authorization': `Bearer ${apiToken?.SSPToken}` }
	};

	return newHeader;
};
