import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import Asset from '../AssetRowComponent';
import { ScrollableList } from '../ScrollableListComponent';
import { pageSize } from '../constants';
import { formatConditionIndexOverviewRequest, filteredAssetData } from '../helpers';
import { apiCallStatus } from 'helpers/constants';


const AssetOverviewComponent = (props) => {
    const { t: translate } = useTranslation();
    const [timer, setTimer] = useState(null);
    const [showAsset, setShowAsset] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState({});
    const [finalAssetOverviewLoadingStatus, setFinalAssetOverviewLoadingStatus] = useState(null);
    const [filteredAssetOverviewList, setFilteredAssetOverviewList] = useState(null);
    const [isHorizontalScrollActive, setIsHorizontalScrollActive] = useState(false);
    const {
        pageNumber,
        assetOverviewLoadingStatus,
        assetOverviewList,
        hasMore,
    } = props.model;
    const { getAssetConditionIndicesRequest } = props.actions;
    const {
        assetNumber,
        selectedFilters,
        searchBoxFilter,
        conditionFilter,
        assetTypeFilter,
        subscriptionTypeFilter,
        withVisibleFilter,
        asset,
        allAssets
    } = props;
    const overviewData = useSelector(state => state?.overview?.assets);

    useEffect(() => {
        clearTimeout(timer);

        const newTimer = setTimeout(() => {
            getAssetConditionIndicesRequest(formatConditionIndexOverviewRequest(
                1,
                selectedFilters,
                searchBoxFilter,
                conditionFilter,
                assetTypeFilter,
                subscriptionTypeFilter
            ));
        }, searchBoxFilter?.length > 0 ? 700 : 0);

        setTimer(newTimer);
    }, [
        searchBoxFilter,
        JSON.stringify(selectedFilters),
        JSON.stringify(conditionFilter),
        assetTypeFilter,
        JSON.stringify(subscriptionTypeFilter)
    ]);

    const handleSelectedItem = (asset) => {
        setSelectedAsset(asset?.asset || asset);
    };

    useEffect(() => {
        handleSelectedItem(asset);
    }, [asset]);

    const handleReset = () => {
        setSelectedAsset(null);
    };

    const handleAssetToggle = useCallback(() => setShowAsset(prevShowAsset => !prevShowAsset), []);

    useEffect(() => {
        if (finalAssetOverviewLoadingStatus === apiCallStatus.LOADING) {
            setShowAsset(false);
        }
    }, [finalAssetOverviewLoadingStatus]);

    const onHorizontalScrollHandler = (isScroll) => {
        setIsHorizontalScrollActive(isScroll);
    };

    //TODO: Remove this logic when List API get replaced with micro-service
    useEffect(() => {
        if (overviewData?.data?.length > 0 && assetOverviewLoadingStatus === apiCallStatus.SUCCESS) {
            setFinalAssetOverviewLoadingStatus(apiCallStatus.SUCCESS);
            setFilteredAssetOverviewList(filteredAssetData(overviewData?.data, assetOverviewList));
        } else if (overviewData?.data?.length === 0 && assetOverviewLoadingStatus === apiCallStatus.SUCCESS) {
            setFinalAssetOverviewLoadingStatus(null);
        } else {
            setFinalAssetOverviewLoadingStatus(apiCallStatus.LOADING);
        }
    }, [overviewData, assetOverviewLoadingStatus, assetOverviewList]);

    const renderAssets = () => {
        //TODO: Replace this variable with `assetOverviewList` when List API get replaced with micro-service
        if (overviewData?.data?.length > 0) {
            const filteredAssetOverviewList = filteredAssetData(overviewData?.data, assetOverviewList);
            return map(filteredAssetOverviewList, (item) => {
                return <Asset
                    key={item.assetId}
                    selectedAsset={selectedAsset}
                    asset={item}
                    allAssets={allAssets}
                    onSelectedItem={handleSelectedItem}
                    onAssetToggle={handleAssetToggle}
                    showThreshold={props?.model?.showThreshold}
                    showTrend={props?.model?.showTrend}
                    isHorizontalScrollActive={isHorizontalScrollActive}
                />;
            });
        }
    };

    return <ScrollableList
        role='scrollable-list'
        noDataLabel={translate('ABB.Powertrain.Frontend.conditionIndicesOverviewNoItems')}
        className={` asset overview ${withVisibleFilter ? 'with-filter' : ''}`}
        assetNumber={assetNumber}
        loadingStatus={finalAssetOverviewLoadingStatus}
        hasMore={hasMore}
        showThreshold={props?.model?.showThreshold}
        showTrend={props?.model?.showTrend}
        pageSize={pageSize}
        loadMore={() => getAssetConditionIndicesRequest(formatConditionIndexOverviewRequest(
            pageNumber,
            selectedFilters,
            searchBoxFilter,
            conditionFilter,
            assetTypeFilter
        ))}
        renderList={renderAssets}
        selectedAsset={selectedAsset}
        hasData={filteredAssetOverviewList}
        asset={selectedAsset}
        onReset={handleReset}
        showAsset={showAsset}
        onHorizontalScroll={onHorizontalScrollHandler}
    />;
};

AssetOverviewComponent.propTypes = {
    model: PropTypes.shape({
        pageNumber: PropTypes.number,
        assetOverviewLoadingStatus: PropTypes.string,
        assetOverviewList: PropTypes.array,
        hasMore: PropTypes.bool
    }),
    actions: PropTypes.shape({
        getAssetConditionIndicesRequest: PropTypes.func
    }),
    assetNumber: PropTypes.number,
    selectedFilters: PropTypes.object,
    searchBoxFilter: PropTypes.string,
    conditionFilter: PropTypes.array,
    assetTypeFilter: PropTypes.string,
    withVisibleFilter: PropTypes.bool
};

export default AssetOverviewComponent;
