import { actionTypes } from './constants';
import { apiCallStatus, dataSources, ASSET_TYPES_MS } from 'helpers/constants';
import { getApiActionCreator } from 'helpers/actionHelper';
import { translate } from 'helpers/translateHelper';
import { notify } from 'sharedComponents/GeneralNotification';
import { NOTIFICATION_TYPES } from 'sharedComponents/GeneralNotification/constants';
import { ComponentApi } from 'api';
import { getSelectedAsset } from '../../selectors';
import { getSensorProperties } from '../../../sensorActions';
import { getSensorTypeValueFromCode } from '../../../helpers';

const loadMeasurementAction = getApiActionCreator(actionTypes.LOAD_MEASUREMENT);

export const loadMeasurement = () => {
    return async (dispatch, getState) => {
        const state = getState();
        const selectedAsset = getSelectedAsset(state);
        const sensorId = state?.detailedInformation?.assetDetails?.data?.details?.sensorId;
        const dataSource = selectedAsset?.assetType === ASSET_TYPES_MS?.DRIVE ? dataSources?.CMD4 : dataSources?.SMARTSENSOR;
        const sensor = state.sensorProperties.sensorProperties.data;
        const sensorTypeId = getSensorTypeValueFromCode(sensor.sensorType);
        const sensorIdentifier = state?.detailedInformation?.assetDetails?.data?.details?.sensorIdentifier;
        dispatch(loadMeasurementAction(apiCallStatus.LOADING));
        return await ComponentApi.loadMeasurement(sensorIdentifier, sensorTypeId, dataSource)
            .then((response) => {
                notify(translate('ABB.Powertrain.Frontend.ptNotificationMessageRequestSuccess'), NOTIFICATION_TYPES.SUCCESS);
                dispatch(loadMeasurementAction(apiCallStatus.SUCCESS, response));
                dispatch(getSensorProperties(sensorId));
            })
            .catch((err) => {
                notify(translate('ABB.Powertrain.Frontend.ptNotificationMessageRequestError'), NOTIFICATION_TYPES.ERROR, true);
                dispatch(loadMeasurementAction(apiCallStatus.ERROR, null, err));
            });
    };
};
