export const actionTypes = {
    GET_ASSET_TREND_DATA: 'operationalParameters_v2/GET_ASSET_TREND_DATA',
    ADD_TREND_KPI_SELECTION: 'operationalParameters_v2/ADD_TREND_KPI_SELECTION',
    ADD_SELECTED_TREND_KPI_DATA: 'operationalParameters_v2/ADD_SELECTED_TREND_KPI_DATA',
    RELOAD_ALL_SELECTED_TREND_KPI: 'operationalParameters_v2/RELOAD_ALL_SELECTED_TREND_KPI',
    SET_ZOOMED: 'operationalParameters_v2/SET_ZOOMED',
    REMOVE_TREND_KPI_SELECTION: 'operationalParameters_v2/REMOVE_TREND_KPI_SELECTION',
    REMOVE_TREND_KPI_SELECTION_LEGEND: 'operationalParameters_v2/REMOVE_TREND_KPI_SELECTION_LEGEND',
    GET_TREND_KPI_LIST_SELECTION: 'operationalParameters_v2/GET_TREND_KPI_LIST_SELECTION',
    CLEAR_UNMONITORED_CHART_DATA: 'operationalParameters_v2/CLEAR_UNMONITORED_CHART_DATA',
    RESET_TREND_KPI_SELECTION: 'operationalParameters_v2/RESET_TREND_KPI_SELECTION',
    SET_SELECTED_LIMITS: 'operationalParameters_v2/SET_SELECTED_LIMITS',
    SET_SELECTED_LIMITS_ACTION: 'operationalParameters_v2/SET_SELECTED_LIMITS_ACTION',
    SET_IS_DEFAULT_SELECTED_LIMITS: 'operationalParameters_v2/SET_IS_DEFAULT_SELECTED_LIMITS',
    // Maintain limits between signals and assets
    MAINTAIN_LIMITS_BETWEEN_SIGNALS_AND_ASSETS: 'operationalParameters_v2/MAINTAIN_LIMITS_BETWEEN_SIGNALS_AND_ASSETS',
};
