import React from 'react';
import PropTypes from 'prop-types';
import { NotificationTypeCode } from 'helpers/constants';
import colors from 'theme/_colors.scss';
import { IconDisconnected, IconFault, IconGateway, IconWarningType, IconMemoryFull, IconBatteryEmpty } from './index';


const IconNotificationCondition = ({ condition, width = '16', height = '16', viewBox = '0 0 16 16' }) => {

    const conditionGood = () => {
        return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width={width} height={height} fill={colors.jungleGreen} />
            <path fillRule='evenodd' clipRule='evenodd' d='M3.63623 8.72716L6.54532 11.6363L12.3635 5.81807L10.909 4.36353L6.54532 8.72716L5.09078 7.27262L3.63623 8.72716Z' fill={colors.white} />
        </svg>;

    };

    const conditionTolerable = () => {
        return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width={width} height={height} fill={colors.orangePeel} />
            <path fillRule='evenodd' clipRule='evenodd' d='M7.99989 3.63647L11.6363 10.9092H4.36353L7.99989 3.63647Z' fill={colors.white} />
        </svg>;

    };

    const conditionPoor = () => {
        return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width={width} height={height} fill={colors.pomegranate} />
            <rect x='6.54541' y='2.18188' width='2.90909' height='7.27273' fill={colors.white} />
            <rect x='6.54541' y='10.9091' width='2.90909' height='2.90909' fill={colors.white} />
        </svg>;
    };

    const warning = () => {
        return <IconWarningType width={width} height={height} viewBox='0 0 32 32' fill={colors.orangePeel} />;
    };

    const fault = () => {
        return <IconFault width={width} height={height} fill={colors.orangePeel} />;
    };

    const maintenanceWarning = () => {
        return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width='16' height='16' fill={colors.orangePeel} />
            <g clipPath='url(#clip0_18271_115158)'>
                <path d='M12.8413 4.80275L10.3738 7.2725L8.73129 5.6285L11.1988 3.1595L10.79 2.75H8.73804L6.68004 4.80275V7.682L4.87629
            9.4865L4.53279 9.83L3.12354 11.24C2.95612 11.3973 2.83806 11.5998 2.78373 11.823C2.72939 12.0462 2.74114 12.2804 2.81754
            12.497C2.88772 12.6787 3.00194 12.8401 3.14992 12.9667C3.2979 13.0934 3.47502 13.1813 3.66537 13.2225C3.85572 13.2638 4.05333
            13.2571 4.24047 13.2032C4.42761 13.1492 4.59841 13.0496 4.73754 12.9133L5.62779 12.0215L5.94954 11.6788L8.32179 9.326H11.1988L13.25
            7.2725V5.21975L12.8413 4.80275Z' fill='white' />
            </g>
            <defs>
                <clipPath id='clip0_18271_115158'>
                    <rect width='12' height='12' fill='white' transform='translate(2 2)' />
                </clipPath>
            </defs>
        </svg>;
    };

    const maintenancFault = () => {
        return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width='16' height='16' fill={colors.pomegranate} />
            <g clipPath='url(#clip0_18271_115158)'>
                <path d='M12.8413 4.80275L10.3738 7.2725L8.73129 5.6285L11.1988 3.1595L10.79 2.75H8.73804L6.68004 4.80275V7.682L4.87629
            9.4865L4.53279 9.83L3.12354 11.24C2.95612 11.3973 2.83806 11.5998 2.78373 11.823C2.72939 12.0462 2.74114 12.2804 2.81754
            12.497C2.88772 12.6787 3.00194 12.8401 3.14992 12.9667C3.2979 13.0934 3.47502 13.1813 3.66537 13.2225C3.85572 13.2638 4.05333
            13.2571 4.24047 13.2032C4.42761 13.1492 4.59841 13.0496 4.73754 12.9133L5.62779 12.0215L5.94954 11.6788L8.32179 9.326H11.1988L13.25
            7.2725V5.21975L12.8413 4.80275Z' fill='white' />
            </g>
            <defs>
                <clipPath id='clip0_18271_115158'>
                    <rect width='12' height='12' fill='white' transform='translate(2 2)' />
                </clipPath>
            </defs>
        </svg>;
    };

    const disconnected = () => {
        return <IconDisconnected width={width} height={height} />;
    };

    const gateway = () => {
        return <IconGateway width={width} height={height} />;
    };

    const trendWarning = () => {
        return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect x='1' y='1' width='14' height='14' fill='#FFA200' />
            <path d='M10.8454 11.399L12.3867 5.64678L6.63447 4.10547L6.21411 5.67426L9.3517 6.51498L2.7212 10.3431L3.48682 11.6692L10.1173 7.84108L9.27661 10.9787L10.8454 11.399Z' fill='white' />
        </svg>;
    };

    const trendFault = () => {
        return <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect x='1' y='1' width='14' height='14' fill='#EF3A34' />
            <path d='M12.5475 8.85772L11.0062 3.10547L5.25391 4.64678L5.67426 6.21557L8.81185 5.37486L4.98373 12.0054L6.30983 12.771L10.138 6.14048L10.9787 9.27807L12.5475 8.85772Z' fill='white' />
        </svg>;
    };

    const sensorBatteryLow = () => {
        return <IconBatteryEmpty width={width} height={height} color={colors.orangePeel} />;
    };

    const sensorMemoryFull = () => {
        return <IconMemoryFull width={width} height={height} />;
    };

    const success = () => {
        return <svg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M7 0C5.14348 0 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7C0 8.85652 0.737498 10.637 2.05025 11.9497C3.36301 13.2625 5.14348 14 7 14C8.85652 14 10.637 13.2625 11.9497 11.9497C13.2625 10.637 14 8.85652 14 7C14 5.14348 13.2625 3.36301 11.9497 2.05025C10.637 0.737498 8.85652 0 7 0ZM6 10L3 7L4 6L6 8L10 4L11 5L6 10Z' fill='#21A67A'/>
        </svg>;
    };

    const processed = () => {
        return <svg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M7 0C5.14348 0 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7C0 8.85652 0.737498 10.637 2.05025 11.9497C3.36301 13.2625 5.14348 14 7 14C8.85652 14 10.637 13.2625 11.9497 11.9497C13.2625 10.637 14 8.85652 14 7C14 5.14348 13.2625 3.36301 11.9497 2.05025C10.637 0.737498 8.85652 0 7 0ZM8 11H6V7H5V6H7C7.27756 6.02527 7.53518 6.15495 7.72081 6.36285C7.90643 6.57075 8.00622 6.84136 8 7.12V11ZM6.75 5C6.41848 5 6.10054 4.8683 5.86612 4.63388C5.6317 4.39946 5.5 4.08152 5.5 3.75C5.5 3.41848 5.6317 3.10054 5.86612 2.86612C6.10054 2.6317 6.41848 2.5 6.75 2.5C7.08152 2.5 7.39946 2.6317 7.63388 2.86612C7.8683 3.10054 8 3.41848 8 3.75C8 4.08152 7.8683 4.39946 7.63388 4.63388C7.39946 4.8683 7.08152 5 6.75 5ZM7 0C5.14348 0 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7C0 8.85652 0.737498 10.637 2.05025 11.9497C3.36301 13.2625 5.14348 14 7 14C8.85652 14 10.637 13.2625 11.9497 11.9497C13.2625 10.637 14 8.85652 14 7C14 5.14348 13.2625 3.36301 11.9497 2.05025C10.637 0.737498 8.85652 0 7 0ZM8 11H6V7H5V6H7C7.27756 6.02527 7.53518 6.15495 7.72081 6.36285C7.90643 6.57075 8.00622 6.84136 8 7.12V11ZM6.75 5C6.41848 5 6.10054 4.8683 5.86612 4.63388C5.6317 4.39946 5.5 4.08152 5.5 3.75C5.5 3.41848 5.6317 3.10054 5.86612 2.86612C6.10054 2.6317 6.41848 2.5 6.75 2.5C7.08152 2.5 7.39946 2.6317 7.63388 2.86612C7.8683 3.10054 8 3.41848 8 3.75C8 4.08152 7.8683 4.39946 7.63388 4.63388C7.39946 4.8683 7.08152 5 6.75 5Z' fill='#0051FF'/>
        </svg>;
    };

    const failed = () => {
        return <svg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M7 0C5.14348 0 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7C0 8.85652 0.737498 10.637 2.05025 11.9497C3.36301 13.2625 5.14348 14 7 14C8.85652 14 10.637 13.2625 11.9497 11.9497C13.2625 10.637 14 8.85652 14 7C14 5.14348 13.2625 3.36301 11.9497 2.05025C10.637 0.737498 8.85652 0 7 0ZM8 11H6V9H8V11ZM8 8H6V2H8V8Z' fill='#EF3A34'/>
        </svg>;
    };

    const subscriptionExpired = () => {
        return <svg width='15' height='16' viewBox='0 0 15 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M4.00539 0V2H10.0024V0H12.0024V2H14.0004V7H13.0014V4H1.00739V14H6.00439V15H1.28739C1.11573 15.0224 0.941239 15.0054 0.777136 14.9503C0.613034 14.8952 0.463635 14.8034 0.340271 14.682C0.216906 14.5606 0.122817 14.4126 0.0651401 14.2494C0.00746304 14.0862 -0.0122873 13.912 0.00738717 13.74V2H2.00739V0H4.00539Z' fill='#EF3A34'/>
            <path d='M10.5044 7C9.31091 7 8.16632 7.47411 7.32241 8.31802C6.47849 9.16193 6.00439 10.3065 6.00439 11.5C6.00439 12.6935 6.47849 13.8381 7.32241 14.682C8.16632 15.5259 9.31091 16 10.5044 16C11.6979 16 12.8425 15.5259 13.6864 14.682C14.5303 13.8381 15.0044 12.6935 15.0044 11.5C15.0044 10.3065 14.5303 9.16193 13.6864 8.31802C12.8425 7.47411 11.6979 7 10.5044 7ZM11.1472 14.0714H9.86153V12.7857H11.1472V14.0714ZM11.1472 12.1429H9.86153V8.28571H11.1472V12.1429Z' fill='#EF3A34'/>
        </svg>;
    };

    switch (condition) {
        case NotificationTypeCode.Warning: return warning();
        case NotificationTypeCode.Fault: return fault();
        case NotificationTypeCode.AssetConditionChangedAlarmShortTermTrend:
        case NotificationTypeCode.AssetConditionChangedAlarm:
        case NotificationTypeCode.AssetConditionChangedAlarmLongTermTrend: return trendFault();
        case NotificationTypeCode.AssetConditionChangedAlertShortTermTrend:
        case NotificationTypeCode.AssetConditionChangedAlert:
        case NotificationTypeCode.AssetConditionChangedAlertLongTermTrend: return trendWarning();
        case NotificationTypeCode.AssetLimitAlertReturnToNormalOperation:
        case NotificationTypeCode.AssetLimitAlarmReturnToNormalOperation: return conditionGood();
        case NotificationTypeCode.AssetLimitReachedAlert:
        case NotificationTypeCode.AssetConditionChangedAlertThresholdCrossing: return conditionTolerable();
        // case NotificationTypeCode.AssetConditionChangedAlertThreshold:
        case NotificationTypeCode.AssetLimitReachedAlarm:
        case NotificationTypeCode.AssetConditionChangedAlarmThresholdCrossing: return conditionPoor();
        // case NotificationTypeCode.AssetConditionChangedAlarmThreshold:
        case NotificationTypeCode.AssetDisconnected: return disconnected();
        case NotificationTypeCode.GatewayDisconnected: return gateway();
        case NotificationTypeCode.SensorBatteryLow: return sensorBatteryLow();
        case NotificationTypeCode.SensorMemoryFull: return sensorMemoryFull();
        // THIS ICONS ADDED FROM UI. NEED TO CHANGE KEY AS PER REQUIREMENT
        case NotificationTypeCode.ConditionBasedMaintenanceScheduleDue:
        case NotificationTypeCode.PreventiveMaintenanceKitScheduleDue: return maintenanceWarning();
        case NotificationTypeCode.ManualMaintenanceScheduleDue: return maintenancFault();
        case NotificationTypeCode.AssetNameplatePropertyChanged:
        case NotificationTypeCode.ParameterChanged:
        case NotificationTypeCode.LoadMeasurementProcessed:
        case NotificationTypeCode.TimeseriesDownloadNodata:
        case NotificationTypeCode.SensorReplaced: return processed();
        case NotificationTypeCode.LoadMeasurementRequestCompleted:
        case NotificationTypeCode.TimeseriesDownloadCompleted: return success();
        case NotificationTypeCode.TimeseriesDownloadFailed: return failed();
        case NotificationTypeCode.AssetSubscriptionExpired: return subscriptionExpired();
        default: return null;
    }

};

IconNotificationCondition.propTypes = {
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    viewBox: PropTypes.string,
    condition: PropTypes.string
};

export default IconNotificationCondition;
