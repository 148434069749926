import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getIcon } from 'sharedComponents/GeneralDialog/helpers';
import { popupTypes } from 'helpers/constants';
import './style.scss';
import { SearchBox } from 'sharedComponents/SearchBox';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import { Checkbox } from 'sharedComponents/Checkbox';
import { IconClose } from 'svgIcons/MotionPortalIcons';
import { getAssetIcon } from 'helpers/icons';

const BulkMuteAssetComponent = ({ bulkAssets = [], data = [], setData, showError, selectedFilters, organization }) => {
    const { t: translate } = useTranslation();
    const [searchFilter, setSearchFilter] = useState('');
    const [muteAssets, setMuteAssets] = useState(bulkAssets);
    const [selectAll, setSelectAll] = useState(false);

    const headerIcon = getIcon(popupTypes.INFO);
    const errorIcon = getIcon(popupTypes.ERROR);
    const selectedSet = new Set(data);

    useEffect(() => setData([]), [setData]);

    useEffect(() => {
        if (bulkAssets?.length > 0) {
            setSelectAll(bulkAssets.every(asset => selectedSet.has(asset?.id)));
        }
    }, [bulkAssets, selectedSet]);

    const handleToggle = useCallback((id) => {
        setData(prevData => prevData.includes(id) ? prevData.filter(item => item !== id) : [...prevData, id]);
    }, [setData]);

    const handleSelectAll = useCallback(() => {
        setSelectAll(prev => {
            const newSelectAll = !prev;
            setData(newSelectAll ? bulkAssets.map(asset => asset?.id) : []);
            return newSelectAll;
        });
    }, [bulkAssets, setData]);

    const handleSearch = useCallback((value) => {
        setSearchFilter(value);
        const lowerCaseValue = value?.toLowerCase();
        setMuteAssets(bulkAssets.filter(({ id, name, serialNumber }) =>
            id?.toString().includes(lowerCaseValue) || name?.toLowerCase().includes(lowerCaseValue) || serialNumber?.toLowerCase().includes(lowerCaseValue)
        ));
    }, [bulkAssets]);

    return (
        <>
            {showError &&
                <div className='bulk-error-icon'>
                    <span className='icon'>{errorIcon}</span>
                    <span>{translate('ABB.Powertrain.Frontend.MuteErrorAssets')}</span>
                </div>
            }
            <div className='bulk-header'>
                <span className='icon'>{headerIcon}</span>
                <span className='title' title={`${translate('ABB.Powertrain.Frontend.MuteAssetList')}: ${selectedFilters?.assetGroup?.name || organization?.name}`}>
                    {translate('ABB.Powertrain.Frontend.MuteAssetList')}: {selectedFilters?.assetGroup?.name || organization?.name}
                </span>
            </div>
            <div className='select-assets'>
                <div className='select-mute-assets'>
                    <h3 className='select-mute-assets__head'>{translate('ABB.Powertrain.Frontend.SelectMuteAssets')}</h3>
                    {bulkAssets?.length > 0 ?
                        <>
                            <div className='search-mute'>
                                <SearchBox
                                    placeholder={translate('ABB.Powertrain.Frontend.searchPlaceholderNameIDSerialNrText')}
                                    onChange={handleSearch}
                                    value={searchFilter}
                                />
                            </div>
                            {muteAssets?.length > 0 ?
                                <div className='mute-asset-list-container'>
                                    <div className='mute-asset-list select-all'>
                                        <Checkbox checked={selectAll} onToggle={handleSelectAll} width={'22px'} height={'22px'} />
                                        <div className='asset-list-bulk asset-list-bulk--select-all' onClick={handleSelectAll} >{translate('ABB.Powertrain.Frontend.SelectAllAssets')}</div>
                                    </div>
                                    <div className='all-assets-list'>
                                        {map(muteAssets, asset =>
                                            <div key={asset.id} className='mute-asset-list'>
                                                <Checkbox checked={selectedSet.has(asset?.id)} onToggle={() => handleToggle(asset?.id)} width={'22px'} height={'22px'} />
                                                <div className='asset-icon-container checkbox-content' onClick={() => handleToggle(asset?.id)}>{getAssetIcon(asset?.assetType, { width: 20, height: 20 })}</div>
                                                <div className='asset-list-bulk checkbox-content' title={asset.name} onClick={() => handleToggle(asset?.id)}>{asset.name}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                :
                                <div className='no-matches-found'>{translate('ABB.Powertrain.Frontend.NoMuteAssets')}</div>
                            }
                        </>
                        :
                        <div className='no-matches-found'>{translate('ABB.Powertrain.Frontend.NoMuteAssets')}</div>
                    }
                </div>
                <div className='muted-assets'>
                    <h3 className='muted-assets__head'>{translate('ABB.Powertrain.Frontend.selectedMuteAssets')} {data?.length > 0 ? `(${data?.length})` : ''}</h3>
                    {data?.length > 0 ?
                        bulkAssets.filter(asset => selectedSet.has(asset?.id)).map(asset =>
                            <div key={asset?.id} className='mute-asset-list'>
                                <div className='asset-list-bulk'>{asset?.name}</div>
                                <button onClick={() => handleToggle(asset?.id)}>
                                    <IconClose />
                                </button>
                            </div>
                        )
                        :
                        <div className='no-selected-asset'>{translate('ABB.Powertrain.Frontend.NoSelectedMuteAsset')}</div>
                    }
                </div>
            </div>
        </>
    );
};

BulkMuteAssetComponent.propTypes = {
    bulkAssets: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    setData: PropTypes.func.isRequired,
    showError: PropTypes.bool,
    selectedFilters: PropTypes.object,
    organization: PropTypes.object
};

export default BulkMuteAssetComponent;
