import forOwn from 'lodash/forOwn';
import map from 'lodash/map';
import { routes } from 'routes';
import { tabIDs, sensorTypeMappings } from './constants';
import { getUTCDateString, formatStartDate, formatEndDate } from 'helpers/dateHelper';
import {
    dataSources,
    DAY_IN_MS,
    chartColors,
    apiCallStatus,
} from 'helpers/constants';
// import React from 'react';
// import IconCondition from 'svgIcons/MotionPortalIcons/IconCondition';
import { getAssetDataSourceType } from 'helpers/assetAndPowertrainFilterHelper';

export const getTabOptions = (translate, isSSAsset, overallStatus, assetCapabilities, asset) => {
    const checkIsMonitored = isSSAsset ? !asset.isMonitored : false;
    return {
        assetDetails: {
            tabID: tabIDs.assetDetails,
            title: translate('ABB.Powertrain.Frontend.detailedInformationAssetDetailsTab'),
            route: routes.AssetDetails
        },
        operationalParametersV2: {
            tabID: tabIDs.operationalParametersv2,
            title: translate('ABB.Powertrain.Frontend.detailedInformationOperationalParametersTabV2'),
            route: routes.OperationalParametersV2,
            hidden: assetCapabilities?.isOperationalParameterV2Hidden || checkIsMonitored
        },
        eventLog: {
            tabID: tabIDs.eventLog,
            title: translate('ABB.Powertrain.Frontend.detailedInformationEventLogTab'),
            route: routes.EventLog,
            hidden: assetCapabilities?.isEventLogHidden || checkIsMonitored
        },
        // COMMENTED AS LIMIT CONFIGURATION MOVED TO OPV2
        // limitsConfiguration: {
        //     tabID: tabIDs.limitsConfiguration,
        //     title: translate('ABB.Powertrain.Frontend.detailedInformationLimitsConfigurationTab'),
        //     route: routes.LimitsConfiguration,
        //     hidden: assetCapabilities.isLimitConfigHidden
        // },
        sensorProperties: {
            tabID: tabIDs.sensorProperties,
            title: translate('ABB.Powertrain.Frontend.detailedInformationSensorPropertiesTab'),
            route: routes.SensorProperties,
            hidden: !isSSAsset
        },
        advancedAnalytics: {
            tabID: tabIDs.advancedAnalytics,
            title: translate('ABB.Powertrain.Frontend.advancedAnalyticsTab'),
            route: routes.AdvancedAnalytics,
            hidden: assetCapabilities.isAdvancedAnalyticsDisabled
        },
        conditionInsights: {
            tabID: tabIDs.conditionInsights,
            title: translate('ABB.Powertrain.Frontend.conditionInsightsTab'),
            // TODO: Command this title icon showing as per PBI 420374
            // titleIcon: overallStatus !== undefined ? <IconCondition condition={overallStatus} /> : null,
            route: routes.ConditionInsights,
            hidden: assetCapabilities?.hasConditionIndexNewCapability || assetCapabilities?.hasConditionDetailsCapability
        }
    };
};
// eslint-disable-next-line import/no-unused-modules
export const isSmSeAsset = (asset) => {
    return asset && getAssetDataSourceType(asset) === dataSources.SMARTSENSOR;
};
// eslint-disable-next-line import/no-unused-modules
export const getDefaultDateSelection = () => {
    const fromDate = new Date();
    fromDate.setTime(fromDate.getTime() - 7 * DAY_IN_MS);
    const formatedFromDate = formatStartDate(fromDate);

    const toDate = formatEndDate(new Date());
    toDate.setTime(toDate.getTime());

    return {
        from: getUTCDateString(formatedFromDate),
        to: getUTCDateString(toDate)
    };
};

export const getTabId = (tabOptions, route) => {
    let tabID = '';
    forOwn(tabOptions, tabOption => {
        if (tabOption.route === route) {
            tabID = tabOption.tabID;
        }
    });
    return tabID;
};

// eslint-disable-next-line import/no-unused-modules
export const getInitialMultiTrendCharts = (preselectedKPIs) => {
    return map(preselectedKPIs, (kpi) => {
        return {
            chartName: '',
            chartID: kpi,
            kpiList: [{
                color: chartColors[0],
                values: [],
                kpiIdentifier: kpi
            }],
            zoom: { isZoomed: false, from: null, to: null },
            loadingStatus: apiCallStatus.SUCCESS,
        };
    });
};

export const getSensorTypeValueFromCode = (SensorCode) => {
    return sensorTypeMappings[SensorCode];
};
