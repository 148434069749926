import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import { Checkbox, Switch, TimeSelectorRow } from 'sharedComponents';
import { getIcon } from 'sharedComponents/GeneralDialog/helpers';
import { popupTypes } from 'helpers/constants';
import { getNotificationGroupColumns, updateMutedGroupList, updateBulkMutedGroupList } from './helpers';
import './style.scss';

const EditMutedAssetComponent = ({
    notificationGroups,
    disabledForPeriod,
    setDisabledForPeriod,
    period,
    setPeriod,
    mutedGroups,
    setMutedGroups,
    muteAssets,
    bulkMutedGroups,
    setBulkMutedgroups,
    setShowMute,
    isGeneralMute = false,
    isBulkEdit = false
}) => {
    const { t: translate } = useTranslation();
    const headerIcon = getIcon(popupTypes.INFO);
    const [isSelectAll, setIsSelectAll] = useState(false);

    // Set bulk muted groups when notificationGroups change
    useEffect(() => {
        if (setBulkMutedgroups) {
            setBulkMutedgroups(updateBulkMutedGroupList(notificationGroups));
        }
    }, [notificationGroups, setBulkMutedgroups]);

    // Reset disabledForPeriod when bulk editing
    useEffect(() => {
        if (isBulkEdit) {
            setDisabledForPeriod(false);
        }
    }, [isBulkEdit, setDisabledForPeriod]);

    // Fix: To avoid setState during render warning
    useEffect(() => {
        if (isGeneralMute) {
            setShowMute(bulkMutedGroups?.some(group => group?.muted));
        }
    }, [bulkMutedGroups, isGeneralMute, setShowMute]);

    useEffect(() => {
        const allMuted = (isBulkEdit || muteAssets ? bulkMutedGroups : mutedGroups)
            ?.every(group => group.muted);
        setIsSelectAll(allMuted);
    }, [mutedGroups, bulkMutedGroups, isBulkEdit, muteAssets]);

    // Toggle select all
    const toggleSelectAll = () => {
        const updatedGroups = notificationGroups.map(group => ({
            ...group,
            muted: !isSelectAll
        }));

        if (isBulkEdit || muteAssets) {
            setBulkMutedgroups(updatedGroups);
        } else {
            setMutedGroups(updatedGroups);
        }
        setIsSelectAll(!isSelectAll);
    };

    // Toggle individual notification group mute
    const toggleGroupMute = (notificationGroup) => {
        if (isBulkEdit || muteAssets) {
            setBulkMutedgroups(updateMutedGroupList(bulkMutedGroups, notificationGroup));
        } else {
            setMutedGroups(updateMutedGroupList(mutedGroups, notificationGroup));
        }
    };

    // Render each notification group with checkbox
    const renderNotificationGroupWithCheckbox = (notificationGroup) =>
        <div className='mute-notification-group' key={notificationGroup?.notificationGroupCode}>
            <Checkbox
                checked={notificationGroup.muted !== null ? notificationGroup.muted : true}
                onToggle={() => toggleGroupMute(notificationGroup)}
                width={'22px'}
                height={'22px'}
            />
            <div className='group-name' onClick={() => toggleGroupMute(notificationGroup)}>
                {translate(notificationGroup?.notificationGroupNameTextKey) || notificationGroup?.notificationGroupName}
            </div>
        </div>
        ;

    // Render all notification groups
    const renderNotificationGroups = () => {
        const columns = isBulkEdit || muteAssets
            ? getNotificationGroupColumns(bulkMutedGroups)
            : getNotificationGroupColumns(mutedGroups);

        return map(columns, (column) =>
            <div className='column' key={column.id}>
                {map(column.columnData, renderNotificationGroupWithCheckbox)}
            </div>
        );
    };

    return (
        <div className='edit-muted-asset-container'>
            <div className='bulk-edit-info-message'>
                <span className='icon'>{headerIcon}</span>
                <span className='title'>{translate('ABB.Powertrain.Frontend.mutedAssetMuteSelectionInfoMessage')}</span>
            </div>
            <div className='notification-group-selection'>
                <div className='section-title'>
                    <div className='title-text'>
                        {translate('ABB.Powertrain.Frontend.mutedAssetsSelectedNotificationGroups')}
                    </div>
                    <div className='select-all'>
                        <a className='select-all__link' onClick={toggleSelectAll}>
                            {isSelectAll
                                ? translate('ABB.Powertrain.Frontend.mutedAssetUnselectAll')
                                : translate('ABB.Powertrain.Frontend.mutedAssetSelectAll')}
                        </a>
                    </div>
                </div>
                <div className='notification-groups-list'>{renderNotificationGroups()}</div>
            </div>
            <div className='period-selection'>
                <div className='section-title'>
                    <Switch
                        onChange={() => {
                            setDisabledForPeriod(!disabledForPeriod);
                            if (!disabledForPeriod) {
                                const today = new Date();
                                today.setHours(0, 0, 0, 0);
                                const toDate = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
                                toDate.setHours(23, 59, 59, 999);
                                setPeriod({ from: today, to: toDate });
                            } else {
                                setPeriod(null);
                            }
                        }}
                        checked={disabledForPeriod}
                    />
                    <div className='title-text'>
                        {translate('ABB.Powertrain.Frontend.mutedAssetsSelectedPeriod')}
                    </div>
                </div>
                {disabledForPeriod &&
                    <div className='mute-period'>
                        <TimeSelectorRow
                            fromDate={period?.from}
                            toDate={period?.to}
                            handleSelectDate={(from, to) => setPeriod({ from, to })}
                            maxDaysBack={0}
                            numberOfMonths={2}
                            withPredefinedSelection={false}
                            disableFuture={false}
                        />
                    </div>
                }
            </div>
        </div>
    );
};

EditMutedAssetComponent.propTypes = {
    disabledForPeriod: PropTypes.bool,
    setDisabledForPeriod: PropTypes.func,
    period: PropTypes.object,
    setPeriod: PropTypes.func,
    mutedGroups: PropTypes.array,
    setMutedGroups: PropTypes.func,
    notificationGroups: PropTypes.array,
    setShowMute: PropTypes.func,
    isGeneralMute: PropTypes.bool,
    isBulkEdit: PropTypes.bool
};

export default EditMutedAssetComponent;
