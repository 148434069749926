import React from 'react';
import { Container } from 'react-bootstrap';
import { InfoMessage, LoadingStatusWrapper, PopOutChart } from 'sharedComponents';
import { histogramContainerId } from './constants';
import useChartConfig, { chartPlugins } from './useChartConfig';

import '../style.scss';

const HistogramComponent = ({
    chartData,
    removeKPIData,
    chartLoadingStatus,
    isZoomed,
    setIsZoomed
}) => {
    const chartOptions = useChartConfig(chartData, removeKPIData);

    return (
        <Container fluid className='cross-asset-chart-container'>
            <LoadingStatusWrapper
                useCustomAnchor
                transparent
                loadingStatus={chartLoadingStatus}>
                <PopOutChart
                    hasKpi={!!chartData?.length}
                    hasData={!!chartData?.length}
                    renderNoKpi={() => <InfoMessage
                        title='ABB.Powertrain.Frontend.chartNoKPIsSelected'
                        text='ABB.Powertrain.Frontend.chartNoKPIsSelectedUserGuide' />}
                    renderNoData={() => <InfoMessage
                        title='ABB.Powertrain.Frontend.chartNoMeasurementData'
                        text='ABB.Powertrain.Frontend.chartNoMeasurementDataUserGuide' />}
                    options={chartOptions}
                    modules={chartPlugins}
                    container={histogramContainerId}
                    isZoomed={isZoomed}
                    setIsZoomed={setIsZoomed}
                    hideDownloadButton={true}
                />
            </LoadingStatusWrapper></Container>
    );
};

export default HistogramComponent;
