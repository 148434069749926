import React from 'react';
import PropTypes from 'prop-types';
import { Expandable } from 'sharedComponents';
import { EventTypesIconWithName } from '../constants';
import './style.scss';

const EventCategory = (props) => {
    return (
        <Expandable
            title={
                <div className='event-group'>
                    <span className='event-type-icon'>{EventTypesIconWithName.find(et => et.typeID === props?.category)?.icon}</span>
                    <span className='event-name'>{props.name}</span>
                </div>
            }
            open={props?.isExpanded}
            onClick={() => {
                props.setIsExpanded(props?.isExpanded);
            }}
        >
            {props.children}
        </Expandable>
    );
};

EventCategory.propTypes = {
    category: PropTypes.string,
    name: PropTypes.string,
    isExpanded: PropTypes.bool,
    setIsExpanded: PropTypes.func,
    children: PropTypes.object,
    key: PropTypes.number
};

export default EventCategory;
