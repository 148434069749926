import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Tippy from '@tippy.js/react';
import { IconHistory } from 'svgIcons/MotionPortalIcons';


const DataLoggerHistoryTooltip = ({ command, userName }) => {
    const { t: translate } = useTranslation();

    const tippyContent = () => {
        return <div className='data-logger__history-tooltip'>
            <div className='data-logger-history-tooltip-content'>
                    <div className='data-logger-history__content-item text-left'>{`${translate('ABB.Powertrain.Frontend.dataLoggerTriggerLastActionLabel')}: ${command}`}</div>
                    <div className='data-logger-history__content-item text-left'>{`${translate('ABB.Powertrain.Frontend.dataLoggerTriggerByLabel')} ${userName} `}</div>
                    <div>DD:MM:YYYY HH:MM:SS</div>
            </div>
        </div>;
    };


    return (
        <Tippy
        content={tippyContent()}
            className='info-button-with-online-help-tippy'
            interactive={true}
            interactiveBorder={10}
            animation='scale'
            theme='light-border'
            trigger='click'
            placement='bottom'
            maxWidth='250px'
        >
           <button className='data-logger__history-tooltip ml-2'>
                <IconHistory />
            </button>
        </Tippy>
    );
};

DataLoggerHistoryTooltip.propTypes = {
    command: PropTypes.string,
    username: PropTypes.string
};

export default DataLoggerHistoryTooltip;
