import React from 'react';
import { IconDownload, IconFile } from 'svgIcons/MotionPortalIcons';
import { downloadFile, b64toBlob } from 'helpers/download-file-util';
import { convertUtcToLocalDateTime, getUTCDateString } from 'helpers/dateHelper';
import { dateKind } from '../../../constants';

export const downloadLoggerFile = (fileData, fileName) => {
    if (fileData) {
        const blob = b64toBlob(fileData);
        downloadFile(fileName, blob);
    }
};


export const getLoggerTableColumnConfig = (translate, downloadLogger, loggers, selectedDateKind, loggerId) => {
    return [
        {
            title: translate('ABB.Powertrain.Frontend.loggerFileNameColumnLabel'),
            dataIndex: 'fileFullName',
            className: 'logger-file-name',
            fixed: 'left',
            isSorting: true,
            // eslint-disable-next-line react/display-name
            render: (fileFullName) => <div className='file-name-cell'>
                <IconFile className='file-icon' /> <span className='file-name-text'>{fileFullName || loggers?.length && loggers[0]?.name}</span>
            </div>
        },
        {
            title: translate('ABB.Powertrain.Frontend.timeColumnLabel'),
            dataIndex: 'timestamp',
            className: 'logger-time',
            isSorting: true,
            render: (timestamp) => selectedDateKind === dateKind.Local ? convertUtcToLocalDateTime(timestamp) : getUTCDateString(new Date(timestamp))
        },
        {
            title: '',
            className: 'logger-download-icon',
            // eslint-disable-next-line react/display-name
            render: () => <div className='download-logger'>
                <div className='download-icon'>
                    <IconDownload width={'14px'} height={'14px'} />
                </div>
            </div>,
            onCell: (logger) => ({
                onClick: () => downloadLogger(logger, loggerId)
            })
        }
    ];
};
