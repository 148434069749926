import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import { useTranslation } from 'react-i18next';
import { ScrollToElementWrapper } from 'sharedComponents/ScrollToElementWrapper';
import AssetItemComponent from './AssetItemComponent';
import { apiCallStatus } from 'helpers/constants';
import { useQueryParams } from 'sharedHooks/useQuery';
import { useKPILoadingTrigger } from './helpers';

const AssetList = ({
    assets,
    selectedAsset,
    selectedAssetCapability,
    onAssetClick,
    selectedKPIType,
    getKPIsForKPIType,
    getTrendKPIsForAssetV2,
    selectedPowertrain,
    crossAssetAnalysisActiveTab,
    setKpi,
    chartData,
    subscription,
    capabilityLoadingStatus,
    getSubscriptionInfo
}) => {
    const { t: translate } = useTranslation();
    const { assetId: assetIdFromQuery } = useQueryParams();
    const [expandableCountData, setExpandableCountData] = useState([]);

    useEffect(() => {
        if (selectedAsset?.id) {
            const assetIdParams = { assetIds: [selectedAsset.id], type: null, statusCodes: [], maxEndDate: null };
            getSubscriptionInfo(assetIdParams);
        }
    }, [selectedAsset]);

    // Updated selected asset capability only when subscription data is available
    const updatedSelectedAssetCapability = {
        ...selectedAssetCapability,
        data: {
            ...selectedAssetCapability.data,
            contractInfo: subscription
        }
    };

    useEffect(() => {
        if (selectedAssetCapability.loadingStatus === apiCallStatus.SUCCESS) {
            if (selectedKPIType !== 1) {
                getKPIsForKPIType(selectedAsset, selectedAssetCapability, selectedKPIType);
            }
            else {
                getTrendKPIsForAssetV2(selectedAsset, selectedAssetCapability, selectedKPIType);
            }
        }
    }, [selectedAssetCapability.data, crossAssetAnalysisActiveTab]);

    const getEmptyAssetListLabel = () => {
        return !selectedPowertrain?.powertrainId ? <div className='asset-list-empty'>{translate('ABB.Powertrain.Frontend.noItemSelectedLabel')}</div> :
            <div className='asset-list-empty'>{translate('ABB.Powertrain.Frontend.noAssetsAssignedToPowertrainLabel')}</div>;
    };

    useEffect(() => {
        let loadChartData = [];
        const intialCount = 1;
        if (chartData?.length > 0) {
            chartData?.map(data => {
                const isExistObj = loadChartData.find(item => item?.componentID === data?.component?.id);
                if (!isExistObj) {
                    loadChartData.push({
                        componentID: data?.component?.id,
                        componentName: data?.component?.name,
                        count: intialCount
                    });
                } else {
                    isExistObj.count += 1;
                }
            });
        }
        if (chartData?.length === 0) {
            loadChartData = [];
        }
        setExpandableCountData(loadChartData);
    }, [chartData]);

    return (
        <div className='asset-list'>
            {assets?.length ? map(sortBy(assets, (asset) => (asset.name ?? '').toLowerCase()), (asset, key) => {
                const getKPICount = expandableCountData.find(data => data?.componentID === asset?.id)?.count;
                return asset && <ScrollToElementWrapper
                    key={key}
                    condition={assetIdFromQuery === asset?.id?.toString()}
                    customTrigger={useKPILoadingTrigger}>
                    <AssetItemComponent
                        asset={asset}
                        selectedAsset={selectedAsset}
                        selectedAssetCapability={updatedSelectedAssetCapability}
                        capabilityLoadingStatus={capabilityLoadingStatus}
                        onAssetClick={onAssetClick}
                        setKpi={setKpi}
                        chartData={chartData}
                        selectedKPIType={selectedKPIType}
                        getKPIsForKPIType={getKPIsForKPIType}
                        getTrendKPIsForAssetV2={getTrendKPIsForAssetV2}
                        crossAssetAnalysisActiveTab={crossAssetAnalysisActiveTab}
                        kpiCount={getKPICount}
                    /></ScrollToElementWrapper>;
            }) : getEmptyAssetListLabel()
            }
        </div>
    );
};

AssetList.propTypes = {
    assets: PropTypes.any,
    selectedAsset: PropTypes.any,
    selectedAssetCapability: PropTypes.any,
    selectedKPIType: PropTypes.any,
    setSelectedAsset: PropTypes.func,
    getKPIsForKPIType: PropTypes.func,
    getTrendKPIsForAssetV2: PropTypes.func,
    selectedPowertrainID: PropTypes.any,
    subscription: PropTypes.any,
    capabilityLoadingStatus: PropTypes.any,
    getSubscriptionInfo: PropTypes.func
};

export default AssetList;
