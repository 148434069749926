import { createSelector } from 'reselect';
import { getFormattedAssets } from '../../../PowertrainAssetDetails/selectors';
import { getAssetsLoadingStatus } from 'helpers/selectors';

const getSelectedAssets = ({ notificationSettings }) => {
    return notificationSettings?.selectedAssets;
};

const getMutedAssetsLoadingStatus = ({ notificationSettings }) => {
    return notificationSettings?.mutedAssets?.loadingStatus;
};

const getMutedAssetList = ({ notificationSettings }) => {
    return notificationSettings?.mutedAssets?.data || [];
};

const getAssetMuteSettings = ({ notificationSettings }) => {
    return notificationSettings?.assetMuteSettings?.data;
};

const getAssetMuteSettingsLoadingStatus = ({ notificationSettings }) => {
    return notificationSettings?.assetMuteSettings?.loadingStatus;
};

const getUpdateAssetMuteSettingsLoadingStatus = ({ notificationSettings }) => {
    return notificationSettings?.updateAssetMuteSettingsLoadingStatus;
};

const getSelectedFilterCountries = createSelector([
    ({ generalFilter }) => generalFilter.selectedFilters,
], (
    selectedFilters,
) => {
    return selectedFilters;
});

const getMutedAssets = createSelector([
    getMutedAssetsLoadingStatus,
    getMutedAssetList
], (
    mutedAssetsLoadingStatus,
    assetList
) => {
    return {
        mutedAssetsLoadingStatus,
        assetList
    };
});

export const getModel = createSelector(
    [
        getSelectedAssets,
        getMutedAssets,
        getAssetMuteSettings,
        getAssetMuteSettingsLoadingStatus,
        getUpdateAssetMuteSettingsLoadingStatus,
        getSelectedFilterCountries,
        getFormattedAssets,
        getAssetsLoadingStatus
    ],
    (
        selectedAssets,
        mutedAssets,
        assetMuteSettings,
        assetMuteSettingsLoadingStatus,
        updateAssetMuteSettingsLoadingStatus,
        selectedFilters,
        bulkAssets,
        bulkAssetsLoadingStatus
    ) => {
        return {
            selectedAssets,
            mutedAssets,
            assetMuteSettings,
            assetMuteSettingsLoadingStatus,
            updateAssetMuteSettingsLoadingStatus,
            selectedFilters,
            bulkAssets,
            bulkAssetsLoadingStatus
        };
    }
);
