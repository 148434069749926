import { actionTypes } from './constants';
import { getAllKPITrendChartList, getDriveKPIList } from './helpers';
const initialState = {
    conditionInsightsTrendKpis: {
        trendKPI: [],
    },
    conditionInsightsDriveStatus: {
        driveKPI: null,
    },
    assetInfo: {
        assetId: null,
        conditionStatus: null
    },
    loadingStatus: '',
    zoom: {
        isZoomed: false,
        from: null,
        to: null
    }

};

// eslint-disable-next-line import/no-unused-modules
export const reducer = (state = initialState, action) => {
    switch (action.type) {
        //COMMON SECTION
        case actionTypes.GET_ASSET_KPI_TREND_LIST: {
            return {
                ...state,
                conditionInsightsTrendKpis: {
                    trendKPI: getAllKPITrendChartList(action?.data
                        ?.trendChartKPIS, state?.conditionInsightsTrendKpis?.trendKPI)
                },
                conditionInsightsDriveStatus: {
                    driveKPI: getDriveKPIList(action?.data?.trendChartKPIS)
                },
                assetInfo: {
                    conditionStatus: action?.data?.assetConditionStatus,
                    assetId: action?.data?.assetID
                },
                loadingStatus: action?.loadingStatus
            };
        }
        case actionTypes.ADD_SELECTED_TREND_KPI_DATA: {
            return {
                ...state,
                zoom: {
                    ...state.zoom,
                    isZoomed: action?.data?.isZoomed,
                    from: action?.data?.from,
                    to: action?.data?.to
                }
            };
        }
        case actionTypes.RELOAD_CONDITION_INSIGHTS_SUCCESS: {
            return {
                ...state,
                zoom: {
                    ...state.zoom,
                    isZoomed: action?.data?.isZoomed,
                    from: action?.data?.from,
                    to: action?.data?.to
                }
            };
        }
        case actionTypes.SET_CONDITION_INSIGHTS_ZOOM_SUCCESS: {
            return {
                ...state,
                zoom: {
                    ...state.zoom,
                    isZoomed: action?.data?.isZoomed
                }
            };
        }
        default:
            return state;
    }
};
