/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/no-unused-modules
import React from 'react';
import PropTypes from 'prop-types';
import { map, round } from 'lodash';
import { Col } from 'react-bootstrap';
import { IconInfo } from 'svgIcons/MotionPortalIcons';
import { InfoMessage } from 'sharedComponents';
import { useTranslation } from 'react-i18next';
import { TrendChart } from '../SingleChartView/Trend/TrendChart';
import { getChartConfigForTrends } from './helpers';

const KPITrendChartListItem = (props) => {
    const { t: translate } = useTranslation();
    const {
        data,
        key,
        from,
        to,
        alertAlarmLimitBackgrounds,
        alertAlarmLimitLines,
        conditionIndicators,
        title,
        nameTextKey,
        loadingStatus,
        conditionStatus,
        zoom,
        unit,
        highErrorValue,
        highAlertLimitValue,
        lowErrorValue,
        lowAlertValue,
        unitGroupId,
        valueType

    } = props.model;
    return (
        <div
            className='conditional-insight-kpi-list'
        >
            <TrendChart
                title={title}
                actions={{
                    reloadAllSelectedTrendKpiData: props?.actions?.reloadConditionInsights,
                    setIsZoomed: props?.actions?.setIsZoomed,
                    zoomInToSelectedTrendKpiData: props?.actions?.zoomInToSelectedTrendKpiData,
                }}
                model={{
                    selectedTrendKPIs: [key],
                    enableUniqueScaling: false,
                    lastMeasurementTimeStampLabel: title,
                    selectedTrendsData: {
                        loadingStatus: loadingStatus,
                        //data: getChartConfigForTrends(data, key, title, key, unit, true),
                        data: getChartConfigForTrends(data, key, title, key, unit, false),
                        zoom: {
                            isZoomed: zoom,
                            from: from,
                            to: to
                        },
                        chartID: 'op-trendselectedTrendsData-chart',
                    },
                    //userLimits: [3, 5],
                    assetOverAllStatus: conditionStatus,
                    title: title,
                    warningLevels: alertAlarmLimitBackgrounds,
                    warningLines: alertAlarmLimitLines,
                    conditionTrendIndicators: conditionIndicators,
                    highErrorValue: highErrorValue,
                    highAlertLimitValue: highAlertLimitValue,
                    lowErrorValue: lowErrorValue,
                    lowAlertValue: lowAlertValue,
                    unitGroupId: unitGroupId,
                    valueType: valueType,
                }}
                assetId={props.assetId}
                assetDetails={props?.assetDetails}
            ></TrendChart>
        </div>
    );
};
KPITrendChartListItem.propTypes = {
    model: PropTypes.shape({
        data: PropTypes.array,
        key: PropTypes.string,
        from: PropTypes.string,
        to: PropTypes.string,
        alertAlarmLimitBackgrounds: PropTypes.array,
        alertAlarmLimitLines: PropTypes.array,
        conditionIndicators: PropTypes.array,
        title: PropTypes.string,
        nameTextKey: PropTypes.string,
        loadingStatus: PropTypes.string,
        conditionStatus: PropTypes.number,
        zoom: PropTypes.bool,
        unit: PropTypes.string,
        highErrorValue: PropTypes.number,
        highAlertLimitValue: PropTypes.number,
        lowErrorValue: PropTypes.number,
        lowAlertValue: PropTypes.number,
        unitGroupId: PropTypes.number,
        valueType: PropTypes.string

    }),
    assetDetails: PropTypes.object
};
// eslint-disable-next-line import/no-unused-modules
export default KPITrendChartListItem;
