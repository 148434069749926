import { translate } from 'helpers/translateHelper';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GeneralDialog, TextArea, GeneralSelect, LoadingStatusWrapper } from 'sharedComponents';
import { maxCommentSize } from './constants';
import {
    checkAffectedAssetContext,
    formatAssets,
    formatIssueObjectData,
    formatIssueTypes,
    formatSubIssueTypes,
    getSelectedAssetFromURL,
    isCancelDisabled,
    isProblemTypeVisible,
    isReportDisabled
} from './helpers';

import './style.scss';


const ReportIssueModalComponent = ({ show, model, actions, closeModal }) => {
    const userId = useSelector(({ overview }) => overview.user.data?.userID);
    const organizationName = model?.selectedGeneralFilterAsset?.organizations[0]?.name;
    const dataSourceType = model?.selectedGeneralFilterAsset?.organizations[0]?.dataSourceType;

    const [selectedAssetId, setSelectedAssetId] = useState(getSelectedAssetFromURL());
    const [affectedAsset, setAffectedAsset] = useState(null);
    const [issueDescription, setIssueDescription] = useState('');
    const [problemCategory, setProblemCategory] = useState(null);
    const [problemType, setProblemType] = useState(null);

    const formattedAssets = formatAssets(model.assets);
    const issueObject = formatIssueObjectData(
        userId,
        organizationName,
        dataSourceType,
        affectedAsset,
        issueDescription,
        problemCategory,
        problemType
    );

    useEffect(() => {
        if (show) {
            actions.getIssueTypes();
            setSelectedAssetId(getSelectedAssetFromURL());
            checkAffectedAssetContext(model.assets, selectedAssetId, formattedAssets, setAffectedAsset);
        }
    }, [show]);

    useEffect(() => {
        setProblemType(null);
    }, [problemCategory]);

    useEffect(() => {
        checkAffectedAssetContext(model.assets, selectedAssetId, formattedAssets, setAffectedAsset);
    }, [selectedAssetId]);

    return (
        <GeneralDialog
            id='report-issue-modal'
            show={show}
            closeButton={!isCancelDisabled(model)}
            cancelButtonProps={{
                text: translate('ABB.Powertrain.Frontend.cancelLabel'),
                disabled: isCancelDisabled(model)
            }}
            close={() => {
                closeModal();
                actions.clearStore();
            }}
            title={translate('ABB.Powertrain.Frontend.mainMenu.reportIssue')}
            acceptButtonProps={{
                text: translate('ABB.Powertrain.Frontend.reportButton'),
                disabled: isReportDisabled(
                    model,
                    affectedAsset,
                    issueDescription,
                    problemCategory,
                    problemType
                ),
                onClick: () => {
                    actions.reportIssue(
                        issueObject,
                        closeModal
                    );
                }
            }}
        >
            <LoadingStatusWrapper
                loadingStatus={[model.issueTypesLoadingStatus, model.reportIssueLoadingStatus]}
                transparent
                useCustomAnchor
            >
                <div className='input-wrapper'>
                    <div className='label'>{translate('ABB.Powertrain.Frontend.affectedAssetLabel')}</div>
                    <GeneralSelect
                        placeholder={translate('ABB.Powertrain.Frontend.affectedAssetPlaceholder')}
                        options={formattedAssets}
                        value={affectedAsset}
                        onChange={value => setAffectedAsset(value)}
                    />
                </div>
                <div className='input-wrapper'>
                    <div className='label'>{translate('ABB.Powertrain.Frontend.issueDescriptionLabel')}</div>
                    <TextArea
                        placeholder={translate('ABB.Powertrain.Frontend.issueDescriptionPlaceholder')}
                        value={issueDescription}
                        resizable={false}
                        maxLength={maxCommentSize}
                        onChange={(e) => setIssueDescription(e)}
                    />
                </div>
                <div className='input-wrapper'>
                    <div className='label'>{translate('ABB.Powertrain.Frontend.problemCategoryLabel')}</div>
                    <GeneralSelect
                        placeholder={translate('ABB.Powertrain.Frontend.problemCategoryPlaceholder')}
                        options={formatIssueTypes(model.issueTypes)}
                        value={problemCategory}
                        onChange={value => setProblemCategory(value)}
                    />
                </div>
                {isProblemTypeVisible(problemCategory) && <div className='input-wrapper'>
                    <div className='label'>{translate('ABB.Powertrain.Frontend.problemTypeLabel')}</div>
                    <GeneralSelect
                        placeholder={translate('ABB.Powertrain.Frontend.problemTypePlaceholder')}
                        options={formatSubIssueTypes(problemCategory)}
                        value={problemType}
                        onChange={value => setProblemType(value)}
                    />
                </div>}
            </LoadingStatusWrapper>
        </GeneralDialog>
    );
};

export default ReportIssueModalComponent;
