
import { getApiActionCreator } from 'helpers/actionHelper';
import { apiCallStatus, ASSET_TYPES_MS_ID } from 'helpers/constants';
import { getZoomingDates } from '../../../../helpers';
import { TimeSeriesApi } from 'api';
import { actionTypes } from './constants';
import {
    createParamMeasurementComponentObjV2,
    getFlatAvailableKPIList
} from '../../../commonTrendHelpers';
import { createEmptyOpTrendDataObject } from './helpers';


const getTrendDataAction = getApiActionCreator(actionTypes.GET_ASSET_TREND_DATA);

export const getTrendData = (selectedKpi, from, to) => {
    return (dispatch, getState) => {

        const state = getState();
        const selectedAsset = state.powertrainAssetDetails.selectedAsset;

        // Create parameter object for POST body content
        const paramsArray = createParamMeasurementComponentObjV2(selectedKpi, selectedAsset);
        dispatch(getTrendDataAction(apiCallStatus.LOADING));
        //Changing Trend Data Here
        return TimeSeriesApi.getTrend(paramsArray, from, to)
            .then((data) => {
                if (data.length === 0) {
                    data = createEmptyOpTrendDataObject(selectedKpi, selectedAsset);
                }
                dispatch(getTrendDataAction(apiCallStatus.SUCCESS, data));
                return data;
            })
            .catch((err) => {
                dispatch(getTrendDataAction(apiCallStatus.ERROR, null, err));
            });
    };
};

const addTrendKPISelectionAction = (selectedTrendKPI, dispatch) => {
    dispatch({
        type: actionTypes.ADD_TREND_KPI_SELECTION,
        selectedTrendKPI
    });
};

export const addTrendKpi = (selectedKpi) => {
    return (dispatch, getState) => {
        const asset = getState().powertrainAssetDetails.selectedAsset;
        const originalAssetID = asset?.id;
        const { fromDate, toDate, zoomed } = getZoomingDates(getState(), 'operationalParametersV2');
        const typeID = ASSET_TYPES_MS_ID[asset?.assetType];
        addTrendKPISelectionAction({
            ...selectedKpi,
            assetTypeID: typeID
        }, dispatch);
        return dispatch(getTrendData(selectedKpi, fromDate, toDate))
            .then((newKpiData) => {
                const assetID = getState().powertrainAssetDetails?.selectedAsset?.id;

                if (originalAssetID === assetID) {
                    dispatch({
                        type: actionTypes.ADD_SELECTED_TREND_KPI_DATA,
                        newKpiData,
                        trendChartZoom: {
                            isZoomed: zoomed,
                            from: fromDate,
                            to: toDate
                        }
                    });
                }
            });
    };
};

export const reloadAllSelectedTrendKpiData = (allTrendKpis) => {
    return (dispatch, getState) => {
        const state = getState();
        const { from, to } = state.overview.dateSelection;
        const availableTrendKPIs = state.operationalParametersV2.availableKPIs.trend.data;
        const trendKPIList = getFlatAvailableKPIList(availableTrendKPIs);
        const filteredArray = allTrendKpis?.map((kpi) => {
            return trendKPIList[kpi];
        });

        return dispatch(getTrendData(filteredArray, from, to))
            .then((newKpiData) => {
                dispatch({
                    type: actionTypes.RELOAD_ALL_SELECTED_TREND_KPI, newKpiData, trendChartZoom: {
                        isZoomed: false,
                        from,
                        to
                    }
                });
            });
    };
};

export const zoomInToSelectedTrendKpiData = (allTrendKpis, from, to) => {
    return (dispatch) => {
        return dispatch(getTrendData(allTrendKpis, from, to))
            .then((newKpiData) => {
                dispatch({
                    type: actionTypes.ADD_SELECTED_TREND_KPI_DATA,
                    newKpiData,
                    trendChartZoom: {
                        isZoomed: true,
                        from,
                        to
                    }
                });
            });
    };
};

export const removeSelectedKpi = (unselectedTrendKPI) => {
    return (dispatch) => { dispatch({ type: actionTypes.REMOVE_TREND_KPI_SELECTION, unselectedTrendKPI }); };
};

export const clearSelectedKpi = () => {
    return (dispatch) => {
        dispatch({ type: actionTypes.CLEAR_UNMONITORED_CHART_DATA });
    };
};

const removeSelectedKpiLegendAction = (signalIndex) => {
    return {
        type: actionTypes.REMOVE_TREND_KPI_SELECTION_LEGEND,
        signalIndex
    };
};

// Action Creators
export const removeSelectedKpiLegend = (signalIndex) => {
    return (dispatch) => {
        dispatch(removeSelectedKpiLegendAction(signalIndex));
    };
};

export const resetSelectedKpi = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.RESET_TREND_KPI_SELECTION
        });
    };
};

export const setIsZoomed = (isTrendChartZoomed) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.SET_ZOOMED, isTrendChartZoomed });
    };
};
