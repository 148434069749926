import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    LoadingStatusWrapper,
    GeneralTable,
    GeneralButton,
    GeneralDialog,
    SearchBox
} from 'sharedComponents';
import { apiCallStatus, popupTypes } from 'helpers/constants';
import { IconBellStrikeThrough, IconPlus } from 'svgIcons/MotionPortalIcons';
import { EditMutedAsset } from './EditMuteSettingsPopup';
import {
    getColumns,
    formatData
} from './helpers';
import './style.scss';
import { isEqual } from 'lodash';
import BulkMuteAssetComponent from './BulkMuteAssets/BulkMuteAssetComponent';
import { updateBulkMutedGroupList } from './EditMuteSettingsPopup/helpers';


const MutedAssetsComponent = (props) => {
    const { t: translate } = useTranslation();

    const { mutedAssets,
        selectedAssets,
        assetMuteSettings,
        assetMuteSettingsLoadingStatus,
        updateAssetMuteSettingsLoadingStatus,
        selectedFilters,
        bulkAssets,
        bulkAssetsLoadingStatus
    } = props.model;
    const { selectMutedAsset, saveMutedAssetSettings, saveBulkMuteAssets, getAssetMutedSettings } = props.actions;

    const [selectedSingleAsset, setSelectedSingleAsset] = useState(null);
    const [editPopupOpen, setEditPopupOpen] = useState(false);
    const [deletePopupOpen, setDeletePopupOpen] = useState(false);
    const [muteAssets, setMuteAssets] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showMute, setShowMute] = useState(true);
    const [disabledForPeriod, setDisabledForPeriod] = useState(null);
    const [period, setPeriod] = useState(null);
    const [mutedGroups, setMutedGroups] = useState(null);
    const notificationGroups = props?.notificationSettings?.notificationChannels?.notificationGroups;
    const [bulkMutedGroups, setBulkMutedgroups] = useState(notificationGroups);
    const [data, setData] = useState([]);
    const [searchFilter, setSearchFilter] = useState('');
    const [filteredAssets, setFilteredAssets] = useState([]);
    const [selectedAssetName, setSelectedAssetName] = useState([]);
    const [isBulkEdit, setIsBulkEdit] = useState(false);
    const timePeriod = assetMuteSettings?.startDate && assetMuteSettings?.endDate ?
        {
            from: assetMuteSettings.startDate,
            to: assetMuteSettings.endDate
        } : null;
    const muteForTimePeriod = !!(assetMuteSettings?.startDate && assetMuteSettings?.endDate);
    const setSelectedAssetOptions = () => {
        setDisabledForPeriod(muteForTimePeriod);
        setPeriod(timePeriod);
        if (selectedSingleAsset?.assetId) {
            getAssetMutedSettings(selectedSingleAsset?.assetId);
        }
    };
    const toggleMuteassets = () => {
        setMuteAssets(false);
        setShowError(false);
    };

    const toggleEditBulkMuteAssets = () => {
        setIsBulkEdit(false);
        setEditPopupOpen(false);
    };

    const resetDisableForPeriod = () => {
        setDisabledForPeriod(false);
        setPeriod(null);
    };

    useEffect(() => {
        setFilteredAssets(mutedAssets?.assetList || []);
    }, [mutedAssets]);

    useEffect(() => {
        setBulkMutedgroups(updateBulkMutedGroupList(bulkMutedGroups));
    }, []);

    useEffect(() => {
        setSelectedAssetOptions();
    }, [selectedSingleAsset]);

    useEffect(() => {
        if (assetMuteSettings?.startDate && assetMuteSettings?.endDate) {
            setDisabledForPeriod(true);
            setPeriod(timePeriod);
        } else {
            resetDisableForPeriod();
        }
    }, [assetMuteSettings]);

    useEffect(() => {
        setMutedGroups(assetMuteSettings ? assetMuteSettings?.notificationGroups || [] : []);
    }, [assetMuteSettings]);

    useEffect(() => {
        setBulkMutedgroups(updateBulkMutedGroupList(notificationGroups || []));
    }, [notificationGroups]);

    useEffect(() => {
        if (data.length > 0) {
            setShowError(false);
        }
    }, [data]);

    const handleEditBulkMute = () => {
        const resetMutedGroups = mutedGroups.map(group => ({
            ...group,
            muted: false
        }));
        setMutedGroups(resetMutedGroups);
        setIsBulkEdit(true);
        setSelectedAssetName(bulkAssets.filter(asset => selectedAssets.includes(asset.id)));
        setEditPopupOpen(true);
    };

    const toggleEditPopupVisibility = () => {
        setIsBulkEdit(false);
        if (editPopupOpen) {
            setSelectedSingleAsset(null);
        }
        setEditPopupOpen(!editPopupOpen);
    };

    const toggleDeletePopupVisibility = () => {
        setIsBulkEdit(false);
        if (deletePopupOpen) {
            setSelectedSingleAsset(null);
        }
        setDeletePopupOpen(!deletePopupOpen);
    };

    const save = () => {
        saveMutedAssetSettings(
            props.organization?.organizationID,
            mutedGroups,
            period,
            selectedSingleAsset.assetId,
            toggleEditPopupVisibility
        );

    };

    const BulkMutesave = () => {
        const BulkSelectedAssets = isBulkEdit ? bulkAssets?.filter((asset) => selectedAssets.find((i) => asset?.id === i) === asset?.id) : bulkAssets?.filter((asset) => data.find((i) => asset?.id === i) === asset?.id);
        const assetIds = BulkSelectedAssets?.map((asset) => asset?.id);
        const startDate = period?.from || null;
        const endDate = period?.to || null;
        saveBulkMuteAssets(
            assetIds,
            bulkMutedGroups,
            startDate,
            endDate,
            props?.organization?.organizationID,
            () => {
                if (isBulkEdit) {
                    toggleEditBulkMuteAssets();
                } else {
                    toggleEditPopupVisibility();
                    toggleMuteassets();
                }
            }
        );
    };

    const handleNextMuteAsset = () => {
        if (bulkAssets?.length) {
            if (data?.length) {
                setShowError(false);
                resetDisableForPeriod();
                toggleEditPopupVisibility();
            } else {
                setShowError(true);
            }
        } else {
            toggleMuteassets();
        }
    };

    // Verify atleast a muted group item is checked
    const checkIsListMuted = () => disabledForPeriod ? mutedGroups?.some(item => item?.muted) ?? false : true;

    // Verify atleast a muted group item is checked for bulk edit
    const checkIsBulkMuted = () => bulkMutedGroups?.some(item => item?.muted);

    const renderSelectedAssetName = () => selectedAssetName?.map(asset => asset.name).join(', ');

    const renderEditPopup = () => {
        return <GeneralDialog
            show={editPopupOpen}
            close={() => {
                toggleEditPopupVisibility();
                if (muteAssets) {
                    toggleMuteassets();
                }
                if (isBulkEdit) {
                    toggleEditBulkMuteAssets();
                }
            }}
            title={<>
                <IconBellStrikeThrough width={'28px'} height={'28px'} />
                {isBulkEdit ?
                    `${translate('ABB.Powertrain.Frontend.Muteassets')} - ${renderSelectedAssetName()}` :
                    muteAssets ?
                        `${translate('ABB.Powertrain.Frontend.Muteassets')}` :
                        `${translate('ABB.Powertrain.Frontend.mutedAssetsEditPopupTitle')} -${selectedSingleAsset?.assetName}`}
            </>}
            closeButton={true}
            leftFooterButtonProps={muteAssets ? {
                text: translate('ABB.Powertrain.Frontend.PreviousLabel'),
                onClick: () => { toggleEditPopupVisibility(); }
            } : null}
            acceptButtonProps={{
                text: isBulkEdit ? translate('ABB.Powertrain.Frontend.saveButton') : muteAssets ? translate('ABB.Powertrain.Frontend.MuteButton') : translate('ABB.Powertrain.Frontend.saveButton'),
                onClick: () => { isBulkEdit ? BulkMutesave() : muteAssets ? BulkMutesave() : save(); },
                disabled: isBulkEdit ? !checkIsBulkMuted() : assetMuteSettingsLoadingStatus === apiCallStatus.LOADING ||
                    updateAssetMuteSettingsLoadingStatus === apiCallStatus.LOADING ||
                    !checkIsListMuted() ||
                    muteAssets ? !showMute : isEqual(mutedGroups, assetMuteSettings?.notificationGroups) &&
                    isEqual(disabledForPeriod, muteForTimePeriod) &&
                isEqual(period, timePeriod)
            }}
            cancelButtonProps={{
                text: translate('ABB.Powertrain.Frontend.cancelLabel'),
                onClick: () => {
                    toggleEditPopupVisibility();
                    if (muteAssets) {
                        toggleMuteassets();
                    }
                    if (isBulkEdit) {
                        toggleEditBulkMuteAssets();
                    }
                }
            }}
            modalClassName={`edit-muted-asset-popup ${muteAssets ? 'bulk-muted-asset-modal' : ''}`}
        >
            <LoadingStatusWrapper loadingStatus={[
                assetMuteSettingsLoadingStatus,
                updateAssetMuteSettingsLoadingStatus
            ]}>
                <EditMutedAsset
                    notificationGroups={props.notificationSettings?.notificationChannels?.notificationGroups}
                    disabledForPeriod={disabledForPeriod}
                    setDisabledForPeriod={setDisabledForPeriod}
                    period={period}
                    setPeriod={setPeriod}
                    mutedGroups={mutedGroups}
                    setMutedGroups={setMutedGroups}
                    muteAssets={muteAssets}
                    bulkMutedGroups={bulkMutedGroups}
                    setBulkMutedgroups={setBulkMutedgroups}
                    setShowMute={setShowMute}
                    isGeneralMute={true}
                    isBulkEdit={isBulkEdit}
                />
            </LoadingStatusWrapper>
        </GeneralDialog>;
    };

    const renderDeletePopup = () => {
        return <GeneralDialog
            show={deletePopupOpen}
            close={toggleDeletePopupVisibility}
            notificationType={popupTypes.WARNING}
            title={translate('ABB.Powertrain.Frontend.mutedAssetsDeletePopupTitle')}
            closeButton={true}
            acceptButtonProps={{
                text: translate('ABB.Powertrain.Frontend.muteAssetsDeleteConfirm'),
                onClick: () => {
                    props.deleteMutedAssets(props.organization?.organizationID,
                        selectedSingleAsset ? [selectedSingleAsset.assetId] : selectedAssets);
                    toggleDeletePopupVisibility();
                }
            }}
            cancelButtonProps={{
                text: translate('ABB.Powertrain.Frontend.cancelLabel'),
                onClick: () => { toggleDeletePopupVisibility(); }
            }}
            modalClassName={'edit-muted-asset-popup'}
        >
            <div>{translate('ABB.Powertrain.Frontend.mutedAssetsDeletePopupDescription')}</div>
        </GeneralDialog>;
    };

    const handleMutedSearch = useCallback((value) => {
        setSearchFilter(value);
        const lowerCaseValue = value.toLowerCase();
        setFilteredAssets(mutedAssets?.assetList?.filter(
            (assetListItem) => assetListItem?.assetId?.toString().includes(lowerCaseValue) ||
                assetListItem?.assetName?.toLowerCase().includes(lowerCaseValue) ||
                assetListItem?.serialNumber?.toString().includes(lowerCaseValue)
        ));
    }, [mutedAssets]);

    return <LoadingStatusWrapper
        loadingStatus={[
            mutedAssets.mutedAssetsLoadingStatus,
            props.notificationSettings.notificationSettingsLoadingStatus,
            bulkAssetsLoadingStatus
        ]}
    >
        <div className='muted-assets-container'>
            {renderEditPopup()}
            {renderDeletePopup()}
            <div className='muted-assets-bulk-operations'>
                {muteAssets &&
                    <GeneralDialog
                        className='mute-bulk-assets'
                        show={muteAssets}
                        size={'lg'}
                        close={toggleMuteassets}
                        title={<>
                            <IconBellStrikeThrough width={'28px'} height={'28px'} />{translate('ABB.Powertrain.Frontend.Muteassets')}
                        </>}
                        closeButton={true}
                        acceptButtonProps={{
                            text: bulkAssets?.length ? translate('ABB.Powertrain.Frontend.nextButton') : translate('ABB.Powertrain.Frontend.cancelLabel'),
                            onClick: handleNextMuteAsset,
                            disabled: showError
                        }}
                        cancelButtonProps={bulkAssets?.length ? {
                            text: translate('ABB.Powertrain.Frontend.cancelLabel'),
                            onClick: () => { toggleMuteassets(); }
                        } : null}
                        modalClassName={'edit-muted-asset-popup bulk-muted-asset-modal'}
                    >
                        <LoadingStatusWrapper loadingStatus={[
                            assetMuteSettingsLoadingStatus,
                            updateAssetMuteSettingsLoadingStatus,
                            bulkAssetsLoadingStatus
                        ]}>
                            <BulkMuteAssetComponent organization={props.organization} showError={showError} bulkAssets={bulkAssets} data={data} setData={setData} selectedFilters={selectedFilters} />
                        </LoadingStatusWrapper>
                    </GeneralDialog>}

                <div className='notification-settings__left-pane'>
                    <div className='search-mute'>
                        <SearchBox
                            placeholder={translate('ABB.Powertrain.Frontend.searchPlaceholderNameIDSerialNrText')}
                            onChange={handleMutedSearch}
                            value={searchFilter}
                        />
                    </div>
                    {selectedAssets?.length > 0 &&
                        < div className='assets-selected'>
                            {`${selectedAssets?.length} / ${mutedAssets?.assetList?.length} ${translate('ABB.Powertrain.Frontend.mutedAssetsAssetsSelected')}`}
                        </div>
                    }
                </div>
                <div className='notification-settings__right-pane'>
                    {selectedAssets?.length > 0 && <>
                        <GeneralButton
                            type='normal'
                            text={translate('ABB.Powertrain.Frontend.mutedAssetsEditSelectedButton')}
                            onClick={() => {
                                handleEditBulkMute();
                            }} />

                        <GeneralButton
                            type='normal'
                            text={translate('ABB.Powertrain.Frontend.mutedAssetsUmuteSelectedButton')}
                            onClick={() => {
                                toggleDeletePopupVisibility();
                            }}
                        />

                    </>}
                    {selectedAssets?.length === 0 &&
                        <GeneralButton
                            type='primary'
                            text={translate('ABB.Powertrain.Frontend.Muteassets')}
                            icon={<IconPlus color={'#ffffff'} />}
                            onClick={() => { setMuteAssets(true); }}
                        />
                    }
                </div>
            </div>
            <GeneralTable
                className='muted-assets-table-container'
                columns={getColumns(
                    selectMutedAsset,
                    toggleEditPopupVisibility,
                    toggleDeletePopupVisibility,
                    setSelectedSingleAsset,
                    selectedAssets,
                    mutedAssets?.assetList.length,
                    props.notificationSettings?.notificationChannels?.notificationGroups?.length
                )}
                data={formatData({ assetList: filteredAssets })}
                emptyText={translate('ABB.Powertrain.Frontend.noMutedAssets')}
                type={'primary'}
                tableKey='id'
            />
        </div>
    </LoadingStatusWrapper>;
};

MutedAssetsComponent.propTypes = {
    model: PropTypes.shape({
        mutedAssets: PropTypes.object,
        selectedAssets: PropTypes.array
    }),
    actions: PropTypes.shape({
        selectMutedAsset: PropTypes.func,
        saveMutedAssetSettings: PropTypes.func
    }),
    deleteMutedAssets: PropTypes.func,
    organization: PropTypes.object,
    notificationSettings: PropTypes.object
};

export default MutedAssetsComponent;
