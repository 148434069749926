import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { IconClose } from 'svgIcons/MotionPortalIcons';
import { getNotificationIcon } from 'helpers/notificationIconHelper';
import colors from 'theme/_colors.scss';
import { translate } from 'helpers/translateHelper';
import { NOTIFICATION_BAR } from 'helpers/constants';

const NotificationStatusBarContent = ({ notification, onClose, isHide, value, contractInfo }) => {
    return <Row className={isHide || value ? 'notification-row' : 'notification-row d-none'}>
        <Col>
            <div className={value ? `error-status-bar notification-status-bar ${contractInfo?.type}-status-bar` : `error-status-bar notification-status-bar ${notification}-status-bar`} >
                <div>
                    <div className='notification-icon-container'>
                        {value ? getNotificationIcon(contractInfo?.type) : getNotificationIcon(notification)}
                    </div>
                    {!value ? <span className='notification-status-bar-text'>{translate('ABB.Powertrain.Frontend.FreeForRenewalNTFMsg')}
                        <a className='link' href={NOTIFICATION_BAR.CONVERTURL} rel='noreferrer' target='_blank'>{translate('ABB.Powertrain.Frontend.ContactDriveSupport')}</a>
                    </span> : <span className={'notification-status-bar-text'}>{translate(contractInfo?.message, { endDate: contractInfo?.messageParams?.endDate })}
                        {contractInfo?.link ? <a className='link' href={contractInfo?.link?.url} rel='noreferrer' target='_blank'>{translate('ABB.Powertrain.Frontend.ContactABBDriveSupport')}</a> : ''}</span>}
                </div>
                <div
                    id='closeNotifyButton'
                    role='presentation'
                    onClick={onClose}
                >
                    <IconClose
                        color={colors.white}
                        strokeWidth='6' />
                </div>
            </div></Col>
    </Row>;
};

NotificationStatusBarContent.propTypes = {
    // REVERT TO THIS while adding the blue and yellow banner
    // notification: PropTypes.shape({
    //     link: PropTypes.shape({
    //         description: PropTypes.string,
    //         url: PropTypes.string,
    //     }),
    //     message: PropTypes.string,
    //     type: PropTypes.string,
    // }),
    notification: PropTypes.string,
    onClose: PropTypes.func,
    isHide: PropTypes.bool,
    value: PropTypes.bool,
    contractInfo: PropTypes.object
};


export default NotificationStatusBarContent;
