import React, { useEffect, useMemo, useRef, useState } from 'react';
import Select, { components } from 'react-select';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import { LEVEL, LIMIT_SYMBOL } from '../../../../LimitConfigurationBeta/components/constants';
import { Checkbox } from 'sharedComponents/Checkbox';
import { opLimitsConstants, SELECTOR_ACTIONS } from './constants';
import useNewCapability from 'sharedHooks/useNewCapability';
import { checkMaxLimitSelected, getSelectedLimitValues } from './helper';
import { useOutsideClickHandler } from '../../../../AssetDetails/Header/AssetLabels/helpers';
import { tabIDs } from '../../../../../DetailedInformation/constants';

const LimitSelectorComponent = (props) => {
    const { model, actions } = props;
    const newCapability = useNewCapability();
    const selectRef = useRef(null);
    const selectWrapRef = useRef(null);
    const { t: translate } = useTranslation();
    const [selectIsOpen, setSelectIsOpen] = useState(false);
    const opLimitCapabilities = useMemo(() => ({
        operationalParametersSelectLimit: newCapability.hasOperationalParametersSelectLimitCapability()
    }), [newCapability]);
    const selectedSignal = model?.trendKPIsList?.length && model?.trendKPIsList?.find((lmt) => lmt?.timeseriesKey === model?.selectedTrendKPIs[0]);
    const selectedLimitValues = getSelectedLimitValues(model?.maintainSelectedLimits, model?.selectedAsset, selectedSignal, model?.selectedOrganization, model?.selectedLimits);
    //  HANDLE OUTSIDE CLICK CLOSE
    const handleClickOutside = () => {
        if (selectRef.current) {
            if (model?.selectedLimitsAction && !selectIsOpen) {
                actions.setSelectedLimitsAction(null);
                setSelectIsOpen(true);
                return;
            }
            setSelectIsOpen(false);
            selectRef.current.blur();
        }
    };
    useOutsideClickHandler(selectWrapRef, handleClickOutside);

    useEffect(() => {
        if (model?.selectedLimitsAction) {
            if (SELECTOR_ACTIONS.includes(model?.selectedLimitsAction?.action)) {
                setSelectIsOpen(true);
            }
        }
    }, [model?.selectedLimitsAction]);

    const toggleSelectIsOpen = () => {
        setSelectIsOpen((value) => !value);
        const selectEl = selectRef.current;
        if (!selectEl) { return; }
        if (selectIsOpen) { selectEl.blur(); } else { selectEl.focus(); }
    };

    const createGroupforLimits = () => {
        const options = [];
        if (selectedSignal && selectedSignal?.thresholdList?.length) {
            const generalLimits = [], personalLimits = [];
            selectedSignal?.thresholdList?.map((lmt, i) => {
                const symbol = lmt?.direction === LEVEL.UpperLimit || lmt?.direction === LEVEL.Upper ? LIMIT_SYMBOL.Greater : LIMIT_SYMBOL.Lower;
                const limitData = { ...lmt, label: `${lmt?.level}: ${symbol} ${lmt?.limitValue}`, value: lmt?.limitValue, id: i, limitValue: +lmt?.limitValue };
                if (lmt?.isUserLimit) {
                    personalLimits.push(limitData);
                } else {
                    generalLimits.push(limitData);
                }
            });
            options.push({
                label: translate('ABB.Powertrain.Frontend.opGeneralLimits')?.toUpperCase(),
                options: checkMaxLimitSelected(generalLimits, selectedLimitValues)
            }, {
                label: translate('ABB.Powertrain.Frontend.opPersonalLimits')?.toUpperCase(),
                options: checkMaxLimitSelected(personalLimits, selectedLimitValues)
            });
        }
        return options || [];
    };

    useEffect(() => {
        if (model?.selectedTrendKPIs?.length > 1 && model?.selectedLimits?.length) {
            actions.setSelectedLimits(null);
            actions.setSelectedLimitsAction(null);
        }
        if (model?.selectedLimits?.length && model?.assetActiveTab !== tabIDs.operationalParametersv2) {
            actions.setSelectedLimits(null);
            actions.setSelectedLimitsAction(null);
        }

        // UPDATE LIMIT SELECTOR ON LIMIT UPDATES FROM LIMIT SETTINGS AND BY DEFAULT SELECT DEFAULT+CUSTOM LIMITS
        if (!model?.isDefaultLimitsSelected && model?.selectedTrendKPIs?.length === 1) {
            const signalLimits = createGroupforLimits();
            const customLimits = signalLimits?.find((sl) => !sl?.isUserLimits);
            if (customLimits?.options?.length) {
                actions.setSelectedLimits(customLimits?.options || []);
                actions.setIsDefaultLimitsSelected(true);
            }
        }
    }, [model?.selectedTrendKPIs, model?.assetActiveTab]);

    const formatGroupLabel = (data) => {
        return <div className='limit-group-label'><span>{data?.label} ({data?.options?.length})</span></div>;
    };
    const formatOptionLabel = (data) => {
        const selected = selectedLimitValues?.find((sl) => sl?.id === data?.id);
        return <div>
            <div className='d-flex'>
                <Checkbox checked={!isEmpty(selected)} />
                <div className='ms-1'>{data?.label}</div>
            </div>
        </div>;
    };
    const handleSelectLimits = (data, action) => {
        actions.setSelectedLimits(data);
        actions.setSelectedLimitsAction(action);
        actions.maintainLimitsAction(data, model?.selectedAsset, selectedSignal, model?.selectedOrganization);
    };

    // CUSTOM VALUE CONTAINER FOR MORE THAN 2 LIMITS SELECTED
    const ValueContainer = props => {
        const { getValue, children } = props;
        const length = getValue()?.length;
        const newChildren = `${length} ${translate('ABB.Powertrain.Frontend.limitsTitle')?.toLowerCase()}`;
        return (
            <components.ValueContainer {...props}>
                {length > opLimitsConstants.MAX_SHOW_SELECTED_LIMITS ? newChildren : children}
            </components.ValueContainer>
        );
    };

    return model?.selectedTrendKPIs?.length === 1 &&
        selectedSignal &&
        selectedSignal?.thresholdList?.length &&
        opLimitCapabilities?.operationalParametersSelectLimit &&
        model?.selectedTrendsData?.data[0]?.values?.length !== 0 &&
        <div ref={selectWrapRef}
            onClick={() => { toggleSelectIsOpen(); }}
            onTouchEndCapture={() => { !selectIsOpen && toggleSelectIsOpen(); }}> {/* FOR TOUCH DEVICES */}
            <Select
                isMulti
                ref={selectRef}
                options={createGroupforLimits()}
                className={`limits-multi-select ${selectIsOpen ? 'active' : ''}`}
                classNamePrefix='op-limits'
                placeholder={translate('ABB.Powertrain.Frontend.opLimitsSelectPlaceholder')}
                components={{
                    IndicatorSeparator: () => null,
                    ValueContainer
                }}
                formatGroupLabel={formatGroupLabel}
                formatOptionLabel={formatOptionLabel}
                onChange={handleSelectLimits}
                hideSelectedOptions={false}
                isSearchable={false}
                isOptionDisabled={(option) => option.isDisabled}
                maxMenuHeight={opLimitsConstants.MAX_OP_LIMITS_MENU_HEIGHT}
                value={selectedLimitValues}
                menuIsOpen={selectIsOpen}
                blurInputOnSelect={true}
                isFocused={selectIsOpen}
                noOptionsMessage={() => <>{translate('ABB.Powertrain.Frontend.noLimitsForSignal')}</>}
            />
        </div>;
};

export default LimitSelectorComponent;
