import { createSelector } from 'reselect';

export const getTrendKPIsList = createSelector([
    ({ operationalParametersV2 }) => operationalParametersV2.availableKPIs.trend.data,
], (
    trendKPIs,
) => {
    const mergedTrendKpiList = [];
    trendKPIs.forEach((kpi) => {
        const kpiObj = kpi.data;
        const keys = Object.keys(kpiObj);
        keys.forEach(key => {
            const newObj = kpiObj[key];
            mergedTrendKpiList.push(newObj);
        });
    });
    return mergedTrendKpiList;
});

const getSelectedTrendKPIs = ({ operationalParametersV2 }) => {
    return operationalParametersV2?.preselectedKPIs?.single?.trend;
};

const getSelectedTrendsData = ({ operationalParametersV2 }) => {
    return operationalParametersV2?.charts?.single?.trend[0]?.kpiList;
};

const getSelectedAsset = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails.selectedAsset;
};

const getDateSelection = ({ overview }) => {
    return overview?.dateSelection;
};

export const getModel = createSelector([
    getTrendKPIsList,
    getSelectedTrendsData,
    getSelectedTrendKPIs,
    getSelectedAsset,
    getDateSelection
], (
    trendKPIsList,
    selectedTrendsData,
    selectedTrendKPIs,
    selectedAsset,
    dateSelection
) => {
    return {
        trendKPIsList,
        selectedTrendsData,
        selectedTrendKPIs,
        selectedAsset,
        dateSelection
    };
});
