import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { filter, map } from 'lodash';
import { Stack } from 'react-bootstrap';

import { loggerCommands, toggleOptions } from '../constants';
import { ToggleButton } from 'sharedComponents/ToggleButton';
import DataLoggerHistoryTooltip from '../DataLoggerHistoryTooltip';
import { getsubscriptionToggleOptions, updatedLoggerCommands } from '../helpers';
import { apiCallStatus } from 'helpers/constants';

const SpecialLoggerComponent = ({ model, actions, asset }) => {
    const { t: translate } = useTranslation();
    const { specialLoggerCommands, triggerDataLoggerCommandsLoadingStatus, specialLoggerCommandsLoadingStatus } = model;
    const filteredSpecialLoggerCommands = filter(specialLoggerCommands, specialLoggerCommand => specialLoggerCommand?.commandType === loggerCommands.SUBSCRIBEUNSUBSCRIBE);

    const subscribeUnsubscribeSpecialLogger = (selectedCommand, loggerCommand) => {
        const [subscribeCommandId, unSubscribeCommandId] = loggerCommand.commandId;
        if (selectedCommand === toggleOptions.SUBSCRIBE) {
            actions?.subscribeUnsubscribeDataLoggerCommands({
                assetId: asset?.id,
                loggerType: loggerCommand?.loggerType,
                commandId: subscribeCommandId,
                commandDisplayName: loggerCommand?.commandDisplayName
            });
        } else if (selectedCommand === toggleOptions.UNSUBSCRIBE) {
            actions?.subscribeUnsubscribeDataLoggerCommands({
                assetId: asset?.id,
                loggerType: loggerCommand?.loggerType,
                commandId: unSubscribeCommandId,
                commandDisplayName: loggerCommand?.commandDisplayName
            });
        }
    };

    const updatedSpecialLoggerCommands = updatedLoggerCommands(filteredSpecialLoggerCommands);

    return (
        map(updatedSpecialLoggerCommands, (loggerCommand, i) => {
            return (
                <Stack direction='horizontal' key={i}>
                <div className='data-logger__type py-2'>{loggerCommand?.commandDisplayName}</div>
                <div className='py-1 d-flex ms-auto'>
                    <ToggleButton
                        buttonProps={{
                            disabled: triggerDataLoggerCommandsLoadingStatus === apiCallStatus.LOADING ||
                                    specialLoggerCommandsLoadingStatus === apiCallStatus.LOADING
                        }}
                        options={getsubscriptionToggleOptions(translate)}
                        onSelected={(selectedCommand) => subscribeUnsubscribeSpecialLogger(selectedCommand, loggerCommand)}
                    />
                    <div className='ml-2 d-none'>
                        <DataLoggerHistoryTooltip command={'{Subscribe | Unsubscribe}'} userName={'{user name}'} />
                    </div>
                </div>
            </Stack>
            );
        })
    );
};

SpecialLoggerComponent.propTypes = {
    model: PropTypes.object,
    actions: PropTypes.object,
    asset: PropTypes.object
};

export default SpecialLoggerComponent;
