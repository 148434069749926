export const LimitTypeOptions = [
    {
        value: 'oneDirectionAlarmOrAlert',
        name: 'One-direction alarm or alert',
        instance: 1,
        type: ['Alarm'],
        limitDirection: ['UpperLimit'],
    },
    {
        value: 'oneDirectionAlarmAndAlert',
        name: 'One-direction alarm and alert',
        instance: 2,
        type: ['Alarm', 'Alert'],
        limitDirection: ['UpperLimit', 'UpperLimit'],
    },
    {
        value: 'outOfRangeAlarmOrAlert',
        name: 'Out-of-range alarm or alert',
        instance: 2,
        type: ['Alarm', 'Alarm'],
        limitDirection: ['UpperLimit', 'LowerLimit'],
    },
    {
        value: 'outOfRangeAlarmAndAlert',
        name: 'Out-of-range alarm and alert',
        instance: 4,
        type: ['Alarm', 'Alert', 'Alert', 'Alarm'],
        limitDirection: ['UpperLimit', 'UpperLimit', 'LowerLimit', 'LowerLimit'],
    }
];

export const WINDOW_EVENTS = {
    BEFORE_UNLOAD: 'beforeunload'
};

export const LIMIT_MENU_OPTIONS = {
    REMOVE_SIGNAL: 'removeKpi',
    EDIT_OFFSET: 'editOffset',
    EXPORT: 'export',
    RESET_DEFAULT_LIMITS: 'reset default limits'
};

export const LEVEL = {
    UpperLimit: 'UpperLimit',
    LowerLimit: 'LowerLimit',
    Upper: 'UpperLimit'
};

export const LIMIT_SYMBOL = {
    Greater: '>',
    Lower: '<',
    Default: '>/<'
};

export const KPI_TYPE = {
    Trend: 'trends',
    HealthTrend: 'healthTrends'
};

export const KPI_ERROR = {
    isSignalError: 'isSignalError'
};

export const SLIDER_VALUES = {
    MIN: 0.9,
    MAX: 1.1
};

export const RANGE = {
    Min: 'min',
    Max: 'max'
};

export const TRENDS = 'Trends';

export const LIMIT_LEVELS = {
    Alert: 'Alert',
    Alarm: 'Alarm'
};

export const LIMIT_DIRECTION = {
    Upper: 'Upper',
    Lower: 'Lower',
};

export const LIMIT_NAME = {
    UpperAlarm: 'ABB.Powertrain.Frontend.High_alarm',
    UpperAlert: 'ABB.Powertrain.Frontend.High_alert',
    LowerAlarm: 'ABB.Powertrain.Frontend.Low_alarm',
    LowerAlert: 'ABB.Powertrain.Frontend.Low_alert',
};

export const LIMIT_VALUE_REGEX = /^-?(\d{0,15}|\d{0,15}\.\d{1,2})$/;
