import { put, takeEvery, call } from 'redux-saga/effects';
import { actionTypes } from './constants';
import { notify } from 'sharedComponents/GeneralNotification';
import { NOTIFICATION_TYPES } from 'sharedComponents/GeneralNotification/constants';
import { apiCallStatus } from 'helpers/constants';
import logger from 'helpers/logger';
import { getDataLoggerCommandsAction, triggerDataLoggerCommandsAction, subscribeUnsubscribeDataLoggerCommandsAction } from './actions';
import { DataLoggerCommandsApi } from 'api';
import { getNotificationMessage } from './helpers';

function* getDatatLoggerCommandsSaga(action) {
    const assetId = action?.assetId;
    yield put(getDataLoggerCommandsAction(apiCallStatus.LOADING));
    try {
        const response = yield call(DataLoggerCommandsApi?.getDataLoggerCommands, assetId);
        if (response?.error) {
            notify(response?.error?.message, NOTIFICATION_TYPES.ERROR, true);
        } else {
            yield put(getDataLoggerCommandsAction(apiCallStatus.SUCCESS, response));
            logger.debug('DataLogger Commands successfully loaded for page ');
        }
    } catch (err) {
        yield put(getDataLoggerCommandsAction(apiCallStatus.ERROR, [], err));
    }
}

function* triggerDataLoggerCommandsSaga({ assetId, loggerType, commandId, commandDisplayName}) {
    yield put(triggerDataLoggerCommandsAction(apiCallStatus.LOADING));
    try {
        const response = yield call(DataLoggerCommandsApi?.triggerDataLoggerCommands, assetId, loggerType, commandId);
        yield put(triggerDataLoggerCommandsAction(apiCallStatus.SUCCESS, response));
        if (response?.error) {
            notify(response?.error?.message, NOTIFICATION_TYPES.ERROR, true);
        } else {
            const notificationMessage = getNotificationMessage(commandId, commandDisplayName);
            if (notificationMessage) {
                notify(notificationMessage, NOTIFICATION_TYPES.SUCCESS);
            }
        }
    } catch (err) {
        yield put(triggerDataLoggerCommandsAction(apiCallStatus.ERROR, [], err));
    }
}

function* subscribeUnsubscibeDataLoggerCommandsSaga({ assetId, loggerType, commandId, commandDisplayName }) {
    yield put(subscribeUnsubscribeDataLoggerCommandsAction(apiCallStatus.LOADING));
    try {
        const response = yield call(DataLoggerCommandsApi?.subscribeUnsubscribeDataLoggerCommands, assetId, loggerType, commandId);
        yield put(subscribeUnsubscribeDataLoggerCommandsAction(apiCallStatus.SUCCESS, response));
        if (response?.error) {
            notify(response?.error?.message, NOTIFICATION_TYPES.ERROR, true);
        } else {
            const notificationMessage = getNotificationMessage(commandId, commandDisplayName);
            if (notificationMessage) {
                notify(notificationMessage, NOTIFICATION_TYPES.SUCCESS);
            }
        }
    } catch (err) {
        yield put(subscribeUnsubscribeDataLoggerCommandsAction(apiCallStatus.ERROR, [], err));
    }
}

export function* dataLoggerWatchers() {
    yield takeEvery(actionTypes.GET_DATA_LOGGER_TRIGGER_REQUEST, triggerDataLoggerCommandsSaga);
    yield takeEvery(actionTypes.GET_DATA_LOGGER_SUBSCRIBE_UNSUBSCRIBE_REQUEST, subscribeUnsubscibeDataLoggerCommandsSaga);
    yield takeEvery(actionTypes.GET_DATA_LOGGER_COMMANDS_REQUEST, getDatatLoggerCommandsSaga);
}
