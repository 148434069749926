import React from 'react';
import PropTypes from 'prop-types';
const IconBroadcast = ({ width = 16, height = 16 }) => {
    return <svg width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M7.99957 4.99945C8.53 4.99945 9.03871 5.21017 9.41378 5.58524C9.78885 5.96031 9.99957 6.46902 9.99957 6.99945C9.99746 7.34848 9.90405 7.69088 9.72862 7.99262C9.55319 8.29436 9.30184 8.54494 8.99957 8.71945V14.9995C8.99957 15.2647 8.89421 15.519 8.70668 15.7066C8.51914 15.8941 8.26478 15.9995 7.99957 15.9995C7.73435 15.9995 7.48 15.8941 7.29246 15.7066C7.10493 15.519 6.99957 15.2647 6.99957 14.9995V8.71945C6.69729 8.54494 6.44595 8.29436 6.27052 7.99262C6.09509 7.69088 6.00168 7.34848 5.99957 6.99945C5.99957 6.46902 6.21028 5.96031 6.58535 5.58524C6.96043 5.21017 7.46914 4.99945 7.99957 4.99945ZM2.62957 1.68945L3.46957 2.53945V2.58945C2.28725 3.78882 1.62445 5.40531 1.62445 7.08945C1.62445 8.7736 2.28725 10.3901 3.46957 11.5895L2.62957 12.4395C1.92235 11.7344 1.36124 10.8966 0.978376 9.97429C0.595515 9.05194 0.398437 8.06311 0.398438 7.06445C0.398437 6.0658 0.595515 5.07696 0.978376 4.15462C1.36124 3.23227 1.92235 2.39455 2.62957 1.68945ZM13.3696 1.68945C14.0768 2.39455 14.6379 3.23227 15.0208 4.15462C15.4036 5.07696 15.6007 6.0658 15.6007 7.06445C15.6007 8.06311 15.4036 9.05194 15.0208 9.97429C14.6379 10.8966 14.0768 11.7344 13.3696 12.4395L12.5296 11.5895C13.7271 10.388 14.3995 8.76079 14.3995 7.06445C14.3995 5.36811 13.7271 3.74094 12.5296 2.53945L13.3696 1.68945ZM4.38957 3.45945L5.23957 4.30945C4.50938 5.0424 4.09945 6.03486 4.09957 7.06945C4.09864 7.58039 4.19891 8.08646 4.39457 8.55845C4.59023 9.03045 4.87742 9.45902 5.23957 9.81945L4.38957 10.6695C3.43633 9.71183 2.90118 8.41564 2.90118 7.06445C2.90118 5.71327 3.43633 4.41708 4.38957 3.45945ZM11.6096 3.45945C12.5628 4.41708 13.098 5.71327 13.098 7.06445C13.098 8.41564 12.5628 9.71183 11.6096 10.6695L10.7596 9.81945C11.1217 9.45902 11.4089 9.03045 11.6046 8.55845C11.8002 8.08646 11.9005 7.58039 11.8996 7.06945C11.8997 6.03486 11.4898 5.0424 10.7596 4.30945L11.6096 3.45945Z' fill='#1F1F1F' />
    </svg>;
};
IconBroadcast.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
export default IconBroadcast;
