import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SensorGatewayInfoDeatils from './SensorGatewayInfoDetails';

const SensorGatewayInfoComponent = (props) => {

    const { t: translate } = useTranslation();
    const sensorGatewayInfo = props?.model?.sensorGatewayInfo?.data;

    return <div className='sensor-properties-list'>
        <div className='sensor-properties-list-title'>{translate('ABB.Powertrain.Frontend.sensorGatewayInfo')}</div>
        <SensorGatewayInfoDeatils sensorGatewayInfo={sensorGatewayInfo} />
    </div>;
};

export default SensorGatewayInfoComponent;

SensorGatewayInfoComponent.propTypes = {
    model: PropTypes.shape({
        sensorGatewayInfo: PropTypes.object
    })
};
