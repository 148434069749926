export const actionTypes = {
    GET_GATEWAY_INFO_SETTINGS: 'powertrainTools/gatewayManagement/GET_GATEWAY_INFO_SETTINGS',
    GET_GATEWAY_INFO: 'powertrainTools/gatewayManagement/GET_GATEWAY_INFO',
    GET_GATEWAY_CONNECTED_ASSETS_SETTINGS: 'powertrainTools/gatewayManagement/GET_GATEWAY_CONNECTED_ASSETS_SETTINGS',
    GET_GATEWAY_CONNECTED_ASSETS: 'powertrainTools/gatewayManagement/GET_GATEWAY_CONNECTED_ASSETS',
    CLEAR_STORE: 'powertrainTools/gatewayManagement/CLEAR_STORE',
};

export const assetOnlineStatus = {
    ONLINE: 'Online',
    OFFLINE: 'Offline'
};

export const gatewayRouteURL = 'tools';
