import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { GeneralDialog, TimeSelectorRow, LoadingStatusWrapper } from 'sharedComponents';
import { MAX_DAYS_RANGE, PRESELECTED_DAYS, ITEMS_PER_PAGE } from './constants';
import { getColumns } from './helpers';
import { sortingDirections, GeneralTable } from 'sharedComponents/GeneralTable';

import './styles.scss';
import { apiCallStatus } from 'helpers/constants';


const ChangedParameterListModal = (props) => {
    const { t: translate } = useTranslation();

    const [from, setFrom] = useState();
    const [to, setTo] = useState();

    useEffect(() => {
        return () => {
            return props.actions.clearChangedParameterFileData();
        };
    }, []);

    useEffect(() => {
        props.actions.getChangedParameterFileListV2(props?.model?.selectedDrive, from, to);
    }, [from, to]);

    const handleSelectDate = (from, to) => {
        if (from && to) {
            setFrom(from);
            setTo(to);
        }
    };

    const fileList = props.model.changedParameterFileList.data || [];

    return (
        <GeneralDialog
            modalClassName='changed-parameter-list-modal'
            show={props.show}
            close={props.close}
            title={props.title}
            closeButton={true}
        >
            <div className='modal-form changed-parameter-list-form'>
                <span>{translate('ABB.Powertrain.Frontend.parameterBackupExplanationText')}</span>
                <TimeSelectorRow
                    fromDate={from}
                    toDate={to}
                    handleSelectDate={handleSelectDate}
                    maxDaysBack={MAX_DAYS_RANGE}
                    preSelectedDays={PRESELECTED_DAYS}
                    footerIntervalText={translate('ABB.Powertrain.Frontend.datePickerMax90DaysNote')}
                />
                <LoadingStatusWrapper
                    useCustomAnchor
                    loadingStatus={props?.model?.changedParametersListLoading}
                    errorMessage={translate('ABB.Powertrain.Frontend.changedParameterListErrorMessage')}>
                    {props.model.changedParametersListLoading !== apiCallStatus.LOADING ?
                        <GeneralTable
                            className='change-parameter-table'
                            tableKey='id'
                            columns={getColumns(translate)}
                            data={fileList}
                            emptyText={translate('ABB.Powertrain.Frontend.noDataLabel')}
                            scroll={{ x: 890, y: null }}
                            type='primary'
                            paginationProps={{ pageSize: ITEMS_PER_PAGE }}
                            initialSortingProps={{
                                column: 'gatewayTime',
                                direction: sortingDirections.DESC
                            }}
                        /> : null}
                </LoadingStatusWrapper>
            </div>
        </GeneralDialog>
    );
};

ChangedParameterListModal.propTypes = {
    actions: PropTypes.object.isRequired,
    model: PropTypes.object.isRequired,
    isVisible: PropTypes.bool,
    close: PropTypes.func.isRequired
};

export default ChangedParameterListModal;
