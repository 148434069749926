import { getApiActionCreator } from 'helpers/actionHelper';
import { actionTypes } from './constants';


export const getAssetConditionIndicesAction = getApiActionCreator(actionTypes.GET_ASSET_OVERVIEW);
export const getAssetGroupConditionIndicesAction = getApiActionCreator(actionTypes.GET_ASSET_GROUP_OVERVIEW);
export const getPowertrainConditionIndicesAction = getApiActionCreator(actionTypes.GET_POWERTRAIN_OVERVIEW);
export const getSubscriptionTypesAction = getApiActionCreator(actionTypes.GET_SUBSCRIPTION_TYPES_SETTINGS);

export const setActiveTab = (tab) => {
    return {
        type: actionTypes.SET_ACTIVE_TAB,
        tab
    };
};

export const toggleConditionFilter = (condition) => {
    return {
        type: actionTypes.TOGGLE_CONDITION_STATUS_FILTER,
        condition
    };
};

export const setAssetTypeFilter = (assetTypeFilter) => {
    return {
        type: actionTypes.SET_ASSET_TYPES_FILTER,
        assetTypeFilter
    };
};

export const setSearchboxFilter = (searchboxFilter) => {
    return {
        type: actionTypes.SET_SEARCHBOX_FILTER,
        searchboxFilter
    };
};

export const setSubscriptionTypeFilter = (subscriptionTypeFilter) => {
    return {
        type: actionTypes.SET_SUBSCRIPTION_TYPE_FILTER,
        subscriptionTypeFilter
    };
};

export const getSubscriptionTypes = (organizationId) => {
    return {
        type: actionTypes.GET_SUBSCRIPTION_TYPES,
        organizationId
    };
};

export const resetFilters = () => {
    return {
        type: actionTypes.RESET_FILTERS
    };
};

export const clearStore = () => {
    return (dispatch) => {
        dispatch({ type: actionTypes.CLEAR_STORE });
    };
};
