import * as constants from './constants';
import { setMaintainEventData } from './EventLogFilter/helpers';
import {
    getDefaultEventTypesByAssetType,
    formatAssetCapabilities,
    updateEventLogs
} from './helpers';


const initialState = {
    dateKind: constants.dateKind.Local,
    selectedAssets: [],
    selectedEvent: null,
    filters: {
        // commented out to fix bug 383709
        groupByNames: true,
        eventStatus: constants.eventStatus.ALL,
        // withCommentsOnly: true,
        // withDataLoggersOnly: true,
        eventName: '',
        causeOfEvent: constants.causeOfEvents.All,
        eventTypes: []
    },
    eventLogs: null,
    eventClosingLoadingStatus: null,
    eventLogLoadingStatus: null,
    isTruncated: false,
    capabilitiesLoadingStatus: null,
    capabilities: null,
    isExpanded: false,
    maintainEventData: {},
};


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.SET_SELECTED_ASSET:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    eventTypes: getDefaultEventTypesByAssetType(action.asset)
                }
            };
        case constants.CLEAR_STORE:
            return initialState;
        case constants.SET_EVENT_LOG_FILTERS:
            return {
                ...state,
                filters: { ...state.filters, ...action.filters }
            };
        case constants.SET_EVENT_LOG_DATE_KIND:
            return {
                ...state,
                dateKind: action.dateKind
            };
        case constants.SET_IS_EXPAND:
            return {
                ...state,
                isExpanded: action.isExpanded
            };
        case constants.SET_SELECTED_EVENT:
            return {
                ...state,
                selectedEvent: action.selectedEvent
            };
        case constants.GET_EVENT_LOGS:
            return {
                ...state,
                eventLogs: action.data,
                isTruncated: action.data?.isTruncated,
                eventLogLoadingStatus: action.loadingStatus
            };
        case constants.UPDATE_EVENT_LOGS:
            return {
                ...state,
                eventLogs: {
                    ...state.eventLogs,
                    events: updateEventLogs([...state.eventLogs.events], action.data)
                }
            };
        case constants.CLOSE_EVENT_LOG:
            return {
                ...state,
                eventClosingLoadingStatus: action.loadingStatus
            };
        case constants.SET_SELECTED_ASSETS:
            return {
                ...state,
                selectedAssets: action.selectedAssets
            };
        case constants.LOAD_CAPABILITIES:
            return {
                ...state,
                capabilitiesLoadingStatus: action.loadingStatus,
                capabilities: formatAssetCapabilities(action.data)
            };
        case constants.MAINTAIN_EVENT_DATA: {
            const modifiedEventData = setMaintainEventData(state, action);
            return {
                ...state,
                maintainEventData: modifiedEventData
            };
        }
        default:
            return state;
    }
}
