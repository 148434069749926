import { NotificationSettingsApi } from 'api/index';
import { actionTypes } from '../constants';
import { getApiActionCreator } from 'helpers/actionHelper';
import { apiCallStatus, NOTIFICATION_TYPES } from 'helpers/constants';
import { notify } from 'sharedComponents/GeneralNotification';
import { translate } from 'helpers/translateHelper';

export const getAssetMuteSettingsAction = getApiActionCreator(actionTypes.GET_ASSET_MUTE_SETTINGS);
export const updateAssetMuteSettingsAction = getApiActionCreator(actionTypes.SET_UPDATE_MUTE_SETTINGS_LOADING);

export const getMutedAssetList = (organizationID) => ({
    type: actionTypes.LOAD_MUTED_ASSETS_REQUEST,
    organizationID
});

export const selectMutedAsset = (assetId = null) => ({
    type: actionTypes.SELECT_MUTED_ASSET,
    assetId
});

export const saveMutedAssetSettings = (organizationID, notificationGroups, period, assetId, close) => ({
    type: actionTypes.UPDATE_MUTED_ASSET_REQUEST,
    organizationID,
    assetId,
    notificationGroups,
    startDate: period?.from || null,
    endDate: period?.to || null,
    close
});


export const saveBulkMuteAssets = (assetIds, notificationGroups, startDate, endDate, organizationID, close) => {
    notificationGroups = notificationGroups.map(({notificationGroupCode, muted}) => {
        return {
            notificationGroupCode,
            muted
        };
    });
    return (dispatch) => {
        dispatch(getAssetMuteSettingsAction(apiCallStatus.LOADING));
        if (organizationID) {
            return NotificationSettingsApi.getMuteBulkAssets(assetIds, notificationGroups, startDate, endDate, organizationID, close)
                .then((data) => {
                    notify(translate('ABB.Powertrain.Frontend.AssetsMuted'), NOTIFICATION_TYPES.SUCCESS);
                    dispatch(getAssetMuteSettingsAction(apiCallStatus.SUCCESS, data));
                    close();
                    dispatch(getMutedAssetList(organizationID));
                    return data;
                })
                .catch((err) => {
                    dispatch(getAssetMuteSettingsAction(apiCallStatus.ERROR, null, err));
                });
        } else {
            dispatch(getAssetMuteSettingsAction(apiCallStatus.SUCCESS, []));
        }
    };
};

export const getAssetMutedSettings = (assetID) => ({
    type: actionTypes.LOAD_ASSET_MUTE_SETTINGS_REQUEST,
    assetID
});
