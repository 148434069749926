import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
    LoadingStatusWrapper,
    ToggleButton,
    GeneralTable,
    SearchBox,
    GeneralSelect,
    GeneralButton
} from 'sharedComponents';
import Select from 'react-select';
import { useHistory } from 'react-router';

import { IconShowFiltersButton } from 'svgIcons/MotionPortalIcons';

import {
    getSearchMatcher,
    getAssesTypeOptions,
    getSubscriptionStatusToggleOptions,
    getSubscriptionsTableColumnConfig,
    getSubscriptionTypeOptions
} from './helpers';
import {
    subscriptionTimeline,
    subscriptionAssetType,
    customMultiSelectStyles,
    subscriptionStatus
} from './constants';

const SubscriptionStatusComponent = (
    {
        actions,
        model
    }
) => {
    const { t: translate } = useTranslation();
    const {
        loadingStatusAsset,
        contracts,
        activeTab,
        selectedNotification
    } = model;
    const [columnConfig, setColumnConfig] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [advanceFilter, setAdvanceFilter] = useState(false);
    const [timeLine, setTimeLine] = useState();
    const [subAssetType, setSubAssetType] = useState(subscriptionAssetType[0]);
    const [subscriptionType, setSubscriptionType] = useState([]);
    const history = useHistory();

    const clearNotificationData = () => {
        actions.setSelectedNotification(null);
    };

    const handleSelectChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map((option) => option.value);
        setSubscriptionType(selectedValues);
    };

    useEffect(() => {
        setColumnConfig(getSubscriptionsTableColumnConfig(translate, activeTab, history));
    }, [activeTab]);

    const handleTabSelection = (tab) => {
        actions.setActiveTabAction(tab);
        setSearchValue('');
    };

    const handleFilterReset = () => {
        setSearchValue('');
        setTimeLine('');
        setSubscriptionType([]);
        setSubAssetType(subscriptionAssetType[0]);
    };

    const filteredContracts = useMemo(() => {
        const isSearchMatch = getSearchMatcher(searchValue);
        if (!contracts) { return; }

        const data = [...contracts.none].sort((a, b) => {
            if (a.endDate === '' && b.endDate !== '') {
                return 1;
            }

            if (a.endDate !== '' && b.endDate === '') {
                return -1;
            }

            const dateA = new Date(a.endDate).getTime();
            const dateB = new Date(b.endDate).getTime();

            return dateA - dateB;
        });


        return (data ?? []).filter(
            (item) =>
                isSearchMatch(item?.componentName) ||
                isSearchMatch(item?.serialNumber) || isSearchMatch(item.id) || isSearchMatch(item.legacyAssetId) || isSearchMatch(item.smSeId)
        );
    }, [searchValue, contracts]);

    const filteredSubscriptionType = useMemo(() =>
        subscriptionType.length > 0
            ? (filteredContracts ?? []).filter((item) => subscriptionType.includes((item.typeName ?? '').toLowerCase()) || subscriptionType.includes((item.prevType ?? '').toLowerCase()))
            : filteredContracts,
        [filteredContracts, subscriptionType]
    );


    const filteredSubscription = useMemo(
        () =>
            activeTab === subscriptionStatus.ACTIVE ? (filteredSubscriptionType ?? []).filter((item) => item?.typeName !== subscriptionStatus.FREE && item?.typeName !== subscriptionStatus.FREE_FOR_RENEWAL)
                : (filteredSubscriptionType ?? []).filter((item) => item?.typeName === subscriptionStatus.FREE || item?.typeName === subscriptionStatus.FREE_FOR_RENEWAL),

        [filteredSubscriptionType, activeTab],
    );
    const filteredAssetType = useMemo(
        () =>
            subAssetType.value === 'all'
                ? filteredSubscription
                : (filteredSubscription ?? []).filter(
                    (item) => item.type.toLowerCase() === subAssetType.value
                ),
        [filteredSubscription, subAssetType],
    );


    const getMonthDifference = (itemEndDate) => {
        const currentDate = moment().startOf('day');
        const endDate = moment(itemEndDate).startOf('day');
        return Math.abs(currentDate.diff(endDate, 'months'));
    };


    const filteredEndDate = useMemo(() => {
        if (!timeLine || timeLine.value === 'All') {
            return filteredAssetType;
        }

        return (filteredAssetType ?? []).filter(item => {
            if (item.endDate && item.endDate !== '') {
                const itemEndDate = moment(item.endDate, 'YYYY-MM-DD'); // Ensure correct date format
                const monthDifference = getMonthDifference(itemEndDate);

                if (timeLine.value === '>12') {
                    return monthDifference > 12;
                } else {
                    return monthDifference < Number(timeLine.value);
                }
            }
            return false; // Ensure items without endDate are filtered out
        });
    }, [filteredAssetType, timeLine]);

    // SET TAB AND FILTER FROM NOTIFICATION
    useEffect(() => {
        if (selectedNotification && contracts && filteredEndDate?.length) {
            const assetName = selectedNotification?.assetName;
            if (activeTab !== subscriptionStatus.EXPIRED) { actions.setActiveTabAction(subscriptionStatus.EXPIRED); }
            setSearchValue(assetName || '');
        }

        return () => {
            if (selectedNotification) {
                clearNotificationData();
            }
        };
    }, [selectedNotification, filteredEndDate?.length]);

    const safeContracts = {
        none: contracts?.none || []
    };

    const subscriptionTypeOptions = getSubscriptionTypeOptions([...safeContracts.none]);

    return (
        <LoadingStatusWrapper loadingStatus={loadingStatusAsset}>
            <div className='tools_powertrain-subscription_status'>
                <div className='title'>
                    {translate('ABB.Powertrain.Frontend.ptToolsSubscriptionStatus')}
                </div>
                <div className='filter_section'>
                    <ToggleButton
                        options={getSubscriptionStatusToggleOptions(translate)}
                        onSelected={handleTabSelection}
                        selectedValue={activeTab}
                    />
                    <div className='filter_section_right'>
                        <div className='total_filter_items'>{(filteredEndDate ?? []).length} {translate('ABB.Powertrain.Frontend.condMonitoringOverviewAssetsLabel')}</div>
                        <SearchBox
                            placeholder={translate('ABB.Powertrain.Frontend.searchPlaceholderNameIDSerialNrText')}
                            onChange={(value) => setSearchValue(value)}
                            value={searchValue}
                        />
                        <div className='reports__header__show-filters' onClick={() => setAdvanceFilter(!advanceFilter)} aria-label='show-filter-button'>
                            <IconShowFiltersButton />
                        </div>
                    </div>
                </div>
                {advanceFilter &&
                    <div className='advance_filters'>
                        <div className='advance_filters__left'>
                            <div className='advance_filters__assetType'>
                                <label htmlFor='report-type-select'>{translate('ABB.Powertrain.Frontend.reportsAssetType')}</label>
                                <GeneralSelect
                                    options={[
                                        { label: translate('ABB.Powertrain.Frontend.allassettypesLabel'), value: 'all' },
                                        ...getAssesTypeOptions(contracts ? [...contracts.none] : [])
                                    ]}
                                    getOptionLabel={option => option.label}
                                    getOptionValue={option => option}
                                    defaultValue={subAssetType}
                                    value={subAssetType}
                                    menuShouldScrollIntoView={false}
                                    onChange={(item) => setSubAssetType(item)}
                                    isSearchable={false}
                                    inputId='subscription-asset-type'
                                />
                            </div>
                            <div className='advance_filters__expiration'>
                                <label htmlFor='report-type-select'>{translate('ABB.Powertrain.Frontend.subscriptionExpTime')}</label>
                                <GeneralSelect
                                    options={subscriptionTimeline}
                                    getOptionLabel={option => option.label}
                                    getOptionValue={option => option}
                                    defaultValue={timeLine}
                                    value={timeLine}
                                    menuShouldScrollIntoView={false}
                                    onChange={(item) => setTimeLine(item)}
                                    isSearchable={false}
                                    inputId='subscription-expiration-time'
                                />
                            </div>
                            <div className='advance_filters__subscriptiontype'>
                                <label htmlFor='report-type-select'>{translate('ABB.Powertrain.Frontend.subscriptionType')}</label>
                                <Select
                                    styles={customMultiSelectStyles}
                                    isMulti
                                    name='subscription-timeline'
                                    options={[
                                        ...subscriptionTypeOptions
                                    ]}
                                    onChange={handleSelectChange}
                                    value={subscriptionTypeOptions.filter((item) => subscriptionType.includes(item.value))}
                                />
                            </div>
                        </div>
                        <div className='advance_filters__reset'>
                            <GeneralButton type='normal' text={translate('ABB.Powertrain.Frontend.reportsResetFilter')} onClick={handleFilterReset} />
                        </div>
                    </div>
                }
                <GeneralTable
                    scroll={{ x: 720, y: null }}
                    tableKey='id'
                    columns={columnConfig}
                    data={contracts && filteredEndDate}
                    emptyText={translate('ABB.Powertrain.Frontend.noInformationAvailableLabel')}
                    paginationProps={{ pageSize: 50 }}
                />
            </div>
        </LoadingStatusWrapper>
    );
};

SubscriptionStatusComponent.propTypes = {
    actions: PropTypes.shape({
        setActiveTabAction: PropTypes.func,
        getContracts: PropTypes.func
    }),
    model: PropTypes.shape({
        contractsLoadingStatus: PropTypes.string,
        loadingStatusAsset: PropTypes.string,
        contracts: PropTypes.shape({
            none: PropTypes.array
        }),
        capabilitiesLoadingStatus: PropTypes.string,
        filteredAssetsLoadingStatus: PropTypes.string,
        filteredAssets: PropTypes.array,
        activeTab: PropTypes.string,
    })
};

export default SubscriptionStatusComponent;
