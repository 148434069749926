export const getNotificationGroupColumns = (notificationGroups) => {
    if (notificationGroups?.length > 5) {
        const columnLength = Math.ceil(notificationGroups.length / 2);
        return [
            {
                id: 'col1',
                columnData: notificationGroups.slice(0, columnLength)
            },
            {
                id: 'col2',
                columnData: notificationGroups.slice(columnLength, notificationGroups?.length)
            }
        ];
    } else {
        return [{
            id: 'col1',
            columnData: notificationGroups
        }];
    }
};

export const updateMutedGroupList = (mutedGroups, selectedGroup) => {
    const updatedList = mutedGroups.map((mg) => {
        if (mg.notificationGroupCode === selectedGroup.notificationGroupCode) {
            return {
                ...mg,
                muted: mg.muted === null ? false : !mg.muted
            };
        }
        return mg;
    });

    return updatedList;
};

export const updateBulkMutedGroupList = (mutedGroups = []) => {
    const updatedList = mutedGroups.map((mg) => {
        if (mg?.notificationGroupCode) {
            return {
                ...mg,
                muted: mg?.muted === null || mg?.muted === undefined ? false : mg?.muted
            };
        }
        return mg;
    });

    return updatedList;
};
