import { ComponentApi, CapabilityApi } from 'api/';
import { getApiActionCreator } from 'helpers/actionHelper';
import { NEWCAPABILITIES, apiCallStatus } from 'helpers/constants';
import { getGeneralFilteredAssetsV2 } from 'helpers/selectors';
import { actionTypes } from '../constants';


export { getAssetDetails, getAssetDetailsV2 } from '../DetailedInformation/actions';
export { getSubscriptionInfo } from '../../PowertrainTools/SubscriptionStatus/actions';

export const setSelectedPowertrain = (powertrain) => ({ type: actionTypes.SET_SELECTED_POWERTRAIN, powertrain });
export const setSelectedAsset = (asset) => ({ type: actionTypes.SET_SELECTED_ASSET, asset });

export const getSelectedPowertrainById = (powertrainId, powertrains) => {
    return (dispatch) => {
        if (powertrains) {
            const selectedPowertrain = powertrains?.find((powertrain) => powertrain?.powertrainId?.toString() === powertrainId) || null;
            dispatch(setSelectedPowertrain(selectedPowertrain));
        }
    };
};

export const getSelectedAssetFromPowertrainById = (assetId) => {
    return (dispatch, getState) => {
        const selectedPowertrain = getState().powertrainAssetDetails?.selectedPowertrain;
        const assets = getGeneralFilteredAssetsV2(getState());
        if (selectedPowertrain) {
            const selectedAsset = assets?.find(
                (asset) => asset?.id?.toString() === assetId || asset?.serialNumber && asset?.serialNumber?.toLowerCase() === assetId?.toLowerCase()
            ) || null;
            dispatch(setSelectedAsset(selectedAsset));
        }
    };
};

export const getSelectedAssetById = (assetId) => {
    return (dispatch, getState) => {
        const assets = getGeneralFilteredAssetsV2(getState());
        if (assets) {
            const selectedAsset = assets?.find(
                (asset) => asset?.id?.toString() === assetId || asset?.serialNumber && asset?.serialNumber?.toLowerCase() === assetId?.toLowerCase() || asset?.assetId?.toString() === assetId
            ) || null;
            dispatch(setSelectedAsset(selectedAsset));
        }
    };
};

export const resetAssetAndPowerTrainSelection = () => {
    return dispatch => {
        dispatch(setSelectedPowertrain(null));
        dispatch(setSelectedAsset(null));
    };
};

const getAssetCapabilityAction = getApiActionCreator(actionTypes.GET_ASSET_CAPABILITY);
const getNewCapabilitiesAction = getApiActionCreator(actionTypes.GET_ASSET_NEWCAPABILITIES);
export const getAssetCapability = (asset) => {
    return (dispatch, getState) => {
        const savedAssetCapability = getState().powertrainAssetDetails.selectedAssetCapability?.data;

        if (savedAssetCapability?.componentID !== asset?.componentID) {
            dispatch(getAssetCapabilityAction(apiCallStatus.LOADING));
            ComponentApi.getAssetCapability(asset).then(
                (data) => {
                    const assetID = getState().powertrainAssetDetails.selectedAsset?.componentID;
                    if (assetID === data?.componentID) {
                        dispatch(getAssetCapabilityAction(apiCallStatus.SUCCESS, data));
                    }

                },
                (err) => {
                    dispatch(getAssetCapabilityAction(apiCallStatus.ERROR, null, err));
                });
        }
    };
};
export const getAssetCapabilityV2 = (asset) => {
    return (dispatch, getState) => {
        const savedAssetCapability = getState().powertrainAssetDetails?.selectedAssetCapability?.data;
        console.log('savedAssetCapability------------------>', savedAssetCapability);
        if (savedAssetCapability?.componentID !== asset?.assetId) {
            dispatch(getAssetCapabilityAction(apiCallStatus.LOADING));
            ComponentApi.getAssetCapability(asset).then(
                (data) => {
                    const assetID = getState().powertrainAssetDetails?.selectedAsset?.assetId;
                    if (assetID === data.componentID) {
                        dispatch(getAssetCapabilityAction(apiCallStatus.SUCCESS, data));
                    }

                },
                (err) => {
                    dispatch(getAssetCapabilityAction(apiCallStatus.ERROR, null, err));
                });
        }
    };
};
export const getApiAssetCapability = (asset) => {
    return (dispatch, getState) => {
        const organizationId = getState().generalFilter?.selectedFilters?.organizations[0]?.organizationID;
        const capabilityCodes = Object.values(NEWCAPABILITIES);
        dispatch(getNewCapabilitiesAction(apiCallStatus.LOADING));
        return CapabilityApi.getCapabilities(organizationId, [asset?.id], capabilityCodes)
            .then((capabilities) => {
                dispatch(getNewCapabilitiesAction(apiCallStatus.SUCCESS, capabilities));
            }, (err) => {
                dispatch(getNewCapabilitiesAction(apiCallStatus.ERROR, [], err));
            });
    };
};

export const getAssetConditionIndicesRequest = (request) => ({
    type: actionTypes.LOAD_ASSET_CONDITION_INDICES_REQUEST,
    request
});

export const clearStore = () => {
    return (dispatch) => {
        dispatch({ type: actionTypes.CLEAR_STORE });
    };
};
