import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import React from 'react';
import { getKPIListForAsset, isKpiSelected, isKpiSelectedTrend, getSelectedParameterSignals } from './helpers';
import { useDispatch, useSelector } from 'react-redux';
import { removeSelectedKPI } from '../../ChartView/actions';
import { LoadingStatusWrapper } from 'sharedComponents/WithLoadingStatus';
import newCapabilityHelper from 'helpers/newCapabilityHelper';
import { contractInfoMessages, apiCallStatus } from 'helpers/constants';
import { ParameterSignalSelector } from './ParameterSignalSelector';
import { Checkbox } from 'sharedComponents/Checkbox';

const KPIListComponent = ({ selectedAsset, selectedAssetCapability, capabilityLoadingStatus, chartData, setKpi, selectedKPIType }) => {
    const { t: translate } = useTranslation();
    const dispatch = useDispatch();
    const { kpis, loadingStatus } = useSelector(
        ({ crossAssetAnalysisSidepanel }) => getKPIListForAsset(crossAssetAnalysisSidepanel?.kpiList, translate, selectedAsset?.id)
    );
    const newcapabilityHelper = new newCapabilityHelper(selectedAssetCapability, selectedAsset);
    const hasActiveSubscription = newcapabilityHelper.subscriptionStatus() === contractInfoMessages?.activeSubscription;
    const selectedParameterSignals = getSelectedParameterSignals(chartData);
    const toggleMeasurement = (kpi, isSelected) => {
        if (isSelected) {
            console.log('removeSelectedKPI', kpi, selectedAsset, selectedKPIType);
            dispatch(removeSelectedKPI(kpi, selectedAsset, selectedKPIType));
        }
        else {
            console.log('SelectedKPI', kpi, selectedAsset, selectedKPIType);
            dispatch(setKpi({ ...kpi, isChecked: true }, selectedAsset));
        }
    };
    return (
        <LoadingStatusWrapper
            className='loading-indicator-content'
            loadingStatus={[loadingStatus, capabilityLoadingStatus, selectedAssetCapability.loadingStatus]}
            indicatorConfig={{ size: 'small', style: { 'padding': '8px 0px' } }}>
            <div className='kpi-list'>
                {
                    //need to check alternative for isUserAccessible not available in microservices.
                    <>
                        {
                            loadingStatus === apiCallStatus.SUCCESS && !kpis?.standardKPIList?.length &&
                            !kpis?.parameterKPIList?.length &&
                            hasActiveSubscription &&
                            <div className='kpi-list-empty'>{translate('ABB.Powertrain.Frontend.noSupportedMeasurementTypes')}</div>
                        }
                        {
                            hasActiveSubscription ?
                                map(kpis?.standardKPIList, (kpi, index) => {
                                    const isSelected = selectedKPIType === 1 ? isKpiSelectedTrend(chartData, kpi) : isKpiSelected(chartData, kpi);
                                    return <>
                                        <div className={isSelected ? 'kpi-list-item selected' : 'kpi-list-item'}
                                            onClick={() => { kpi?.isPlottableToTrendChart && toggleMeasurement(kpi, isSelected); }}
                                            key={index} >
                                            <div><Checkbox checked={isSelected} disabled={!kpi?.isPlottableToTrendChart}
                                            /></div>
                                            <div className='kpi-name'>
                                                {kpi.displayName}
                                            </div>
                                        </div>
                                        {/* eslint-disable-next-line semi */}
                                    </>
                                })
                                :
                                <div className='kpi-list-empty'>{translate('ABB.Powertrain.Frontend.noSubscriptionOrContract')}
                                </div>
                        }
                        {
                            kpis?.parameterKPIList.length && hasActiveSubscription ?
                                <div className='kpi-parameter-signals'>
                                    <ParameterSignalSelector
                                        options={kpis?.parameterKPIList}
                                        setKpi={setKpi}
                                        removeSelectedKPI={removeSelectedKPI}
                                        chartData={chartData}
                                        selectedAsset={selectedAsset}
                                        placeholder={translate('ABB.Powertrain.Frontend.additionaltrends')}
                                        value={selectedParameterSignals}
                                        selectedKPIType={selectedKPIType}

                                    />
                                </div> : null
                        }


                    </>
                }
            </div>
        </LoadingStatusWrapper >
    );
};

KPIListComponent.propTypes = {
    selectedAsset: PropTypes.any,
    kpis: PropTypes.any,
    chartData: PropTypes.array,
    selectedAssetCapability: PropTypes.object,
    setKpi: PropTypes.func,
    selectedKPIType: PropTypes.number,
};

export default KPIListComponent;
