import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import {
    TimeInput
} from 'sharedComponents';
import {
    convertUserTimeFormat,
    get24TimeFormat,
    getDayPeriod,
    detectTimeFormat,
    dayOfWeek,
    defaultMaintenanceData,
    convertTimeString,
    calculateDuration,
    convertDurationToTime,
} from './helpers';

const SensorFirmwareManagementDays = (
    {
        actions,
        model
    }
) => {
    const { externalId, maintenanceSchedule } = model;
    const { addMaintenanceSchedule } = actions || {};
    const [maintenanceData, setMaintenanceData] = useState({});
    const { t: translate } = useTranslation();
    const [validateErrors, setValidateErrors] = useState({
        isStartTimeError: false,
        isEndTimeError: false,
        isStartTimeInvalid: false,
        isEndTimeInvalid: false
    });

    // Initial API response data
    const initMaintanenceData = () => {
        const {startTime, endTime, maintenanceType, startDayOfWeek, endDayOfWeek} = maintenanceSchedule?.data || {};
        const [localStartTime, localStartDayOfWeek] = convertTimeString(startTime, startDayOfWeek);
        const [localEndTime, localEndDayOfWeek] = convertTimeString(endTime, endDayOfWeek);
        return {
            maintenanceType: maintenanceType,
            startDayOfWeek: localStartDayOfWeek,
            endDayOfWeek: localEndDayOfWeek,
            startTime: convertUserTimeFormat(localStartTime),
            startPeriod: getDayPeriod(localStartTime),
            endTime: calculateDuration(localStartTime, localStartDayOfWeek, localEndTime, localEndDayOfWeek),
        };
    };

    useEffect(() => {
        if (externalId) {
            if (maintenanceSchedule?.data?.maintenanceType) {
                setMaintenanceData(initMaintanenceData());
            } else {
                setMaintenanceData(defaultMaintenanceData());
            }
        }
    }, [maintenanceSchedule?.data]);

    const updateMaintanenceData = (updatedObject) => {
        setMaintenanceData({
            ...maintenanceData,
            ...updatedObject
        });
    };

    // Show and Hide Save button by comparing data
    const isShowSaveOption = () => {
        if (maintenanceSchedule?.data?.maintenanceType) {
            return !isEqual(initMaintanenceData(), maintenanceData);
        }
        return true;
    };

    // Time validation pattern
    const isSaveTimeData = () => {
        const pattern = detectTimeFormat() ? /^([0][1-9]|1[0-2]):([0-5][0-9])$/ : /^([01][0-9]|2[0-3]):([0-5][0-9])$/;
        setValidateErrors({
            isStartTimeError: !maintenanceData.startTime,
            isEndTimeError: !maintenanceData.endTime,
            isStartTimeInvalid: !pattern.test(maintenanceData.startTime),
            isEndTimeInvalid: !pattern.test(maintenanceData.endTime) || maintenanceData.endTime === '00:00'
        });
        if (!addMaintenanceSchedule || !externalId || !maintenanceData.startTime || !maintenanceData.endTime
             || !pattern.test(maintenanceData.startTime) || !pattern.test(maintenanceData.endTime)
                 || maintenanceData.endTime === '00:00') {
            return false;
        }
        return true;
    };

    const handleDayClick = (day) => {
        updateMaintanenceData({ startDayOfWeek: day });
    };

    const handleResetClick = () => {
        addMaintenanceSchedule(externalId, {
            'maintenanceType': 'FOTA',
            'startDayOfWeek': 'Sunday',
            'startTime': '00:00:00',
            'endDayOfWeek': 'Sunday',
            'endTime': '02:00:00',
        }, false);
        setValidateErrors({
            isStartTimeError: false,
            isEndTimeError: false,
            isStartTimeInvalid: false,
            isEndTimeInvalid: false,
        });
    };

    const handleSaveClick = () => {
        if (isSaveTimeData()) {
            const {
                maintenanceType,
                startDayOfWeek,
                startTime,
                startPeriod,
                endTime,
            } = maintenanceData;
            const convertedStartTime = get24TimeFormat(startTime, startPeriod);
            const [utcStartTime, utcStartDayOfWeek] = convertTimeString(convertedStartTime, startDayOfWeek, false);
            const [utcEndTime, utcEndDayOfWeek] = convertDurationToTime(utcStartTime, utcStartDayOfWeek, endTime);
            addMaintenanceSchedule(externalId, {
                'maintenanceType': maintenanceType,
                'startDayOfWeek': utcStartDayOfWeek,
                'startTime': utcStartTime,
                'endDayOfWeek': utcEndDayOfWeek,
                'endTime': utcEndTime,
            }, true);
        }
    };

    return <>
            <div className='day-of-week-container'>
                <label className='label-title'>{translate('ABB.Powertrain.Frontend.sensorFirmwareManagementDaysTitle')}</label>
                <div className='label-box'>
                    {Object.keys(dayOfWeek).map(day =>
                        <button
                            key={day}
                            className={`day ${maintenanceData.startDayOfWeek === dayOfWeek[day] ? 'selected' : 'unselected'}`}
                            onClick={() => handleDayClick(dayOfWeek[day])}
                            disabled={!externalId}
                        >
                            {day}
                        </button>
                    )}
                </div>
            </div>

            <div className='timelabel'>
                <TimeInput
                    label={translate('ABB.Powertrain.Frontend.sensorFirmwareManagementStartTime')}
                    defaultTime={maintenanceData?.startTime}
                    defaultPeriod={maintenanceData?.startPeriod}
                    setTime={(value) => updateMaintanenceData({ startTime: value })}
                    setPeriod={(value) => updateMaintanenceData({ startPeriod: value })}
                    timeError={validateErrors.isStartTimeError}
                    externalId={externalId}
                    validationError={validateErrors.isStartTimeInvalid}
                    isForStartTime={true}
                />
                <TimeInput
                    label={translate('ABB.Powertrain.Frontend.sensorFirmwareManagementEndTime')}
                    defaultTime={maintenanceData?.endTime}
                    setTime={(value) => updateMaintanenceData({ endTime: value })}
                    timeError={validateErrors.isEndTimeError}
                    externalId={externalId}
                    validationError={validateErrors.isEndTimeInvalid}
                />
            </div>
            <div className='buttons'>
                <button
                    className='reset'
                    disabled={!externalId}
                    onClick={handleResetClick}
                >
                    {translate('ABB.Powertrain.Frontend.resetButton')}
                </button>
                <button
                    className='save'
                    disabled={!externalId || !isShowSaveOption()}
                    onClick={handleSaveClick}
                >
                    {translate('ABB.Powertrain.Frontend.saveButton')}
                </button>
            </div>
        </>;
};

export default SensorFirmwareManagementDays;
