export const actionTypes = {
    GET_ASSET_OVERVIEW: 'powertrainAssetsOverview/GET_ASSET_OVERVIEW',
    GET_ASSET_GROUP_OVERVIEW: 'powertrainAssetsOverview/GET_ASSET_GROUP_OVERVIEW',
    GET_POWERTRAIN_OVERVIEW: 'powertrainAssetsOverview/GET_POWERTRAIN_OVERVIEW',
    GET_SUBSCRIPTION_TYPES: 'powertrainAssetsOverview/GET_SUBSCRIPTION_TYPES',
    GET_SUBSCRIPTION_TYPES_SETTINGS: 'powertrainAssetsOverview/GET_SUBSCRIPTION_TYPES_SETTINGS',
    SET_ACTIVE_TAB: 'powertrainAssetsOverview/SET_ACTIVE_TAB',
    TOGGLE_CONDITION_STATUS_FILTER: 'powertrainAssetsOverview/TOGGLE_CONDITION_STATUS_FILTER',
    SET_ASSET_TYPES_FILTER: 'powertrainAssetsOverview/SET_ASSET_TYPES_FILTER',
    SET_SEARCHBOX_FILTER: 'powertrainAssetsOverview/SET_SEARCHBOX_FILTER',
    SET_SUBSCRIPTION_TYPE_FILTER: 'powertrainAssetsOverview/SET_SUBSCRIPTION_TYPE_FILTER',
    RESET_FILTERS: 'powertrainAssetsOverview/RESET_FILTERS',
    CLEAR_ASSET_OVERVIEW: 'powertrainAssetsOverview/CLEAR_ASSET_OVERVIEW',
    CLEAR_ASSET_GROUP_OVERVIEW: 'powertrainAssetsOverview/CLEAR_ASSET_GROUP_OVERVIEW',
    CLEAR_POWERTRAIN_OVERVIEW: 'powertrainAssetsOverview/CLEAR_POWERTRAIN_OVERVIEW',
    CLEAR_STORE: 'powertrainAssetsOverview/CLEAR_STORE',

    LOAD_ASSET_CONDITION_INDICES_REQUEST: 'powertrainAssetsOverview/LOAD_ASSET_CONDITION_INDICES_REQUEST',
    LOAD_ASSET_GROUP_CONDITION_INDICES_REQUEST: 'powertrainAssetsOverview/LOAD_ASSET_GROUP_CONDITION_INDICES_REQUEST',
    LOAD_POWERTRAIN_CONDITION_INDICES_REQUEST: 'powertrainAssetsOverview/LOAD_POWERTRAIN_CONDITION_INDICES_REQUEST'
};

export const toggleButtonOptions = {
    POWERTRAINS: 'Powertrains',
    ASSETGROUPS: 'AssetGroups',
    ASSETS: 'Assets'
};

export const assetActive = {
    ACTIVE: 'active'
};

export const sortOrder = ['Poor', 'Tolerable', 'Good', 'Unknown', 'Not Supported'];

export const pageSize = 500;

export const conditionIndices = {
    TOLERABLE: 'Tolerable',
    GOOD: 'Good',
    POOR: 'Poor',
    UNSUPPORTED: 'Unsupported',
    UNKNOWN: 'Unknown'
};
