import { createSelector } from 'reselect';
import { uniqBy } from 'lodash';
import { getCountriesFromRawSiteList, getSitesByCountry, mapAssetGroups } from '../helpers';
import { getOrganizations } from 'helpers/selectors';

const getSelectedFilters = ({ generalFilterEdit }) => {
	return generalFilterEdit.selectedFilters;
};

const getSelectedOrganization = ({ generalFilterEdit }) => {
	return generalFilterEdit?.selectedFilters?.organizations[0] || null;
};

const getLoadingStatuses = ({ generalFilterEdit }) => {
	return {
		organizationsLoadingStatus: generalFilterEdit.organizations.loadingStatus,
		sitesLoadingStatus: generalFilterEdit.sites.loadingStatus,
		assetGroupsLoadingStatus: generalFilterEdit.assetGroups.loadingStatus
	};
};

const getAvailableCountries = createSelector([
	({ generalFilterEdit }) => generalFilterEdit.sites.data,
	({ overview }) => {
		return overview?.assets?.data?.map(asset => asset?.site);
	}
], (
	rawSites,
	assetSites
) => {
	const countries = getCountriesFromRawSiteList(rawSites);
	const assetCountries = getCountriesFromRawSiteList(assetSites);
	const combinedCountries = [...countries, ...assetCountries];
	return uniqBy(combinedCountries, 'countryCode');
});

const getAvailableSites = createSelector([
	getAvailableCountries,
	({ generalFilterEdit }) => generalFilterEdit.sites.data,
	({ generalFilterEdit }) => generalFilterEdit.selectedFilters.countries
], (
	availableCountries,
	rawSites,
	selectedCountryFilters,
) => {
	const sites = getSitesByCountry(rawSites, availableCountries, selectedCountryFilters);
	return sites;
});

const getAvailableAssetGroups = createSelector([
	({ generalFilterEdit }) => generalFilterEdit.assetGroups.data,
], (
	rawAssetGroups,
) => {
	const mappedGroups = mapAssetGroups(rawAssetGroups);
	return mappedGroups;
});
const isAdmin = ({ overview }) => {
	return overview?.user?.data?.isAdminFromCIAM;
};

const isCIAMSyncFlag = ({ generalFilter }) => {
	return generalFilter?.refeshCIAMSync;
};

export const getModel = createSelector([
	getOrganizations,
	getAvailableCountries,
	getAvailableSites,
	getAvailableAssetGroups,
	getSelectedFilters,
	getLoadingStatuses,
	getSelectedOrganization,
	isAdmin,
	isCIAMSyncFlag
], (
	organizations,
	availableCountries,
	availableSites,
	availableAssetGroups,
	selectedFilters,
	loadingData,
	selectedOrganization,
	isAdmin,
	isCIAMSyncFlag
) => {
	return {
		organizations,
		availableCountries,
		availableSites,
		availableAssetGroups,
		selectedFilters,
		loadingData,
		selectedOrganization,
		isAdmin,
		isCIAMSyncFlag
	};
});
