import { map, sortBy, uniqBy, forEach, keys, cloneDeep } from 'lodash';
import { dataSources } from 'helpers/constants';
import CapabilityHelper from 'helpers/newCapabilityHelper';
import { dateKind, causeOfEvents } from '../constants';
import { ALL_VALUE } from './constants';
import { getAssetDataSourceType } from 'helpers/assetAndPowertrainFilterHelper';


export const getDateKindOptions = (translate) => {
    return map(dateKind, (id, key) => {
        return { id, title: translate(`ABB.Powertrain.Frontend.eventListFilterDateKind${key}`) };
    });
};

export const getDefaultTimeZoneValue = (timeZone, translate) => {
    const defaultKey = Object.keys(dateKind).find(key => dateKind[key] === timeZone);
    return defaultKey ? { id: timeZone, title: translate(`ABB.Powertrain.Frontend.eventListFilterDateKind${defaultKey}`) } : null;
};

export const getCauseOfDriveEventsOptions = (translate, includeAll = true) => {
    const listOfCauses = map(causeOfEvents, (id) => {
        return { id, title: translate(`ABB.Powertrain.Frontend.eventCauseOption${id}`) };
    });

    if (!includeAll) {
        listOfCauses.shift();
    }

    return sortBy(listOfCauses, [entry => entry.title.toLowerCase()]);
};

export const getDefaultCauseOfDriveEvent = (id, translate) => {
    return { id, title: translate(`ABB.Powertrain.Frontend.eventCauseOption${id}`) };
};

const isAssetDisabledByCapability = (capabilities, asset) => {
    if (asset && keys(capabilities)?.length) {
        const capability = capabilities[asset?.id];
        const capabilityHelper = new CapabilityHelper({ data: capability }, asset);
        const isDisabled = !(capabilityHelper.checkEventListMultipleAssetsCapability() &&
            capabilityHelper.hasEventListCapability());
        return isDisabled;
    }

    return true;
};

export const getFormattedAssets = (capabilities, assets, selectedAsset) => {
    const assetList = [];

    forEach(assets, (asset) => {
        if (getAssetDataSourceType(asset) === dataSources.CMD4) {
            assetList.push({
                value: asset?.id,
                label: asset?.name,
                isDisabled: isAssetDisabledByCapability(capabilities, asset) ||
                    selectedAsset?.id === asset?.id,
                componentType: asset?.assetType?.toLowerCase(),
                componentID: asset?.id,
                id: asset?.id
            });
        }
    });

    return uniqBy(sortBy(assetList, (asset) => {
        return (asset?.label || '').toLowerCase();
    }), 'id'
    );
};

export const getAllAssetsOption = (translate) => {
    return {
        value: ALL_VALUE,
        label: translate('ABB.Powertrain.Frontend.eventFilterallAssetsOption'),
        isDisabled: false
    };
};

export const setMaintainEventData = (state, { data, selectedAsset, selectedOrganization, eventOptionType }) => {
    const getMaintainSelectedEventData = cloneDeep(state?.maintainEventData);
    const assetId = selectedAsset?.id, organizationId = selectedOrganization;
    const optionTypeName = eventOptionType?.name;
    if (getMaintainSelectedEventData && assetId && organizationId) {
        if (getMaintainSelectedEventData[organizationId]) {
            if (getMaintainSelectedEventData[organizationId][assetId]) {
                getMaintainSelectedEventData[organizationId][assetId][optionTypeName] = data;
            } else {
                getMaintainSelectedEventData[organizationId][assetId] = {
                    [optionTypeName]: data
                };
            }
        } else {
            getMaintainSelectedEventData[organizationId] = {
                [assetId]: {
                    [optionTypeName]: data
                }
            };
        }
    }
    return getMaintainSelectedEventData;
};

const getMaintainEventData = (maintainSelectedEventData, selectedAsset, selectedOrganization, eventOptionType) => {
    const assetId = selectedAsset?.id, organizationId = selectedOrganization, optionTypeName = eventOptionType?.name;
    if (maintainSelectedEventData && assetId && organizationId && optionTypeName) {
        if (maintainSelectedEventData[organizationId] && maintainSelectedEventData[organizationId][assetId] && maintainSelectedEventData[organizationId][assetId][optionTypeName]) {
            return maintainSelectedEventData[organizationId][assetId][optionTypeName];
        }
    }
    return null;
};

export const getSelectedEventData = (maintainSelectedEventData, selectedAsset, selectedOrganization, selectedEventData, eventOptionType) => {
    const maintainEventData =  getMaintainEventData(maintainSelectedEventData, selectedAsset, selectedOrganization, eventOptionType);
    return maintainEventData ? maintainEventData : selectedEventData || [];
};
