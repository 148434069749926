export const actionTypes = {
    GET_NOTIFICATION_LIST: 'notifications/GET_NOTIFICATION_LIST',
    SET_NOTIFICATION_LIST: 'notifications/SET_NOTIFICATION_LIST',
    READ_NOTIFICATION: 'notifications/READ_NOTIFICATION',
    LOAD_NOTIFICATION: 'notifications/LOAD_NOTIFICATION',
    SET_SELECTED_NOTIFICATION: 'notifications/SET_SELECTED_NOTIFICATION',
    GET_ASSET_DETAILS: 'detailedInformation/GET_ASSET_DETAILS',
};

export const MAX_NOTIFICATION = 99;
export const NOTIFICATION_PAGE_SIZE = 50;

const minutes = 60, seconds = 60, miliSeconds = 1000;
const interval = minutes * seconds * miliSeconds;
export const NOTIFICATION_INTERVAL = interval;
