import { map, filter, forEach } from 'lodash';
import { dataSources, ASSET_TYPES_MS } from 'helpers/constants';
import {
    eventStatus,
    eventClosingReason,
    EventTypeIDs
} from './constants';
import { getAssetDataSourceType } from 'helpers/assetAndPowertrainFilterHelper';

export const getEventStatusOptions = (translate) => {
    return map(eventStatus, (id) => {
        return { id, title: translate(`ABB.Powertrain.Frontend.eventListFilterSSEventStatus${id}`) };
    });
};

export const getDefaultEventStatus = (status, translate) => {
    return { id: status, title: translate(`ABB.Powertrain.Frontend.eventListFilterSSEventStatus${status}`) };
};

export const getClosingReasons = (translate) => {
    return [
        {
            id: eventClosingReason.Dismiss,
            title: translate(`ABB.Powertrain.Frontend.eventClosingReason${eventClosingReason.Dismiss}`),
        },
        {
            id: eventClosingReason.FalseAlarm,
            title: translate(`ABB.Powertrain.Frontend.eventClosingReason${eventClosingReason.FalseAlarm}`),
        },
        {
            id: eventClosingReason.Corrected,
            title: translate(`ABB.Powertrain.Frontend.eventClosingReason${eventClosingReason.Corrected}`),
        }
    ];
};

export const updateEventLogs = (eventLogs, newEvent) => {
    return map(eventLogs, (eventLog) => {
        if (eventLog?.eventId === newEvent?.eventId) {
            eventLog.closingReasonCode = newEvent?.closingReasonCode;
            eventLog.lifecycle = eventStatus?.CLOSED;
            return eventLog;
        }
        else { return eventLog; }
    });
};

export const filterAndFormatCMDAssets = (assets) => {
    const cmdAssets = assets?.length &&
        filter(assets, asset => getAssetDataSourceType(asset) === dataSources.CMD4);
    return map(cmdAssets, (asset) => asset?.id);
};

export const getFormattedAsset = (asset) => {
    return {
        value: asset?.id,
        label: asset?.name,
        isDisabled: false,
        componentType: asset?.assetType?.toLowerCase(),
        componentID: asset?.id,
        id: asset?.id
    };
};

export const getDefaultEventTypesByAssetType = (asset) => {
    return asset?.assetType === ASSET_TYPES_MS.DRIVE ?
        [EventTypeIDs.FAULT, EventTypeIDs.ALARM, EventTypeIDs.WARNING, EventTypeIDs.ALERT] :
        [EventTypeIDs.ALERT, EventTypeIDs.ALARM];
};

export const formatAssetCapabilities = (capabilities) => {
    const capabilityDict = {};

    forEach(capabilities.assetCapabilitiesInfos, (assetCapability) => {
        capabilityDict[assetCapability?.assetId] = {
            assetCapabilitiesInfos: [{ ...assetCapability }]
        };
    });

    return capabilityDict;
};
