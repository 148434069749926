export const actionTypes = {
    CLEAR_STORE: 'detailedInformation/header/CLEAR_STORE',
    SELECT_MENU_ITEM: 'assetDetailsMenuItem/SELECT_MENU_ITEM',
    CLOSE_DIALOGUE: 'overview/CLOSE_DIALOGUE'
};

export const assetMenuItems = {
    ALERT_ALARM_OFFSET: 'assetDetailsMenuItem/ALERT_ALARM_OFFSET',
    EXPORT_PARAMETERS: 'assetDetailsMenuItem/EXPORT_PARAMETERS',
    EXPORT_PARAMETERSV2: 'assetDetailsMenuItem/EXPORT_PARAMETERSV2',
    EXPORT_MEASUREMENTS: 'assetDetailsMenuItem/EXPORT_MEASUREMENTS',
    CHANGED_PARAMETER_LIST: 'assetDetailsMenuItem/CHANGED_PARAMETER_LIST',
    CHANGED_PARAMETER_LIST_V2: 'assetDetailsMenuItem/CHANGED_PARAMETER_LIST_V2',
    UPDATE_ASSET: 'assetDetailsMenuItem/UPDATE_ASSET',
    USER_LIMITS: 'assetDetailsMenuItem/USER_LIMITS',
    BULK_UPLOAD: 'assetDetailsMenuItem/BULK_UPLOAD',
    EMAIL_ALERT: 'assetDetailsMenuItem/EMAIL_ALERT',
    GENERATE_INSTANT_REPORT: 'assetDetailsMenuItem/GENERATE_INSTANT_REPORT',
    VIEW_REPORTS_FOR_ASSET: 'assetDetailsMenuItem/VIEW_REPORTS_FOR_ASSET',
    MUTE_ASSET: 'assetDetailsMenuItem/MUTE_ASSET',
    LOADING: 'assetDetailsMenuItem/LOADING',
    DATA_LOGGER_TRIGGER: 'assetDetailsMenuItem/DATA_LOGGER_TRIGGER',
};
