import { filterAssets, filterPowertrains } from 'helpers/assetAndPowertrainFilterHelper';
import { getGeneralFilteredPowertrains, getGeneralFilteredAssetsV2 } from 'helpers/selectors';
import { createSelector } from 'reselect';
import { orderByAlphabet } from './helpers';

const getSelectedAsset = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails.selectedAsset;
};

const getSelectedAssetCrossAssetAnalysis = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails.selectedAssetCapability?.data?.componentName;
};

const getFilters = ({ assetAndPowetrainFilter }) => {
    return assetAndPowetrainFilter;
};

export const getFormattedAssets = createSelector(
    [getGeneralFilteredAssetsV2, getFilters],
    (assets, filters) => {
        return filterAssets(assets, filters);
    }
);

export const getFormattedPowertrains = createSelector(
    [getGeneralFilteredPowertrains, getFilters],
    (powertrains, filters) => {
        return orderByAlphabet(filterPowertrains(powertrains, filters));
    }
);

const getSelectedPowertrain = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails?.selectedPowertrain;
};

const getSelectedOrganizationId = ({ generalFilter }) => {
    return generalFilter?.selectedFilters?.organizations[0]?.organizationID;
};
/*const getAssetsList = ({ overview }) => {
    return overview?.assets?.data;
};*/

export const getModel = createSelector([
    getSelectedAsset,
    getFormattedAssets,
    getSelectedAssetCrossAssetAnalysis,
    getSelectedPowertrain,
    getSelectedOrganizationId
], (
    selectedAsset,
    assets,
    selectedAssetCrossAssetAnalysis,
    selectedPowertrain,
    selectedOrganizationId
) => {
    return {
        selectedAsset,
        assets,
        selectedAssetCrossAssetAnalysis,
        selectedPowertrain,
        selectedOrganizationId
    };
});
