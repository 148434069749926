import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Tippy from '@tippy.js/react';
import { debounce } from 'lodash';
import { SearchBox, LoadingStatusWrapper, GeneralSelect, GeneralButton } from 'sharedComponents';
import {
    IconBroadcast,
    IconInfo,
    IconGatewayFilter,
} from 'svgIcons/MotionPortalIcons';
import GatewayListManagement from './GatewayListManagementComponent';
import { GatewayDetails } from './GatewayDetails';
import GatewayManagementEmptyComponent from './GatewayManagementEmptyComponent';
import { MAX_SEARCH_LENGTH } from './helpers';

const GatewayManagmentComponent = ({ model, actions }) => {
    const { t: translate } = useTranslation();
    const [leftWidth, setLeftWidth] = useState(30);
    const [isDragging, setIsDragging] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [selectedGatewayType, setSelectedGatewayType] = useState(null);
    const { selectedOrganization, gatewayDetails, gatewayType } = model;
    const { getGatewayList, getGatewayType } = actions || {};
    const [gatewayID, setGatewayID] = useState(null);
    const [gatewayStatus, setGatewayStatus] = useState(null);
    const [statusChangeTimestamp, setStatusChangeTimestamp] = useState(null);
    const [isFilterActive, setIsFilterActive] = useState(false);

    useEffect(() => {
        if (selectedOrganization?.organizationID) {
            getGatewayList(selectedOrganization?.organizationID);
            getGatewayType();
        }
    }, [selectedOrganization?.organizationID, getGatewayList]);

    const handleMouseUp = useCallback(() => {
        setIsDragging(false);
    }, []);

    const handleMouseDown = useCallback(() => {
        setIsDragging(true);
    }, []);

    const handleMouseMove = useCallback(
        (e) => {
            if (isDragging) {
                const containerRect = e.currentTarget.getBoundingClientRect();
                const newLeftWidth = (e.clientX - containerRect.left) / containerRect.width * 100;
                const constrainedLeftWidth = Math.min(Math.max(newLeftWidth, 25), 50);
                setLeftWidth(constrainedLeftWidth);
            }
        },
        [isDragging]
    );

    const handleOnSelectedGatewayData = ({ id, status, timeStamp }) => {
        setGatewayID(id);
        setGatewayStatus(status);
        setStatusChangeTimestamp(timeStamp);
    };

    const toggleFilter = () => {
        setIsFilterActive(prevState => !prevState);
    };

    const debouncedSearch = useCallback(
        debounce((value) => {
            const filters = {
                keyword: value,
                gatewayType: selectedGatewayType?.gatewayType || null,
            };

            const siteIDArr = [];
            gatewayDetails.gatewayList.forEach((item) => {
                const siteId = item?.site?.siteId;
                if (siteId && !siteIDArr.includes(siteId)) {
                    siteIDArr.push(siteId);
                }
            });
            if (selectedOrganization?.organizationID) {
                getGatewayList(selectedOrganization.organizationID, siteIDArr, filters);
            }
        }, 500),
        [gatewayDetails.gatewayList, selectedGatewayType, selectedOrganization, getGatewayList]
    );

    const handleFilterSearchChange = (value) => {
        if (value.length <= MAX_SEARCH_LENGTH) {
            setSearchValue(value);
            debouncedSearch(value);
        }
    };

    const handleResetFilter = () => {
        setSearchValue('');
        setSelectedGatewayType(null);
        if (selectedOrganization?.organizationID) {
            getGatewayList(selectedOrganization.organizationID);
        }
    };

    return (
        <div className='gateway-management-wrapper'>
            <div className='gateway-management'
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
            >
                <div className='gateway-management__left-pane' style={{ width: `${leftWidth}%` }}>
                    <div className='gateway-title'>
                        <div className='title-content'>
                            <IconBroadcast /><span className='title'>{translate('ABB.Powertrain.Frontend.gatewayTitle')}</span>
                        </div>
                    </div>
                    <div className='list-header'>
                        <div className='list-header__left-content'>
                            <SearchBox className='search-box'
                                placeholder={translate('ABB.Powertrain.Frontend.gatewayManagementSearch')}
                                onChange={handleFilterSearchChange}
                                value={searchValue}
                            />
                            <Tippy
                                content={translate('ABB.Powertrain.Frontend.gatewaySearchMessage')}
                                interactive={true}
                                interactiveBorder={10}
                                animation='scale'
                                theme='light-border'
                                trigger='click'
                                placement='left'>
                                <div className='info-icon'>
                                    <IconInfo width={'16'} height={'16'} color={'black'} />
                                </div>
                            </Tippy>
                        </div>
                        <div className='list-header__right-content'>
                            <button className={`filter-icon ${isFilterActive ? 'filter-icon--active' : ''}`} onClick={toggleFilter}>
                                <IconGatewayFilter />
                            </button>
                        </div>
                    </div>
                    {isFilterActive &&
                        <div className='filter-section'>
                            <div className='filter-section__legend'>
                                {translate('ABB.Powertrain.Frontend.gatewayTypeLabel')}
                            </div>
                            <GeneralSelect
                                className='gateway-type-filter'
                                options={gatewayType?.gatewayType}
                                getOptionLabel={option => option.gatewayTypeName}
                                getOptionValue={option => option.gatewayType}
                                onChange={setSelectedGatewayType}
                                value={selectedGatewayType}
                                menuShouldScrollIntoView={false}
                                isSearchable={false}
                            />
                            <div className='filter-section__btn'>
                                <GeneralButton
                                    type='discreet'
                                    text={translate('ABB.Powertrain.Frontend.Reset')}
                                    onClick={handleResetFilter}
                                />
                                <GeneralButton
                                    type='primary'
                                    text={translate('ABB.Powertrain.Frontend.gatewayManagementSearch')}
                                    onClick={() => debouncedSearch(searchValue)}
                                    disabled={!selectedGatewayType}
                                />
                            </div>
                        </div>
                    }
                    <LoadingStatusWrapper loadingStatus={gatewayDetails?.loadingStatus}>
                        <GatewayListManagement gatewayDetails={gatewayDetails} onSelectedGatewayData={handleOnSelectedGatewayData} />
                    </LoadingStatusWrapper>
                </div>
                <div className='resize-handle' onMouseDown={handleMouseDown}>
                    <button className='resize-handle__button' onMouseDown={handleMouseDown}>
                        <IconAdjust />
                    </button>
                </div>
                <div className='gateway-management__right-pane' style={{ width: `${100 - leftWidth}%` }}>
                    <div className='right-pane__contents'>
                        {gatewayID ? <GatewayDetails gatewayID={gatewayID} gatewayStatus={gatewayStatus} statusChangeTimestamp={statusChangeTimestamp} /> : <GatewayManagementEmptyComponent />}
                    </div>
                </div>
            </div>
        </div>
    );
};

const IconAdjust = () => {
    return (
        <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='10' cy='3.5' r='1' transform='rotate(90 10 3.5)' fill='#696969' />
            <circle cx='6' cy='3.5' r='1' transform='rotate(90 6 3.5)' fill='#696969' />
            <circle cx='10' cy='8.5' r='1' transform='rotate(90 10 8.5)' fill='#696969' />
            <circle cx='6' cy='8.5' r='1' transform='rotate(90 6 8.5)' fill='#696969' />
            <circle cx='10' cy='13.5' r='1' transform='rotate(90 10 13.5)' fill='#696969' />
            <circle cx='6' cy='13.5' r='1' transform='rotate(90 6 13.5)' fill='#696969' />
        </svg>
    );
};

export default GatewayManagmentComponent;
