import { createSelector } from 'reselect';

const getSelectedOrganization = ({ generalFilter }) => {
    return generalFilter?.selectedFilters?.organizations[0];
};

const getSelectedLanguage = ({ overview }) => {
    return overview?.asset?.data?.languageCode;
};

const getEnvironmentStageSelector = ({ portal: { environmentProperties } }) => {
    return environmentProperties?.data?.orgMgtUrl;
};

export const getModel = createSelector(
    [
        getSelectedLanguage,
        getSelectedOrganization,
        getEnvironmentStageSelector
    ],
    (
        selectedLanguage,
        selectedOrganization,
        environmentStageSelector
    ) => {
        return {
            selectedLanguage,
            selectedOrganization,
            environmentStageSelector
        };
    }
);
