import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useNewCapability } from 'sharedHooks';
import { filterAndFormatCMDAssets, getFormattedAsset } from './helpers';
import { EventLogs } from './EventLogs';
import { EventLogFilter } from './EventLogFilter';
import { EventDetailsSS } from './EventDetails/EventDetailsSS';
import { EventDetailsCMD } from './EventDetails/EventDetailsCMD';
import { apiCallStatus } from 'helpers/constants';


const EventLogComponent = (props) => {
    const capability = useNewCapability();
    const isSmartSensor = capability.isSmartSensor();

    const {
        generalFilteredAssets,
        selectedAsset,
        selectedOrganization,
        isExpanded,
        assetCapabilityLoadingStatus
    } = props.model;

    const {
        loadCapabilitiesForAssets,
        setSelectedAssets,
        setSelectedEvent,
        setIsExapnded,
    } = props.actions;

    const [eventPage, setEventPage] = useState(1);
    const selectedEventCategoryRef = useRef(null);
    const [eventItemPage, setEventItemPage] = useState(1);
    const selectedEventItemPageRef = useRef(null);

    useEffect(() => {
        generalFilteredAssets?.length && !isSmartSensor &&
            loadCapabilitiesForAssets(selectedOrganization?.organizationID, filterAndFormatCMDAssets(generalFilteredAssets));
    }, [generalFilteredAssets]);

    useEffect(() => {
        setSelectedAssets([getFormattedAsset(selectedAsset)]);
    }, [selectedAsset]);

    const handleSelectedPage = (page) => {
        setEventPage(page);
    };

    const handleSelectedEventItemPage = (pageItem) => {
        setEventItemPage(pageItem);
    };

    const handleSelectEventDetailPage = (page, selectedEvent, selectedEventItemPage) => {
        setEventPage(page);
        if (selectedEvent) {
            selectedEventCategoryRef.current = selectedEvent;
        }
        if (selectedEventItemPage) {
            selectedEventItemPageRef.current = selectedEventItemPage;
        }
    };

    const renderEventDetails = () => {
        return isSmartSensor ?
            <EventDetailsSS
                {...props}
                setSelectedEvent={setSelectedEvent}
                setSelectedEventPage={handleSelectEventDetailPage}
                eventPage={eventPage}
                eventItemPage={eventItemPage}
            />
            :
            <EventDetailsCMD
                {...props}
                setSelectedEvent={setSelectedEvent}
                setSelectedEventPage={handleSelectEventDetailPage}
                eventPage={eventPage}
                eventItemPage={eventItemPage}
            />;
    };


    return (
        <div className='event-log-content'>
            {!props.model.selectedEvent ?
                <>
                    <EventLogFilter {...props}
                        isSS={isSmartSensor}
                        setSelectedAssets={setSelectedAssets}
                        setIsExapnded={setIsExapnded}
                    />
                    {assetCapabilityLoadingStatus === apiCallStatus.SUCCESS && <EventLogs
                        {...props}
                        isSS={isSmartSensor}
                        setSelectedEvent={setSelectedEvent}
                        setIsExapnded={setIsExapnded}
                        isExpanded={isExpanded}
                        assistanceCapability={
                            capability.hasAssistancePageCapability()
                        }
                        setSelectedEventPage={handleSelectedPage}
                        selectedEventPage={eventPage}
                        selectedEventCategory={selectedEventCategoryRef.current}
                        setSelectedEventItemPage={handleSelectedEventItemPage}
                        selectedEventItemPage={selectedEventItemPageRef.current}
                    />}
                </>
                : renderEventDetails()
            }
        </div>
    );
};

EventLogComponent.propTypes = {
    actions: PropTypes.shape({
        setSelectedEvent: PropTypes.func,
        loadCapabilitiesForAssets: PropTypes.func,
        setSelectedAssets: PropTypes.func,
        setIsExapnded: PropTypes.func
    }),
    asset: PropTypes.any,
    model: PropTypes.shape({
        selectedAsset: PropTypes.object,
        selectedOrganization: PropTypes.number,
        selectedEvent: PropTypes.object,
        generalFilteredAssets: PropTypes.array,
        isExpanded: PropTypes.bool,
        assetListCapabilityLoadingStatus: PropTypes.string,
        assetCapabilityLoadingStatus: PropTypes.string
    })
};


export default EventLogComponent;
