import { createSelector } from 'reselect';

const getSelectedAsset = ({ powertrainAssetDetails }) => {
    return powertrainAssetDetails.selectedAsset;
};

const getSelectedAssetV2 = ({ conditionIndices }) => {
    return conditionIndices?.assets;
};

const getIsInternalTabLimitActive = ({ operationalParametersV2 }) => {
    return operationalParametersV2?.isInternalTabLimitActive;
};

const getAssetActiveTab = ({ detailedInformation }) => {
    return detailedInformation?.activeTab;
};

export const getModel = createSelector([
    getSelectedAsset,
    getSelectedAssetV2,
    getIsInternalTabLimitActive,
    getAssetActiveTab
], (
    asset,
    assetV2,
    isInternalTabLimitActive,
    assetActiveTab
) => {
    return {
        asset,
        assetV2,
        isInternalTabLimitActive,
        assetActiveTab
    };
});
