import { ReportApi, CapabilityApi } from 'api/index';
import { getApiActionCreator } from 'helpers/actionHelper';
import { CAPABILITIES, apiCallStatus } from 'helpers/constants';
import { actionTypes } from './constants';
import { roleBasedCapabilityHelper } from 'helpers/capabilityHelper';

const getReportsAction = getApiActionCreator(actionTypes.GET_REPORTS);
const getReportCapabilitiesAction = getApiActionCreator(actionTypes.GET_REPORT_CAPABILITIES);

export const loadReports = () => {
    return (dispatch, getState) => {
        const organizations = getState().generalFilter.selectedFilters.organizations;
        dispatch(getReportsAction(apiCallStatus.LOADING));
        return ReportApi.getReports(organizations, roleBasedCapabilityHelper.hasExpertReportCapability())
            .then((reports) => {
                dispatch(getReportsAction(apiCallStatus.SUCCESS, reports));
            }, (err) => {
                dispatch(getReportsAction(apiCallStatus.ERROR, [], err));
            });
    };
};

export const loadReportCapabilities = (assets) => {
    return (dispatch, getState) => {
        const organizationId = getState().generalFilter.selectedFilters.organizations[0].organizationID;
        const assetIds = assets.map((asset) => asset.id);
        const capabilityCodes = [CAPABILITIES.assetInstantReport, CAPABILITIES.expertReportUpload, CAPABILITIES.deleteReport, CAPABILITIES.fleetOverviewReportDelete];
        return CapabilityApi.getCapabilities(organizationId, assetIds, capabilityCodes)
            .then((capabilities) => {
                dispatch(getReportCapabilitiesAction(apiCallStatus.SUCCESS, capabilities));
            }, (err) => {
                dispatch(getReportCapabilitiesAction(apiCallStatus.ERROR, [], err));
            });
    };
};
